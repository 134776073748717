import { useEffect } from "react";
import TitlePage from "../../components/titlePage/TitlePage";
import UploadPhotoGrid from "../../components/QuizGroup/ModesPage/UploadPhotoGrid";
import { useDispatch } from "react-redux";
import { setPageType } from "../../toolkit/slices/checkTypePageSlice";
import { getConfigResult } from "../../toolkit/slices/getConfigSlice";
import { Helmet } from "react-helmet-async";

const UploadPhoto = ({ pageType }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageType(pageType));
    dispatch(getConfigResult());
    window.scrollTo({ top: 0, behavior: "instant" });
  });
  return (
    <>
      <Helmet>
        <title>Interior Design AI Services</title>
        <meta property="og:title" content={"Interior Design AI Services"} />
        <meta name="twitter:title" content={"Interior Design AI Services"} />
        <meta
          name="description"
          content={`Choose "Inspiration" for creative ideas, "Redesign" to update layouts, or "Fill the Space (Staging)" to furnish vacant areas.`}
        />
        <meta
          property="og:description"
          content={`Choose "Inspiration" for creative ideas, "Redesign" to update layouts, or "Fill the Space (Staging)" to furnish vacant areas.`}
        />
        <meta
          name="twitter:description"
          content={`Choose "Inspiration" for creative ideas, "Redesign" to update layouts, or "Fill the Space (Staging)" to furnish vacant areas.`}
        />
      </Helmet>
      <div className="relative">
        <TitlePage
          title={`Choose mode`}
          h1
          lineBreak={0}
          size={"normal type"}
          animation={false}
        />

        {/* <SelectedHashtag /> */}
        <UploadPhotoGrid />
      </div>
    </>
  );
};

export default UploadPhoto;
