export const dataPins = {
  living_room: [
    {
      id: 1,
      active: false,
      visible: false,
      boundingBox: {
        xPercent: 8,
        yPercent: 15,
        wPercent: 10,
        hPercent: 10,
      },
      productInfo: {
        pos: 1,
        name: "Amanda Accent Lights",
        cost: "168$",
        image: "/img/lights-lr.png",
      },
    },
    {
      id: 100,
      active: false,
      visible: false,
      boundingBox: {
        xPercent: 53,
        yPercent: 59,
        wPercent: 10,
        hPercent: 10,
      },
      productInfo: {
        pos: 1,
        name: "Side Table",
        cost: "104$",
        image: "/img/table-lr.png",
      },
    },
    {
      id: 7,
      active: false,
      visible: false,
      boundingBox: {
        xPercent: 86,
        yPercent: 50,
        wPercent: 10,
        hPercent: 10,
      },
      productInfo: {
        pos: 0,
        name: "Sinatra Dining Chair",
        cost: "307$",
        image: "/img/chair-lr.png",
      },
    },
    {
      id: 10,
      active: false,
      visible: false,
      boundingBox: {
        xPercent: 65,
        yPercent: 31.5,
        wPercent: 10,
        hPercent: 10,
      },
      productInfo: {
        pos: 0,
        name: "IKEA KNAPPA",
        cost: "850$",
        image: "/img/sofa-lr.png",
      },
    },
  ],
  bedroom: [
    {
      id: 3,
      active: false,
      visible: false,
      boundingBox: {
        xPercent: 9,
        yPercent: 25,
        wPercent: 10,
        hPercent: 10,
      },
      productInfo: {
        pos: 1,
        name: "IKEA Light",
        cost: "64$",
        image: "/img/light-b.png",
      },
    },
    {
      id: 38,
      active: false,
      visible: false,
      boundingBox: {
        xPercent: 89,
        yPercent: 42,
        wPercent: 10,
        hPercent: 10,
      },
      productInfo: {
        pos: 0,
        name: "IKEA Pair Lights",
        cost: "139$",
        image: "/img/light-b-b.png",
      },
    },
    {
      id: 100,
      active: false,
      visible: false,
      boundingBox: {
        xPercent: 45,
        yPercent: 35,
        wPercent: 10,
        hPercent: 10,
      },
      productInfo: {
        pos: 1,
        name: "IKEA Bed",
        cost: "509$",
        image: "/img/bed-b.png",
      },
    },
  ],
};
