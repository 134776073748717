import React from 'react';

const PreviewEmail = ({ setNameForm }) => {
  const email = localStorage.getItem('email');
  // console.log('email :>> ', email);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData);
    // Add further processing or API call here
  };
  // const hideEmail = (val) => {
  //   const inputString = val;
  //   const regex = /^(.{2}).*?(@.{2}).*$/;
  //   const replacement = '$1***$2****';
  //   const maskedString = inputString.replace(regex, replacement);

  //   return maskedString;
  // };
  return (
    <>
      <div className="title">Confidential information</div>
      <form className="account" onSubmit={handleSubmit}>
        <div className="inputBox">
          <div className="title">your Email</div>
          <input type="email" placeholder="Username" value={email} disabled />
        </div>
        {/* <button className="" onClick={() => setNameForm('pinEmail')}>
          change email
        </button> */}
        <button className="transparent" onClick={() => setNameForm('password')}>
          change password
        </button>
      </form>
    </>
  );
};

export default PreviewEmail;
