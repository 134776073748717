import React from "react";
import InputForm from "../../input/InputForm";
// import ArrowBack from '../../icon/ArrowBack';
import { useDispatch, useSelector } from "react-redux";
import { postResetPasswordConfirm } from "../../../toolkit/slices/authorization/resetPasswordSlice";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import cl from "./AuthComponent.module.css";

const ChangePasswordForm = () => {
  const errorMessage = useSelector((state) => state.resetPassword.errorMessage);

  const schema = z
    .object({
      newPassword: z.string().min(8, "Password must be at least 8 characters"),
      repeatPassword: z
        .string()
        .min(8, "Password must be at least 8 characters"),
    })
    .refine((data) => data.repeatPassword === data.newPassword, {
      path: ["repeatPassword"],
      message: "The passwords did not match",
    });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = (data) => {
    dispatch(
      postResetPasswordConfirm({
        new_password1: data.newPassword,
        new_password2: data.repeatPassword,
      })
    );
  };

  const dispatch = useDispatch();

  return (
    <div className="form">
      <div className="title mt-24">New password</div>
      <form onSubmit={handleSubmit(onSubmit)} className={cl.formChange}>
        <InputForm
          title={
            errors.newPassword?.message
              ? errors.newPassword?.message
              : errorMessage
              ? errorMessage
              : "Enter your password"
          }
          type={"password"}
          placeholder={"password"}
          name={"newPassword"}
          id={"password"}
          register={register}
          error={errors.newPassword?.message || errorMessage ? false : true}
          // {...register('newPassword')}
        />
        <InputForm
          title={
            errors.repeatPassword?.message
              ? errors.repeatPassword?.message
              : errorMessage
              ? errorMessage
              : "Confirm your password"
          }
          type={"password"}
          placeholder={"password"}
          name={"repeatPassword"}
          id={"passwordConfirm"}
          error={errors.repeatPassword?.message || errorMessage ? false : true}
          register={register}
          // {...register('repeatPassword')}
        />

        <button
          type="submit"
          className="button green"
          // onClick={() => resetPasswordHandle(changePassword1, changePassword2)}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default ChangePasswordForm;
