import { api } from "./api";

export const widgetApi = api.injectEndpoints({
  endpoints: (build) => ({
    contact: build.mutation({
      query: (data) => ({
        url: "/users/call-to-action/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Contact" }],
    }),
  }),
});

export const { useContactMutation } = widgetApi;
