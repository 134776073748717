import React from "react";
// import { Link } from 'react-router-dom';
import UserIcon from "../icon/UserIcon";
import LogOutIcon from "../icon/LogOutIcon";
import { useDispatch } from "react-redux";
import { setActiveAuthModal } from "../../toolkit/slices/authorization/authorizationModalSlice";
import { useNavigate } from "react-router-dom";
import { gtmEvent } from "../../lib/gtm";

const LoginButton = ({ textValue, pageType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logOut = () => {
    localStorage.removeItem("key");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    localStorage.removeItem("data");
    localStorage.removeItem("user");
    localStorage.removeItem("stripeLink");
    dispatch(setActiveAuthModal(false));
    navigate("/", { replace: true });
  };

  return (
    <>
      <button
        onClick={
          pageType === "account"
            ? () => {logOut(); localStorage.removeItem("num_fill")}
            : () => {
                dispatch(setActiveAuthModal(true));
                gtmEvent("LOG_IN_SIGN_UP", "category");
              }
        }
        className="buttonMenu login"
      >
        <div className="icon">
          {pageType === "account" ? <LogOutIcon /> : <UserIcon />}
        </div>
        {textValue ? <div className="text">{textValue}</div> : null}
      </button>
    </>
  );
};

export default LoginButton;
