import React, { useEffect, useRef } from 'react';
import './projectModal.css';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getModal } from '../../toolkit/slices/getResultImageSlice';
import { motion } from 'framer-motion';
import { closeModalOutside } from '../../lib/scrypts';
import CloseModalIcon from '../icon/CloseModalIcon';
import TitlePage from '../titlePage/TitlePage';
import EmailIcon from '../icon/EmailIcon';
import { setEmail, setIsAuth } from '../../toolkit/slices/createProjectSlice';

const VerifyEmailModal = () => {
  const ref = useRef();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(getModal(false));
    dispatch(setIsAuth(true));
    dispatch(setEmail(''));
  };

  const variants = {
    hidden: {
      y: '30px',
      opacity: 0,
    },
    visible: {
      y: '0px',
      opacity: 1,
      transition: { type: 'Tween', delay: 0.1 },
    },
  };

  useEffect(()=>{
    window.scrollTo({top:0, behavior: 'smooth'})
  },[])

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      className="projectModal checkEmail"
      onClick={(e) => closeModalOutside(e, ref, navigate, '/result')}>
      <motion.div variants={variants} className="content" ref={ref}>
        <Link to={'/'} className="closeModal" onClick={() => closeModal()}>
          <CloseModalIcon />
        </Link>
        <div className="iconRound">
          <div className="icon">
            <EmailIcon />
          </div>
        </div>
        <TitlePage
          title={'We have sent a link to verify your email'}
          lineBreak={5}
          size={'normal'}
          animation={true}
        />
        <div className="subTitle">Check your mail and get back to creating beautiful interiors</div>
      </motion.div>
    </motion.div>
  );
};

export default VerifyEmailModal;
