import React from 'react';
import EmailIcon from '../../icon/EmailIcon';
import TitlePage from '../../titlePage/TitlePage';

const ConfirmModal = ({ title, lineBreak, subTitle }) => {
  return (
    <div className="confirmContent">
      <div className="iconRound">
        <div className="icon">
          <EmailIcon />
        </div>
      </div>
      <TitlePage title={title} lineBreak={lineBreak} size={'normal'} animation={true} />
      {subTitle}
    </div>
  );
};

export default ConfirmModal;
