export const TimeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    {...props}
  >
    <path
      d="M5 6.5V4.5M9.5 3L8.5 2M4 1H6M5 10.5C3.93913 10.5 2.92172 10.0786 2.17157 9.32843C1.42143 8.57828 1 7.56087 1 6.5C1 5.43913 1.42143 4.42172 2.17157 3.67157C2.92172 2.92143 3.93913 2.5 5 2.5C6.06087 2.5 7.07828 2.92143 7.82843 3.67157C8.57857 4.42172 9 5.43913 9 6.5C9 7.56087 8.57857 8.57828 7.82843 9.32843C7.07828 10.0786 6.06087 10.5 5 10.5Z"
      stroke="#686A6C"
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
