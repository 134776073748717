import React from "react";
import cl from './StyleMoodResult.module.css';
import CarouselComponent from "../styleSwiper/StyleSwiper";

const StyleMoodResult = ({ data }) => {
  return (
    <section className="container">
      <div className={cl.resultSection}>
        <p className={cl.resultTitle}>
          Based on our super fancy algorithm™, your main style is
          <br />
          {data.short_response}
        </p>
        {/* <p className="result_link">
          I don’t like it, I want to take the test again
        </p> */}
      </div>
      <CarouselComponent />
      <div className={cl.resultAboutStyle}>
        <h5 className={cl.resultNameStyle}> {data.short_response}</h5>
        <p className={cl.resultDescr}>{data.long_response}</p>
      </div>
    </section>
  );
};

export default StyleMoodResult;
