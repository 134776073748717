import React, { useRef } from "react";
import "./projectModal.css";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getModal } from "../../toolkit/slices/getResultImageSlice";
import { motion } from "framer-motion";
import { closeModalOutside } from "../../lib/scrypts";
import CloseModalIcon from "../icon/CloseModalIcon";
import TitlePage from "../titlePage/TitlePage";
import {
  setEmail,
  setErrorMessage,
  setIsAuth,
} from "../../toolkit/slices/createProjectSlice";
import CryingSmileIcon from "../icon/CryingSmileIcon";
import Button from "../button/Button";
import {
  setActiveAuthModal,
  setActiveModalName,
  setActiveModalPrice,
} from "../../toolkit/slices/authorization/authorizationModalSlice";

const AttemptsOverModal = ({
  title,
  lineBreak,
  isButton = true,
  topUp = false,
  setIsActive = null,
  link,
}) => {
  const ref = useRef();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const closeModal = () => {
    if (setIsActive) setIsActive(false);
    dispatch(setEmail(""));
    dispatch(setErrorMessage(""));
    dispatch(getModal(false));
    dispatch(setIsAuth(true));
  };

  const variants = {
    hidden: {
      y: "30px",
      opacity: 0,
    },
    visible: {
      y: "0px",
      opacity: 1,
      transition: { type: "Tween", delay: 0.1 },
    },
  };

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      className="projectModal checkEmail"
      onClick={(e) => {
        if (setIsActive) setIsActive(false);
        closeModalOutside(
          e,
          ref,
          navigate,
          topUp ? "/profile/billing/" : "/interior-ai/result"
        );
      }}
    >
      <motion.div variants={variants} className="content" ref={ref}>
        <Link to={"/"} className="closeModal" onClick={() => closeModal()}>
          <CloseModalIcon />
        </Link>
        {!link && (
          <div className="iconRound">
            <div className="icon">
              <CryingSmileIcon />
            </div>
          </div>
        )}
        {/* eslint-disable */}

        <TitlePage
          title={title}
          lineBreak={lineBreak}
          size={"normal"}
          animation={true}
        />
        {isButton ? (
          <div className="row center">
            {topUp ? (
              <Button
                link={"/profile/billing/"}
                color={"green"}
                text={"TOP UP"}
              />
            ) : link ? (
                  <Button
                    link={link}
                    target={"_blank"}
                    color={"green"}
                    text={"Take the survey"}
                  />
            ) : (
              <Button
                link={""}
                color={"green"}
                text={"Sing up"}
                onClick={() => {
                  dispatch(setEmail(""));
                  dispatch(setErrorMessage(""));
                  dispatch(setActiveAuthModal(true));
                  dispatch(setActiveModalName("Create account"));
                  dispatch(setActiveModalPrice(true));
                }}
              />
            )}
          </div>
        ) : (
          false
        )}
      </motion.div>
    </motion.div>
  );
};

export default AttemptsOverModal;
