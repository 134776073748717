import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postLogin } from '../../../toolkit/slices/authorization/logInSlice';
import InputForm from '../../input/InputForm';
import ConfirmIcon from '../../icon/confirm/ConfirmIcon';
import TitlePage from '../../titlePage/TitlePage';
import StripeIcon from '../../icon/StripeIcon';
import { useSearchParams } from 'react-router-dom';

const LoginStripeForm = ({
  emailOnChange,
  passwordOnChange,
  userEmail,
  userPassword,
  forgotPasswordToggle,
}) => {
  const dispatch = useDispatch();

  const logInHandle = (email, password) => {
    dispatch(postLogin({ email: email, password: password }));
  };
  const errorMessage = useSelector((state) => state.login.errorMessageLoginUser);

  const handleSubmit = (e) => {
    e.preventDefault();
    logInHandle(userEmail, userPassword);
  };

  const [urlParams] = useSearchParams();
  const user_id = urlParams.get('user_id');

  return (
    <form className={errorMessage ? 'form error' : 'form'} onSubmit={handleSubmit}>
      <div className="iconRound">
        <div className="icon">
          <ConfirmIcon />
        </div>
      </div>
      <TitlePage
        title={'Well done [break] just one more step'}
        lineBreak={1}
        size={'normal'}
        animation={true}
      />
      <div className="subTitle">
        You can <u> Log In </u> now and continue enjoying the best inspiration AI interior design
        tool
      </div>
      <div className="subTitle">
        You <b>won’t be charged</b> now. You can cancel your subscription any time before trial
        period ends with no fee
      </div>
      <InputForm
        title={'Enter your Email'}
        type={'email'}
        placeholder={'example@mail.com'}
        name={'email'}
        id={'email'}
        onChangeHandle={emailOnChange}
        value={userEmail}
        error={errorMessage ? false : true}
      />
      <InputForm
        title={'Enter your password'}
        type={'password'}
        placeholder={'password'}
        name={'password'}
        id={'password'}
        onChangeHandle={passwordOnChange}
        value={userPassword}
        error={errorMessage ? false : true}
      />
      <div className="forgotPassword" onClick={() => forgotPasswordToggle('Forgot password')}>
        Forgot password?
      </div>
      <button type="submit" className="button green">
        {user_id ? (
          <>
            pay with <StripeIcon />
          </>
        ) : (
          'Log in'
        )}
      </button>
      <div className="errorFooter">{errorMessage ? errorMessage : ''}</div>
    </form>
  );
};

export default LoginStripeForm;
