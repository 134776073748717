import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { setActiveModalName } from "./authorizationModalSlice";
import { postPaymentProduct, postPaymentSubscription } from "./stripeSlice";
import { gtmEvent } from "../../../lib/gtm";
import { setSubscriptionAll, setSubscriptionDateEnd, setSubscriptionPlan } from "./subscriptionPlanSlice";

const initialState = {
  isActiveAuthModal: false,
  createUserSuccessful: false,
  errorMessage: "",
  plan: "Monthly",
  planValue: 0,
  price: "19",
  priceOld: "49",
  stripeLink: "",
  productInfo: "pro",
};

export const postCreateUser = createAsyncThunk(
  "postCreateUser/postCreateUser",
  async (data, { dispatch, getState }) => {
    let loginData = {};
    let dataUser = data[0];
    let active_tab = data[1];

    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/rest-auth/registration/`,
        dataUser,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        loginData = response.data;
        //console.log('registration :>> ', response.data);
        dispatch(setStripeLink(""));
        dispatch(setErrorMessage(""));
        dispatch(setSubscriptionPlan(""));
        dispatch(setSubscriptionDateEnd(""));
        dispatch(setSubscriptionAll([]));

        if (loginData.user_data.is_email_confirmed === true) {
          localStorage.setItem("key", loginData.key);
          localStorage.setItem("email", loginData.user_data.email);
          localStorage.setItem("name", loginData.user_data.first_name);
          localStorage.setItem("user", loginData.user_data.id);
          localStorage.setItem("data", JSON.stringify(loginData.user_data));
        }
        dispatch(setErrorMessage(""));
        gtmEvent("SIGN UP", "category");
      })
      .catch(function (error) {
        // console.log('error.response.data :>> ', error.response.data);
        const textMessage = String(Object.values(error.response.data));
        // dispatch(setErrorMessage(textMessage));
        if (textMessage === "Password field is required.") {
          dispatch(setErrorMessage("User already exists"));
        } else {
          dispatch(setErrorMessage(textMessage));
        }

        throw error;
      });

    let planValue = getState().singin.planValue;
    await axios
      .patch(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/rest-auth/user/`,
        {
          first_name: data.name,
          subscription_type: planValue,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${loginData.key}`,
          },
        }
      )
      .then((response) => {
        // dispatch(setStripeLink('Username'));
        if (!response.data.is_paid && response.data.is_email_confirmed) {
          if (active_tab.active_tab === "pro") {
            dispatch(
              postPaymentSubscription({
                key: localStorage.getItem("key"),
                subscription_type: response.data.subscription_type,
              })
            );
          } else if (active_tab.active_tab === "personal") {
            dispatch(
              postPaymentProduct({
                key: localStorage.getItem("key"),
                amount: `${getState().rangeVisionSlice.valueMoney * 10}`, //getState().rangeVisionSlice.valueMoney;
                name: `${
                  getState().rangeVisionSlice.valueVisions
                } visualisation`, //getState().rangeVisionSlice.valueVisions;
              })
            );
          }
        }
        dispatch(setErrorMessage(""));
        setTimeout(() => {
          if (loginData.user_data.is_email_confirmed) {
            dispatch(setActiveModalName("Update your account"));
          } else {
            dispatch(setActiveModalName("Verify email"));
          }
        }, 1800);
      })
      .catch(function (error) {
        console.log("error.response.data :>> ", error.response.data);
      });
  }
);

export const signInSlice = createSlice({
  name: "singin",
  initialState,
  reducers: {
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setCreateUserSuccessful: (state, action) => {
      state.createUserSuccessful = action.payload;
    },
    setPlan: (state, action) => {
      state.plan = action.payload;
    },
    setPlanValue: (state, action) => {
      state.planValue = action.payload;
    },
    setPrice: (state, action) => {
      state.price = action.payload;
    },
    setPriceOld: (state, action) => {
      state.priceOld = action.payload;
    },
    setStripeLink: (state, action) => {
      state.stripeLink = action.payload;
    },
    setProductInfo: (state, action) => {
      state.productInfo = action.payload.toLowerCase();
    },
  },
});

export const {
  setErrorMessage,
  setCreateUserSuccessful,
  setPlan,
  setPrice,
  setPriceOld,
  setPlanValue,
  setStripeLink,
  setProductInfo,
} = signInSlice.actions;
export default signInSlice.reducer;
