import React, { useEffect, useState } from "react";
import "./uploadPhoto.css";
import { useDispatch, useSelector } from "react-redux";
import ChoiceButton from "../../button/ChoiceButton";
import { checkPathname } from "../../../lib/scrypts";
import UploadPhotoBlock from "./UploadPhotoBlock";
import {
  setImageBase64,
  setImageFileName,
  setImageFileSize,
  setImageHeight,
  setImagePaintBase64,
  setImageWidth,
  setTypeUploadPhoto,
} from "../../../toolkit/slices/createProjectSlice";
import {
  setPromt,
  setPromtNegative,
} from "../../../toolkit/slices/getConfigSlice";
// import { MyModal } from "../../UI/MyModal/MyModal";
// import cl from "./UploadPhoto.module.css";
// import { useRef } from "react";
import { DrawModal } from "../../modal/DrawModal/DrawModal";
import { gtmEvent } from "../../../lib/gtm";
import AttemptsOverModal from "../../modal/AttemptsOverModal";

const UploadPhotoGrid = () => {
  // useTopToPage()

  const [optionsData] = useState([
    {
      id: 0,
      title: "Inspiration",
      subTitle: "Get the inspiration image from the scratch",
      classBG: "first",

      promptConfig: "prompt",
      promptNegativeConfig: "negative_prompt",
    },
    {
      id: 2,
      title: "Redesign",
      subTitle: "Update the layout with new styles",
      classBG: "third",
      promptConfig: "prompt_image_to_image_redesign",
      promptNegativeConfig: "negative_prompt_image_to_image_redesign",
    },
    {
      id: 1,
      title: "Fill the space (Staging)",
      subTitle: "Furnish your vacant area with furniture",
      classBG: "second",
      promptConfig: "prompt_image_to_image",
      promptNegativeConfig: "negative_prompt_image_to_image",
    },
  ]);

  const dispatch = useDispatch();
  const hastagArr = useSelector((state) => state.roomType.hastagArr);
  const promptConfig = useSelector((state) => state.config.allPromt);
  const imageBase64 = useSelector((state) => state.createProject.imageBase64);
  // const imagePaintBase64 = useSelector(state => state.createProject.imagePaintBase64);
  const uploadType = useSelector((state) => state.createProject.uploadType);
  const [isModalDraw, setIsModalDraw] = useState(false);
  // const imageRef = useRef(null);
  const pageLink = window.location.pathname.slice(1);
  let activeCardName = hastagArr[checkPathname(pageLink)]["name"];
  const activeItem = Number(uploadType);
  const [activeContinue, setActiveContinue] = useState(false);
  // const userInfo = useSelector((state) => state.getUser.userData);
  // const subscriptionAll = useSelector(
  //   (state) => state.subscriptionPlan.subscriptionAll
  // );

  const handleItemClick = (id, item, image, promt, promptNegative) => {
    // if (id === 1) {
    //   if (checkFillSpaceNums()) {
    //     setIsAttempts(true);
    //     return;
    //   }
    // }
    if (uploadType !== id) {
      dispatch(setTypeUploadPhoto(id));
      dispatch(setImageBase64(""));
      dispatch(setImageHeight(0));
      dispatch(setImageWidth(0));
      dispatch(setImagePaintBase64(""));
      setIsModalDraw(false);
      dispatch(setImageFileName(""));
      dispatch(setImageFileSize(""));
      dispatch(setPromt(promt));
      dispatch(setPromtNegative(promptNegative));
    }
  };

  useEffect(() => {
    if (activeItem === 0) {
      setActiveContinue(true);
    } else if (imageBase64 && activeItem) {
      setActiveContinue(true);
    } else {
      setActiveContinue(false);
    }

    const getParamsFilter = {
      0: "prompt",
      1: "prompt_image_to_image",
      2: "prompt_image_to_image_redesign",
    };
    const getParamsNegativeFilter = {
      0: "negative_prompt",
      1: "negative_prompt_image_to_image",
      2: "negative_prompt_image_to_image_redesign",
    };
    dispatch(setPromt(promptConfig[getParamsFilter[activeItem]]));
    dispatch(
      setPromtNegative(promptConfig[getParamsNegativeFilter[activeItem]])
    );
  }, [imageBase64, activeItem, promptConfig, dispatch]);

  const handleSave = (image) => {
    console.log(image);
    dispatch(setImagePaintBase64(image));
    setIsModalDraw(false);
  };

  const handleContinue = () => {
    let gtmContinue = {
      0: "Step1_Inspiration_Continue",
      1: "Step1_Space_Continue",
      2: "Step1_Redesign_Continue",
    };
    gtmEvent(gtmContinue[activeItem], "category");
  };

  const handleSkip = () => {
    let gtmContinue = {
      0: "Step1_Inspiration_Skip",
      1: "Step1_Space_Skip",
      2: "Step1_Redesign_Skip",
    };
    gtmEvent(gtmContinue[activeItem], "category");
  };

  // function checkFillSpaceNums() {
  //   let curNum = localStorage.getItem("num_fill");
  //   if (curNum) {
  //     let activeSubscription = subscriptionAll.find((e) => e.status);
  //     if (
  //       activeSubscription && activeSubscription.start && activeSubscription.end
  //         ? +curNum >= 10
  //         : userInfo.is_paid
  //         ? +curNum >= 1
  //         : +curNum >= 1
  //     ) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  const [isAttempts, setIsAttempts] = useState(false);

  // console.log(isModalDraw && activeItem === 1, isModalDraw, activeItem === 1)

  return (
    <>
      {isAttempts ? (
        <AttemptsOverModal
          title={`Sorry, your Fill the Space (Staging) attempts are over`}
          topUp
          isActive={isAttempts}
          setIsActive={setIsAttempts}
          lineBreak={4}
        />
      ) : null}
      <DrawModal
        image={imageBase64}
        handleSave={handleSave}
        isActive={isModalDraw && activeItem === 1}
        setIsActive={setIsModalDraw}
      />
      <div className="container">
        <div className="info">Select one of the proposed modes</div>
        <div className="relative">
          <div className="row center chooseGrid">
            {optionsData.map((item, i) => (
              <UploadPhotoBlock
                key={item.id}
                id={item.id}
                token={i+1}
                title={item.title}
                subTitle={item.subTitle}
                handleItemClick={handleItemClick}
                active={activeItem}
                setIsModalDraw={setIsModalDraw}
                uploadZone={item.id !== 0}
                classBG={item.classBG}
                promptConfig={promptConfig[item.promptConfig]}
                promptNegativeConfig={promptConfig[item.promptNegativeConfig]}
              />
            ))}
          </div>
          <ChoiceButton
            active={activeCardName}
            backLink={"/interior-ai/"}
            continueLink={"/interior-ai/room-type/"}
            isActive={activeContinue}
            skip={true}
            skipLink={"/interior-ai/room-type/"}
            skipValue={"none"}
            handleSkip={handleSkip}
            handleContinue={handleContinue}
          />
        </div>
      </div>
    </>
  );
};

export default UploadPhotoGrid;
