import React from 'react';
import InputForm from '../../input/InputForm';
import { useDispatch, useSelector } from 'react-redux';
import { postLogin } from '../../../toolkit/slices/authorization/logInSlice';
import GoogleLoginButton from '../../menu/GoogleLoginButton';
import FacebookLoginButton from '../../menu/FacebookLoginButton';
import { gtmEvent } from '../../../lib/gtm';
import { useSearchParams } from 'react-router-dom';
// import { getUser } from '../../../toolkit/slices/authorization/getUserSlice';

const LoginForm = ({
  emailOnChange,
  passwordOnChange,
  userEmail,
  userPassword,
  forgotPasswordToggle,
}) => {
  const dispatch = useDispatch();

  const logInHandle = (email, password) => {
    dispatch(postLogin({ email: email, password: password }));
  };
  const errorMessage = useSelector((state) => state.login.errorMessageLoginUser);

  const handleSubmit = (e) => {
    e.preventDefault();
    logInHandle(userEmail, userPassword);
    gtmEvent('LOG_IN', 'category');
  };

  const [urlParams] = useSearchParams();

  return (
    <>
      <form className={errorMessage ? 'form error' : 'form'} onSubmit={handleSubmit}>
        <InputForm
          title={'Enter your Email'}
          type={'email'}
          placeholder={'example@mail.com'}
          name={'email'}
          id={'email'}
          onChangeHandle={emailOnChange}
          value={userEmail}
          error={errorMessage ? false : true}
        />
        <InputForm
          title={'Enter your password'}
          type={'password'}
          placeholder={'password'}
          name={'password'}
          id={'password'}
          onChangeHandle={passwordOnChange}
          value={userPassword}
          error={errorMessage ? false : true}
        />
        <div className="forgotPassword" onClick={() => forgotPasswordToggle('Forgot password')}>
          Forgot password?
        </div>
        <button type="submit" className="button green">
          {urlParams.get('user_id') ? 'Pay' : 'Log in'}
        </button>

        <div className="errorFooter">{errorMessage ? errorMessage : ''}</div>
      </form>
      <div className="socialButton">
        <div className="line">Or</div>
        <GoogleLoginButton />
        <FacebookLoginButton />
      </div>
    </>
  );
};

export default LoginForm;
