import React, { useState } from 'react';
import './accountForms.css';
import NameForm from './NameForm';
import PreviewEmail from './PreviewEmail';
import PasswordForm from './PasswordForm';
import PinEmail from './PinEmail';

const AccountForms = () => {
  const [nameFrom, setNameForm] = useState('');
  return (
    <div className="account row">
      <div className="col">
        <div className="title">Public information</div>
        <NameForm />
      </div>
      <div className="col">
        {!nameFrom ? (
          <PreviewEmail setNameForm={setNameForm} />
        ) : nameFrom === 'password' ? (
          <PasswordForm setNameForm={setNameForm} />
        ) : nameFrom === 'pinEmail' ? (
          <PinEmail setNameForm={setNameForm} />
        ) : (
          nameFrom === 'email' && ''
        )}
      </div>
    </div>
  );
};

export default AccountForms;
