import React from "react";

const TagsMarkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M1.7812 18.4062L8.31245 18.4062C8.96557 18.4062 9.87757 18.0286 10.3395 17.5667L18.1604 9.74581C18.6223 9.28387 18.6223 8.52803 18.1604 8.06669L10.9333 0.839562C10.4713 0.377625 9.71548 0.377625 9.25413 0.839562L1.43326 8.66044C0.97132 9.12238 0.593695 10.0344 0.593695 10.6875V17.2187C0.593695 17.8719 1.12807 18.4062 1.7812 18.4062ZM13.4822 9.08022C13.7144 9.31237 13.7144 9.68762 13.4822 9.91978L9.32538 14.0766C9.09323 14.3088 8.71798 14.3088 8.48582 14.0766C8.25366 13.8445 8.25366 13.4692 8.48582 13.2371L12.6427 9.08022C12.8748 8.84806 13.2507 8.84806 13.4822 9.08022ZM11.701 7.29897C11.9331 7.53112 11.9331 7.90638 11.701 8.13853L7.54413 12.2954C7.31198 12.5275 6.93673 12.5275 6.70457 12.2954C6.47241 12.0632 6.47241 11.688 6.70457 11.4558L10.8614 7.29897C11.0936 7.06681 11.4694 7.06681 11.701 7.29897ZM4.92391 9.67397L9.08076 5.51712C9.31291 5.28497 9.68816 5.28497 9.92032 5.51712C10.1525 5.74928 10.1525 6.12453 9.92032 6.35669L5.76348 10.5135C5.53132 10.7457 5.15607 10.7457 4.92391 10.5135C4.69176 10.282 4.69176 9.90613 4.92391 9.67397ZM2.9687 14.8437C3.62479 14.8437 4.1562 15.3752 4.1562 16.0312C4.1562 16.6873 3.62479 17.2188 2.9687 17.2187C2.3126 17.2187 1.7812 16.6873 1.7812 16.0312C1.7812 15.3752 2.3126 14.8437 2.9687 14.8437Z"
        fill="black"
      />
    </svg>
  );
};

export default TagsMarkIcon;
