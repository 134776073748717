import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { TimeIcon } from "../../icon/Time";
import Skeleton from "react-loading-skeleton";
import { formatDate } from "../../../helpers/blogFormatData";
import cl from "./BlogsComponent.module.css";

export const BlogItem = ({ blog, setFilter }) => (
  <li className={cl.blogItem} key={blog.id}>
    <Link to={"/blogs/" + blog.slug + "/"} className={cl.linkWrapper}>
      <LazyLoadImage
        src={blog.meta_image}
        alt="Blog Image"
        className={cl.imageItem}
      />
    </Link>
    <div className={cl.tagsBlock}>
      {/* TAGS */}
      <div className={cl.tags}>
        {blog.tags.map((tag, i) => (
          <span
            className={cl.tag}
            key={i}
            onClick={(e) => setFilter(tag)}
          >
            {tag}
          </span>
        ))}
      </div>
      <span className={cl.blogPublished}>{formatDate(blog.published_at)}</span>
    </div>
    <Link to={"/blogs/" + blog.slug + "/"} className={cl.linkWrapper}>
      <h2 className={cl.titleItem}>{blog.title}</h2>
    </Link>
    <p className={cl.descItem}>{blog.description}</p>
    <span className={cl.readingTime}>
      <TimeIcon />
      {blog.reading_time}
      &nbsp;min. read
    </span>
  </li>
);

export const BlogSkeletonItem = () => (
  <li className={cl.blogItem}>
    <Skeleton className={cl.imageItem} />
    <div className={cl.tagsBlock}>
      <div className={cl.tags}>
        <Skeleton className={cl.tagSkeleton} />
      </div>
      <Skeleton className={cl.blogPublishedSkeleton} />
    </div>
    <Skeleton className={cl.titleItem} />
    <Skeleton className={cl.descItem} />
    <Skeleton className={cl.readingTime} />
  </li>
);
