import React, { useState } from 'react';
import './input.css';
import PasswordHideIcon from '../icon/PasswordHideIcon';
import PasswordShowIcon from '../icon/PasswordShowIcon';

const InputForm = ({ title, type, placeholder, name, id, onChangeHandle, value, error, register = null }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const showPasswordToggle = () => {
    setShowPassword((showPassword) => !showPassword);
    setShowPasswordConfirm((showPasswordConfirm) => !showPasswordConfirm);
  };

  if (register) {
    return (
      <label className={error === false ? 'inputBlock error' : 'inputBlock '}>
        <p className="title">{title}</p>
        <input
          type={showPassword & (type === 'password') ? 'text' : type}
          className="input"
          placeholder={placeholder}
          autoComplete="on"
          name={name}
          id={id}
          onChange={(e) => onChangeHandle(e.target.value)}
          value={value}
          {...register(name)}
        />
        <i onClick={() => showPasswordToggle()}>
          {showPassword & (id === 'password') || showPasswordConfirm & (id === 'passwordConfirm') ? (
            <PasswordHideIcon />
          ) : !showPassword & (id === 'password') ||
            !showPasswordConfirm & (id === 'passwordConfirm') ? (
            <PasswordShowIcon />
          ) : (
            ''
          )}
        </i>
      </label>
    );
  }
  return (
    <label className={error === false ? 'inputBlock error' : 'inputBlock '}>
      <p className="title">{title}</p>
      <input
        type={showPassword & (type === 'password') ? 'text' : type}
        className="input"
        placeholder={placeholder}
        autoComplete="on"
        name={name}
        id={id}
        onChange={(e) => onChangeHandle(e.target.value)}
        value={value}
      
      />
      <i onClick={() => showPasswordToggle()}>
        {showPassword & (id === 'password') || showPasswordConfirm & (id === 'passwordConfirm') ? (
          <PasswordHideIcon />
        ) : !showPassword & (id === 'password') ||
          !showPasswordConfirm & (id === 'passwordConfirm') ? (
          <PasswordShowIcon />
        ) : (
          ''
        )}
      </i>
    </label>
  );
};

export default InputForm;
