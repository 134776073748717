const transformNameToFilename = (name) => {
  return name.replace(/(_| )/gi, "-").toLowerCase();
};

export const createListOfImages = (roomType, mode) => {
  let typeMode =
    roomType !== "Living room" && mode === "fill_the_space" ? "redesign" : mode;
  return [
    typeMode === "inspiration"
      ? null
      : {
          path: `/img/designs/${transformNameToFilename(
            roomType
          )}/${transformNameToFilename(typeMode)}-original.webp`,
        },
    {
      path: `/img/designs/${transformNameToFilename(
        roomType
      )}/${transformNameToFilename(typeMode)}.webp`,
    },
  ].filter((e) => e !== null);
};
