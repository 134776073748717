import { FC, useLayoutEffect, useRef, useState } from "react";
import cl from "./HeroStaging.module.css";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/all";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { Link } from "react-router-dom";
import { gtmEvent } from "../../../lib/gtm";
import CheckModeButtons from "../../landingPage/CheckModeButtons";
import { dataPins } from "./dataPins";
import { setTypeUploadPhoto } from "../../../toolkit/slices/createProjectSlice";
import AttemptsOverModal from "../../modal/AttemptsOverModal";
import { useDispatch } from "react-redux";
import TitlePage from "../../titlePage/TitlePage";
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

interface HeraStagingProps {}

const HeroStaging: FC<HeraStagingProps> = () => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const [isAttempts, setIsAttempts] = useState(false);
  const bedroomRef = useRef<HTMLDivElement>(null);
  const lrRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const [modeList] = useState([
    { id: 1, name: "Living room", type_mode: "living_room", active: false },
    { id: 0, name: "Bedroom", type_mode: "bedroom", active: true },
  ]);

  const [pinsState, setPinsState] = useState<DataPins>(dataPins);

  useLayoutEffect(() => {
    if (sectionRef.current) {
      const comp = sectionRef.current;
      const ctx = gsap.context(() => {
        const titleEls = [
          ...comp.querySelectorAll("." + cl.titleText),
        ] as HTMLElement[];
        titleEls.forEach((el, i) => {
          gsap.fromTo(
            el,
            { opacity: 0, y: "10vh" },
            {
              y: "-1vh",
              opacity: 1,
              duration: 1,
              ease: "power1.inOut",
              delay: i * 0.35,
            }
          );
        });
      }, comp);
      return () => ctx.revert();
    }
  }, []);

  const checkAttempts = (type = 0) => {
    switch (type) {
      case 0: {
        return true;
      }
      case 1: {
        return true;
      }
      case 2: {
        return true;
      }
      default:
        return false;
    }
  };

  const handleClickArea = (el: IPin, type: "bedroom" | "living_room") => {
    setPinsState({
      ...pinsState,
      [type]: pinsState[type].map<IPin>((item) =>
        item.id === el.id
          ? { ...item, active: !item.active }
          : { ...item, active: false }
      ),
    } as DataPins);
  };

  const selectType = (val: InputEvent) => {
    const activeType = (val.target! as HTMLDivElement).getAttribute(
      "data-type-mode"
    ) as "bedroom" | "living_room";
    const compB = bedroomRef.current;
    const compL = lrRef.current;
    let widthAnim = window.innerWidth * 0.33;

    if (activeType === "bedroom") {
      gsap.fromTo(
        compB,
        { opacity: 0, pointerEvents: "none", x: widthAnim },
        {
          opacity: 1,
          pointerEvents: "all",
          x: 0,
          duration: 0.55,
          ease: "power1.out",
          delay: 0.35,
        }
      );
      gsap.fromTo(
        compL,
        { opacity: 1, pointerEvents: "all", x: 0 },
        {
          opacity: 0,
          pointerEvents: "none",
          x: -widthAnim,
          duration: 0.55,
          ease: "power1.in",
        }
      );
    } else {
      gsap.fromTo(
        compL,
        { opacity: 0, pointerEvents: "none", x: -widthAnim },
        {
          opacity: 1,
          pointerEvents: "all",
          x: 0,
          duration: 0.55,
          ease: "power1.out",
          delay: 0.35,
        }
      );
      gsap.fromTo(
        compB,
        { opacity: 1, pointerEvents: "all", x: 0 },
        {
          opacity: 0,
          pointerEvents: "none",
          x: widthAnim,
          duration: 0.55,
          ease: "power1.in",
        }
      );
    }
  };

  return (
    <>
      {isAttempts ? (
        <AttemptsOverModal
          title={`Sorry, your attempts are over, you need to ${"Top Up"}`}
          topUp
          isActive={isAttempts}
          // @ts-ignore
          setIsActive={setIsAttempts}
          lineBreak={4}
        />
      ) : null}
      <section className={cl.section} ref={sectionRef}>
        <img
          src="/img/staging/carpet-m.png"
          className={cl.carpetMainM}
          alt="Carpet Staging"
        />
        <div className={`container ${cl.cont}`}>
          <TitlePage
            title={
              "Virtual Staging: [break] Envision and Furnish Your Ideal Room"
            }
            classTitle={cl.titleNew}
            lineBreak={1}
            h1
            size={"normal"}
            animation={true}
          />
          {/* <h1 className={cl.title}>
            <div className={cl.titleBlock}>
              <span className={[cl.titleText, cl.titleBold].join(" ")}>
                Virtual Staging:
              </span>
            </div>
            <div className={cl.titleBlock}>
              <span className={cl.titleText}>Envision and Furnish</span>
            </div>
            <div className={cl.titleBlock}>
              <span className={cl.titleText}>Your Ideal Room</span>
            </div>
          </h1> */}

          <div className={cl.btnBlock}>
            {checkAttempts(1) ? (
              <Link to={"/interior-ai/mode-options/"}>
                <button
                  className={`button green ${cl.btn}`}
                  onClick={() => {
                    gtmEvent("Create_Now_Staging", "category");
                    dispatch(setTypeUploadPhoto(1));
                  }}
                >
                  create now
                </button>
              </Link>
            ) : (
              <button
                className={`button green ${cl.btn}`}
                onClick={() => {
                  setIsAttempts(true);
                }}
              >
                create now
              </button>
            )}
          </div>

          <div className={cl.demoStaging}>
            <img
              src="/img/staging/carpet-hero.png"
              className={cl.carpetMain}
              alt="Carpet Staging"
            />

            <div className={cl.switchBlock}>
              <div className={cl.wrapperDefSwitcher}>
                <div
                  className={
                    modeList[0].active
                      ? [cl.bedroomBlock, cl.bedroomActive].join(" ")
                      : cl.bedroomBlock
                  }
                  ref={bedroomRef}
                >
                  <img
                    src="/img/staging/hero-bedroom.png"
                    alt="Sofa Lr"
                    className={cl.sofaLr}
                  />
                  {pinsState.bedroom.map(({ boundingBox, ...product }) => (
                    <div
                      className={cl.iProductBlock}
                      key={product.id}
                      style={{
                        left:
                          boundingBox.xPercent + boundingBox.wPercent / 2 + "%",
                        top:
                          boundingBox.yPercent + boundingBox.hPercent / 2 + "%",
                      }}
                    >
                      <div
                        className={cl.iProductCenter}
                        onClick={(e) =>
                          handleClickArea(
                            { boundingBox, ...product },
                            "bedroom"
                          )
                        }
                      >
                        <span className={cl.iProductDot}></span>
                      </div>
                      <div
                        className={[
                          cl.iProductInfoB,
                          cl[`iProductInfoB${product.productInfo.pos}`],
                          product.active ? cl.iProductInfoBA : "",
                        ].join(" ")}
                      >
                        <div className={cl.iProductWrapper}>
                          <div className={cl.iProductImg}>
                            <img
                              src={product.productInfo.image}
                              alt="Product"
                            />
                          </div>
                          <div className={cl.iProductInfo}>
                            <span className={cl.iProductTitle}>
                              {product.productInfo.name}
                            </span>
                            <span className={cl.iProductCost}>
                              {product.productInfo.cost}
                            </span>
                          </div>
                          <span
                            className={cl.close}
                            onClick={(e) => {
                              e.stopPropagation();
                              setPinsState({
                                ...pinsState,
                                bedroom: pinsState.bedroom.map((k) =>
                                  k.id === product.id
                                    ? { ...k, active: false }
                                    : k
                                ),
                              });
                            }}
                          >
                            <svg
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.74935 5.53906L10.0892 2.19922C10.2477 2.04099 10.3369 1.82627 10.3371 1.60231C10.3373 1.37834 10.2485 1.16347 10.0903 1.00496C9.93202 0.846451 9.71731 0.757291 9.49334 0.757094C9.26937 0.756896 9.0545 0.845677 8.89599 1.00391L5.55615 4.34375L2.21631 1.00391C2.0578 0.845397 1.84282 0.756348 1.61865 0.756348C1.39449 0.756348 1.1795 0.845397 1.02099 1.00391C0.862487 1.16241 0.773438 1.3774 0.773438 1.60156C0.773438 1.82573 0.862487 2.04071 1.02099 2.19922L4.36084 5.53906L1.02099 8.8789C0.862487 9.03741 0.773438 9.2524 0.773438 9.47656C0.773438 9.70073 0.862487 9.91571 1.02099 10.0742C1.1795 10.2327 1.39449 10.3218 1.61865 10.3218C1.84282 10.3218 2.0578 10.2327 2.21631 10.0742L5.55615 6.73437L8.89599 10.0742C9.0545 10.2327 9.26949 10.3218 9.49365 10.3218C9.71782 10.3218 9.9328 10.2327 10.0913 10.0742C10.2498 9.91571 10.3389 9.70073 10.3389 9.47656C10.3389 9.2524 10.2498 9.03741 10.0913 8.8789L6.74935 5.53906Z"
                                fill="#171D24"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div
                className={
                  modeList[1].active
                    ? [cl.lrBlock, cl.lrActive].join(" ")
                    : cl.lrBlock
                }
                ref={lrRef}
              >
                <img
                  src="/img/staging/hero-lr.png"
                  alt="Sofa Lr"
                  className={cl.sofaLr}
                />
                {pinsState.living_room.map(({ boundingBox, ...product }) => (
                  <div
                    className={cl.iProductBlock}
                    key={product.id}
                    style={{
                      left:
                        boundingBox.xPercent + boundingBox.wPercent / 2 + "%",
                      top:
                        boundingBox.yPercent + boundingBox.hPercent / 2 + "%",
                    }}
                  >
                    <div
                      className={cl.iProductCenter}
                      onClick={(e) =>
                        handleClickArea(
                          { boundingBox, ...product },
                          "living_room"
                        )
                      }
                    >
                      <span className={cl.iProductDot}></span>
                    </div>
                    <div
                      className={[
                        cl.iProductInfoB,
                        cl[`iProductInfoB${product.productInfo.pos}`],
                        product.active ? cl.iProductInfoBA : "",
                      ].join(" ")}
                    >
                      <div className={cl.iProductWrapper}>
                        <div className={cl.iProductImg}>
                          <img src={product.productInfo.image} alt="Product" />
                        </div>
                        <div className={cl.iProductInfo}>
                          <span className={cl.iProductTitle}>
                            {product.productInfo.name}
                          </span>
                          <span className={cl.iProductCost}>
                            {product.productInfo.cost}
                          </span>
                        </div>
                        <span
                          className={cl.close}
                          onClick={(e) => {
                            e.stopPropagation();
                            setPinsState({
                              ...pinsState,
                              living_room: pinsState.living_room.map((k) =>
                                k.id === product.id
                                  ? { ...k, active: false }
                                  : k
                              ),
                            });
                          }}
                        >
                          <svg
                            width="11"
                            height="11"
                            viewBox="0 0 11 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.74935 5.53906L10.0892 2.19922C10.2477 2.04099 10.3369 1.82627 10.3371 1.60231C10.3373 1.37834 10.2485 1.16347 10.0903 1.00496C9.93202 0.846451 9.71731 0.757291 9.49334 0.757094C9.26937 0.756896 9.0545 0.845677 8.89599 1.00391L5.55615 4.34375L2.21631 1.00391C2.0578 0.845397 1.84282 0.756348 1.61865 0.756348C1.39449 0.756348 1.1795 0.845397 1.02099 1.00391C0.862487 1.16241 0.773438 1.3774 0.773438 1.60156C0.773438 1.82573 0.862487 2.04071 1.02099 2.19922L4.36084 5.53906L1.02099 8.8789C0.862487 9.03741 0.773438 9.2524 0.773438 9.47656C0.773438 9.70073 0.862487 9.91571 1.02099 10.0742C1.1795 10.2327 1.39449 10.3218 1.61865 10.3218C1.84282 10.3218 2.0578 10.2327 2.21631 10.0742L5.55615 6.73437L8.89599 10.0742C9.0545 10.2327 9.26949 10.3218 9.49365 10.3218C9.71782 10.3218 9.9328 10.2327 10.0913 10.0742C10.2498 9.91571 10.3389 9.70073 10.3389 9.47656C10.3389 9.2524 10.2498 9.03741 10.0913 8.8789L6.74935 5.53906Z"
                              fill="#171D24"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className={cl.optionsPick}>
              <CheckModeButtons
                option={"type"}
                groupName={"roomType"}
                modeList={modeList}
                // handle={selectUploadImageTypeHandle}
                selectRoomTypeView={selectType}
                numberOfActiveEl={1}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroStaging;
