import React, { useEffect } from "react";
import "../modal/authorization/checkButtons.css";
const CheckModeButtons = ({
  option,
  groupName,
  modeList,
  type = undefined,
  selectRoomTypeView = (val) => {},
  numberOfActiveEl,
}) => {
  useEffect(() => {
    const overlay = document.querySelector(`.overlay.${option}`);
    const first_el = document.querySelector(`.radio-thing.${option}`);
    const all_els = document.querySelectorAll(`.radio-thing.${option}`);
    let arr = [...all_els];
    let activeEl = arr.find((e) => e.id === `radio_${numberOfActiveEl}`);
    if (numberOfActiveEl) {
      activeEl.checked = true;
    } else {
      first_el.checked = true;
    }

    const timeoutId = setTimeout(() => {
      if (numberOfActiveEl) {
        overlay.style.top = `${activeEl.parentElement.offsetTop}px`;
        overlay.style.left = `${activeEl.parentElement.offsetLeft}px`;
        overlay.style.height = `${activeEl.parentElement.offsetHeight}px`;
        overlay.style.width = `${activeEl.parentElement.offsetWidth}px`;
      } else {
        overlay.style.top = `${first_el.parentElement.offsetTop}px`;
        overlay.style.left = `${first_el.parentElement.offsetLeft}px`;
        overlay.style.height = `${first_el.parentElement.offsetHeight}px`;
        overlay.style.width = `${first_el.parentElement.offsetWidth}px`;
      }

      overlay.style.display = `block`;
    }, 0);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [option, numberOfActiveEl]);

  const selectRadioButton = (e) => {
    // console.log('object adadasdasdas :>> ');
    const overlay = document.querySelector(`.overlay.${option}`);

    if (e.target.className === `radio-thing ${option}`) {
      e.target.checked = true;

      //  console.log('overlay :>> ', overlay);

      overlay.style.top = `${e.target.parentElement.offsetTop}px`;
      overlay.style.left = `${e.target.parentElement.offsetLeft}px`;
      overlay.style.width = `${e.target.parentElement.offsetWidth}px`;
      overlay.style.height = `${e.target.parentElement.offsetHeight}px`;
      overlay.style.display = `block`;
    }
  };

  return (
    <div className={`radioButtonBox `} onClick={(e) => selectRadioButton(e)}>
      <div className={`overlay ${option}`}></div>
      {modeList.map((mode) => {
        return (
          <div
            className={`radio-button ${
              "image" in mode ? "radio-button-with-image" : ""
            }`}
            key={`radio_key_${mode.id}`}
          >
            <input
              onClick={(e) => selectRoomTypeView(e)}
              type="radio"
              name={groupName}
              className={`radio-thing ${option}`} //${active === mode.name ? 'active' : ''}
              id={`radio_${mode.id}`}
              data-name-type={`${mode.name}`}
              data-name-id={`${mode.id}`}
              data-type-mode={`${mode.type_mode}`}
            />
            <label htmlFor={`radio_${mode.id}`} style={{ cursor: "pointer" }}>
              {"image" in mode ? (
                <img
                  src={mode.image}
                  width={56}
                  height={56}
                  alt="pick"
                  onL
                  className="image-pick-radio"
                />
              ) : null}
              {mode.name}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default CheckModeButtons;
