import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getUserInfoAccount } from './infoUserSlice';

const initialState = { errorMessage: '', animation: false };

export const patchUser = createAsyncThunk(
  'patchUser/patchUser',
  async (data, { rejectWithValue, dispatch, getState }) => {
    const token = localStorage.getItem('key');
    await axios
      .patch(`${process.env.REACT_APP_BACKEND_HOST}/api/v1/rest-auth/user/`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        // console.log('response patchUser :>> ', response);
        dispatch(setErrorMessage(''));
        dispatch(setAnimationVal(true));
        dispatch(getUserInfoAccount());
        setTimeout(() => {
          dispatch(setAnimationVal(false));
        }, 1800);
      })
      .catch(function (error) {
        // console.log('error :>> ', error.response.data);
        dispatch(setErrorMessage(Object.values(error.response.data)));
      });
  },
);

export const changeInfoUserSlice = createSlice({
  name: 'changeInfoUser',
  initialState,
  reducers: {
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setAnimationVal: (state, action) => {
      state.animation = action.payload;
    },
  },
});

export const { setErrorMessage, setAnimationVal } = changeInfoUserSlice.actions;
export default changeInfoUserSlice.reducer;
