import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../../store";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api/v1/`,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set("Authentication", `Bearer ${token}`);
    }
    return headers;
  },
});

export const privateApi = createApi({
  reducerPath: "privateApi",
  baseQuery: baseQuery,
  tagTypes: ["User", "Portfolio"],
  endpoints: () => ({}),
});
