import cl from "./TagsCloudComp.module.css";
import { motion } from "framer-motion";
import MyTagInput from "../../UI/MyTagInput/MyTagInput";
import React, {useState } from "react";
import { v4 as uuidv4 } from 'uuid';

const TagsAddCloud = ({
  activeTags,
  setActiveTags,
}) => {

  const [customTagInput, setCustomTagInput] = useState("");
  const [isActiveInput, setIsActiveInput] = useState(false);

  const deleteCustomTag = (customTag) => {
    setActiveTags(activeTags.filter((e) => e.id !== customTag.id));
  };

  const handleInputValue = (e) => {
    setCustomTagInput(e.target.value);
  };

  const addCustomValue = () => {
    if (customTagInput.length) {
      setActiveTags([
        ...activeTags,
        {
          id: uuidv4(),
          name: customTagInput,
          custom: true,
        },
      ]);
      setCustomTagInput("");
      setIsActiveInput(false);
      return;
    }
    setIsActiveInput(false);
  };

  return (
    <div className={`relative ${cl.tagsWrapper}`}>
      <motion.h2
        initial={{
          opacity: 0,
          y: 15,
        }}
        animate={{
          opacity: 1,
          y: 0,
        }}
        transition={{ duration: 0.4 }}
        className={cl.titleAdd}
      >
        Add personal tags
      </motion.h2>
      <motion.p
        initial={{
          opacity: 0,
          y: 15,
        }}
        animate={{
          opacity: 1,
          y: 0,
        }}
        transition={{ duration: 0.4, delay: 0.1 }}
        className={cl.descrAdd}
      >
        Here you can add your personal tag
      </motion.p>
      <div className={cl.addTagWrapper}>
        <ul className={cl.customList}>
          {activeTags
            .filter((e) => "custom" in e)
            .map((customTag) => {
              return (
                <li
                  className={[cl.customItem, cl.activateItem].join(" ")}
                  key={customTag.id}
                  onClick={() => deleteCustomTag(customTag)}
                >
                  <div className={cl.customTag}>
                    <span className={cl.tagsItemText}>{customTag.name}</span>
                  </div>
                </li>
              );
            })}
          <li
            className={[
              cl.customItem,
              cl.customInputItem,
              isActiveInput && activeTags.length < 5 ? cl.activeCustomItem : "",
              activeTags.length === 5 ? cl.cancelBtn : "",
            ].join(" ")}
            onClick={(e) => setIsActiveInput(true)}
          >
            <div className={cl.hiddenBlock}>
              <MyTagInput
                disable={activeTags.length === 5 ? false + "" : true + ""}
                placeholder="Tag Name"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    addCustomValue();
                  }
                }}
                maxLength={26}
                value={customTagInput}
                classInput={cl.customInput}
                onChange={handleInputValue}
              />
            </div>
            <button
              className={cl.addHoldButton}
              onClick={(e) => {
                e.stopPropagation();
                addCustomValue();
              }}
            >
              +
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TagsAddCloud;
