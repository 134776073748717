import React, { useEffect, useState, useRef, useMemo, memo } from "react";
import cl from "./MyRangeImages.module.css";
import RangeSliderIcon from "../../icon/RangeSliderIcon";

const PinsForImages = ({
  pinsData,
  rangeValue,
  maxRange,
  pinsDataMin,
  handleClickAreaMin,
}) => {
  const [isCard, setIsCard] = useState(null);
  const [isViewPins, setIsViewPins] = useState(pinsData);

  useMemo(() => {
    if (!pinsDataMin) {
      if (rangeValue && maxRange) {
        let arrState = pinsData.map((pin) =>
          (rangeValue / maxRange) * 100 <=
          pin.boundingBox.xPercent + pin.boundingBox.wPercent / 2
            ? { ...pin, visible: true }
            : { ...pin, active: false, visible: false }
        );
        if (
          arrState
            .map((e) => {
              return e.active + "" + e.visible + e.productInfo.name;
            })
            .join("") !==
          isViewPins
            .map((e) => {
              return e.active + "" + e.visible + e.productInfo.name;
            })
            .join("")
        ) {
          setIsViewPins(arrState);
          return true;
        }
        return false;
      }
    } else {
      if (rangeValue && maxRange) {
        let arrState = pinsData.map((pin) =>
          (rangeValue / maxRange) * 100 <= pin.xPercent + pin.wPercent / 2
            ? { ...pin, visible: true }
            : { ...pin, active: false, visible: false }
        );
        if (
          arrState
            .map((e) => {
              return e.active + "" + e.visible + e.id;
            })
            .join("") !==
          isViewPins
            .map((e) => {
              return e.active + "" + e.visible + e.id;
            })
            .join("")
        ) {
          setIsViewPins(arrState);
          return true;
        }
        return false;
      }
    }
    // eslint-disable-next-line
  }, [rangeValue, maxRange, pinsData]);

  const handleClickArea = (id) => {
    if (!pinsDataMin) {
      setIsCard((prev) => (prev === id ? null : id));
      return;
    }
    handleClickAreaMin(id);
  };

  if (pinsDataMin) {
    return isViewPins.map((product) => (
      <div
        className={[
          cl.iProductBlock,
          product.visible ? cl.iProductBlockVisible : "",
          isCard === product.id && product.visible ? cl.iProductActive : "",
        ].join(" ")}
        key={product.id}
        style={{
          left: product.xPercent + product.wPercent / 2 + "%",
          top: product.yPercent + product.hPercent / 2 + "%",
        }}
      >
        <div
          className={cl.iProductCenter}
          onClick={(e) => handleClickArea(product.id, true)}
        >
          <span className={cl.iProductDot}></span>
        </div>
      </div>
    ));
  }
  return isViewPins.map((product) => (
    <div
      className={
        // isCard
        [
          cl.iProductBlock,
          product.visible ? cl.iProductBlockVisible : "",
          isCard === product.id && product.visible ? cl.iProductActive : "",
        ].join(" ")
      }
      key={product.id}
      style={{
        width: product.boundingBox.wPercent + "%",
        height: product.boundingBox.hPercent + "%",
        left: product.boundingBox.xPercent + "%",
        top: product.boundingBox.yPercent + "%",
      }}
    >
      <div
        className={cl.iProductCenter}
        onClick={(e) => {
          window.innerWidth > 576 && handleClickArea(product.id);
        }}
      >
        <span className={cl.iProductDot}></span>
      </div>
      <div className={cl.IProductCenterBlock}>
        <div
          className={[
            cl.iProductCard,
            product.productInfo.pos === 0
              ? cl.leftTopProduct
              : product.productInfo.pos === 1
              ? cl.rightTopProduct
              : product.productInfo.pos === 2
              ? cl.rightBottomProduct
              : cl.leftBottomProduct,
            isCard === product.id && product.visible ? cl.iProductActive : "",
          ].join(" ")}
        >
          <span className={cl.closeCard} onClick={(e) => setIsCard(null)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
            >
              <path
                d="M10.7494 9.53906L14.0892 6.19922C14.2477 6.04099 14.3369 5.82627 14.3371 5.60231C14.3373 5.37834 14.2485 5.16347 14.0903 5.00496C13.932 4.84645 13.7173 4.75729 13.4933 4.75709C13.2694 4.7569 13.0545 4.84568 12.896 5.00391L9.55615 8.34375L6.21631 5.00391C6.0578 4.8454 5.84282 4.75635 5.61865 4.75635C5.39449 4.75635 5.1795 4.8454 5.02099 5.00391C4.86249 5.16241 4.77344 5.3774 4.77344 5.60156C4.77344 5.82573 4.86249 6.04071 5.02099 6.19922L8.36084 9.53906L5.02099 12.8789C4.86249 13.0374 4.77344 13.2524 4.77344 13.4766C4.77344 13.7007 4.86249 13.9157 5.02099 14.0742C5.1795 14.2327 5.39449 14.3218 5.61865 14.3218C5.84282 14.3218 6.0578 14.2327 6.21631 14.0742L9.55615 10.7344L12.896 14.0742C13.0545 14.2327 13.2695 14.3218 13.4937 14.3218C13.7178 14.3218 13.9328 14.2327 14.0913 14.0742C14.2498 13.9157 14.3389 13.7007 14.3389 13.4766C14.3389 13.2524 14.2498 13.0374 14.0913 12.8789L10.7494 9.53906Z"
                fill="#171D24"
              />
            </svg>
          </span>
          <img
            src={product.productInfo.image}
            alt="Product Pic"
            className={cl.iProductImage}
          />
          <div className={cl.iProductInfo}>
            <h2 className={cl.iProductTitle}>{product.productInfo.name}</h2>
            <p className={cl.iProductCost}>{product.productInfo.cost}</p>
          </div>
        </div>
      </div>
    </div>
  ));
};

export const MyRangeImages = memo(
  ({
    imageBefore,
    imageAfter,
    pinsData,
    rangeClass,
    pinsDataMin,
    handleClickArea,
  }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [rangeValue, setRangeValue] = useState(450);
    const [maxRange, setMaxRange] = useState(700);
    // const [imageProperties, setImageProperties] = useState({
    //   width: 0,
    //   height: 0,
    // });
    const inputRef = useRef(null);
    const imageRef = useRef(null);
    const blockRef = useRef(null);

    useEffect(() => {
      if (imageRef.current && blockRef.current) {
        if (
          blockRef.current.offsetWidth !== 0 &&
          blockRef.current.offsetHeight !== 0
        ) {
          // setImageProperties({
          //   width: blockRef.current.offsetWidth,
          //   height: blockRef.current.offsetHeight,
          // });
          setMaxRange(blockRef.current.offsetWidth);
          setRangeValue(blockRef.current.offsetWidth / 2);
        }
      }
      // eslint-disable-next-line
    }, [blockRef.current, isLoaded]);


    const handleClickAreaMin = (id) => {
      handleClickArea(id, true, imageRef.current)
    }

    if (pinsDataMin) {
      return (
        <div
          className={[cl.rangeBlock, cl.rangePins, rangeClass].join(" ")}
          style={{ "--p": rangeValue + "px" }}
          ref={blockRef}
        >
          {pinsData && pinsData !== null ? (
            <PinsForImages
              pinsData={pinsData}
              rangeValue={rangeValue}
              maxRange={maxRange}
              pinsDataMin={pinsDataMin ? pinsDataMin : false}
              handleClickAreaMin={handleClickArea ? handleClickAreaMin : false}
            />
          ) : null}

          <div className={cl.imagesBlock}>
            <div className={cl.imageBeforeBlock}>
              <img
                src={imageBefore}
                alt="Before"
                crossOrigin="*"
                ref={imageRef}
                onLoad={() => setIsLoaded(true)}
              />
            </div>
            <div className={cl.imageAfterBlock}>
              <img src={imageAfter} crossOrigin="*" alt="Before" className={cl.imageAfter} />
            </div>
          </div>
          <div className={cl.inputBlock}>
            <input
              type="range"
              max={maxRange}
              ref={inputRef}
              value={rangeValue}
              className={cl.rangeInput}
              onChange={(e) => {
                setRangeValue(e.target.value);
              }}
            />
            <div className={cl.thumbBlock}>
              <div className={cl.thumb}>
                <RangeSliderIcon />
              </div>
              <div className={cl.lineBlock}>
                <span className={cl.topLeftRounding}></span>
                <span className={cl.topRightRounding}></span>
                <span className={cl.bottomRightRounding}></span>
                <span className={cl.bottomLeftRounding}></span>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className={[cl.rangeBlock, rangeClass].join(" ")}
        style={{ "--p": rangeValue + "px" }}
        ref={blockRef}
      >
        {pinsData && pinsData !== null ? (
          <PinsForImages
            pinsData={pinsData}
            rangeValue={rangeValue}
            maxRange={maxRange}
          />
        ) : null}
        <div className={cl.imagesBlock}>
          <div className={cl.imageBeforeBlock}>
            <img
              src={imageBefore}
              alt="Before"
              ref={imageRef}
              onLoad={() => setIsLoaded(true)}
            />
          </div>
          <div className={cl.imageAfterBlock}>
            <img src={imageAfter} alt="Before" className={cl.imageAfter} />
          </div>
        </div>
        <div className={cl.inputBlock}>
          <input
            type="range"
            max={maxRange}
            ref={inputRef}
            value={rangeValue}
            className={cl.rangeInput}
            onChange={(e) => {
              setRangeValue(e.target.value);
            }}
          />
          <div className={cl.thumbBlock}>
            <div className={cl.thumb}>
              <RangeSliderIcon />
            </div>
            <div className={cl.lineBlock}>
              <span className={cl.topLeftRounding}></span>
              <span className={cl.topRightRounding}></span>
              <span className={cl.bottomRightRounding}></span>
              <span className={cl.bottomLeftRounding}></span>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
