export const ArrowPagIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="17"
    viewBox="0 0 9 17"
    fill="none"
    {...props}
  >
    <path
      d="M1 1.5L7.29289 7.79289C7.68342 8.18342 7.68342 8.81658 7.29289 9.20711L1 15.5"
      stroke="black"
      strokeWidth="1.5"
    />
  </svg>
);
