import React from "react";
import BlogsComponent from "../../components/BlogsPage/BlogsComponent/BlogsComponent";
import { useTopToPage } from "../../lib/scrypts";
import { Helmet } from "react-helmet-async";

function BlogsPage() {
  useTopToPage();
  return (
    <>
      <Helmet>
        <title>PaintIt.ai Blog: Insights & Trends</title>
        <meta
          property="og:title"
          content={"PaintIt.ai Blog: Insights & Trends"}
        />
        <meta
          name="twitter:title"
          content={"PaintIt.ai Blog: Insights & Trends"}
        />
        <meta
          name="description"
          content="Discover in-depth articles on the latest trends, tips, and innovations in AI-powered interior design."
        />
        <meta
          property="og:description"
          content={
            "Discover in-depth articles on the latest trends, tips, and innovations in AI-powered interior design."
          }
        />
        <meta
          name="twitter:description"
          content={
            "Discover in-depth articles on the latest trends, tips, and innovations in AI-powered interior design."
          }
        />
      </Helmet>
      <BlogsComponent />
    </>
  );
}

export default BlogsPage;
