import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  setActiveAuthModal,
  setActiveModalName,
  setActiveModalPrice,
} from "../toolkit/slices/authorization/authorizationModalSlice";
import { setStripeLink } from "../toolkit/slices/authorization/stripeSlice";

export const useTopToPage = () => {
  const { pathname } = useLocation();
  // window.scrollTo({ top: 0, behavior: "instant" });
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [pathname]);
  // window.onload = function () {
  //   window.scrollTo(0, 0);
  // }
};

export const useOpenStrype = () => {
  const stripeLink = useSelector((state) => state.stripe.stripeLink);
  const dispatch = useDispatch();
  useEffect(() => {
    if (stripeLink) {
      window.location.replace(stripeLink);
      dispatch(setStripeLink(""));
    }
  }, [stripeLink, dispatch]);
};

export const closeModalOutside = (e, reff, navigate, link) => {
  if (reff.current && !reff.current.contains(e.target)) {
    navigate(link, { replace: true });
    document.body.style.overflow = "auto";
  }
};

export const useCloseModalWindow = () => {
  const dispatch = useDispatch();
  const customDispatch = (e, reff, action, button, navigate, link) => {
    let closeLink = link.split("?");
    if (reff.current && !reff.current.contains(e.target)) {
      document.body.style.overflow = "auto";
      dispatch(setActiveAuthModal(action));
      dispatch(setActiveModalName("Login"));
      dispatch(setActiveModalPrice(false));
      navigate(closeLink, { replace: true });
    } else if (button) {
      document.body.style.overflow = "auto";
      dispatch(setActiveAuthModal(action));
      dispatch(setActiveModalName("Login"));
      dispatch(setActiveModalPrice(false));
      navigate(closeLink, { replace: true });
    }
  };

  return customDispatch;
};

export const importAllImageLink = (r) => {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

export const checkPathname = (pageLink) => {
  let pageLinkArr = pageLink.split("/");
  return pageLinkArr[0] === process.env.REACT_APP_BASE_NAME
    ? pageLinkArr[1].replace(/-/gi, '')
    : pageLinkArr[0];
};

export const checkNameInput = (val) =>
  /^(?=.*[a-zA-Z])[a-zA-Z\d@#$%^&+=]{3,10}$/.test(val);
export const checkEmailInput = (val) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(val);
export const checkPasswordInput = (val) =>
  /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@#$%^&!])[A-Za-z\d@#$%^&!]{6,20}$/.test(val);

export const useValidationCreateUserForm = () => {
  const [createUserName, setCreateUserName] = useState({
    value: "",
    error: true,
  });
  const [createUserEmail, setCreateUserEmail] = useState({
    value: "",
    error: true,
  });
  const [createUserPassword1, setCreateUserPassword1] = useState({
    value: "",
    error: true,
  });
  const [createUserPassword2, setCreateUserPassword2] = useState({
    value: "",
    error: true,
  });
  const newNameValue = (newData) => {
    setCreateUserName((prevData) => ({
      ...prevData,
      value: newData,
      error: checkNameInput(newData),
    }));
  };
  const newEmailValue = (newData) => {
    setCreateUserEmail((prevData) => ({
      ...prevData,
      value: newData,
      error: checkEmailInput(newData),
    }));
  };
  const newPassword1Value = (newData) => {
    setCreateUserPassword1((prevData) => ({
      ...prevData,
      value: newData,
      error: checkPasswordInput(newData),
    }));
  };
  const newPassword2Value = (newData) => {
    setCreateUserPassword2((prevData) => ({
      ...prevData,
      value: newData,
      error: checkPasswordInput(newData),
    }));
  };
  return {
    createUserName,
    newNameValue,
    createUserEmail,
    newEmailValue,
    createUserPassword1,
    newPassword1Value,
    createUserPassword2,
    newPassword2Value,
  };
};

export const useLoginForm = () => {
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const onChangeEmail = (newData) => {
    setUserEmail(newData);
  };
  const onChangePassword = (newData) => {
    setUserPassword(newData);
  };

  return { userEmail, onChangeEmail, userPassword, onChangePassword };
};
export const useResetPasswordForm = () => {
  const [userEmailChangePassword, setUserEmailChangePassword] = useState("");
  const onChangeEmailChangePassword = (newData) => {
    setUserEmailChangePassword(newData);
  };

  return { userEmailChangePassword, onChangeEmailChangePassword };
};
export const useResetPasswordConfirmForm = () => {
  const [changePassword1, setChangePassword1] = useState("");
  const [changePassword2, setChangePassword2] = useState("");
  const onChangePassword1 = (newData) => {
    setChangePassword1(newData);
  };
  const onChangePassword2 = (newData) => {
    setChangePassword2(newData);
  };

  return {
    changePassword1,
    onChangePassword1,
    changePassword2,
    onChangePassword2,
  };
};

export const urlWithTagsParams = (tags) => {
  const allValues = Object.values(tags).flatMap((arr) =>
    arr.map((obj) => obj.value)
  );
  const paramString = allValues
    .map(
      (tag) =>
        `tag=${tag.toLowerCase().replace("#", "%23").replace(" ", "%20")}`
    )
    .join("&");
  return paramString;
};

export const useResetFilter = (initialValue) => {
  const [isReset, setIsReset] = useState(initialValue);

  const setTrue = () => {
    setIsReset(true);
  };

  const setFalse = () => {
    setIsReset(false);
  };

  return {
    isReset,
    setTrue,
    setFalse,
  };
};

export const changeDateFormat = (date) => {
  var originalDate = new Date(date);

  const year = originalDate.getFullYear();
  const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");
  const day = originalDate.getDate().toString().padStart(2, "0");

  const formattedDate = `${day}.${month}.${year}`;

  return formattedDate;
};

export const scrollToSection = (sectionId) => {
  const element = document.getElementById(sectionId);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

export const useLocalStorage = (key, initialValue) => {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : initialValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  const updateValue = (newValue) => {
    setValue(newValue);
  };

  return [value, updateValue];
};

// const handleCrop = useCallback(() => {
//   const canvas = document.createElement('canvas');
//   const ctx = canvas.getContext('2d');
//   const img = new Image();
//   // console.log('handleCrop :>> ', image);
//   img.src = image;
//   img.onload = () => {
//     // Calculate the cropping dimensions
//     const aspectRatio = 16 / 9; // Adjust this ratio as needed
//     const canvasWidth = img.width;
//     const canvasHeight = canvasWidth / aspectRatio;
//     const offsetX = 0;
//     const offsetY = (img.height - canvasHeight) / 2;

//     canvas.width = canvasWidth;
//     canvas.height = canvasHeight;

//     // Draw the cropped image
//     ctx.drawImage(
//       img,
//       offsetX,
//       offsetY,
//       canvasWidth,
//       canvasHeight,
//       0,
//       0,
//       canvasWidth,
//       canvasHeight,
//     );

//     const croppedImageUrl = canvas.toDataURL('image/jpeg');
//     setCroppedImageSrc(croppedImageUrl);
//     dispatch(setImageBase64(croppedImageUrl));
//   };
// }, [image, dispatch]);

export const useLocalStorageCheck = (key) => {
  const [myData, setMyData] = useState(localStorage.getItem(key));

  useEffect(() => {
    const handleStorageChange = () => {
      setMyData(localStorage.getItem(key));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [key]);

  return [myData, setMyData];
};

export const updateLocalStorage = () => {
  const storageEvent = new Event("storage");
  window.dispatchEvent(storageEvent);
};
