import React, { useEffect } from "react";
import "swiper/css";
import "./landingPage.css";
import TabsContainer from "./HeroComponent/HeroComponent";
import { useNavigate } from "react-router-dom";
import PartnersContainer from "./PartnersContainer";
import TestimonialsContainer from "./TestimonialsComponent/TestimonialsContainer";
import ToolsContainer from "./ToolsContainer";
import WhoIsOurProduct from "./WhoIsOurProduct";
import RoomDesignContainer from "./RoomDesignContainer";
import BenefitsContainer from "./BenefitsContainer";
import QuestionsContainer from "./QuestionsContainer";
import { useSearchParams } from "react-router-dom";
import AttemptsOverModal from "../modal/AttemptsOverModal";
import { useState } from "react";
// import { useDispatch } from "react-redux";
// import { setActiveModalName } from "../../toolkit/slices/authorization/authorizationModalSlice";
// import { useSearchParams } from "react-router-dom";
// import NavBar from './NavBar';

const MainLanding = () => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [isViewCTA, setIsViewCTA] = useState(false);
  useEffect(() => {
    if (!sessionStorage.getItem("ctaTime")) {
      sessionStorage.setItem('ctaTime', new Date().getTime());
    }

    if (!sessionStorage.getItem("cta") && sessionStorage.getItem("ctaTime")) {
      console.log(+sessionStorage.getItem("ctaTime") + 60000, new Date().getTime())
      if (parseInt(+sessionStorage.getItem("ctaTime")) + 60000 < new Date().getTime() ) {
        setIsViewCTA(true);
      }
    }

    if (
      localStorage.getItem("make design") &&
      params.get("social") &&
      params.get("code") &&
      localStorage.getItem("key")
    ) {
      navigate("/interior-ai/result");
    }
  }, [navigate, params]);

  return (
    <>
      {isViewCTA && (
        <div className="titleSmall" onClick={e=>{setIsViewCTA(false);sessionStorage.setItem('cta', 'true')}}>
          <AttemptsOverModal
            isButton={true}
            title={
              "Got a minute? Help a budding startup refine our products with your feedback through our survey."
            }
            link={"https://forms.gle/X8QABmMZ766GAqaa7"}
            lineBreak={10}
          />
        </div>
      )}

      {/* <NavBar /> */}
      <TabsContainer />
      <PartnersContainer />
      <RoomDesignContainer />
      <TestimonialsContainer />
      <ToolsContainer />
      <WhoIsOurProduct />
      <BenefitsContainer />
      <QuestionsContainer />
    </>
  );
};

export default MainLanding;
