import React from 'react';

const PasswordHideIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M2.25 9.75C4.95 3.75 13.05 3.75 15.75 9.75"
        stroke="black"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 12.75C8.70453 12.75 8.41194 12.6918 8.13896 12.5787C7.86598 12.4657 7.61794 12.2999 7.40901 12.091C7.20008 11.8821 7.03434 11.634 6.92127 11.361C6.8082 11.0881 6.75 10.7955 6.75 10.5C6.75 10.2045 6.8082 9.91194 6.92127 9.63896C7.03434 9.36598 7.20008 9.11794 7.40901 8.90901C7.61794 8.70008 7.86598 8.53434 8.13896 8.42127C8.41194 8.3082 8.70453 8.25 9 8.25C9.59674 8.25 10.169 8.48705 10.591 8.90901C11.0129 9.33097 11.25 9.90326 11.25 10.5C11.25 11.0967 11.0129 11.669 10.591 12.091C10.169 12.5129 9.59674 12.75 9 12.75Z"
        stroke="black"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PasswordHideIcon;
