import { configureStore } from "@reduxjs/toolkit";
import roomTypeSlice from "./slices/roomTypeSlice.js";
import createProjectSlice from "./slices/createProjectSlice.js";
import longPollingReducer from "./slices/getResultImageSlice.js";
import filtersSlice from "./slices/getFiltersSlice.js";
import configSlice from "./slices/getConfigSlice.js";
import confirmEmailSlice from "./slices/confirmEmailSlice.js";
import imagineRetrySlice from "./slices/imagineRetrySlice.js";
import authorizationModalSlice from "./slices/authorization/authorizationModalSlice.js";
import logInSlice from "./slices/authorization/logInSlice.js";
import singInSlice from "./slices/authorization/singInSlice.js";
import resetPasswordSlice from "./slices/authorization/resetPasswordSlice.js";
import getUserSlice from "./slices/authorization/getUserSlice.js";
import productsFromImage from './slices/productsImageSlice.js';
import portfolioUserSlice from "./slices/account/portfolioUserSlice.js";
import changePasswordUserSlice from "./slices/account/changePasswordUserSlice.js";
import changeInfoUserSlice from "./slices/account/changeInfoUserSlice.js";
import infoUserSlice from "./slices/account/infoUserSlice.js";
import checkTypePageSlice from "./slices/checkTypePageSlice.js";
import subscriptionPlanSlice from "./slices/authorization/subscriptionPlanSlice.js";
import stripeSlice from "./slices/authorization/stripeSlice.js";
import socialAuthSlice from "./slices/authorization/socialAuthSlice.js";
import rangeVisionSlice from "./slices/rangeVisionSlice.js";
import selectImagesSlice from "./slices/selectImagesSlice.js";
import tagsSlice from "./slices/getTagsSlice.js";
import productsBoundingSlise from "./slices/productsImageSlice.js";
import { api } from "./services/public/api";
import { privateApi } from "./services/private/api.private";
import { combineReducers } from "@reduxjs/toolkit";
import AuthSlice from "./features/auth.reducer";

const rootReducer = combineReducers({
  // API
  [api.reducerPath]: api.reducer,
  [privateApi.reducerPath]: privateApi.reducer,
  // REDUCERS
  auth: AuthSlice,
  roomType: roomTypeSlice,
  createProject: createProjectSlice,
  productsImage: productsBoundingSlise,
  longPolling: longPollingReducer,
  filters: filtersSlice,
  tags: tagsSlice,
  productsFromImage: productsFromImage,
  config: configSlice,
  confirmEmail: confirmEmailSlice,
  emagineRetry: imagineRetrySlice,
  checkTypePage: checkTypePageSlice,
  selectedImages: selectImagesSlice,
  //Auth
  authorizationModal: authorizationModalSlice,
  login: logInSlice,
  singin: singInSlice,
  resetPassword: resetPasswordSlice,
  getUser: getUserSlice,
  //Account
  portfolioUser: portfolioUserSlice,
  changePasswordUser: changePasswordUserSlice,
  changeInfoUser: changeInfoUserSlice,
  infoUser: infoUserSlice,
  subscriptionPlan: subscriptionPlanSlice,
  stripe: stripeSlice,
  socialAuth: socialAuthSlice,
  //
  rangeVisionSlice: rangeVisionSlice,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware, privateApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
