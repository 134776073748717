import React from 'react';
import InputForm from '../../input/InputForm';
import { postCreateUser } from '../../../toolkit/slices/authorization/singInSlice';
import { useDispatch, useSelector } from 'react-redux';
import GoogleLoginButton from '../../menu/GoogleLoginButton';
import FacebookLoginButton from '../../menu/FacebookLoginButton';

const CreateAccountForm = ({
  nameOnChange,
  emailOnChange,
  passwordOnChange,
  passwordConfirmOnChange,
  createUserName,
  createUserEmail,
  createUserPassword1,
  createUserPassword2,
}) => {
  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.singin.errorMessage);
  const planValue = useSelector((state) => state.singin.planValue);
  const activeCheckBoxName = useSelector((state) => state.singin.productInfo);

  const payments_valueMoney = useSelector((state) => state.rangeVisionSlice.valueMoney);
  const payments_valueVisions = useSelector((state) => state.rangeVisionSlice.valueVisions);

  // console.log(stripeLink);
  const createAccountHandler = () => {
    dispatch(
      postCreateUser([
        {
          first_name: createUserName.value,
          email: createUserEmail.value,
          password1: createUserPassword1.value,
          password2: createUserPassword2.value,
        },
        { active_tab: activeCheckBoxName },
      ]),
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createAccountHandler(createUserPassword1, createUserPassword2);

    localStorage.setItem('payments', activeCheckBoxName);
    localStorage.setItem('payments_valueMoney', payments_valueMoney);
    localStorage.setItem('payments_valueVisions', payments_valueVisions);
  };

  return (
    <>
      <form className={errorMessage ? 'form error' : 'form'} onSubmit={handleSubmit}>
        <InputForm
          title={'Enter your Name'}
          type={'text'}
          placeholder={'John'}
          name={'name'}
          id={'name'}
          onChangeHandle={nameOnChange}
          value={createUserName.value}
          // error={createUserName.error}
        />
        <InputForm
          title={'Enter your Email'}
          type={'email'}
          placeholder={'example@mail.com'}
          name={'email'}
          id={'email'}
          onChangeHandle={emailOnChange}
          value={createUserEmail.value}
          error={createUserEmail.error}
        />
        <InputForm
          title={'Enter your password'}
          type={'password'}
          placeholder={'password'}
          name={'password'}
          id={'password'}
          onChangeHandle={passwordOnChange}
          value={createUserPassword1.value}
          error={errorMessage ? false : true}
        />
        <InputForm
          title={'Confirm your password'}
          type={'password'}
          placeholder={'password'}
          name={'passwordConfirm'}
          id={'passwordConfirm'}
          onChangeHandle={passwordConfirmOnChange}
          value={createUserPassword2.value}
          error={errorMessage ? false : true}
        />
        <button
          type="submit"
          className={planValue === 4 ? 'button green disable' : 'button green '}>
          sign up
        </button>
        <div className="errorFooter">{errorMessage ? errorMessage : ''}</div>
      </form>
      <div className="socialButton">
        <div className="line">Or</div>
        <GoogleLoginButton />
        <FacebookLoginButton />
      </div>
    </>
  );
};

export default CreateAccountForm;
