import { FC, HTMLAttributes, useEffect, useState } from "react";
import cl from "./QuickQuiz.module.css";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../UI/Popover/Popover";
import { ArrowDown } from "../../UI/Icons/ArrowDown";
import { Tabs, TabsList, TabsTrigger } from "../../UI/Tabs/Tabs";
// import { NUMBER_OF_IMAGES } from "./constants";
import { useAppDispatch, useAppSelector } from "../../../toolkit/hooks";
import {
  setGuidanceScale,
  setImageStrength,
  // setNumberOfImages,
  setPhotorealism,
} from "../../../toolkit/slices/createProjectSlice";
import { Slider } from "../../UI/Slider/Slider";
import { Textarea } from "../../UI/Textarea/Textarea";
import { setPromt } from "../../../toolkit/slices/getConfigSlice";
import { Palette } from "../../../types/quiz/pallete";
import {
  removeHashtag,
  setHashtags,
} from "../../../toolkit/slices/roomTypeSlice";
import { Switch } from "../../UI/Switch/Switch";
import { cn } from "../../../lib/utils";
import { createNewPropmptString } from "../../../helpers/collectDataForProject";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../UI/MyTooltip/Tooltip";

interface QuickQuizProps {}
type hashtags = "type" | "design";

const QuickQuiz: FC<QuickQuizProps> = () => {
  const dispatch = useAppDispatch();
  const {
    //  numberOfImages,
    imageStrength,
    guidanceScale,
    photorealism,
  } = useAppSelector((state) => state.createProject);
  const { prompt } = useAppSelector((state) => state.config);
  const {
    roomDesign,
    roomType,
    palette: filterPalletes,
    all,
  } = useAppSelector((state) => state.filters) as {
    roomType: { name: string; color_palettes: Palette[] }[];
    roomDesign: { name: string; color_palettes: Palette[] }[];
    palette: { id: string; name: string; description: string }[];
    all: {};
  };
  const hastagArrState = useAppSelector((state) => state.roomType.hastagArr);
  const uploadType = useAppSelector((state) => state.createProject.uploadType);

  const [tagsToPrompt, setTagsToPrompt] = useState(hastagArrState);
  const [palette, setPalette] = useState<Palette[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  const { colorpalette, roomtype, roomdesignstyle } = tagsToPrompt;

  const setPaletteFromFilters = (tag: string, type: hashtags) => {
    const searchArray = type === "type" ? roomType : roomDesign;
    const activeType = searchArray.find((item) => item.name === tag);
    if (activeType) {
      // console.log(activeType);
      const activeTypePalettes = activeType.color_palettes as Palette[];
      // console.log(activeTypePalettes);
      if (activeTypePalettes.length > 1) {
        setPalette(activeTypePalettes);
        return;
      }
      setPalette([]);
      dispatch(removeHashtag("colorpalette"));
    }
  };

  type ReplacementRule = {
    regExp: RegExp;
    replacer: string;
    nestedProperty: null;
  };

  const updatePrompt = (tags: any, dataArr: ReplacementRule[]) => {
    const newPrompt = createNewPropmptString(
      tags,
      prompt,
      all,
      "type",
      dataArr ? dataArr : []
    );
    console.log(newPrompt, dataArr);
    dispatch(setPromt(newPrompt));
  };

  useEffect(() => {
    if (roomtype.name.length === 0) return;
    setPaletteFromFilters(roomtype.name, "type");
    // updatePrompt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomtype]);

  useEffect(() => {
    if (roomdesignstyle.name.length === 0) return;
    setPaletteFromFilters(roomdesignstyle.name, "design");
    // updatePrompt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomdesignstyle]);

  // useEffect(() => {
  //   if (colorpalette.name.length === 0) return;
  //   updatePrompt();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [colorpalette]);

  useEffect(() => {
    setTagsToPrompt((prev) => {
      const prevTags = JSON.stringify(prev);
      const nextTags = JSON.stringify(hastagArrState);
      if (prevTags !== nextTags) {
        const roomtypeReg =
          prev.roomtype.hashtag &&
          prev.roomtype.hashtag !== hastagArrState.roomtype.hashtag
            ? {
                regExp: prev.roomtype.hashtag,
                replacer: hastagArrState.roomtype.hashtag,
                nestedProperty: null,
              }
            : null;
        const roomdesignReg =
          prev.roomdesignstyle.hashtag &&
          prev.roomdesignstyle.hashtag !==
            hastagArrState.roomdesignstyle.hashtag
            ? {
                regExp: prev.roomdesignstyle.hashtag,
                replacer: hastagArrState.roomdesignstyle.hashtag,
                nestedProperty: null,
              }
            : null;
        const paletteReg =
          prev.colorpalette.hashtag &&
          prev.colorpalette.hashtag !== hastagArrState.colorpalette.hashtag
            ? {
                regExp: prev.colorpalette.hashtag,
                replacer: hastagArrState.colorpalette.hashtag,
                nestedProperty: null,
              }
            : null;
        const tagsReg =
          prev.additionaltags.hashtag &&
          prev.additionaltags.hashtag !== hastagArrState.additionaltags.hashtag
            ? {
                regExp: prev.additionaltags.hashtag,
                replacer: `Use these tags as optional to the design: ${hastagArrState.additionaltags.hashtag}`,
                nestedProperty: null,
              }
            : null;
        const dataArr = (
          [
            roomtypeReg,
            roomdesignReg,
            paletteReg,
            tagsReg,
          ] as (ReplacementRule | null)[]
        ).filter((e) => e) as ReplacementRule[];
        updatePrompt(hastagArrState, dataArr);
      }
      return hastagArrState;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hastagArrState]);

  const getColors = (str: string) => {
    return str.split(", ");
  };

  return (
    <div>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger
          className={`inline-flex arrow-up gap-2.5 cursor-pointer transition-all duration-300 py-3 h-[38px] pl-4 pr-5 rounded-full bg-light-gray items-center justify-center text-sm leading-none font-medium ${
            roomtype.hashtag && roomdesignstyle.hashtag ? "!bg-accent" : ""
          }`}
        >
          Additional options
          <ArrowDown className="transition-all duration-200 rotate-180" />
        </PopoverTrigger>
        <PopoverContent align={"start"}>
          {/* <BlockWrapper title="Number of images:">
            <Tabs
              value={numberOfImages + ""}
              onValueChange={(value) => dispatch(setNumberOfImages(+value))}
            >
              <TabsList>
                {NUMBER_OF_IMAGES.map((item) => (
                  <TabsTrigger value={item.value + ""} key={item.value}>
                    {item.label}
                  </TabsTrigger>
                ))}
              </TabsList>
            </Tabs>
          </BlockWrapper> */}
          {uploadType === 2 ? (
            <>
              <div className="flex items-center justify-between pt-[12px]">
                <span className={cl.title}>Photorealism:</span>
                <Switch
                  onCheckedChange={(value) => dispatch(setPhotorealism(value))}
                  checked={photorealism}
                />
              </div>
              <BlockWrapper
                tooltip={
                  "How strongly the generation should reflect the prompt. 7 is recommended. "
                }
                title="Guidance Scale:"
              >
                <div className="flex gap-3 items-center justify-between">
                  <Slider
                    defaultValue={[guidanceScale]}
                    max={20}
                    min={1}
                    step={1}
                    onValueChange={(value) =>
                      dispatch(setGuidanceScale(value[0]))
                    }
                  />
                  <span className="block w-5">{guidanceScale}</span>
                </div>
              </BlockWrapper>
              <BlockWrapper
                tooltip={
                  "How strongly the generated images should reflect the original image"
                }
                title="Image Strength:"
              >
                <div className="flex gap-3 items-center justify-between">
                  <Slider
                    defaultValue={[imageStrength]}
                    max={0.9}
                    min={0.1}
                    step={0.01}
                    onValueChange={(value) =>
                      dispatch(setImageStrength(value[0]))
                    }
                  />
                  <span className="block w-5">{imageStrength}</span>
                </div>
              </BlockWrapper>
            </>
          ) : null}

          {roomtype.hashtag && roomdesignstyle.hashtag ? (
            <BlockWrapper className="pt-3" title="Custom Prompt:">
              <Textarea
                value={prompt}
                onChange={(e) => dispatch(setPromt(e.target.value))}
              />
            </BlockWrapper>
          ) : null}

          {palette.length > 1 ? (
            <BlockWrapper isLast title="Choose your color palette:">
              <Tabs
                value={colorpalette.name + "&" + colorpalette.hashtag}
                onValueChange={(value) => {
                  const [name, prompt] = value.split("&");
                  dispatch(setHashtags(["colorpalette", name, prompt]));
                }}
              >
                <TabsList variant="column">
                  {palette.map((item) => {
                    const itemPalette = item as Palette;
                    const filterPallete = filterPalletes.find(
                      (e) => e.id === itemPalette.id
                    );
                    if (!filterPallete) return null;
                    const pallete = getColors(filterPallete.description);
                    // console.log(pallete);
                    return (
                      <TabsTrigger
                        value={itemPalette.name + "&" + itemPalette.prompt}
                        title={itemPalette.prompt}
                        key={itemPalette.id}
                        className="rounded-[7px] h-[42px] bg-white hover:bg-gray"
                      >
                        <div className="flex w-full px-2 justify-between items-center">
                          {pallete.map((color) => (
                            <span
                              key={color}
                              className="rounded-full block w-[30px] h-[30px] border border-[#DFDFDF]"
                              style={{
                                backgroundColor: color,
                              }}
                            ></span>
                          ))}
                          {/* <span className="rounded-full block w-[30px] h-[30px] bg-white border border-[#DFDFDF]"></span>
                          <span className="rounded-full block w-[30px] h-[30px] bg-white border border-[#DFDFDF]"></span>
                          <span className="rounded-full block w-[30px] h-[30px] bg-white border border-[#DFDFDF]"></span>
                          <span className="rounded-full block w-[30px] h-[30px] bg-white border border-[#DFDFDF]"></span>
                          <span className="rounded-full block w-[30px] h-[30px] bg-white border border-[#DFDFDF]"></span> */}
                        </div>
                      </TabsTrigger>
                    );
                  })}
                </TabsList>
              </Tabs>
            </BlockWrapper>
          ) : null}
        </PopoverContent>
      </Popover>
    </div>
  );
};

interface BlockWrapperProps extends HTMLAttributes<HTMLDivElement> {
  isLast?: true;
  tooltip?: string;
  soon?: boolean;
}

const BlockWrapper: FC<BlockWrapperProps> = ({
  children,
  title,
  tooltip,
  className,
  isLast,
}) => {
  return (
    <>
      <div className={cn("pt-[12px] !w-full relative", className)}>
        <TooltipProvider>
          <Tooltip delayDuration={200}>
            <TooltipTrigger className="w-full !text-start block">
              {title ? (
                <span className={`${cl.title} mb-[10px]`}>{title}</span>
              ) : null}
            </TooltipTrigger>
            {tooltip ? <TooltipContent>{tooltip}</TooltipContent> : null}
          </Tooltip>
        </TooltipProvider>
        {children}
        {!isLast ? (
          <span className="block h-[1px] bg-[#DFDFDF] w-full mt-[18px]"></span>
        ) : null}
      </div>
    </>
  );
};

export default QuickQuiz;
