import React from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import cl from "./StyleSwiper.module.css";

const CarouselResponsive = () => {
  const slidesArr = [
    "/img/home/main_1.jpg",
    "/img/home/main_2.jpg",
    "/img/home/main_3.jpg",
  ];

  return (
    <Swiper slidesPerView={1} spaceBetween={10} loop className={cl.swiper}>
      {slidesArr.map((slide, i) => (
        <SwiperSlide key={i}>
          <img src={slide} alt="Room Design Ai" className={cl.image} />
        </SwiperSlide>
      ))}
      <SwiperPaginations/>
    </Swiper>
  );
};

const SwiperPaginations = () => {
  const swiperInstance = useSwiper();
  return (
    <>
      <button className={cl.prevSlide} onClick={()=>{swiperInstance.slidePrev()}}>
        <ArrowSvg transform={'rotate(180deg)'}/>
      </button>
      <button className={cl.nextSlide} onClick={()=>{swiperInstance.slideNext()}}>
        <ArrowSvg/>
      </button>
    </>
  );
};

const ArrowSvg = ({...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    style={{...props}}
  >
    <path
      d="M9.42188 14.6191L14.4844 9.55664L9.42187 4.49414M13.7812 9.55664L3.51562 9.55664"
      stroke="black"
      strokeWidth="1.3125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CarouselResponsive;
