import React from 'react';

const PinterestIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.04 19.54C8 19.83 8.97 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 14.25 2.67 17.9 6.44 19.34C6.35 18.56 6.26 17.27 6.44 16.38L7.59 11.44C7.59 11.44 7.3 10.86 7.3 9.94C7.3 8.56 8.16 7.53 9.14 7.53C10 7.53 10.4 8.16 10.4 8.97C10.4 9.83 9.83 11.06 9.54 12.24C9.37 13.22 10.06 14.08 11.06 14.08C12.84 14.08 14.22 12.18 14.22 9.5C14.22 7.1 12.5 5.46 10.03 5.46C7.21 5.46 5.55 7.56 5.55 9.77C5.55 10.63 5.83 11.5 6.29 12.07C6.38 12.13 6.38 12.21 6.35 12.36L6.06 13.45C6.06 13.62 5.95 13.68 5.78 13.56C4.5 13 3.76 11.18 3.76 9.71C3.76 6.55 6 3.68 10.32 3.68C13.76 3.68 16.44 6.15 16.44 9.43C16.44 12.87 14.31 15.63 11.26 15.63C10.29 15.63 9.34 15.11 9 14.5L8.33 16.87C8.1 17.73 7.47 18.88 7.04 19.57V19.54Z"
        fill="black"
      />
    </svg>
  );
};

export default PinterestIcon;
