import React from 'react';
import InputForm from '../../input/InputForm';
import ArrowBack from '../../icon/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import cl from './AuthComponent.module.css';
import { postResetPassword } from '../../../toolkit/slices/authorization/resetPasswordSlice';

const ForgotPasswordForm = ({
  userEmailChangePassword,
  onChangeEmailChangePassword,
  forgotPasswordToggle,
}) => {
  const errorMessage = useSelector((state) => state.resetPassword.errorMessage);

  const dispatch = useDispatch();
  const resetPasswordHandle = (email) => {
    dispatch(postResetPassword({ email: email }));
  };

  return (
    <div className="form">
      <button onClick={() => forgotPasswordToggle('Login')} className="closeForgotPassword">
        <ArrowBack />
      </button>
      <div className="title mt-24">Forgot password?</div>
      <div className={`subTitle ${cl.forgotDescr}`}>
        If you want to recover your account password. Open your e-mail and follow the link to create
        a new password.
      </div>
      <InputForm
        title={'Enter your Email'}
        type={'email'}
        placeholder={'example@mail.com'}
        name={'email'}
        id={'email'}
        onChangeHandle={onChangeEmailChangePassword}
        value={userEmailChangePassword}
        error={errorMessage ? false : true}
      />
      <button className="button green" onClick={() => resetPasswordHandle(userEmailChangePassword)}>
        Submit
      </button>
      <div className="errorFooter">{errorMessage ? errorMessage : ''}</div>
    </div>
  );
};

export default ForgotPasswordForm;
