import React from 'react';
import './checkButtons.css';
const CheckButtons = ({ styling, option_1, option_2, handle, active }) => {
  // console.log('option_1 :>> ', option_1);
  // console.log('CheckButtons :>> ', active);
  return (
    <div className={`contentRadio ${styling}`}>
      <div className="toggleBox">
        <input
          id="toggle-on"
          className={option_1 === active ? 'toggle toggle-left checked' : 'toggle toggle-left'}
          name="toggle"
          value="option1"
          type="radio"
        />
        <label htmlFor="toggle-on" className="btn" onClick={() => handle(option_1)}>
          <p> {option_1}</p>
        </label>
        <input
          id="toggle-off"
          className={option_2 === active ? 'toggle toggle-right checked' : 'toggle toggle-right'}
          name="toggle"
          value="option2"
          type="radio"
        />
        <label htmlFor="toggle-off" className="btn" onClick={() => handle(option_2)}>
          <p> {option_2}</p>
        </label>
      </div>
    </div>
  );
};

export default CheckButtons;
