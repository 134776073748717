import React, { useEffect, useState } from "react";
import TitlePage from "../../titlePage/TitlePage";
import SelectComponent from "./../SelectComponent";
import { useMediaQuery } from "react-responsive";
import CheckModeButtons from "./../CheckModeButtons";
import cl from "./HeroComponent.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getFilters } from "../../../toolkit/slices/getFiltersSlice";
import { Link } from "react-router-dom";
import { setHashtags } from "../../../toolkit/slices/roomTypeSlice";
// import { setTypeUploadPhoto } from "../../../toolkit/slices/createProjectSlice";
// import ImageBeforeAfterTab from "../../imageBeforeAfter/ImageBeforeAfterTab";
import { Swiper, SwiperSlide } from "swiper/react";
import { gtmEvent } from "../../../lib/gtm";
import { MyRangeImages } from "../../UI/MyRangeImages/MyRangeImages";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { dataPins } from "./pinsData";
import AttemptsOverModal from "../../modal/AttemptsOverModal";
import { createListOfImages } from "../../../helpers/createListOfImages";

const TabsContainer = () => {
  const [tabsData] = useState([
    {
      id: 1,
      active: true,
      name: "Residential",
      isAvailable: true,
    },
    {
      id: 2,
      active: false,
      name: "Commercial",
      isAvailable: false,
    },
    {
      id: 3,
      active: false,
      name: "Exterior",
      isAvailable: false,
    },
    {
      id: 4,
      active: false,
      name: "Events",
      isAvailable: false,
    },
    {
      id: 5,
      active: false,
      name: "Wall Painter",
      isAvailable: false,
    },
  ]);

  const isMobileMediaQuery = useMediaQuery({ query: "(max-width: 1025px)" });
  const cards = useSelector((state) => state.filters.roomType);
  const [isAttempts, setIsAttempts] = useState(false);

  const userInfo = useSelector((state) => state.getUser.userData);
  const dataStorage = JSON.parse(localStorage.getItem("data"));

  const setDataOfSyles = (id) => {
    const selectData = cards.find((e) => e.id === id);
    if (selectData) {
      dispatch(setHashtags(["roomtype", selectData.name, selectData.name]));
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFilters(0));
    dispatch(getFilters(1));
  }, [dispatch]);

  const [isActiveStyle, setIsActiveStyle] = useState({
    name: "Living room",
    id: 1,
  });

  const handleSelect = (slide) => {
    setDataOfSyles(slide.id);
    setIsActiveStyle(slide);
  };

  return (
    <>
      {isAttempts ? (
        <AttemptsOverModal
          title={`Sorry, your attempts are over, you need to ${"Top Up"}`}
          topUp
          isActive={isAttempts}
          setIsActive={setIsAttempts}
          lineBreak={4}
        />
      ) : null}
      <div className="container">
        <div className={cl.tabsBlock}>
          <Swiper className={cl.tabsList}>
            {tabsData.map((tab) => {
              return (
                <SwiperSlide className={cl.tabItem} key={tab.id}>
                  <button
                    className={[
                      cl.tabButton,
                      tab.isAvailable ? cl.tabAvailable : "",
                      tab.active ? cl.tabActive : "",
                    ].join(" ")}
                    disabled={!tab.isAvailable}
                    aria-label={`${tab.name} mode`}
                  >
                    {tab.name}
                    {!tab.isAvailable ? (
                      <span className={"soon"}>Soon</span>
                    ) : null}
                  </button>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>

        <section
          id="Resedential"
          className={`${cl.section} tabcontent ${
            tabsData.find((e) => e.active).name === "Resedential"
              ? "active"
              : ""
          }`}
        >
          <div className="sliderContent room_type">
            {isMobileMediaQuery ? (
              <SelectComponent tabSlider={cards} handleSelect={handleSelect} />
            ) : (
              // <Slider {...settings} ref={sliderRef} afterChange={testIndex}>
              <Swiper
                spaceBetween={11}
                slidesPerView={"auto"}
                autoHeight={true}
                className={cl.swiperStyles}
              >
                {cards.length
                  ? cards.map((slide, index) => (
                      <SwiperSlide
                        key={slide.name}
                        className={[
                          cl.slideStyles,
                          isActiveStyle.name === slide.name
                            ? cl.activeStyle
                            : "",
                        ].join(" ")}
                        onClick={(e) => {
                          handleSelect(slide);
                        }}
                      >
                        <LazyLoadImage
                          src={slide.image}
                          width={47}
                          height={47}
                          alt={slide.name}
                          className={cl.imageSlide}
                        />
                        <p className={cl.textStyle}>{slide.name}</p>
                      </SwiperSlide>
                    ))
                  : [1, 2, 3, 4, 5, 6, 7].map((slide, index) => (
                      <SwiperSlide
                        key={slide}
                        className={[cl.slideStyles, cl.slideSkeleton].join(" ")}
                      >
                        <div className={cl.imageSlide}></div>
                        <p style={{ visibility: "hidden" }}>asdasdasd</p>
                      </SwiperSlide>
                    ))}
              </Swiper>
            )}
          </div>
          <div className="body">
            <div className="info">
              <TitlePage
                title={"Design your dream [break] home"}
                lineBreak={1}
                h1
                size={"normal"}
                animation={true}
              />
              <div className="discription">
                Tailored designs for every room, reflecting your unique
                lifestyle. from functional kitchens to relaxing retreats
              </div>
              {"attempts_left" in userInfo && dataStorage ? (
                +userInfo.attempts_left <= 0 ||
                +dataStorage.attempts_left <= 0 ? (
                  <button
                    className="button green"
                    onClick={() => setIsAttempts(true)}
                  >
                    create now
                  </button>
                ) : (
                  <div className={cl.buttonWrapper}>
                    <Link to="/interior-ai/mode-options/">
                      <button
                        className="button green"
                        onClick={() =>
                          gtmEvent("Create_Now_Resedential", "category")
                        }
                      >
                        create now
                      </button>
                    </Link>
                  </div>
                )
              ) : (
                <div className={cl.buttonWrapper}>
                  <Link to="/interior-ai/mode-options/">
                    <button
                      className="button green"
                      onClick={() =>
                        gtmEvent("Create_Now_Resedential", "category")
                      }
                    >
                      create now
                    </button>
                  </Link>
                </div>
              )}
            </div>
            <div className="image">
              <div className="projectModalLanding">
                {/* <div className={onLoadImg ? "skeleton" : ""}></div> */}
                <ImageRangeScene
                  activeName={isActiveStyle.name}
                  // imagesData={createListOfImages(
                  //   isActiveStyle.name,
                  //   imageTypeMode
                  // )}
                  // pins={activePins}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

const ImageRangeScene = ({ activeName }) => {
  const [imageTypeMode, setImageTypeMode] = useState("fill_the_space");
  const imagesData = createListOfImages(activeName, imageTypeMode);

  const modeList = [
    { id: 0, name: "Inspiration", type_mode: "inspiration" },
    activeName === "Living room"
      ? { id: 1, name: "Fill the space", type_mode: "fill_the_space" }
      : null,
    { id: 2, name: "Redesign", type_mode: "redesign" },
  ].filter((e) => e);

  const selectRoomTypeView = (val) => {
    const type_mode = val.target.getAttribute("data-type-mode");
    setImageTypeMode(type_mode);
  };

  let activePins = dataPins[activeName.replace(/(-| )/gim, "_").toLowerCase()]
    ? dataPins[activeName.replace(/(-| )/gim, "_").toLowerCase()][imageTypeMode]
    : null;

  return (
    <div className="overflow">
      {imagesData.length - 1 ? (
        <MyRangeImages
          imageBefore={imagesData[1].path}
          imageAfter={imagesData[0].path}
          rangeClass={cl.rangeBlock}
          pinsData={activePins}
        />
      ) : (
        <div className={cl.imageBlock}>
          <div className={cl.skeletonimage}></div>
          <LazyLoadImage
            alt={"Inspiration"}
            height={410}
            src={imagesData[0].path}
            className={cl.imageInpiration}
            width={"100%"}
          />
        </div>
      )}

      <div className="positionRadioButtonBox 444">
        {modeList.find((e) => e.type_mode === "fill_the_space") ? (
          <CheckModeButtons
            option={"type"}
            styling={"whiteCheckBox"}
            groupName={"roomType"}
            modeList={modeList}
            // handle={selectUploadImageTypeHandle}
            active={"Fill the space"}
            selectRoomTypeView={selectRoomTypeView}
            numberOfActiveEl={1}
          />
        ) : (
          <CheckModeButtons
            option={"type"}
            styling={"whiteCheckBox"}
            groupName={"roomType"}
            modeList={modeList}
            // handle={selectUploadImageTypeHandle}
            active={"Fill the space"}
            selectRoomTypeView={selectRoomTypeView}
            numberOfActiveEl={2}
          />
        )}
      </div>
    </div>
  );
};

export default TabsContainer;
