import React from 'react';
import './button.css';
import { Link } from 'react-router-dom';

const ChoiceButtonAccount = ({ isActive }) => {
  return (
    <div className={'choiceButton account'}>
      <ul>
        <li className={isActive === 'info' ? 'active' : ''}>
          <Link to={'/profile/'}>Personal Info</Link>
        </li>
        <li className={isActive === 'portfolio' ? 'active' : ''}>
          <Link to={'/profile/gallery/'}>My gallery</Link>
        </li>
        <li className={isActive === 'billing' ? 'active' : ''}>
          <Link to={'/profile/billing/'}>Billing</Link>
        </li>
      </ul>
    </div>
  );
};

export default ChoiceButtonAccount;
