import React, { useEffect } from "react";
import Error404Grid from "../components/error404/Error404Grid";
import { useDispatch } from "react-redux";
import { setPageType } from "../toolkit/slices/checkTypePageSlice";
import { Helmet } from "react-helmet-async";

const Error404 = ({ pageType }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageType(pageType));
  });
  return (
    <>
      {pageType === "error" ? (
        <Helmet>
          <title>Something went wrong!</title>
        </Helmet>
      ) : (
        <Helmet>
          <title>Page not found</title>
          <meta name="errorpage" content="true" />
          <meta name="errortype" content="404 - Not Found" />
          <meta name="prerender-status-code" content="404" />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      )}
      <Error404Grid isError={pageType === "error"} />
    </>
  );
};

export default Error404;
