import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = { data: {}, errorMessage: '' };

export const getUserInfoAccount = createAsyncThunk(
  'getUserInfoAccount/getUserInfoAccount',
  async (_, { rejectWithValue, dispatch, getState }) => {
    const token = localStorage.getItem('key');
    // console.log('token :>> ', token);
    await axios
      .get(`${process.env.REACT_APP_BACKEND_HOST}/api/v1/rest-auth/user/`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        // console.log('getUserInfoAccount :>> ', response.data);
        localStorage.setItem('data', JSON.stringify(response.data));
        dispatch(setData(response.data));
      })
      .catch(function (error) {
        console.log('error :>> ', error.response.data);
      });
  },
);

export const infoUserSlice = createSlice({
  name: 'infoUser',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
  },
});

export const { setData, setErrorMessage } = infoUserSlice.actions;
export default infoUserSlice.reducer;
