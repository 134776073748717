import React, {useState} from "react";
// import gsap from 'gsap';
import CircleCheckIcon from "../../icon/CircleCheckIcon";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import cl from "./AuthComponent.module.css";
import CheckBoxGroupe from "../../input/CheckBoxGroupe";
import { setProductInfo } from "../../../toolkit/slices/authorization/singInSlice";
import SelectVisionsSliderComponent from "../../input/SelectVisionsSliderComponent";
import {
  setValueVision,
  setValueMoney,
  setValuePercent,
  setAdditionalValueVisions,
} from "../../../toolkit/slices/rangeVisionSlice";
import InfoIcon from "../../icon/InfoIcon";

const PriceBlockModal = ({
  paymentToggle,
  variantsOpenPrice,
  isVisiblePrice,
  isSignUp,
}) => {
  const dispatch = useDispatch();
  const valueMoney = useSelector((state) => state.rangeVisionSlice.valueMoney);
  const valueVisions = useSelector(
    (state) => state.rangeVisionSlice.valueVisions
  );
  const valuePercent = useSelector(
    (state) => state.rangeVisionSlice.valuePercent
  );
  const trackBackground = `linear-gradient(to right, #000 ${valuePercent}%, rgb(0, 0, 0, 0.09) ${valuePercent}%, rgb(0, 0, 0, 0.09) 100%)`;
  const handleChangeVision = (event) => {
    var min = 2;
    var max = 10;
    var percentage = ((event.target.value - min) / (max - min)) * 100;

    dispatch(setValuePercent(percentage));
    dispatch(setValueMoney(event.target.value));
    dispatch(setValueVision(event.target.value * 5));
    dispatch(setAdditionalValueVisions(event.target.value * 5 / 10));

  };

  const additionalValueVisions = useSelector((state) => state.rangeVisionSlice.additionalValueVisions);
  const activeCheckBoxName = useSelector((state) => state.singin.productInfo);
  const [isShowMore, setIsShowMore] = useState(false);

  const productList = [
    { name: "pro", group: "stripe_products" },
    { name: "personal", group: "stripe_products" },
    // { id: 12, name: 'asdas_dadasda', group: 'stripe_products' },
    // { id: 13, name: 'asdas_dadasda_asdasd', group: 'stripe_products' },
  ];
  const advantagesList = [
    {
      name: "Free trial",
      tooltip: "",
      type: ["Free", "Personal"],
      description: "No",
    },
    { name: "Free trial", tooltip: "", type: ["Pro"], description: "" },
    { name: "Watermark", tooltip: "", type: ["Free"], description: "" },
    {
      name: "Watermark free",
      tooltip: "",
      type: ["Personal", "Pro"],
      description: "",
    },
    { name: "Low quality", tooltip: "", type: ["Free"], description: "" },
    {
      name: "High quality",
      tooltip: "",
      type: ["Personal", "Pro"],
      description: "",
    },

    { name: "Public mode", tooltip: "", type: ["Free"], description: "" },
    {
      name: "Private mode",
      tooltip: "",
      type: ["Personal", "Pro"],
      description: "",
    },

    {
      name: "Limited",
      tooltip: "",
      type: ["Free", "Personal"],
      description: "",
    },
    { name: "Unlimited", tooltip: "", type: ["Pro"], description: "" },

    {
      name: "Personal usage only",
      tooltip: "",
      type: ["Free"],
      description: "",
    },
    {
      name: "Commercial usage license (1 person)",
      tooltip: "",
      type: ["Personal"],
      description: "",
    },
    {
      name: "Commercial usage license (5 persons)",
      tooltip: "",
      type: ["Pro"],
      description: "",
    },

    {
      name: "Number of visualizations",
      tooltip: "",
      type: ["Free"],
      description: "1",
    },
    {
      name: "Number of visualizations (soon)",
      tooltip: "",
      type: ["Personal", "Pro"],
      description: "4",
    },
    {
      name: "Furniture recognitions",
      tooltip: "",
      type: ["Free", "Personal", "Pro"],
      description: "",
    },
    {
      name: "Download",
      tooltip: "",
      type: ["Personal", "Pro"],
      description: "",
    },
    {
      name: "Personal AI designer",
      tooltip: "",
      type: ["Personal", "Pro"],
      description: "",
    },
    {
      name: "Premium support",
      tooltip: "",
      type: ["Personal", "Pro"],
      description: "",
    },
    {
      name: "Commercial properties",
      tooltip: "",
      type: ["Pro"],
      description: "",
    },

    // { name: '', tooltip: '', type: ['Free', 'Personal', 'Pro'], description: '' },
    // { name: '', tooltip: '', type: ['Free', 'Personal', 'Pro'], description: '' },
  ];

  const changeHandler = (name) => {
    dispatch(setProductInfo(name));
    localStorage.setItem("payments", name);
  };

  return (
    <motion.div
      // className="blackSide"
      className={[
        activeCheckBoxName === "pro" ? "blackSide" : "blackSide green",
        cl.priceBlock,
        isSignUp ? cl.activePrice : "",
        isShowMore ? cl.showFeaturesActive : "",
      ].join(" ")}
      animate={
        window.innerWidth < 576
          ? ""
          : isVisiblePrice
          ? "visiblePrice"
          : "hiddenPrice"
      }
      variants={variantsOpenPrice}
      initial={false}
      onClick={(e) => setIsShowMore(!isShowMore)}
    >
      <CheckBoxGroupe
        productList={productList}
        activeCheckBoxName={activeCheckBoxName}
        changeHandler={changeHandler}
      />
      {activeCheckBoxName === "pro" ? (
        <div
          className={`product_info_modal ${
            activeCheckBoxName === "pro" ? "black" : "green"
          } ${cl.proBlock}`}
        >
          <div>
            <div className="title">{activeCheckBoxName}</div>
            <div className="subTitle">
              Paintit Pro is ideal for redecorating your home, interior
              designers and real estate agents
            </div>
          </div>

          <div className={`${cl.priceDesktop}`}>
            <div className="price">
              <div>$10</div>
              <span>
                per
                <br />
                month
              </span>
            </div>
          </div>
          <div className={`${cl.mobilePrice}`}>
            <div className="price">
              <div>$10</div>
              <span>
                per
                <br />
                month
              </span>
            </div>
          </div>
            <ul className={cl.listFeatures}>
              {advantagesList.map((advantage, index) => {
                if (advantage.type.includes("Pro")) {
                  return (
                    <li key={advantage.name + "_" + index}>
                      <div className="check">
                        <div className="proPlan">
                          <CircleCheckIcon />
                        </div>
                      </div>
                      <div className="">
                        {advantage.name}
                        {advantage.tooltip && (
                          <span>
                            <InfoIcon />
                          </span>
                        )}
                      </div>
                    </li>
                  );
                }
                return null;
              })}
            </ul>
      
        </div>
      ) : (
        <div
          className={`product_info_modal ${
            activeCheckBoxName === "pro" ? "black" : "green"
          }`}
        >
          <div>
            <div className="title">{activeCheckBoxName}</div>
            <div className="subTitle">
              Add a set of 10 options for only +5 usd
            </div>
          </div>
          <SelectVisionsSliderComponent
            color={"green"}
            addValue={additionalValueVisions}
            valueMoney={valueMoney}
            valueVisions={valueVisions}
            handleChangeVision={handleChangeVision}
            trackBackground={trackBackground}
          />
          <ul className={cl.listFeatures}>
            {advantagesList.map((advantage, index) => {
              if (advantage.type.includes("Personal")) {
                return (
                  <li key={advantage.name + "_" + index}>
                    <div className="check">
                      <div className="proPlan">
                        <CircleCheckIcon />
                      </div>
                    </div>
                    <div className="">
                      {advantage.name}
                      {advantage.tooltip && (
                        <span>
                          <InfoIcon />
                        </span>
                      )}
                    </div>
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </div>
      )}

      {/* <div className="center">{planValue === 4 && 'Comming soon'}</div> */}
    </motion.div>
  );
};

export default PriceBlockModal;
