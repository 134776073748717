export const smoothScrollToPrice = (id) => {
  const priceElement = document.getElementById(id);
  if (priceElement) {
    const startPosition = window.scrollY;
    const targetPosition = priceElement.offsetTop;
    let scrollDistance = targetPosition - startPosition;

    let startTime = null;

    function animationStep(timestamp) {
      if (!startTime) startTime = timestamp;
      let timeElapsed = timestamp - startTime;
      let ease = easeInOutQuad(timeElapsed, startPosition, scrollDistance, 500); // adjust duration as needed

      window.scrollTo(0, ease);

      if (timeElapsed < 500) {
        // adjust duration here as well
        window.requestAnimationFrame(animationStep);
      }
    }

    window.requestAnimationFrame(animationStep);
  }
};

export function easeInOutQuad(t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
}
