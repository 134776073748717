import React from 'react';

const ArrowBack = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.5 1.5L1.70711 6.29289C1.31658 6.68342 1.31658 7.31658 1.70711 7.70711L6.5 12.5"
        stroke="black"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default ArrowBack;
