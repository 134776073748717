import { Link } from "react-router-dom";
import imageLogo from "../../images/logo.png";
import TickIcon from "../icon/TickIcon";
import { useDispatch, useSelector } from "react-redux";
import LoginButton from "../menu/LoginButton";
import { ImageAttempts } from "../icon/ImageAttempts";
import cl from "./../landingPage/Header/HeaderMenu.module.css";
import QuickQuiz from "./QuickQuiz/QuickQuiz";
import { usePathname } from "../../../src/hooks/usePathname";
import { ArrowDown } from "../UI/Icons/ArrowDown";
import { resetQuiz } from "../../toolkit/slices/createProjectSlice";
import { cn } from "../../lib/utils";

const QuizMenu = ({ result = false }) => {
  const dispatch = useDispatch();
  const hashTag = useSelector((state) => state.roomType.hastagArr);
  const userInfo = useSelector((state) => state.getUser.userData);
  const uploadType = useSelector((state) => state.createProject.uploadType);
  const { roomtype, roomdesignstyle } = useSelector(
    (state) => state.roomType.hastagArr
  );
  // console.log(uploadType);
  const path = usePathname();
  const isFirstPage = path.includes("mode-options");
  const backToMain =
    uploadType === 0
      ? isFirstPage || !(roomtype.hashtag && roomdesignstyle.hashtag)
      : isFirstPage || uploadType === 1;

  const isAuth = localStorage.getItem("key");
  const userName = localStorage.getItem("name");
  const dataStorage = JSON.parse(localStorage.getItem("data"));

  return (
    <>
      <div className={`topnav topnav-fixed_top`} id="myTopnav">
        <div
          className={`container topnav-container quiz-menu ${
            result && "quiz-cont"
          }`}
        >
          <div>
            {backToMain ? (
              <div className="absolute">
                <Link
                  to={"/"}
                  onClick={() => {
                    dispatch(resetQuiz());
                  }}
                  className=" z-0 inline-flex arrow-up gap-2.5 cursor-pointer transition-all duration-300 py-3 h-[38px] pl-4 pr-5 rounded-full bg-light-gray items-center justify-center !text-sm leading-none font-medium"
                >
                  <ArrowDown className="transition-all duration-200 -rotate-90" />
                  Back to Home
                </Link>
              </div>
            ) : null}
            <div
              className={cn(
                `relative z-20 transition-all opacity-0 pointer-events-none`,
                !backToMain ? "opacity-1 pointer-events-auto duration-300" : ""
              )}
            >
              <QuickQuiz />
            </div>
          </div>

          {!result ? (
            <div className="pipeline quiz-menu_center">
              <ul>
                {hashTag["modeoptions"].name ? (
                  <li className="black">
                    <TickIcon />
                  </li>
                ) : (
                  <li>1</li>
                )}
                {hashTag["roomtype"].name ? (
                  <li className="black">
                    <TickIcon />
                  </li>
                ) : (
                  <li>2</li>
                )}
                {hashTag["roomdesignstyle"].name ? (
                  <li className="black">
                    <TickIcon />
                  </li>
                ) : (
                  <li>3</li>
                )}
                {/* {uploadType !== 1 ? (
                  hashTag["colorpalette"].name ? (
                    <li className="black">
                      <TickIcon />
                    </li>
                  ) : (
                    <li>4</li>
                  )
                ) : null} */}
                {uploadType !== 1 ? (
                  hashTag["additionaltags"].name ? (
                    <li className="black">
                      <TickIcon />
                    </li>
                  ) : (
                    <li>4</li>
                  )
                ) : null}
              </ul>
            </div>
          ) : null}

          <div
            className={`logo quiz-menu_right ${result ? "center-logo" : ""}`}
          >
            <Link
              to="/"
              title=""
              className="nav__link "
              onClick={(e) => {
                dispatch(resetQuiz());
              }}
            >
              <img src={imageLogo} alt="logo" />
            </Link>
          </div>

          {result ? (
            <div className="resultLog">
              <LoginButton
                textValue={isAuth && userName ? userName : "Log In / sign up"}
              />
              {isAuth ? (
                <div className={`buttonMenu login relk ${cl.attemptsCounts}`}>
                  <div className="icon">
                    <ImageAttempts />
                  </div>
                  <div className="text">
                    {"attempts_left" in userInfo && userInfo.attempts_left
                      ? userInfo.attempts_left
                      : dataStorage.attempts_left}
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default QuizMenu;
