import React, { useEffect } from "react";
import TitlePage from "../../components/titlePage/TitlePage";
import AccountForms from "../../components/account/AccountForms";
import { useDispatch } from "react-redux";
import { getUserInfoAccount } from "../../toolkit/slices/account/infoUserSlice";
import ChoiceButtonAccount from "../../components/button/ChoiceButtonAccount";
import { useTopToPage } from "../../lib/scrypts";
import { setPageType } from "../../toolkit/slices/checkTypePageSlice";
import MyCrumbs from "../../components/UI/MyCrumbs/MyCrumbs";
import { Helmet } from "react-helmet-async";

const InfoPersonal = ({ pageType }) => {
  useTopToPage();
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("data"));

  useEffect(() => {
    dispatch(getUserInfoAccount());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setPageType(pageType));
  });


  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="container">
        <MyCrumbs classCrumbs={"centerCrumbs"} />
        <TitlePage
          title={`Welcome to personal account [break] ${userData.first_name}`}
          lineBreak={3}
          size={"normal"}
          h1
          animation={true}
        />
        <AccountForms />
        <ChoiceButtonAccount isActive={"info"} />
      </div>
    </>
  );
};

export default InfoPersonal;
