import React, { useEffect } from "react";
import ResultGrid from "../components/QuizGroup/ResultPage/ResultGrid";
import CreateProjectModal from "../components/modal/CreateProjectModal";
import { useOpenStrype, useTopToPage } from "../lib/scrypts";
import { useDispatch, useSelector } from "react-redux";
import { getModal, startPolling } from "../toolkit/slices/getResultImageSlice";
import VerifyEmailModal from "../components/modal/VerifyEmailModal";
import { checkEmail } from "../toolkit/slices/confirmEmailSlice";
import { setPageType } from "../toolkit/slices/checkTypePageSlice";
import AttemptsOverModal from "../components/modal/AttemptsOverModal";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
// import { testPromt } from '../toolkit/slices/createProjectSlice';
// import { postEmagineRetry } from '../toolkit/slices/imagineRetrySlice';

const Result = ({ pageType }) => {
  useTopToPage();
  useOpenStrype();
  const isAuthStorage = localStorage.getItem("key");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageType("result"));
  });
  const status = useSelector((state) => state.longPolling.status);
  const imageId = useSelector((state) => state.longPolling.imageId);
  const isAuth = useSelector((state) => state.createProject.isAuth);
  const isActiveModal = useSelector((state) => state.createProject.createModal);

  const isConfirmEmail = useSelector((state) => state.confirmEmail.isConfirm);
  const confirmedEmail = useSelector((state) => state.confirmEmail.userEmail);
  const newImageId = useSelector((state) => state.emagineRetry.imagine_id);
  const errorMessage = useSelector((state) => state.createProject.errorMessage);

  const key = localStorage.getItem("key");

  const pageLink = window.location.pathname.slice(1);
  const pageLinkParams = window.location.search;
  const [urlParams] = useSearchParams(pageLinkParams);
  const token = urlParams.get("token");
  const user_id = urlParams.get("user_id");
  const imagine_id = urlParams.get("imagine_id");
  let checkPathname = pageLink.split("/")[0];
  let newPath = "";
  if (checkPathname === process.env.REACT_APP_BASE_NAME) {
    newPath = `/${process.env.REACT_APP_BASE_NAME}/`;
  } else {
    newPath = "/";
  }
  // console.log(checkPathname);

  useEffect(() => {
    if (status === "Completed") {
      dispatch(getModal(false));
      window.location.replace(`${newPath}?id=${imageId}`);
    }
  });


  useEffect(() => {
    if (newImageId) {
      dispatch(
        startPolling({ newImageId: newImageId, confirmedEmail: confirmedEmail })
      );
      // console.log(newImageId);
    }
  }, [newImageId, confirmedEmail, dispatch]);

  useEffect(() => {
    if (token !== null && user_id !== null) {
      dispatch(
        checkEmail({ key: token, user_id: user_id, imagine_id: imagine_id })
      );
      // dispatch(postEmagineRetry());
    }
  }, [token, user_id, imagine_id, dispatch]);

  //confirmEmail
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {/* <button onClick={() => dispatch(testPromt())}>testPromt</button> */}
      <ResultGrid />
      {isConfirmEmail && <CreateProjectModal />}
      {isActiveModal && <CreateProjectModal />}
      {!isAuth && <VerifyEmailModal />}
      {errorMessage === "You have run out of tokens." && key ? (
        <AttemptsOverModal
          title={
            "Sorry, your test 3 attempts are over, you need to subscribe to continue"
          }
          lineBreak={6}
        />
      ) : (
        ""
      )}
      {errorMessage === "You have run out of tokens." && !key ? (
        <AttemptsOverModal
          title={`Sorry, your test 3 attempts are over, you need to ${
            isAuth && isAuthStorage ? "Top Up" : "Sign Up"
          }`}
          topUp={isAuth && isAuthStorage ? true : false}
          lineBreak={6}
        />
      ) : (
        ""
      )}
      {status === "Failed" && (
        <AttemptsOverModal
          isButton={false}
          title={
            "Something went wrong, our AI is tired and needs some rest. Please try again in a few minutes"
          }
          lineBreak={2}
        />
      )}
    </>
  );
};

export default Result;
