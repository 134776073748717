import React from "react";
import "./privacyPolicy.css";
import { useTopToPage } from "../../lib/scrypts";
import MyCrumbs from "../UI/MyCrumbs/MyCrumbs";
import { Helmet } from "react-helmet-async";

const TermsOfService = () => {
  useTopToPage();

  const crumbsMicrodata = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Main page",
        item: "/",
      },
      {
        "@type": "ListItem",
        position: 4,
        name: "Terms of Service",
      },
    ],
  });

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>PaintIt.ai Terms of Service</title>
        <link rel="canonical" href={"https://paintit.ai/terms-of-service/"} />
        <meta property="og:title" content={"PaintIt.ai Terms of Service"} />
        <meta name="twitter:title" content={"PaintIt.ai Terms of Service"} />
        <meta
          name="description"
          content="Read the terms of service for using PaintIt.ai's innovative AI-powered design tools and services."
        />
        <meta
          property="og:description"
          content={
            "Read the terms of service for using PaintIt.ai's innovative AI-powered design tools and services."
          }
        />
        <meta
          name="twitter:description"
          content={
            "Read the terms of service for using PaintIt.ai's innovative AI-powered design tools and services."
          }
        />
        <script type="application/ld+json">{crumbsMicrodata}</script>
      </Helmet>
      <div className="container">
        <div className={"crumbsBlock"}>
          <MyCrumbs />
        </div>
        <div className="privacy_policy">
          <h1 className="title large">Terms of Service</h1>
          {/* <p>Free hour, when our</p> */}
          {/* <ol>
          <li>
            <p>Free hour, when</p>
          </li>
          <li>
            <p>Free hour,</p>
          </li>
        </ol> */}
          {/* <div className="title sub">Privacy Policy</div> */}

          <div className="title sub">Welcome to paintit.ai</div>

          <p>
            <span>
              The following terms of service (these &#8220;Terms of
              Service&#8221;), govern your access to and use of the PAINTITAI
              LTD website and mobile application, including any content,
              functionality and services offered on or through
              https://paintit.ai/ or the PAINTITAI LTD mobile application (the
              &#8220;Site&#8221;) by PAINTITAI LTD (London, England). PAINTITAI
              LTD and its subsidiaries are collectively referred hereto as
              &#8220;PAINTITAI&#8221; &#8220;we&#8221; or &#8220;us&#8221; and
              “you” or “user” means you as a user of the Site.
            </span>
          </p>
          <p>
            <span>
              Please read the Terms of Service carefully before you start to use
              the Site. By using the Site, clicking &#8220;order&#8221; you
              accept and agree, on behalf of yourself or on behalf of your
              employer or any other entity (if applicable), to be bound and
              abide by these Terms of Service and PAINTITAI Payment Terms, found
              here (“Payment Terms”), which is incorporated herein by reference.
              You further acknowledge, you have read and understood our Privacy
              Policy, found here. If you do not want to agree to these Terms of
              Service or the Privacy Policy, you must not access or use the
              Site. For more detailed policies surrounding the activity and
              usage on the Site, please access the designated articles herein.
            </span>
          </p>
          <p>
            <span>
              This Site is offered and available to users who are at least 18
              years of age and of legal age to form a binding contract. If you
              are under 18 and at least 13 years of age, you are only permitted
              to use the Site through an account owned by a parent or legal
              guardian with their appropriate permission. If you are under 13
              you are not permitted to use the Site or the PAINTITAI services.
              By using the Site, you represent and warrant that you meet all of
              the foregoing eligibility requirements. If you do not meet all of
              these requirements, you must not access or use the Site
            </span>
          </p>
          <p>
            <span>
              Our Customer Support team is available:{" "}
              <a href="mailto:hi@paintit.ai">hi@paintit.ai</a> if you have any
              questions regarding the Site or Terms of Service. Contacting our
              Customer Support team can be performed by submitting a request
              here.
            </span>
          </p>
          <p>
            <span>
              The original language of these Terms of Service, as well as all
              other texts throughout the Site, is{" "}
            </span>
            <span>English</span>
            <span>
              . PAINTITAI makes this translation available for convenience only.
              In case of conflicts between the original{" "}
            </span>
            <span>English </span>
            <span>version and any translation, the </span>
            <span>English </span>
            <span>version shall prevail.</span>
          </p>

          <div className="title sub">Key Terms</div>

          <p>
            <span>Clients are users who purchase services on Paintit.ai</span>
          </p>
          <p>
            <span>
              Custom Orders are requests made by a Buyer to receive a Custom
              Offer from a designer of PAINTITAI.
            </span>
          </p>
          <p>
            <span>
              Orders are the formal agreements between a Buyer and designer
              after a purchase was made on this site.
            </span>
          </p>
          <p>
            <span>
              Purchase &#8211; service for the implementation of 3D
              visualization by means of computer programs.
            </span>
          </p>
          <p>
            As a service providing AI-generated interior visualizations, we
            utilize third party APIs to deliver this unique experience to our
            users. Through third party APIs, we are able to offer our customers
            high-quality, personalized interior design visualizations, tailored
            to their preferences and requirements. By using our services, you
            acknowledge and agree that the visualization outputs are the result
            of the collaboration between our platform and third party APIs.
            Please review our Terms of Service carefully to understand the scope
            and limitations of our offerings. If you continue to use our
            service, it will be deemed as acceptance of these terms and
            conditions.
          </p>
          <p>
            As examples on our website, we have utilized interior design images
            sourced from Pinterest. These images serve as visual representations
            to showcase various design styles and ideas, providing inspiration
            for our users. Please note that these images are used for
            illustrative purposes only and do not necessarily reflect the exact
            output of our AI-generated interior visualizations. The actual
            results may vary based on individual preferences and input provided
            by our users during the design process.
          </p>

          <div className="title sub">Overview </div>

          <p>
            <span>
              After viewing the site and familiarizing yourself with our
              service, you can place an order for the service. To do this, you
              need to enter your basic data, following the instructions for
              placing an order.
            </span>
          </p>
          <p>
            <span>
              You are solely responsible for any activity on this site.
            </span>
          </p>
          <p>
            <span>
              Orders are purchased through the &#8221;Order&#8221; or &#8221;Get
              started&#8221; button.
            </span>
          </p>
          <p>
            <span>For payments please read the Payment Terms</span>
          </p>
          <p>
            <span>
              Client cannot cancel an order when it has already been paid.
            </span>
          </p>
          <p>
            <span>
              PAINTITAI retains the right to publish material on the site if the
              user has given appropriate confirmation for this action.
            </span>
          </p>
          <p>
            <span>About specification</span>
          </p>

          <div className="title sub">Orders and Purchasing</div>

          <p>
            <span>
              By going to the checkout page, clicking the &#8220;Start&#8221;
              button, you follow the instructions for placing your custom order.
              By uploading your data, you consent to their processing by the
              relevant persons performing the work at your request. After
              completing the steps for filling in and sending data, you get to
              the payment page, where by entering your personal data, you
              consent to their use. We do not use your personal data for
              purposes other than those described on the site. You can read more
              about the rights on the &#8220;privacy policy&#8221; page
            </span>
          </p>
          <p>
            <span>Cases of interaction with the client:</span>
          </p>
          <p>
            <span>
              If the purchase service is not performed within the period paid by
              the client, the parties may come to an agreement
            </span>
          </p>
          <ol>
            <li>
              <p>a full refund will be issued</p>
            </li>
            <li>
              <p>
                the site will return half of the cost of the order to the client
                and perform the visualization within the agreed time.
              </p>
            </li>
          </ol>
          <p>
            <span>
              If the client did not like the result of the designer&#8217;s
              vision
            </span>
          </p>
          <ol>
            <li>
              <p>
                the client has the right to write about it to the email address
                of the site support hi@paintit.ai, describing in detail what
                exactly did not like. In this case, the site will provide the
                client with a new corrected version of the visualization for
                free, taking into account the client&#8217;s comments.
              </p>
            </li>
          </ol>
          <p>
            <span>
              If the client is not satisfied with the result of the work as a
              whole, this will be discussed personally through the support
              service for further decisions.
            </span>
          </p>
          <p>
            <span>
              Users may not offer payments using any method other than placing
              an order through paintit.ai
            </span>
          </p>
          <p>
            <span>Non-Permitted Usage</span>
          </p>
          <p>
            <span>
              Adult Services &#038; Pornography &#8211; PAINTITAI does not allow
              any exchange of adult oriented or pornographic materials and
              services.
            </span>
          </p>
          <p>
            <span>
              Privacy &#038; Identity &#8211; You may not publish or post other
              people&#8217;s private and confidential information. Any exchange
              of personal information required for the completion of a service
              must be provided in the Order Page. PAINTITAI further confirm that
              whatever information they receive from the Clients , which is not
              public domain, shall not be used for any purpose whatsoever other
              than for the work.{" "}
            </span>
          </p>
          <p>
            <span>
              Intellectual Property Claims &#8211; PAINTITAI will respond to
              clear and complete notices of alleged copyright or trademark
              infringement, and/or violation of third party’s terms of service.{" "}
            </span>
          </p>

          <div className="title sub">
            Unsubscription from SAAS Product (image-to-image):
          </div>

          <p>
            <span>
              Users have the right to unsubscribe from the PRO account
              subscription any time on{" "}
            </span>
            <a href="https://paintit.ai/interior-ai/">
              <span>https://paintit.ai/interior-ai/</span>
            </a>
            <span>
              . To do so, please follow the instructions provided on the Site or
              contact our Customer Support team for assistance. Upon successful
              unsubscription, you will no longer be billed for the PRO account
              subscription. Please note that any fees paid prior to
              unsubscription are non-refundable unless otherwise specified in
              our Refund Policy.
            </span>
          </p>

          <div className="title sub">Refund Policy:</div>

          <p>
            <span>
              Please note that if you&#8217;ve purchased a monthly or yearly
              subscription, we do not offer refunds for these periods. However,
              you can easily cancel your subscription at any time through your
              personal account dashboard or by contacting us directly. Upon
              cancellation, your subscription will not be automatically renewed.
            </span>
          </p>
          <p>
            <span>All refund transactions are facilitated through</span>
            <a href="https://stripe.com/privacy" rel="nofollow">
              <span> Stripe</span>
            </a>
            <span>
              , our selected payment gateway. Refund amounts and conditions are
              assessed individually, in harmony with our Payment Terms and
              Stripe&#8217;s policies.
            </span>
          </p>

          <div className="title sub">Usage Rights:</div>

          <p>
            <span>
              We value the trust you place in us by purchasing our products. As
              such, we grant you the right to use the results of all products
              available on our site, for which you have made a payment, for both
              personal and commercial purposes. This means you can freely use
              the outputs in your projects, marketing materials, presentations,
              or any other medium, without any additional licensing fees or
              restrictions.
            </span>
          </p>

          <div className="title sub">Limitation on Liability</div>

          <p>
            <span>
              IN NO EVENT WILL PAINTITAI, SERVICE PROVIDERS, BE LIABLE FOR
              DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN
              CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SITE, ANY
              WEBSITES LINKED TO IT, ANY CONTENT ON THE SITE OR SUCH OTHER
              WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE OR
              SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL,
              INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT
              LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL
              DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR
              ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA,
              AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF
              CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.
            </span>
          </p>
          <p>
            <span>
              THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE
              EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </span>
          </p>

          <div className="title sub">Contact Information:</div>

          <p>
            <span>
              PAINTITAI LTD, 52 Leytonstone road, London, E15 1SQ, UK
              <br />
            </span>
            <span>
              Phone: +44 7366 359241 <br />
              Alternate Phone: +34 697 357 937 <br />
              Email: hi@paintit.ai
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsOfService;
