import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postChangePasswordUser } from '../../toolkit/slices/account/changePasswordUserSlice';
import ConfirmIcon from '../icon/confirm/ConfirmIcon';

const PasswordForm = ({ setNameForm }) => {
  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.changePasswordUser.errorMessage);
  const animation = useSelector((state) => state.changePasswordUser.animation);
  const [formData, setFormData] = useState({
    // currentPassword: '',
    new_password1: '',
    new_password2: '',
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData);
    // console.log('formData :>> ', formData);
    dispatch(postChangePasswordUser(formData));
  };
  return (
    <>
      <div className="title">change password</div>
      <form className="account" onSubmit={handleSubmit}>
        <div className="inputBox">
          <div className="title">new password</div>
          <input
            type="text"
            placeholder=""
            value={formData.new_password1}
            onChange={(e) => setFormData({ ...formData, new_password1: e.target.value })}
            className={errorMessage && 'error'}
          />
        </div>
        <div className="inputBox">
          <div className="title">ConfirM your password</div>
          <input
            type="text"
            placeholder=""
            value={formData.new_password2}
            onChange={(e) => setFormData({ ...formData, new_password2: e.target.value })}
            className={errorMessage && 'error'}
          />
        </div>
        <div className="buttonBox">
          <button className="transparent" onClick={() => setNameForm('')}>
            Cancel
          </button>
          <button className="" type="submit">
            {animation ? <ConfirmIcon /> : 'Save change'}
          </button>
        </div>
        <div className="errorMessage">{errorMessage}</div>
      </form>
    </>
  );
};

export default PasswordForm;
