import React from "react";

const SelectVisionsSliderComponent = ({
  color,
  valueMoney,
  valueVisions,
  handleChangeVision,
  trackBackground,
  addValue,
  visions,
}) => {
  return (
    <>
      <div
        className="price_range_block"
        style={{
          display:
            color === "green" && visions !== "per month" ? "block" : "none",
        }}
      >
        <div className="buy">
          <div className="price">${valueMoney}</div>
          <div className="visions">
            <div className="visions_block_value">
              <div className={"add_value_vis"}>+{addValue}</div>
              {valueVisions}
            </div>{" "}
            <span>Tokens</span>
          </div>
        </div>
      </div>
      <div className="range_slider_container">
        <input
          type="range"
          min="2"
          max="10"
          step="2"
          defaultValue={valueMoney}
          className="range_money"
          id="myRange"
          onChange={handleChangeVision}
          style={{
            background: trackBackground,
          }}
        />
      </div>
    </>
  );
};

export default SelectVisionsSliderComponent;
