import React from "react";
import "./slider.css";
import { motion } from "framer-motion";

const slidesArr = [
  "/img/home/main_1.jpg",
  "/img/home/main_2.jpg",
  "/img/home/main_3.jpg",
];
const animation = {
  0: {
    hidden: { rotate: 0, opacity: 0 },
    visible: {
      rotate: "-7deg",
      opacity: 1,
      transition: { type: "Tween", delay: 0.5, duration: 0.3 },
    },
  },
  1: {
    hidden: { rotate: 0, opacity: 0 },
    visible: {
      rotate: "7deg",
      opacity: 1,
      transition: { type: "Tween", delay: 0.5, duration: 0.3 },
    },
  },
  2: {
    hidden: { rotate: 0, opacity: 0 },
    visible: {
      rotate: "-7deg",
      opacity: 1,
      transition: { type: "Tween", delay: 0.5, duration: 0.3 },
    },
  },
};

const slides = slidesArr.map((slide, index) => {
  return (
    <motion.div
      key={index}
      data-index={index}
      initial={animation[index]["hidden"]}
      animate={animation[index]["visible"]}
      variants={animation[index]}
      className="slide"
      style={{ backgroundImage: "url(" + slide + ")" }}
    >
      <div style={{ backgroundImage: "url(" + slide + ")" }}></div>
    </motion.div>
  );
});

const Slider = () => {
  return <div className="slider">{slides}</div>;
};

export default Slider;
