import React from "react";
import "./privacyPolicy.css";
import { useTopToPage } from "../../lib/scrypts";
import MyCrumbs from "../UI/MyCrumbs/MyCrumbs";
import { Helmet } from "react-helmet-async";

const PrivacyPolicy = () => {
  useTopToPage();

  const crumbsMicrodata = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Main page",
        item: "/",
      },
      {
        "@type": "ListItem",
        position: 4,
        name: "Privacy Policy",
      },
    ],
  });

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>PaintIt.ai Privacy Policy</title>
        <link rel="canonical" href={"https://paintit.ai/privacy-policy/"} />
        <meta property="og:title" content={"PaintIt.ai Privacy Policy"} />
        <meta name="twitter:title" content={"PaintIt.ai Privacy Policy"} />
        <meta
          name="description"
          content="Learn how PaintIt.ai protects your personal information and ensures your privacy while using our services."
        />
        <meta
          property="og:description"
          content={
            "Learn how PaintIt.ai protects your personal information and ensures your privacy while using our services."
          }
        />
        <meta
          name="twitter:description"
          content={
            "Learn how PaintIt.ai protects your personal information and ensures your privacy while using our services."
          }
        />
        <script type="application/ld+json">{crumbsMicrodata}</script>
      </Helmet>
      <div className="container">
        <div className={"crumbsBlock"}>
          <MyCrumbs />
        </div>
        <div className="privacy_policy">
          <h1 className="title large">Privacy Policy</h1>
          {/* <p>Free hour, when our</p> */}
          {/* <ol>
          <li>
            <p>Free hour, when</p>
          </li>
          <li>
            <p>Free hour,</p>
          </li>
        </ol> */}
          {/* <div className="title sub">Privacy Policy</div> */}
          <p>
            Please read this privacy policy (the “Privacy Policy”) carefully to
            understand how PAINTITAI LTD, London (“PAINTITAI ”, “we”) uses
            personal information. By accessing or using https://paintit.ai/, you
            acknowledge that you have read and understood this Policy. This
            Policy may change from time to time; any changes we make to this
            Policy will be posted on this Site, we will also take any other
            steps, to the extent required by applicable law, including notifying
            you about material changes. Changes to this Policy are effective as
            of the stated “Last Updated” date. We recommend that you check the
            Policy periodically for any updates or changes.
          </p>
          <p>
            Information We Collect – You directly provide us with most of the
            information we collect: when you register to the Site, use it or
            complete forms. We also collect information about your
            communications with PAINTITAI.
          </p>
          <div className="title sub">The short version</div>
          <p>
            Our Legal Basis for Using Your Personal Information – Where relevant
            under applicable laws, all processing of your personal information
            will be justified by a “lawful ground” for processing as detailed
            below.
          </p>
          <p>
            How Do We Use the Information Collected? – We use personal
            information to provide you with quality service and security, to
            operate the Site, understand how people use the Site, and to perform
            our obligations to you; to ensure marketplace integrity and
            security; to prevent fraud; to contact you and send you direct
            marketing communications; to promote and advertise the Site, our
            services and the PAINTITAI service; to comply with lawful requests
            by public authorities and to comply with applicable laws and
            regulations.
          </p>
          <p>
            How Long Do We Keep Personal Information – We will keep personal
            information only for as long as is required to fulfil the purpose
            for which it was collected. However, in some cases we will retain
            personal information for longer periods of time.
          </p>
          <p>
            Children – This Site is offered and available to users who are at
            least 18 years of age and of legal age to form a binding contract.
            Minors under 18 and at least 13 years of age, are only permitted to
            use the Site through an account owned by a parent or legal guardian
            with their appropriate permission. Minors under 13 are not permitted
            to use the Site or the PAINTITAI service. We do not knowingly
            collect personal information from children under 13.
          </p>
          <p>
            Sharing Personal Information with Third Parties – We share personal
            information with third parties in order to operate the Site, provide
            our services to you, fulfil obligations imposed on us by applicable
            laws and regulations, and prevent fraud, infringements and illegal
            activities.
          </p>
          <p>
            Where We Store Personal Information – Some of the personal
            information you provide to us will be stored or processed on our
            behalf by third party suppliers and data processors and may be
            located in other jurisdictions, such as the Ukraine.
          </p>
          <p>
            Cookies – We use cookies and similar technologies (such as web
            beacons, pixels, tags, and scripts) to improve and personalize your
            experience, provide our services, analyze website performance and
            for marketing purposes.
          </p>
          <p>
            Do Not Track (DNT) – Our Site does not respond to Do Not Track (DNT)
            signals.
          </p>
          <p>
            External Links – the Site do not contains links to third party
            sites.
          </p>
          <p>
            Security – We implement technical and organizational measures to
            maintain the security of the Site and your personal information and
            in preventing unauthorized access, loss, misuse, alteration,
            destruction or damage to it through industry standard technologies
            and internal procedures.
          </p>
          <p>
            Rights of EU, EEA, USA and UK Users – Where we process personal data
            related to EU, EEA, USA and UK user, further terms apply to our
            processing in relation to your rights as a data subject under EU
            data protection laws, as detailed below.
          </p>
          <p>
            Contact Us –{" "}
            <span>
              <a href="mailto:hi@paintit.ai" target="_blank" rel="noreferrer">
                hi@paintit.a
              </a>
              i
            </span>
          </p>
          <p>
            The above are just the highlights. We encourage you to read more
            about the information we collect, how we use it, understand the
            meaning of cookies and more in the long version of our Policy below.
          </p>
          <div className="title sub">The longer version</div>
          <p>
            PAINTITAI is committed to protecting the personal information of its
            users – clients and other visitors browsing the Site (“users”). We
            believe that you have a right to know our practices regarding the
            information we collect when you visit and/or use the Site. This
            Policy explains our privacy practices for the Site and services
            provided by PAINTITAI. By accessing and/or using the Site, you
            acknowledge that you understand how we process your personal data in
            accordance with this Policy, including our collection, use,
            disclosure, processing and retention of personal information. You
            can also learn how to limit sharing of information in this Policy.
          </p>
          <div className="title sub">Information We Collect</div>
          <p>
            Information You Provide. When you use the Site, complete forms, we
            ask you to provide certain personal information. We
          </p>
          <p>
            <span>
              will also ask you to provide or otherwise collect additional
              information, such as, your name, profile details, billing
              information, telephone number or other contact details,
              transactional information, payment information (for example, in
              certain cases we process your payment method and credit card
              number). We also collect information about or contained in your
              communications with <span>PAINTITAI</span>.
            </span>
          </p>
          <p>
            <span>
              Information We Collect Automatically. We collect information while
              you access, browse, view or otherwise use the Site. In other
              words, when you access the Site we collect personal information on
              your usage of the Site, including transactions and the pages you
              visited, as well as other actions on Site. We also, collect, use
              and process the information relating to such usage, including
              geo-location information, IP address, device and connection
              information, browser information and web-log information, and the
              URLs of the web pages you’ve viewed or engaged with before or
              after using the Site. We also collect and process information
              relating to the use of cookies and similar technologies, as
              detailed below. We use that information to provide you our
              services, enhance user experience, personalize your browsing
              experience as well as monitor the Site for preventing fraud and
              inappropriate content or behaviour.
            </span>
          </p>
          <p>
            <span>
              Additionally, in order to improve your online experience at{" "}
              <span>PAINTITAI</span> , we have implemented impression reporting.
              While you view our ads, we gather user Global Unique Identifier,
              HTTP request data like, user agent, IP, host, URL,
              country/continent from which request made, browser info,
              device/operating system/operating system version.
            </span>
          </p>
          <p>
            <span>
              Information We Receive from Third Parties. We receive information
              from third party vendors and/or other commercially available
              sources, such as:
            </span>
          </p>
          <p>
            <span>
              Third party vendors who provide us data to supplement the
              information we collect about you, in accordance with applicable
              laws. For example, we receive fraud warnings from service
              providers for our fraud prevention and risk assessment efforts. We
              also receive information from enrichment service providers to
              enhance and personalize your experience as well as from
              advertising and marketing partners in order to manage and measure
              our campaigns.
            </span>
          </p>
          <p>
            <span>
              If you integrate into your account information from third parties
              or otherwise integrate your account with third party systems,
              tools or products, we will receive certain information about you
              according to your permissions at such third parties.{" "}
            </span>
          </p>
          <p>
            <span>
              We combine your personal information with information we collect
              automatically or obtain from other companies and use it for the
              purposes detailed in this Policy.
            </span>
          </p>
          <p>
            <span>Our Legal Basis for Using Your Personal Information</span>
          </p>
          <p>
            <span>
              Where relevant under applicable laws, all processing of your
              personal information will be justified by a &#8220;lawful
              ground&#8221; for processing. In the majority of cases, processing
              will be justified on the basis that:{" "}
            </span>
            <span>
              you have provided your consent for us to use your personal
              information for a specific purpose;{" "}
            </span>
            <span>
              our use of your personal information is necessary to perform a
              contract or take steps to enter into a contract with you (e.g. to
              provide you with services which you have purchased);{" "}
            </span>
            <span>
              the processing is necessary to comply with a relevant legal
              obligation or regulatory obligation that we have (e.g. fraud
              prevention); or{" "}
            </span>
            <span>
              the processing is necessary to support our legitimate interests as
              a business (e.g. to improve our services to you), subject to your
              interests and fundamental rights and provided it is conducted at
              all times in a way that is proportionate.
            </span>
          </p>
          <div className="title sub">
            How Do We Use The Information Collected?
          </div>
          <ol>
            <li>
              <p>
                to provide you with quality service and security, to operate the
                Site, to perform our obligations to you and to develop and
                improve our service. For example, we use personal information to
                verify your identity.
              </p>
            </li>
            <li>
              <p>
                <span>
                  to ensure marketplace integrity, prevent fraud and maintain a
                  safe and secure marketplace and services. For example, we use
                  personal information to monitor, track and prevent fraudulent
                  activities and other inappropriate activities, monitor content
                  integrity, conduct security investigations and risk
                  assessments, verify or authenticate information provided by
                  you, enforce our Terms of Service and comply with applicable
                  laws. We conduct certain behavioural analytics to achieve the
                  above objectives and in limited cases, if we detect activity
                  that we think poses a risk to the PAINTITAI service. If you
                  would like to challenge any such decision, please contact us
                  at{" "}
                  <span>
                    <a
                      href="mailto:hi@paintit.ai"
                      target="_blank"
                      rel="noreferrer"
                    >
                      hi@paintit.ai
                    </a>
                  </span>
                </span>
              </p>
            </li>
            <li>
              <p>
                to contact you, as requested by you or as otherwise approved by
                you or permitted according to this Policy.
              </p>
            </li>
          </ol>
          <p>
            We will ask for your consent before using information for a purpose
            other than those set out in this Policy.
          </p>
          <div className="title sub">
            How Long Do We Keep Personal Information?
          </div>
          <p>
            <span>
              We apply a general rule of keeping personal information only for
              as long as is required to fulfil the purpose for which it was
              collected. However, in some circumstances, we will retain your
              personal information for longer periods of time. We will retain
              personal information for the following purposes:
            </span>
          </p>
          <p>
            <span>
              as long as it is necessary and relevant for our operations, e.g.
              so that we have an accurate record of your dealings with us in the
              event of any complaints or challenge; and
            </span>
          </p>
          <p>
            <span>
              in relation to personal information from closed accounts to comply
              with applicable laws, prevent fraud, collect any fees owed,
              resolve disputes, troubleshoot problems, assist with any
              investigation, enforce our Site terms and take other actions as
              permitted by law.
            </span>
          </p>
          <div className="title sub">Children</div>
          <p>
            <span>
              This Site is offered and available to users who are at least 18
              years of age or older and of legal age to form a binding contract.
              Minors under 18 and at least 13 years of age, are only permitted
              to use the Site through an account owned by a parent or legal
              guardian with their appropriate permission. Minors under 13 are
              not permitted to use the Site or the PAINTITAI services. We do not
              knowingly collect personal information from children under 13.
              Parents and guardians should at all times supervise their
              children&#8217;s activities. If we learn we have collected or
              received personal information from a child under 13, we will
              delete that personal information. If you believe we might have any
              information from or about a child under 13, please
              <span> contact us at hi@paintit.ai.</span>
            </span>
          </p>

          <div className="title sub">
            Sharing Personal Information with Third Parties
          </div>
          <p>
            <span>
              We do not sell or rent your personal information to third parties
              for their marketing purposes without your explicit consent.
            </span>
          </p>
          <p>
            <span>
              We reserve the right to publish photos on the site with the
              consent of both parties. If the client does not want the photo
              uploaded by him (task for the designer) not to be used on the site
              for advertising purposes on the media platform, the paint group
              (namely, the site, instagram, facebook) does not publish this data
              on the site
            </span>
          </p>
          <p>
            <span>
              We share your personal information with third parties as detailed
              below:
            </span>
          </p>
          <p>
            <span>
              With designers PAINTITAI. This information includes photos you
              upload and additional information you may choose to add to your
              profile.
            </span>
          </p>
          <p>
            <span>
              With Service Providers. We share personal information for business
              purposes with service providers that provide us with services for
              the purpose of operating the Site, opening and operating your
              account as well as providing ancillary services and solutions.
              These include, among others, hosting services, billing and payment
              processors and vendors, CRM systems, forum hosting, community
              management services, data and cybersecurity services, web
              analytics and performance tools, translation tools, IT SaaS
              services, local recording, communication systems, mailing systems,
              data optimization and marketing services, data enrichment
              services, legal and financial advisors or technical consultants.
              Consistent with applicable legal requirements, we take appropriate
              technical and organizational measures to require third parties to
              adequately safeguard your personal information and only process it
              in accordance with our instructions;
            </span>
          </p>
          <p>
            <span>
              For legal reasons. We share personal information with law
              enforcement agencies, public authorities or other parties in order
              to respond to a subpoena or court order, judicial process or to
              regulatory authorities, if we believe we are required to do so by
              law, or that doing so is reasonably necessary to comply with legal
              processes; when we believe it necessary or appropriate to disclose
              personal information to law enforcement authorities, such as to
              investigate actual or suspected fraud or violations of law,
              breaches of security, or breaches of this Policy; to respond to
              claims against us; and to protect the rights, property, or
              personal safety of paintit.ai, our customers, or the public;
            </span>
          </p>
          <p>
            <span>
              For payments and fraud detection. We share personal information
              with payment processors, fraud detection agencies and similar
              third parties for the purpose of facilitating payments done via
              the Site and securing the Site and protecting it against fraud,
              unauthorized transactions (such as money laundering), claims or
              other liabilities;
            </span>
          </p>
          <p>
            <span>
              With social media partners. We also share limited personal
              information with such social media platforms and marketing
              partners, such as Facebook and Google, to promote our services or
              the PAINTITAI service. Such features and third party services
              collect certain information about you and may set a cookie to
              enable their functionality. Such third parties’ features are
              governed by their policies.
            </span>
          </p>

          <div className="title sub">
            {" "}
            Where We Store Your Personal Information
          </div>
          <p>
            Some of the personal information you provide to us will be stored or
            processed on our behalf by third party suppliers and data processors
            and may be located in other jurisdictions, such as the Ukraine,
            whose laws may differ from the jurisdiction in which you live. With
            regard to data transfers recipients outside we provide appropriate
            safeguards, in particular, by way of entering into country-specific
            standard contractual clauses as amended from time to time, and/or
            equivalent data transfer safeguards. We will take all steps
            reasonably necessary to ensure that your personal information is
            treated securely and in accordance with this Policy.
          </p>
          <div className="title sub">Cookies</div>
          <p>
            We use cookies and similar technologies (such as web beacons,
            pixels, tags, and scripts) to improve and personalize your
            experience, provide our services, analyze website performance and
            for marketing purposes. To learn more about how we and our third
            party service providers use cookies and your control over these
            Cookies, please see our Cookie Policy.
          </p>

          <div className="title sub">Do Not Track</div>

          <p>
            Do Not Track (DNT) is a privacy preference that users can set in
            some web browsers, allowing users to opt out of tracking by websites
            and online services. We do not honor browser requests not to be
            tracked online (known as “Do Not Track”), but our Cookie Policy
            describes how you can opt out of receiving cookies.
          </p>

          <div className="title sub">Security</div>

          <p>
            We implement technical and organizational measures to maintain the
            security of the Site, our services and personal information and in
            preventing unauthorized access, loss, misuse, alteration,
            destruction or damage to it through industry standard technologies
            and internal procedures. Among other things, we regularly maintain a
            PCI DSS (Payment Card Industry Data Security Standards)
            certification (with respect to payment by credit cards), as
            required. In addition, we contractually ensure that any third party
            processing your personal information equally provides for
            confidentiality and integrity of your data in a secure way. However,
            the transmission of data via the internet is not completely secure,
            and although we will do our best to protect your personal
            information, we cannot guarantee the security of your data
            transmitted to the Site. Once we have received your data, we will
            use strict procedures and security features to try to prevent
            unauthorized access.
          </p>
          <p>
            Users who have registered to the Site agree to keep their password
            in strict confidence and not disclose such password to any third
            party.
          </p>
          <p>
            Further information about our data security practices can be
            provided on request.
          </p>

          <div className="title sub">Contact Us</div>

          <p>
            Please include all the relevant details, so your email can be
            handled correctly. We will process any requests in line with any
            local laws and our policies and procedures. You may contact us at{" "}
            <a href="mailto:hi@paintit.ai" target="_blank" rel="noreferrer">
              hi@paintit.ai
            </a>
          </p>
          <p>
            If you have any questions (or comments) concerning this Policy, or
            any another claims please email our team at{" "}
            <a href="mailto:hi@paintit.ai" target="_blank" rel="noreferrer">
              hi@paintit.ai
            </a>
            , and we will make an effort to reply within a reasonable timeframe.
          </p>
          <p>
            <strong>PAINTITAI LTD</strong>
            <br />
            Company number: 14759763
            <br />
            Registered office address: 52 Leytonstone Road, London, England, E15
            1SQ
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
