import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import type { PayloadAction } from "@reduxjs/toolkit";

// interface SubscriptionState {
//   id: string;
//   subscription_type: number;
//   start: string;
//   end: string;
//   status: string;
//   is_valid: boolean;
//   customerPortal: string;
//   subscription_type_representation: string;
//   status_representation: string;
// }

interface AuthState {
  isAuth: boolean;
  token: string | null;
  user: {
    attempts_left: number;
    email: string;
    first_name: string;
    has_usable_password: boolean;
    id: string;
    is_email_confirmed: boolean;
    is_email_subscribed: boolean;
    is_paid: boolean;
    last_name: string;
    subscription_type: number;
  };
}

const initialState: AuthState = {
  isAuth: false,
  token: null,
  user: {
    attempts_left: 0,
    email: "",
    first_name: "",
    has_usable_password: false,
    id: "",
    is_email_confirmed: false,
    is_email_subscribed: false,
    is_paid: false,
    last_name: "",
    subscription_type: 0,
  },
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setAuth: (state, action: PayloadAction<AuthState>) => {
      state.isAuth = true;
      state.user = action.payload.user;
    },
    setUnAuth: (state) => {
      state.isAuth = false;
      state.token = null;
      state.user = initialState.user;
    },
  },
});

export const { setAuth, setUnAuth, setToken } = AuthSlice.actions;
export const selectAuth = (state: RootState) => state.auth;
export default AuthSlice.reducer;
