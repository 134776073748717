// import { useEffect } from 'react';

import { useEffect, useRef, useState } from 'react';
// import './style.css';

const CheckBoxGroupe = ({ productList, activeCheckBoxName, changeHandler }) => {
  const overlayRef = useRef(null);
  const radioButtonBox = useRef(null);

  //   const [activeTab, setActiveTab] = useState('pro');

  useEffect(() => {
    const activeTabSize = radioButtonBox.current.querySelector('.active');
    const overlay = overlayRef;
    overlay.current.style.height = `${activeTabSize.offsetHeight}px`;
    overlay.current.style.width = `${activeTabSize.offsetWidth}px`;
    overlay.current.style.top = `${activeTabSize.offsetTop}px`;
    overlay.current.style.left = `${activeTabSize.offsetLeft}px`;
    overlay.current.style.display = `block`;
  }, []);

  const selectRadioButton = (e) => {
    e.preventDefault();
    const activeTabName = e.currentTarget.querySelector('.radio-thing').getAttribute('data-name');
    // setActiveTab(activeTabName);
    console.log('radioButtonBox :>> ', activeTabName);

    changeHandler(activeTabName);

    const activeTabSize = e.currentTarget;

    const overlay = overlayRef;
    overlay.current.style.height = `${activeTabSize.offsetHeight}px`;
    overlay.current.style.width = `${activeTabSize.offsetWidth}px`;
    overlay.current.style.top = `${activeTabSize.offsetTop}px`;
    overlay.current.style.left = `${activeTabSize.offsetLeft}px`;
    overlay.current.style.display = `block`;
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className={`radioButtonBox`} ref={radioButtonBox}>
      <div className={`overlay`} ref={overlayRef}></div>
      {productList.map((mode) => {
        return (
          <div
            onClick={(e) => {e.stopPropagation();selectRadioButton(e)}}
            className={`radio-button ${activeCheckBoxName === mode.name ? 'active' : ''}`}
            key={`radio_key_${mode.name}`}>
            <input
              data-name={mode.name}
              type="radio"
              name={'stripe_prods'}
              className={`radio-thing`}
              id={`radio_${mode.name}`}
              checked={activeCheckBoxName === mode.name ? true : false}
              onChange={handleOnChange}
              //   readOnly
            />
            <label htmlFor={`radio_${mode.name}`}>{mode.name}</label>
          </div>
        );
      })}
    </div>
  );
};

export default CheckBoxGroupe;
