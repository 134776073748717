import React from 'react';
import { Link } from 'react-router-dom';
import './button.css';

const Button = ({ color, text, link, isDisable, onClick, target }) => {
  return (
    <Link to={link} target={target} className={`button ${color} ${isDisable}`} onClick={onClick}>
      {text}
    </Link>
  );
};

export default Button;
