import React from 'react';

const CircleCheckIcon = ({color = 'white'}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M7.95 10.35L6.3375 8.7375C6.2 8.6 6.025 8.53125 5.8125 8.53125C5.6 8.53125 5.425 8.6 5.2875 8.7375C5.15 8.875 5.08125 9.05 5.08125 9.2625C5.08125 9.475 5.15 9.65 5.2875 9.7875L7.425 11.925C7.575 12.075 7.75 12.15 7.95 12.15C8.15 12.15 8.325 12.075 8.475 11.925L12.7125 7.6875C12.85 7.55 12.9187 7.375 12.9187 7.1625C12.9187 6.95 12.85 6.775 12.7125 6.6375C12.575 6.5 12.4 6.43125 12.1875 6.43125C11.975 6.43125 11.8 6.5 11.6625 6.6375L7.95 10.35ZM9 16.5C7.9625 16.5 6.9875 16.303 6.075 15.909C5.1625 15.515 4.36875 14.9807 3.69375 14.3062C3.01875 13.6312 2.4845 12.8375 2.091 11.925C1.6975 11.0125 1.5005 10.0375 1.5 9C1.5 7.9625 1.697 6.9875 2.091 6.075C2.485 5.1625 3.01925 4.36875 3.69375 3.69375C4.36875 3.01875 5.1625 2.4845 6.075 2.091C6.9875 1.6975 7.9625 1.5005 9 1.5C10.0375 1.5 11.0125 1.697 11.925 2.091C12.8375 2.485 13.6312 3.01925 14.3062 3.69375C14.9812 4.36875 15.5157 5.1625 15.9097 6.075C16.3037 6.9875 16.5005 7.9625 16.5 9C16.5 10.0375 16.303 11.0125 15.909 11.925C15.515 12.8375 14.9807 13.6312 14.3062 14.3062C13.6312 14.9812 12.8375 15.5157 11.925 15.9097C11.0125 16.3037 10.0375 16.5005 9 16.5Z"
        fill={color}
      />
    </svg>
  );
};

export default CircleCheckIcon;
