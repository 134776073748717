import React from 'react';
import FilterDropDown from './FilterDropDown';
import { useSelector } from 'react-redux';
import { useResetFilter } from '../../lib/scrypts';

const FilterGrid = ({ pageType }) => {
  const allFilters = useSelector((state) => state.filters.all);
  const { isReset, setTrue, setFalse } = useResetFilter(false);
  const openFilterHandler = (val) => {
    const filteredData = allFilters.filter((item) => item.filter_type === val);
    const stateOptions = filteredData.map((item) => ({ value: item.name, label: item.name }));
    return stateOptions;
  };
  return (
    <div className="filter">
      <FilterDropDown
        type={'type'}
        typeVal={0}
        optionFilter={openFilterHandler(0)}
        isReset={isReset}
        setFalse={setFalse}
        pageType={pageType}
      />
      <FilterDropDown
        type={'design'}
        typeVal={1}
        optionFilter={openFilterHandler(1)}
        isReset={isReset}
        setFalse={setFalse}
        pageType={pageType}
      />

      <FilterDropDown pageType={pageType} type={'reset'} setTrue={setTrue} setFalse={setFalse} />
    </div>
  );
};

export default FilterGrid;
