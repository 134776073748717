import { FC, useLayoutEffect, useRef } from "react";
import cl from "./WidgetInnovation.module.css";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/all";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import TitlePage from "../../titlePage/TitlePage";
import { gtmEvent } from "../../../lib/gtm";
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

interface WidgetInnovationProps {}

const WidgetInnovation: FC<WidgetInnovationProps> = () => {
  const sectionRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (sectionRef.current) {
      const comp = sectionRef.current;
      const ctx = gsap.context(() => {
        const paths = [
          ...comp.querySelectorAll(`.${cl.pathAnim}`),
        ] as Element[];

        paths.reverse().forEach((path, i) => {
          gsap.fromTo(
            path,
            {
              opacity: 0,
            },
            {
              opacity: 1,
              transitionDelay: 0.1 * i,
              scrollTrigger: {
                trigger: comp.querySelector(`.${cl.graphDev}`),
                scrub: true,
                start: `top ${window.innerHeight * 0.6}px`,
                end: `top ${window.innerHeight * 0.6}px`,
              },
            }
          );
        });

        gsap.fromTo(
          comp.querySelectorAll(`.${cl.graphSales2}`),
          {
            y: "20%",
            opacity: 0,
          },
          {
            y: "0%",
            opacity: 1,
            scrollTrigger: {
              trigger: comp.querySelector(`.${cl.graphSales2}`),
              scrub: true,
              start: `top ${window.innerHeight * 0.75}px`,
              end: `top ${window.innerHeight * 0.75}px`,
              markers: false,
            },
          }
        );
        gsap.fromTo(
          comp.querySelectorAll(`.${cl.graphSales}`),
          {
            opacity: 0,
          },
          {
            opacity: 1,
            scrollTrigger: {
              trigger: comp.querySelector(`.${cl.graphSales}`),
              scrub: true,
              start: `top ${window.innerHeight * 0.75}px`,
              end: `top ${window.innerHeight * 0.75}px`,
              markers: false,
            },
          }
        );
      }, comp);
      return () => ctx.revert();
    }
  }, []);

  return (
    <section ref={sectionRef} className={cl.section}>
      <div className={`container ${cl.cont}`}>
        <TitlePage
          title={
            "Our innovative widget seamlessly integrates with your website, inviting [break] prospects to visualize and personalize spaces with style and sophistication that sells"
          }
          classTitle={cl.titleNew}
          lineBreak={8}
          h1
          size={"normal"}
          animation={true}
        />
        <div className={cl.benefitsBlock}>
          <ul className={cl.benefitsList}>
            <li className={cl.benefitsItem}>
              <h2 className={cl.benefitsTitle}>Up to 10%</h2>
              <p className={cl.benefitsDescr}>Increase in sale prices</p>
            </li>
            <li className={cl.benefitsItem}>
              <h2 className={cl.benefitsTitle}>+40%</h2>
              <p className={cl.benefitsDescr}>Engagement time</p>
            </li>
            <li className={cl.benefitsItem}>
              <h2 className={cl.benefitsTitle}>Up to 25%</h2>
              <p className={cl.benefitsDescr}>Conversion increase</p>
            </li>
            <li className={cl.benefitsItem}>
              <h2 className={cl.benefitsTitle}>5 min</h2>
              <p className={cl.benefitsDescr}>Integration process</p>
            </li>
          </ul>
        </div>
        <div className={cl.benefitsCards}>
          <div className={[cl.benefitsCard, cl.benefitsDevelopers].join(" ")}>
            <div className={cl.contentBlock}>
              <TitlePage
                title={"Benefits for Real Estate [break] Developers"}
                classTitle={cl.titleCard}
                lineBreak={3}
                left={true}
                size={"normal"}
                animation={true}
              />
              <p className={cl.titleList}>
                Effortless Integration & Enhanced Visualization
              </p>
              <ul className={cl.cardList}>
                <li className={cl.cardItem}>
                  <h3 className={cl.cardTitle}>Rapid 5-Minute Integration:</h3>
                  <p className={cl.cardDescr}>
                    Seamlessly embed our AI widget to turn static 2D floor plans
                    into interactive 3D visuals within minutes
                  </p>
                </li>
                <li className={cl.cardItem}>
                  <h3 className={cl.cardTitle}>Proven Market Performance:</h3>
                  <p className={cl.cardDescr}>
                    Experience up to a 10% increase in sales prices, similar to
                    the benefits seen with traditional staging in the US and
                    Spain markets
                  </p>
                </li>
              </ul>
            </div>
            <div className={cl.graphBlock}>
              <svg
                width="690"
                height="610"
                viewBox="0 0 690 610"
                className={cl.graphDev}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_790_321)">
                  <rect width="690" height="610" rx="14" fill="#F5F5F5" />
                  <g clip-path="url(#clip1_790_321)">
                    <line
                      x1="-141"
                      y1="348.166"
                      x2="690"
                      y2="348.166"
                      stroke="#DFDFDF"
                      stroke-width="1.66867"
                      stroke-dasharray="3.34 3.34"
                    />
                    <path
                      className={cl.pathAnim}
                      d="M643 151.675C643 147.988 640.012 145 636.325 145H609.675C605.988 145 603 147.988 603 151.675V349H643V151.675Z"
                      fill="#21CBA3"
                    />
                    <path
                      className={cl.pathAnim}
                      d="M560.039 232.223C560.039 228.536 557.051 225.548 553.364 225.548H529.72C526.033 225.548 523.045 228.536 523.045 232.223V349.03H560.039V232.223Z"
                      fill="#21CBA3"
                    />
                    <path
                      className={cl.pathAnim}
                      d="M478.656 270.602C478.656 266.916 475.668 263.928 471.982 263.928H444.637C440.951 263.928 437.963 266.916 437.963 270.602V349.03H478.656V270.602Z"
                      fill="#21CBA3"
                    />
                    <path
                      className={cl.pathAnim}
                      d="M395.414 293.964C395.414 290.277 392.426 287.289 388.739 287.289H361.395C357.709 287.289 354.721 290.277 354.721 293.964V349.03H395.414V293.964Z"
                      fill="#21CBA3"
                    />
                    <path
                      className={cl.pathAnim}
                      d="M312 332.675C312 328.988 309.012 326 305.325 326H279.675C275.988 326 273 328.988 273 332.675V349H312V332.675Z"
                      fill="#21CBA3"
                    />
                    <path
                      className={cl.pathAnim}
                      d="M230 370.325C230 374.012 227.012 377 223.325 377H196.675C192.988 377 190 374.012 190 370.325V348H230V370.325Z"
                      fill="#FEB4B4"
                    />
                    <path
                      className={cl.pathAnim}
                      d="M147 442.325C147 446.012 144.012 449 140.325 449H113.675C109.988 449 107 446.012 107 442.325V348H147V442.325Z"
                      fill="#FEB4B4"
                    />
                    <path
                      className={cl.pathAnim}
                      d="M64 506.325C64 510.012 61.0116 513 57.3253 513H30.6747C26.9884 513 24 510.012 24 506.325V348H64V506.325Z"
                      fill="#FEB4B4"
                    />
                    <rect
                      x="577"
                      y="83"
                      width="91"
                      height="43"
                      rx="21.5"
                      fill="black"
                    />
                    <path
                      d="M603.576 110.848H600.664V116H598.456V110.848H587.928V109.344L599.096 94.368H600.664V108.896H603.576V110.848ZM590.52 108.896H598.456V98.016L590.52 108.896ZM606.57 105.216C606.57 98.048 609.322 94.048 614.25 94.048C619.146 94.048 621.93 98.048 621.93 105.216C621.93 112.384 619.21 116.32 614.25 116.32C609.258 116.32 606.57 112.384 606.57 105.216ZM608.874 105.216C608.874 111.168 610.698 114.336 614.25 114.336C617.738 114.336 619.626 111.2 619.626 105.216C619.626 99.2 617.738 96.032 614.25 96.032C610.762 96.032 608.874 99.168 608.874 105.216ZM631.179 107.616C627.339 107.616 624.715 104.608 624.715 100.896C624.715 97.184 627.339 94.176 631.179 94.176C635.051 94.176 637.675 97.184 637.675 100.896C637.675 104.608 635.051 107.616 631.179 107.616ZM635.147 116H633.259L645.419 94.368H647.339L635.147 116ZM631.179 105.984C633.675 105.984 635.723 104.128 635.723 100.896C635.723 97.632 633.675 95.808 631.179 95.808C628.715 95.808 626.635 97.632 626.635 100.896C626.635 104.128 628.715 105.984 631.179 105.984ZM649.643 116.256C645.771 116.256 643.147 113.28 643.147 109.536C643.147 105.824 645.771 102.816 649.643 102.816C653.515 102.816 656.139 105.824 656.139 109.536C656.139 113.28 653.515 116.256 649.643 116.256ZM649.643 114.624C652.107 114.624 654.187 112.768 654.187 109.536C654.187 106.272 652.107 104.48 649.643 104.48C647.179 104.48 645.099 106.272 645.099 109.536C645.099 112.768 647.179 114.624 649.643 114.624Z"
                      fill="white"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_790_321">
                    <rect width="690" height="610" rx="14" fill="white" />
                  </clipPath>
                  <clipPath id="clip1_790_321">
                    <rect
                      width="685"
                      height="496"
                      fill="white"
                      transform="translate(5 57)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div className={[cl.benefitsCard, cl.benefitsSales].join(" ")}>
            <div className={cl.graphBlock2}>
              <img
                src="/img/g-1-layer-1.svg"
                alt="Graph Sales"
                className={cl.graphSales}
              />
              <img
                src="/img/g-1-layer-2.svg"
                alt="Graph Sales"
                className={cl.graphSales2}
              />
              <div className={cl.percentBlock2}>25%</div>
            </div>
            <div className={cl.contentBlock}>
              <TitlePage
                title={"Benefits for Real Estate [break] Sales"}
                classTitle={cl.titleCard}
                lineBreak={3}
                left={true}
                size={"normal"}
                animation={true}
              />
              <p className={cl.titleList}>
                Sales Optimization & Client Engagement
              </p>
              <ul className={cl.cardList}>
                <li className={cl.cardItem}>
                  <h3 className={cl.cardTitle}>Faster Transactions:</h3>
                  <p className={cl.cardDescr}>
                    Double the attraction to your listings, mirroring the 40%
                    increase in website engagement thanks to interactive
                    visualizations like those seen with traditional staging
                  </p>
                </li>
                <li className={cl.cardItem}>
                  <h3 className={cl.cardTitle}>Higher Conversion Rates:</h3>
                  <p className={cl.cardDescr}>
                    With objects of properties staged virtually selling at
                    approximately 20% above the asking price, expect to see an
                    increase in sales velocity, often twice as fast as the usual
                    sales period
                  </p>
                </li>
                <li className={cl.cardItem}>
                  <h3 className={cl.cardTitle}>Streamlined Sales Process: </h3>
                  <p className={cl.cardDescr}>
                    Enjoy a smoother, optimized sales journey with an
                    effortless, one-time integration and continuous minimal
                    maintenance, transforming engagement across platforms and
                    boosting the privacy of each property listing.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={cl.btnBlock}>
          <a
            href="https://calendly.com/paintit-ai/paintit-ai-widget-demo"
            target="_blank"
            rel="noreferrer"
            className={`button green ${cl.btn}`}
            onClick={() => {
              gtmEvent("SPEED_UP_SALES", "category");
            }}
          >
            Speed Up Sales
          </a>
        </div>
      </div>
    </section>
  );
};

export default WidgetInnovation;
