export const SaveIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <path
      d="M12 2.56725V10.7887C12 11.1337 11.8845 11.4218 11.6535 11.6528C11.422 11.8843 11.1337 12 10.7887 12H1.21125C0.86625 12 0.57825 11.8845 0.34725 11.6535C0.11575 11.422 0 11.1337 0 10.7887V1.21125C0 0.86625 0.11575 0.57825 0.34725 0.34725C0.57825 0.11575 0.86625 0 1.21125 0H9.43275L12 2.56725ZM5.99625 9.4035C6.41225 9.4035 6.76675 9.258 7.05975 8.967C7.35325 8.676 7.5 8.32275 7.5 7.90725C7.5 7.49175 7.3545 7.13725 7.0635 6.84375C6.7725 6.55025 6.41925 6.4035 6.00375 6.4035C5.58825 6.4035 5.23375 6.549 4.94025 6.84C4.64675 7.1315 4.5 7.485 4.5 7.9005C4.5 8.316 4.6455 8.6705 4.9365 8.964C5.2275 9.257 5.58075 9.4035 5.99625 9.4035ZM2.0775 4.3275H7.64475V2.0775H2.0775V4.3275Z"
      fill="#171D24"
    />
  </svg>
);
