import React from 'react';

const UserIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.5 8C11.1569 8 12.5 6.65685 12.5 5C12.5 3.34315 11.1569 2 9.5 2C7.84315 2 6.5 3.34315 6.5 5C6.5 6.65685 7.84315 8 9.5 8Z"
        fill="black"
      />
      <path
        d="M9.5 16.25C12.3995 16.25 14.75 14.9069 14.75 13.25C14.75 11.5931 12.3995 10.25 9.5 10.25C6.60051 10.25 4.25 11.5931 4.25 13.25C4.25 14.9069 6.60051 16.25 9.5 16.25Z"
        fill="black"
      />
    </svg>
  );
};

export default UserIcon;
