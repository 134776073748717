import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  all: [],
  roomType: [],
  roomDesign: [],
  palette: [],
  designAi: {},
};

export const getFilters = createAsyncThunk(
  "getFilters/getFilters",
  async (id, { dispatch }) => {
    // console.log(id);

    try {
      const filters = await axios.get(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/filters/?filter_type=${id}`
      );

      // console.log('filters :>> ', filters);

      if (id === 0) {
        if (filters.data.find((e) => e.name === "Living room")) {
          let indexOfLivingRoom = filters.data.findIndex(
            (e) => e.name === "Living room"
          );
          let newDataFilters = filters.data;
          [newDataFilters[0], newDataFilters[indexOfLivingRoom]] = [
            newDataFilters[indexOfLivingRoom],
            newDataFilters[0],
          ];
          dispatch(setRoomType(newDataFilters));
          return;
        }
        dispatch(setRoomType(filters.data));
      } else if (id === 1) {
        dispatch(setRoomDesign(filters.data));
      } else if (id === 2) {
        dispatch(setRoomDesign(filters.data));
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const getFiltersAll = createAsyncThunk(
  "getFiltersAll/getFiltersAll",
  async (_, { dispatch }) => {
    // console.log(id);
    try {
      const filters = await axios.get(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/filters/`
      );
      // console.log('getFiltersAll :>> ', filters);
      dispatch(setAll(filters.data));
    } catch (error) {
      console.log(error);
    }
  }
);

export const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setAll: (state, action) => {
      state.all = action.payload;
      state.palette = action.payload.filter((e) => e.filter_type === 2);
    },
    setDesignToAll: (state, action) => {
      state.designAi = {
        description: action.payload.long,
        prompt: action.payload.long,
        description_1: null,
        name: action.payload.short,
        id: uuidv4(),
      };
    },
    setRoomType: (state, action) => {
      state.roomType = action.payload;
    },
    setRoomDesign: (state, action) => {
      state.roomDesign = action.payload;
    },
    setPalette: (state, action) => {
      state.palette = action.payload;
    },
  },
});

export const {
  setAll,
  setRoomType,
  setRoomDesign,
  setPalette,
  setDesignToAll,
} = filtersSlice.actions;
export default filtersSlice.reducer;
