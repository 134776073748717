import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { setCustomerPortalLink } from './subscriptionPlanSlice';

const initialState = { errorMessage: '', stripeLink: '' };

export const postPaymentSubscription = createAsyncThunk(
  'postPaymentSubscription/postPaymentSubscription',
  async (data, { dispatch, getState }) => {
    // console.log('postPaymentSubscription data :>> ', data);
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/payments/subscription/create/`,
        { subscription_type: data.subscription_type },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${data.key}`,
          },
        },
      )
      .then((response) => {
        dispatch(setErrorMessage(''));
        dispatch(setStripeLink(response.data.url));
        localStorage.setItem('stripeLink', response.data.url);
        if (data.billing) {
          return response.data.url;
        }
      })
      .catch(function (error) {
        const textMessage = String(Object.values(error.response.data));
        dispatch(setErrorMessage(textMessage));
        throw error;
      });
  },
);

export const postPaymentProduct = createAsyncThunk(
  'postPaymentProduct/postPaymentProduct',
  async (data, { dispatch, getState }) => {
    // console.log('postPaymentProduct data :>> ', data);
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/payments/product/create/`,
        {
          amount: `${data.amount}`,
          name: `${data.name} visualisations`,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${data.key}`,
          },
        },
      )
      .then((response) => {
        dispatch(setErrorMessage(''));
        dispatch(setStripeLink(response.data.url));
        localStorage.setItem('stripeLink', response.data.url);
        // alert(JSON.stringify(data));
        if (data.billing) {
          return response.data.url;
        }
      })
      .catch(function (error) {
        const textMessage = String(Object.values(error.response.data));
        dispatch(setErrorMessage(textMessage));
        throw error;
      });
  },
);

export const getCustomPortal = createAsyncThunk(
  'getCustomPortal/getCustomPortal',
  async (id, { dispatch, getState }) => {
    const token = localStorage.getItem('key');
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/subscriptions/${id}/get-customer-portal/`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((response) => {
        dispatch(setCustomerPortalLink(response.data.customer_portal_link));
      })
      .catch(function (error) {
        console.log('error.response.data :>> ', error.response.data);
        dispatch(setCustomerPortalLink('THROTTLED'));
        throw error;
      });
  },
);

export const stripeSlice = createSlice({
  name: 'stripe',
  initialState,
  reducers: {
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setStripeLink: (state, action) => {
      state.stripeLink = action.payload;
    },
    setChoisePlanStripeLink: (state, action) => {
      state.choisePlanStripeLink = action.payload;
    },
  },
});

export const { setErrorMessage, setStripeLink } = stripeSlice.actions;

export default stripeSlice.reducer;
