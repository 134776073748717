export const blogsMicrodata = ({results}) =>
  JSON.stringify({
    "@context": "https://schema.org/",
    "@type": "Blog",
    "@id": "https://paintit.ai/blogs/",
    mainEntityOfPage: "https://paintit.ai/blogs/",
    name: "Blog",
    description: "Blog description",
    publisher: {
      "@type": "Organization",
      "@id": "https://paintit.ai/",
      name: "Paintit.ai",
      logo: {
        "@type": "ImageObject",
        "@id":
          "https://www.eu-startups.com/wp-content/uploads/2023/06/PAINTITAI-150x150.jpg",
        url: "https://www.eu-startups.com/wp-content/uploads/2023/06/PAINTITAI-150x150.jpg",
        width: "100",
        height: "100",
      },
    },
    blogPost: results.map((blog) => ({
      "@type": "BlogPosting",
      "@id": `http://paintit.ai/blogs/${blog.slug}/`,
      mainEntityOfPage: `http://paintit.ai/blogs/${blog.slug}/`,
      headline: blog.slug,
      name: blog.title,
      description: blog.description,
      datePublished: blog.published_at.split("T")[0],
      dateModified: blog.published_at.split("T")[0],
      author: {
        "@type": "Person",
        "@id": "https://paintit.ai/",
        name: "Paintit.ai",
      },
      image: {
        "@type": "ImageObject",
        "@id": blog.meta_image,
        url: blog.meta_image,
        height: "350",
        width: "320",
      },
      url: `http://paintit.ai/blogs/${blog.slug}/`,
    })),
  });
