import React, { useState } from "react";
import AccordionUploadPhoto from "./AccordionUploadPhoto";
import { useDispatch } from "react-redux";
import {
  setImageBase64,
  setImageFileName,
  setImageFileSize,
  setImageHeight,
  setImagePaintBase64,
  setImageWidth,
} from "../../../toolkit/slices/createProjectSlice";
import { useSelector } from "react-redux";
import cl from "./UploadPhoto.module.css";
import { Draw } from "../../icon/Draw";
import { setHashtags } from "../../../toolkit/slices/roomTypeSlice";
// import { useTopToPage } from "../../lib/scrypts";
import toast, { Toaster } from "react-hot-toast";

const notify = () =>
  toast("The file size is too large. The maximum file size is 4MB", {
    icon: "⚠️",
    iconTheme: {
      primary: "#000",
      secondary: "#FFA500",
    },
  });

const UploadPhotoBlock = ({
  id,
  title,
  subTitle,
  active,
  handleItemClick,
  uploadZone,
  classBG,
  token,
  promptConfig,
  promptNegativeConfig,
  setIsModalDraw,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const dispatch = useDispatch();
  const { imageBase64, imagePaintBase64 } = useSelector(
    (state) => state.createProject
  );

  const cancelModeType = (e) => {
    e.preventDefault();
    setIsDragging(false);
    dispatch(setImageBase64(""));
    dispatch(setImageHeight(0));
    dispatch(setImageWidth(0));
    dispatch(setImagePaintBase64(""));
    dispatch(setImageFileName(""));
    dispatch(setImageFileSize(""));
    dispatch(setHashtags(["modeoptions", "", ""]));
  };
  const isAuth = localStorage.getItem("key");

  const uploadImages = (file, id) => {
    let fileSize = isAuth ? 20005000 : 4001000;
    if (file && file.size < fileSize) {
      if (
        file.type.startsWith("image/") &&
        file.type.match(/(png|jpg|jpeg)/gi)
      ) {
        const reader = new FileReader();
        reader.onload = () => {
          const img = new Image();
          img.onload = () => {
            if (id === 2) {
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");

              const roundedToFinal = (n) => ~~(n / 8) * 8;

              let maxValue = 1360;
              let width = img.width;
              let height = img.height;

              if (width > maxValue || height > maxValue) {
                let ratio = Math.min(maxValue / width, maxValue / height);
                width = width * ratio;
                height = height * ratio;
              }

              // Resize the canvas
              canvas.width = roundedToFinal(width);
              canvas.height = roundedToFinal(height);

              // Draw the image onto the canvas (cropped)
              ctx.drawImage(img, 0, 0, width, height);

              // Get the cropped image data as base64
              const croppedDataUrl = canvas.toDataURL(file.type);
              console.log(croppedDataUrl);
              dispatch(setImageBase64(croppedDataUrl));
              dispatch(setImageHeight(height));
              dispatch(setImageWidth(width));
            } else {
              dispatch(setImageBase64(reader.result));
              dispatch(setImageHeight(img.height));
              dispatch(setImageWidth(img.width));
            }
            dispatch(setImageFileName(file.name));
            dispatch(setImageFileSize(file.size));
            // setIsModalDraw(true);
          };
          img.src = reader.result;
        };
        reader.readAsDataURL(file);
      }
    } else if (file && file.size > 4001000) {
      notify();
    }
  };

  const handleDrop = (e, id, active) => {
    e.preventDefault();
    if (active === id) {
      const file = e.dataTransfer.files[0];
      uploadImages(file, id);
      setIsDragging(false);
    }
  };

  const handleDragOver = (e, id, active) => {
    e.preventDefault();
    if (active === id) {
      setIsDragging(true);
    }
  };

  const handleDragLeave = (e, id, active) => {
    e.preventDefault();
    if (active === id) {
      setIsDragging(false);
    }
  };

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        toastOptions={{
          className: "",
          duration: 4000,
          style: {
            background: "#fff",
            color: "#000",
            border: "1px solid #00FFC3",
            boxShadow: "0 0 10px #3333330b",
          },
        }}
      />
      <div
        className={`box ${active === id ? "active" : "not"} ${
          isDragging ? "hover" : ""
        }`}
        onClick={() =>
          handleItemClick(
            id,
            "",
            imageBase64,
            promptConfig,
            promptNegativeConfig
          )
        }
        onDrop={(e) => handleDrop(e, id, active)}
        onDragOver={(e) => handleDragOver(e, id, active)}
        onDragLeave={(e) => handleDragLeave(e, id, active)}
      >
        <div className="checker">
          <div className="tokens_block">{`${token} ${
            token > 1 ? "tokens" : "token"
          }`}</div>
          <div className="roundCheckBox">
            <input
              type="checkbox"
              checked={active === id ? "checked" : ""}
              id="checkbox"
              disabled
            />
            <label htmlFor="checkbox"></label>
          </div>
        </div>
        {id === 1 ? (
          <h2 className={["title", cl.titleSoon].join(" ")}>
            {title}
            {/* <span className={"soon"}>PRO</span> */}
          </h2>
        ) : (
          <h2 className="title">{title}</h2>
        )}
        <div className="subTitle">{subTitle}</div>
        <div className={`imageBox ${cl.imageWrapper} ${classBG}`}>
          {imageBase64 && id === active ? (
            id === 1 ? (
              <>
                <div
                  className={cl.brushBlock}
                  onClick={(e) => uploadZone && setIsModalDraw(true)}
                >
                  <Draw className={cl.brush} />
                </div>
                <img
                  src={imagePaintBase64 ? imagePaintBase64 : imageBase64}
                  alt="Dropped"
                />
              </>
            ) : (
              <img src={imageBase64} alt="Dropped" />
            )
          ) : (
            <div className="backgroundBlock">
              <div>
                <div className="shevron">Upload</div>
              </div>
              <div>
                <div className="shevron">Result</div>
              </div>
            </div>
          )}
        </div>
        {uploadZone ? (
          <AccordionUploadPhoto
            handleCancel={cancelModeType}
            handleUpload={uploadImages}
            id={id}
          />
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
};

export default UploadPhotoBlock;
