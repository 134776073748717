import React from 'react';
import './projectCard.css';
import { Link } from 'react-router-dom';
import OpenIcon from '../icon/project/OpenIcon';

const ProjectCard = ({ id, link, tags, account }) => {
  const typeName = tags.map((tag) => {
    if (tag.tag_type === 1) {
      return tag.name;
    } else {
      return '';
    }
  });

  let linkModalProject = account ? `?id=${id}&account=${account}` : `?id=${id}`;

  return (
    <Link to={linkModalProject} className="projectCard">
      <div className="typeName">{typeName}</div>
      <img src={link.image} alt="" />
      <div className="buttonsBlock">
        <button type="button">
          <OpenIcon />
        </button>
      </div>
    </Link>
  );
};

export default ProjectCard;
