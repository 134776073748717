export const PrintIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="18"
    viewBox="0 0 15 18"
    fill="none"
    {...props}
  >
    <path
      d="M6.42083 0.210938C6.36615 0.084375 6.23854 0 6.09635 0H5.40365C5.26146 0 5.13021 0.084375 5.07917 0.210938L4.32448 2.02852C4.20781 2.30977 3.79219 2.30977 3.67552 2.02852L2.92083 0.210938C2.86615 0.084375 2.73854 0 2.59635 0H2.25C1.28385 0 0.5 0.755859 0.5 1.6875V9H14.5V1.6875C14.5 0.755859 13.7161 0 12.75 0H8.90365C8.76146 0 8.63021 0.084375 8.57917 0.210938L7.82448 2.02852C7.70781 2.30977 7.29219 2.30977 7.17552 2.02852L6.42083 0.210938ZM0.5 10.125V11.25C0.5 12.491 1.54635 13.5 2.83333 13.5H5.16667V15.75C5.16667 16.991 6.21302 18 7.5 18C8.78698 18 9.83333 16.991 9.83333 15.75V13.5H12.1667C13.4536 13.5 14.5 12.491 14.5 11.25V10.125H0.5ZM7.5 15.1875C7.65471 15.1875 7.80308 15.2468 7.91248 15.3523C8.02187 15.4577 8.08333 15.6008 8.08333 15.75C8.08333 15.8992 8.02187 16.0423 7.91248 16.1477C7.80308 16.2532 7.65471 16.3125 7.5 16.3125C7.34529 16.3125 7.19692 16.2532 7.08752 16.1477C6.97812 16.0423 6.91667 15.8992 6.91667 15.75C6.91667 15.6008 6.97812 15.4577 7.08752 15.3523C7.19692 15.2468 7.34529 15.1875 7.5 15.1875Z"
      fill="#fff"
    />
  </svg>
);
