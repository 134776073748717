import React from 'react';
import { motion } from 'framer-motion';

const ArrowUp = ({ isOpenFree, color = '#252525' }) => {
  return (
    <motion.div
      initial="collapsed"
      animate={{
        rotate: isOpenFree ? 180 : 0,
        transition: { duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] },
      }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none">
        <path
          d="M3.9375 9.42188L9 14.4844L14.0625 9.42188M9 13.7812L9 3.51562"
          stroke={color}
          strokeWidth="1.6875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </motion.div>
  );
};

export default ArrowUp;
