export const Draw = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    {...props}
  >
    <path
      d="M17.918 0.887586C18.3608 0.590914 18.8929 0.457102 19.4235 0.50898C19.954 0.560858 20.4501 0.79521 20.8271 1.17206C21.2041 1.5489 21.4387 2.04489 21.4909 2.5754C21.543 3.10591 21.4094 3.63807 21.113 4.08109L18.2555 8.36509C16.9473 10.3274 15.09 11.8607 12.9155 12.7736C12.6169 11.921 12.1301 11.1465 11.4913 10.5077C10.8525 9.8689 10.0781 9.38216 9.22545 9.08359C10.1387 6.90886 11.6726 5.05149 13.6355 3.74359L17.918 0.887586ZM7.24846 11.0006C6.25389 11.0006 5.30007 11.3957 4.5968 12.0989C3.89354 12.8022 3.49846 13.756 3.49846 14.7506C3.49864 14.8752 3.46777 14.9979 3.40862 15.1076C3.34948 15.2173 3.26393 15.3106 3.15971 15.3789C3.05549 15.4472 2.93589 15.4885 2.8117 15.499C2.68752 15.5095 2.56268 15.4889 2.44846 15.4391C2.23952 15.3473 2.00762 15.3213 1.78354 15.3644C1.55945 15.4075 1.35378 15.5178 1.19381 15.6805C1.03384 15.8432 0.927142 16.0508 0.887875 16.2755C0.848609 16.5003 0.87863 16.7318 0.973956 16.9391C1.47247 18.0293 2.32907 18.916 3.40136 19.452C4.47365 19.9879 5.69706 20.1407 6.86824 19.885C8.03941 19.6293 9.08782 18.9805 9.83919 18.0465C10.5906 17.1124 10.9997 15.9493 10.9985 14.7506C10.9985 13.756 10.6034 12.8022 9.90011 12.0989C9.19684 11.3957 8.24302 11.0006 7.24846 11.0006Z"
      fill="#171D24"
    />
  </svg>
);
