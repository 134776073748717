import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  prompt: "",
  promptNegative: "",
  allPromt: {},
};

export const getConfigResult = createAsyncThunk(
  "getConfigResult/getConfigResult",
  async (_, { dispatch, getState }) => {
    try {
      await axios
        .get(`${process.env.REACT_APP_BACKEND_HOST}/api/v1/config/result/`)
        .then((response) => {
          // console.log('response.data :>> ', response.data);
          dispatch(setAllPromt(response.data));
          // dispatch(setPromt(response.data.prompt));
        })
        .catch((error) => {
          console.log("error :>> ", error.response.data);
        });
    } catch (error) {} 
  }
);

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setPromt: (state, action) => {
      state.prompt = action.payload;
    },
    setPromtNegative: (state, action) => {
      state.promptNegative = action.payload;
    },
    setAllPromt: (state, action) => {
      // console.log('action.payload :>> ', action.payload);
      state.allPromt = action.payload;
    },
  },
});

export const { setPromt, setPromtNegative, setAllPromt } = configSlice.actions;
export default configSlice.reducer;
