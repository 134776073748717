import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getUser } from "./getUserSlice";
import {
  postPaymentProduct,
  postPaymentSubscription,
  setErrorMessage,
  setStripeLink,
} from "./stripeSlice";
import {
  setSubscriptionAll,
  setSubscriptionDateEnd,
  setSubscriptionPlan,
} from "./subscriptionPlanSlice";

const initialState = {
  userToken: "",
  errorMessageLoginUser: "",
};

export const postLogin = createAsyncThunk(
  "postLogin/postLogin",
  async (data, { dispatch }) => {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/rest-auth/login/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // console.log('response :>> ', response.data);
        dispatch(setErrorMessageLogIn(""));
        if (!response.data.user_data.has_usable_password) {
          dispatch(setErrorMessageLogIn("You need update/create a password"));
        } else {
          if (!response.data.user_data.is_paid) {
            const payments = localStorage.getItem("payments");

            if (payments === "pro") {
              dispatch(
                postPaymentSubscription({
                  key: response.data.key,
                  subscription_type: 0,
                })
              );
            } else if (payments === "personal") {
              const payments_valueMoney = localStorage.getItem(
                "payments_valueMoney"
              );
              const payments_valueVisions = localStorage.getItem(
                "payments_valueVisions"
              );
              dispatch(
                postPaymentProduct({
                  key: response.data.key,
                  amount: Number(payments_valueMoney) * 100,
                  name: payments_valueVisions,
                })
              );
            }
          }
          // console.log('response.data :>> ', response.data);
          dispatch(setUserToken(response.data.key));
          dispatch(setStripeLink(""));
          dispatch(setErrorMessage(""));
          dispatch(setSubscriptionPlan(""));
          dispatch(setSubscriptionDateEnd(""));
          dispatch(setSubscriptionAll([]));
          localStorage.setItem("key", response.data.key);
          localStorage.setItem("email", response.data.user_data.email);
          localStorage.setItem("name", response.data.user_data.first_name);
          localStorage.setItem("user", response.data.user_data.id);
          localStorage.setItem("data", JSON.stringify(response.data.user_data));
          dispatch(getUser());
        }
      })
      .catch((error) => {
        if (
          Object.values(error.response.data)[0][0] !== "E-mail isn`t confirm."
        ) {
          dispatch(setErrorMessageLogIn(Object.values(error.response.data)));
        } else {
          dispatch(setErrorMessageLogIn("Email not verified"));
        }
      });
  }
);

export const logInSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setErrorMessageLogIn: (state, action) => {
      state.errorMessageLoginUser = action.payload;
    },
    setUserToken: (state, action) => {
      state.userToken = action.payload;
    },
  },
});

export const { setErrorMessageLogIn, setUserToken } = logInSlice.actions;
export default logInSlice.reducer;
