export const testimonialsData = [
  {
    img: "Alex.jpg",
    name: "Alex",
    profession: "Odesa, Ukraine - Residential",
    text: `Best UI, so easy and intuitive to use, especially when you don’t really understand interior design, like me for example.`,
  },
  {
    img: "John-Smith.jpg",
    name: "John Smith",
    profession: "New York, USA - Residential",
    text: `Thrilled with the AI-generated design of my living room! The process was so smooth, and the AI's visualization from scratch was key in choosing the perfect style. Exactly what I wanted. Highly recommend!`,
  },
  {
    img: "Emily-Johnson.jpg",
    name: "Emily Johnson",
    profession: "California, USA - Commercial",
    text: `Our office space transformation is incredible, all thanks to your AI service. It understood our brand and created a functional and beautiful space. The renderings were instrumental in presenting to our investors. Simply amazing!`,
  },
  {
    img: "Svetlana.jpg",
    name: "Svetlana",
    profession: "Kyiv, Ukraine - Residential",
    text: `The result is not perfect yet, but here is an option for inspiration - just super! There are so many ideas to be found.`,
  },
  {
    img: "Oliver-Brown.jpg",
    name: "Oliver Brown",
    profession: "Texas, USA - Residential",
    text: `The AI transformation of my kitchen is stunning. The process was professional, and the design is incredibly functional. The visualization really helped in deciding on the final look. Five stars to this innovative service!`,
  },
  {
    img: "Arthur-Clarke.jpg",
    name: "Arthur Clarke",
    profession: "London, UK - Residential",
    text: `Superb service from your AI tool! My flat now has a chic, cohesive space, thanks to the spot-on visualizations. The attention to detail was remarkable. Cheers to this tech-driven design approach!`,
  },
  {
    img: "Jordi.jpg",
    name: "Jordi",
    profession: "Barcelona, Spain - Residential",
    text: `It's fun to experiment with styles and colors. This tool will free up so much time for me to search for ideas and prepare references for clients.`,
  },
  {
    img: "Maximilian-Bauer.jpg",
    name: "Maximilian Bauer",
    profession: "Berlin, Germany - Commercial",
    text: `The AI's redesign of our cafe has been a hit! Professional and innovative, the visualizations helped us select the best design, which has received numerous compliments. Fantastic job by the AI tool!"`,
  },
  {
    img: "Priya-Desai.jpg",
    name: "Priya Desai",
    profession: "Mumbai, India - Residential",
    text: `I loved how the AI blended traditional and modern elements in my home. The visualizations from real images of my space were a game-changer. The final design is perfect and exactly meets my needs.`,
  },
  {
    img: "Pierre.jpg",
    name: "Pierre",
    profession: "Gent, Belgium - Residential",
    text: `I'm looking forward to new features and updates. You guys are simply incredible.`,
  },
  {
    img: "Amit-Patel-.jpg",
    name: "Amit Patel",
    profession: "New Delhi, India - Residential",
    text: `Impressed with the AI's quick turnaround and the stunning result. The visualizations helped me reimagine our living room, and it has never looked better. Great job by this innovative service!`,
  },
  {
    img: "Surasak-Wong.jpg",
    name: "Surasak Wong",
    profession: "Bangkok, Thailand - Residential",
    text: `The AI design service was exceptional. It transformed my condo into a stylish, yet cozy space, with visualizations that were incredibly helpful in deciding the final design. I am beyond satisfied.`,
  },
    {
    img: "Jason.jpg",
    name: "Jason",
    profession: "St. Catharines, Canada - Residential",
    text: `My basement has been transformed into the best playroom I could ask for. My twins are excited about their future playground.`,
  },
  {
    img: "Lukáš-Novák.jpg",
    name: "Lukáš Novák",
    profession: "Bratislava, Slovakia - Residential",
    text: `Your AI did an amazing job with my apartment. The visualizations brought my ideas to life, resulting in a modern, yet comfortable space. The attention to detail is commendable.`,
  },
  {
    img: "Maria-Rossi.jpg",
    name: "Maria Rossi",
    profession: "Rome, Italy - Residential",
    text: `Transformazione incredibile grazie al vostro AI! My kitchen is now a masterpiece of function and style. The AI's visualizations from my existing kitchen layout were key in achieving this fantastic result.`,
  },
];
