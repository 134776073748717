import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  tags: [],
};

export const getTag = createAsyncThunk("filters/getTags", async (id) => {
  const res = await axios.get(
    `${process.env.REACT_APP_BACKEND_HOST}/api/v1/tags/${id}/`
  );
  const data = res.data;
  return data;
});

export const getAllTags = createAsyncThunk(
  "filters/getAllTags",
  async (data) => {
    if (
      data.roomdesignstyle.match(
        /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gim
      )
    ) {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_HOST}/api/v1/clouds/?prompt_filter=${data.roomtypeId}&condition=AND&prompt_filter=${data.roomdesignstyle}`
        );
        const dataRes = res.data;
        return dataRes[0].tags;
      } catch (error) {
        return null;
      }
    }
    return null;
  }
);

export const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    deactivateTag: (state, action) => {
      let removeAllIncludedTags = state.tags.filter(
        (tag) =>
          !("includedBy" in tag && tag.includedBy.includes(action.payload))
      );
      state.tags = removeAllIncludedTags.map((tag) => {
        if ("excludedBy" in tag && tag.excludedBy === action.payload) {
          delete tag.excludedBy;
          delete tag.hidden;
          return tag;
        }
        return tag;
      });
    },
    removeAllTags: (state, action) => {
      return { ...state, tags: [] };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTag.fulfilled, (state, action) => {
      let excluded = action.payload.excludes;
      let includes = action.payload.includes;

      let modifiedTagList = state.tags.map((tag) => {
        let isExcludedTag = excluded.some((t) => t.id === tag.id);

        if (tag.id === action.payload.id) {
          return { ...tag, ...action.payload };
        }

        if (isExcludedTag) {
          if ("excludedBy" in tag) {
            return { ...tag, hidden: true, excludedBy: action.payload.id };
          }
          return { ...tag, hidden: true, excludedBy: action.payload.id };
        }

        return tag;
      });

      let indexOfActiveTag = state.tags.indexOf(action.payload.id);

      function insertArrayAt(array, index, arrayToInsert) {
        Array.prototype.splice.apply(array, [index, 0].concat(arrayToInsert));
        return array;
      }

      includes = includes.filter(
        (tag) => !modifiedTagList.some((t) => t.id === tag.id)
      );

      state.tags = insertArrayAt(
        modifiedTagList,
        indexOfActiveTag,
        includes.map((t) => {
          if ("includedBy" in t) {
            return { ...t, includedBy: [...t.includedBy, action.payload.id] };
          }
          return { ...t, includedBy: [action.payload.id] };
        })
      );
    });
    builder.addCase(getAllTags.fulfilled, (state, action) => {
      state.tags = action.payload ? action.payload : [];
    });
  },
});

export const { deactivateTag, removeAllTags } = tagsSlice.actions;
export default tagsSlice.reducer;
