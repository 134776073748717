import React from 'react';

const RoomDesignIcon = () => {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
    //   <path
    //     d="M10.9433 5.64729L14.6476 2.76579C14.7919 2.65386 14.9721 2.59842 15.1543 2.60988C15.3366 2.62134 15.5084 2.69891 15.6376 2.82804L17.8838 5.07354C18.0128 5.20278 18.0903 5.37466 18.1016 5.55693C18.1129 5.73919 18.0573 5.91933 17.9453 6.06354L15.0653 9.76779C15.2684 10.5961 15.2806 11.4597 15.101 12.2934C14.9214 13.1271 14.5547 13.9091 14.0286 14.5803C13.5025 15.2516 12.8307 15.7945 12.0641 16.168C11.2974 16.5416 10.4559 16.7361 9.60306 16.7368C6.97656 16.736 3.60156 15.6095 1.72656 14.4845C4.71156 12.2345 4.01181 10.877 4.35156 9.60954C5.14506 6.64704 7.98306 4.91679 10.9433 5.64729ZM13.5016 7.05504C13.5511 7.10304 13.5991 7.15179 13.6471 7.20054L14.4991 8.05254L16.3553 5.66604L15.0458 4.35654L12.6593 6.21279L13.5016 7.05504Z"
    //     fill="black"
    //   />
    // </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
      <g clipPath="url(#clip0_1221_1584)">
        <path
          d="M10.9433 5.64729L14.6476 2.76579C14.7919 2.65386 14.9721 2.59842 15.1543 2.60988C15.3366 2.62134 15.5084 2.69891 15.6376 2.82804L17.8838 5.07354C18.0128 5.20278 18.0903 5.37466 18.1016 5.55693C18.1129 5.73919 18.0573 5.91933 17.9453 6.06354L15.0653 9.76779C15.2684 10.5961 15.2806 11.4597 15.101 12.2934C14.9214 13.1271 14.5547 13.9091 14.0286 14.5803C13.5025 15.2516 12.8307 15.7945 12.0641 16.168C11.2974 16.5416 10.4559 16.7361 9.60306 16.7368C6.97656 16.736 3.60156 15.6095 1.72656 14.4845C4.71156 12.2345 4.01181 10.877 4.35156 9.60954C5.14506 6.64704 7.98306 4.91679 10.9433 5.64729ZM13.5016 7.05504C13.5511 7.10304 13.5991 7.15179 13.6471 7.20054L14.4991 8.05254L16.3553 5.66604L15.0458 4.35654L12.6593 6.21279L13.5016 7.05504Z"
          fill="black"
        />
      </g>
    </svg>
  );
};

export default RoomDesignIcon;
