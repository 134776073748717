import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { startPolling, startPollingIsAuth } from "./getResultImageSlice";
import { removeHashtags } from "./roomTypeSlice";
import { setActiveAuthModal } from "./authorization/authorizationModalSlice";
import { setErrorMessageLogIn } from "./authorization/logInSlice";

const guidanceScaleDefault = 4;
const imageStrengthDefault = 0.27;

const initialState = {
  userEmail: "",
  userKey: "",
  imageId: "",
  subscribe: "",
  isAuth: true,
  isPaid: false,
  createModal: false,
  errorMessage: "",
  progressUpload: 0,
  imageFileName: "",
  imageFileSize: "",
  imageBase64: "",
  imagePaintBase64: "",
  imageHeight: 0,
  imageWidth: 0,
  numberOfImages: 1,
  guidanceScale: guidanceScaleDefault,
  imageStrength: imageStrengthDefault,
  photorealism: false,
  // fill_the_space: false,
  uploadType: 0,
};

export const resetQuiz = createAsyncThunk("reset", (_, { dispatch }) => {
  dispatch(removeHashtags());
  dispatch(setResetQuiz());
  localStorage.removeItem("make design");
});

export const login = createAsyncThunk(
  "login/login",
  async ({ val, body }, { rejectWithValue, dispatch, getState }) => {
    const state = getState(); // <-- invoke and access state object
    let userData = {};
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/rest-auth/login/`,
        JSON.stringify(val),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        userData = response.data;
        dispatch(setIsPaid(userData.user_data.is_paid));
        dispatch(setUserKey(userData.key));
        console.log("response.data :>> ", response.data);
      })
      .catch((error) => {
        console.log("error.response.data :>> ", error.response.data);
        dispatch(setActiveAuthModal(true));
        dispatch(
          setErrorMessageLogIn("You are already registered, please login")
        );
      });

    console.log("body :>> ", body);
    let createImage = {};
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/integrations/imagine/`,
        JSON.stringify(body),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData.key}`,
          },
        }
      )
      .then((response) => {
        createImage = response.data;
        if (body.prompt_type === 1) {
          let curNum = localStorage.getItem("num_fill");
          if (curNum) {
            localStorage.setItem("num_fill", +curNum + 1);
          } else {
            localStorage.setItem("num_fill", 1);
          }
        }
        dispatch(setErrorMessage(""));

        // console.log('integrations/imagine/ :>> ', response.data);
      })
      .catch((error) => {
        // console.log('error integrations/imagine/ :>> ', error.response.data);
        const textMessage = String(Object.values(error.response.data));
        dispatch(setErrorMessage(textMessage));
        throw error;
      });

    let subscribeVal = state.createProject.subscribe;

    if (subscribeVal) {
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/users/email-subscribe/`,
        JSON.stringify({
          subscribe: subscribeVal,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData.key}`,
          },
        }
      );
    }

    if (createImage.status === 6) {
      dispatch(setIsAuth(false));
    } else {
      dispatch(setImageId(createImage.id));
      dispatch(setModalActive(true));
      dispatch(startPolling({ newImageId: createImage.id }));
      dispatch(removeHashtags());
    }
  }
);

export const isLogin = createAsyncThunk(
  "isLogin/isLogin",
  async ({ token, body }, { rejectWithValue, dispatch }) => {
    console.log("body :>> ", body);
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/integrations/imagine/`,
        JSON.stringify(body),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              token ? token : localStorage.getItem("key")
            }`,
          },
        }
      )
      .then((response) => {
        dispatch(setImageId(response.data.id));
        dispatch(setModalActive(true));
        if (body.prompt_type === 1) {
          let curNum = localStorage.getItem("num_fill");
          if (curNum) {
            localStorage.setItem("num_fill", +curNum + 1);
          } else {
            localStorage.setItem("num_fill", 1);
          }
        }
        dispatch(
          startPollingIsAuth({ newImageId: response.data.id, token: token })
        );
        dispatch(removeHashtags());
      })
      .catch((error) => {
        const textMessage = String(Object.values(error.response.data));
        dispatch(setErrorMessage(textMessage));
      });
  }
);

export const createProjectSlice = createSlice({
  name: "createProject",
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    setUserKey: (state, action) => {
      state.userKey = action.payload;
    },
    setImageId: (state, action) => {
      state.imageId = action.payload;
    },
    setImageFileSize: (state, action) => {
      state.imageFileSize = action.payload;
    },
    setSubscribe: (state, action) => {
      state.subscribe = action.payload;
    },
    setImageFileName: (state, action) => {
      state.imageFileName = action.payload;
    },
    setIsAuth: (state, action) => {
      state.isAuth = action.payload;
    },
    setIsPaid: (state, action) => {
      state.isPaid = action.payload;
    },
    setModalActive: (state, action) => {
      state.createModal = action.payload;
    },
    setImagePaintBase64: (state, action) => {
      state.imagePaintBase64 = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setProgressUpload: (state, action) => {
      state.progressUpload = action.payload;
    },
    setImageBase64: (state, action) => {
      // console.log('setImageBase64 :>> ', action.payload);
      state.imageBase64 = action.payload;
    },
    setImageHeight: (state, action) => {
      state.imageHeight = action.payload;
    },
    setImageWidth: (state, action) => {
      state.imageWidth = action.payload;
    },
    // setFillTheSpace: (state, action) => {
    //   state.fill_the_space = action.payload;
    // },
    setGuidanceScale: (state, action) => {
      state.guidanceScale = action.payload;
    },
    setNumberOfImages: (state, action) => {
      state.numberOfImages = action.payload;
    },
    setImageStrength: (state, action) => {
      state.imageStrength = action.payload;
    },
    setPhotorealism: (state, action) => {
      state.photorealism = action.payload;
    },
    setTypeUploadPhoto: (state, action) => {
      // console.log('setTypeUploadPhoto :>> ', action.payload);
      state.uploadType = action.payload;
    },
    setResetQuiz: (state) => {
      state.numberOfImages = 1;
      state.guidanceScale = guidanceScaleDefault;
      state.photorealism = false;
      state.imageStrength = imageStrengthDefault;
    },
  },
});

export const {
  setEmail,
  setUserKey,
  setImageId,
  setGuidanceScale,
  setSubscribe,
  setIsAuth,
  setImageFileSize,
  setPhotorealism,
  setImageStrength,
  setImageFileName,
  setIsPaid,
  setNumberOfImages,
  setModalActive,
  setErrorMessage,
  setProgressUpload,
  setImageBase64,
  setImageHeight,
  setImagePaintBase64,
  setImageName,
  setImageWidth,
  setResetQuiz,
  // setFillTheSpace,
  setTypeUploadPhoto,
} = createProjectSlice.actions;
export default createProjectSlice.reducer;
