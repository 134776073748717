import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  type: '',
};

export const checkTypePageSlice = createSlice({
  name: 'checkTypePage',
  initialState,
  reducers: {
    setPageType: (state, action) => {
      state.type = action.payload;
    },
  },
});

export const { setPageType } = checkTypePageSlice.actions;
export default checkTypePageSlice.reducer;
