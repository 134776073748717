import React from 'react';

const UploadImageIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M22 12C22 11.7348 21.8946 11.4804 21.7071 11.2929C21.5196 11.1054 21.2652 11 21 11C20.7348 11 20.4804 11.1054 20.2929 11.2929C20.1054 11.4804 20 11.7348 20 12H22ZM12 4C12.2652 4 12.5196 3.89464 12.7071 3.70711C12.8946 3.51957 13 3.26522 13 3C13 2.73478 12.8946 2.48043 12.7071 2.29289C12.5196 2.10536 12.2652 2 12 2V4ZM19.5 20H4.5V22H19.5V20ZM4 19.5V4.5H2V19.5H4ZM20 12V19.5H22V12H20ZM4.5 4H12V2H4.5V4ZM4.5 20C4.36739 20 4.24021 19.9473 4.14645 19.8536C4.05268 19.7598 4 19.6326 4 19.5H2C2 20.163 2.26339 20.7989 2.73223 21.2678C3.20107 21.7366 3.83696 22 4.5 22V20ZM19.5 22C20.163 22 20.7989 21.7366 21.2678 21.2678C21.7366 20.7989 22 20.163 22 19.5H20C20 19.6326 19.9473 19.7598 19.8536 19.8536C19.7598 19.9473 19.6326 20 19.5 20V22ZM4 4.5C4 4.36739 4.05268 4.24021 4.14645 4.14645C4.24021 4.05268 4.36739 4 4.5 4V2C3.83696 2 3.20107 2.26339 2.73223 2.73223C2.26339 3.20107 2 3.83696 2 4.5H4Z"
        fill="black"
      />
      <path
        d="M3 17.5L8.3465 12.599C8.52671 12.4338 8.76117 12.3404 9.00558 12.3364C9.24999 12.3323 9.48742 12.4179 9.673 12.577L16 18M14 15.5L16.3865 13.1135C16.5567 12.9432 16.7827 12.84 17.0229 12.8229C17.2631 12.8058 17.5014 12.876 17.694 13.0205L21 15.5M18.5 9V3M18.5 3L16 5.5M18.5 3L21 5.5"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UploadImageIcon;
