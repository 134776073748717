import React, { useEffect, useState } from 'react';
import './subscriptionBilling.css';
import SubscriptionBillingBox from './SubscriptionBillingBox';
import SubscriptionBillingBoxPoints from './SubscriptionBillingBoxPoints';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSubscriptionPlan,
  getSubscriptions,
  // setSubscriptionDateEnd,
} from '../../toolkit/slices/authorization/subscriptionPlanSlice';
import AttemptsOverModal from '../modal/AttemptsOverModal';
// import { getUserInfoAccount } from '../../toolkit/slices/account/infoUserSlice';
// import { getCustomPortal } from '../../toolkit/slices/authorization/stripeSlice';
// import { changeDateFormat } from '../../lib/scrypts';

const SubscriptionBillingGrid = ({ is_paid }) => {
  const dispatch = useDispatch();
  const subscriptionDateEnd = useSelector((state) => state.subscriptionPlan.end);
  const subscriptionAll = useSelector((state) => state.subscriptionPlan.subscriptionAll);
  // const customerPortalLink = useSelector((state) => state.subscriptionPlan.customerPortalLink);
  const userInfo = useSelector((state) => state.getUser.userData);
  const [isErrorModal,setIsErrorModal] = useState(false);
  // console.log(subscriptionDateEnd);
  useEffect(() => {
    if (subscriptionAll.length === 0) {
      dispatch(getSubscriptions());
    }
    // if (!customerPortalLink) {
    //   subscriptionAll.map((subscription, index) => {
    //     if (subscription.start && subscription.end) {
    //       // console.log(`subscription :>> ${index}`, subscription.id);
    //       dispatch(getCustomPortal(subscription.id));
    //       dispatch(setSubscriptionDateEnd(changeDateFormat(subscription.end)));
    //     }
    //     return '';
    //   });
    // }
  }, [subscriptionAll, dispatch]);

  const subscription = useSelector((state) => state.subscriptionPlan.value);

  // console.log('is_paid :>> ', is_paid);

  // const cancelSubscriptionHandler = (val) => {
  //   // dispatch(setSubscriptionPlan(val));
  // };

  const choiceSubcriptionHandler = (val) => {
    dispatch(setSubscriptionPlan(val));
  };

  const checkTrialDaysLeft = (end) => {
    const dateEnd = new Date(end);
    const dateNow = new Date();
    let timeDiff = dateEnd - dateNow;
    const oneDay = 24 * 60 * 60 * 1000;
    let daysRemaining = Math.floor(timeDiff / oneDay) + 1;
    return daysRemaining;
  };

  const dayLeft = checkTrialDaysLeft(subscription && subscription.end);

  return (<>
    {isErrorModal? 
     <AttemptsOverModal
     title={`Sorry, you made a lot of requests, try again later!`}
     isButton={false}
     isActive={isErrorModal}
     setIsActive={setIsErrorModal}
     lineBreak={6}
   />
    : null
    }
     <div className="billing content">
      {dayLeft !== 0 ||
        (dayLeft && (
          <div className="trialInfo">
            You have {dayLeft} more trial {dayLeft === 1 ? 'day' : 'days'}, hope you enjoy the
            process
          </div>
        ))}
      <div className="subTitle">Manage your subscription</div>
      <div className="choice">
        <SubscriptionBillingBoxPoints
          name={'Personal'}
          value={4}
          isActiveName={subscription}
          active={subscriptionAll.find(e=>e.is_valid)?.subscription_type === 4 ? true : false}
          price={190}
          priceOld={490}
          saving={true}
          setIsErrorModal={setIsErrorModal}
          savingPrice={20}
          choiceHandler={choiceSubcriptionHandler}
          // cancelHandler={cancelSubscriptionHandler}
          dateEnd={subscriptionDateEnd}
          disable={false}
        />
        <SubscriptionBillingBox
          name={'Pro'}
          value={0}
          isActiveName={subscription}
          subscriptionAll={subscriptionAll}
          active={subscriptionAll.find(e=>e.is_valid)?.subscription_type === 0 ? true : false}
          is_paid={is_paid}
          price={10}
          priceOld={49}
          saving={false}
          savingPrice={120}
          attempts={userInfo.attempts_left}
          setIsErrorModal={setIsErrorModal}
          choiceHandler={choiceSubcriptionHandler}
          // cancelHandler={cancelSubscriptionHandler}
          dateEnd={subscriptionDateEnd}
          disable={false}
        />
      </div>
    </div>
  
  </>
 
  );
};

export default SubscriptionBillingGrid;
