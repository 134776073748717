import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { urlWithTagsParams } from "../../../lib/scrypts";

const initialState = {
  token: "",
  idUser: "",
  data: [],
  project: [],
  onLoadPage: true,
  modalProject: {},
  boundings: null,
  filterTags: {
    type: [],
    design: [],
    style: [],
  },
  pagination: {
    count: 10,
    numPage: 0,
  },
};

export const getUserInfoForPortfolio = createAsyncThunk(
  "getUserInfoForPortfolio/getUserInfoForPortfolio",
  async (val, { rejectWithValue, dispatch, getState }) => {
    const token = localStorage.getItem("key");
    await axios
      .get(`${process.env.REACT_APP_BACKEND_HOST}/api/v1/rest-auth/user/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        dispatch(setIdUser(response.data.id));
        dispatch(getUserPortfolio(response.data.id));
      })
      .catch(function (error) {
        console.log("error :>> ", error);
      });
  }
);

export const getUserPortfolio = createAsyncThunk(
  "getUserPortfolio/getUserPortfolio",
  async (_, { rejectWithValue, dispatch, getState }) => {
    const token = localStorage.getItem("key");
    const numPage = getState().portfolioUser.pagination.numPage + 1;
    const tags = getState().portfolioUser.filterTags;
    const encodeStrSpace = urlWithTagsParams(tags);
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/users/portfolio/?condition=AND&page=${numPage}&${encodeStrSpace}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        dispatch(setPaginationCount(response.data));
        dispatch(setPortfolio(response.data.results));
      })
      .catch(function (error) {
        console.log("error :>> ", error);
      });
  }
);

export const getHomePortfolio = createAsyncThunk(
  "getHomePortfolio/getHomePortfolio",
  async (_, { rejectWithValue, dispatch, getState }) => {
    const numPage = getState().portfolioUser.pagination.numPage + 1;
    const tags = getState().portfolioUser.filterTags;
    const encodeStrSpace = urlWithTagsParams(tags);
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/images/portfolio/?page_size=9&condition=AND&page=${numPage}&${encodeStrSpace}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        dispatch(setPaginationCount(response.data));
        dispatch(setPortfolio(response.data.results));
      })
      .catch(function (error) {
        console.log("error :>> ", error);
      });
  }
);

export const getProject = createAsyncThunk(
  "getProject/getProject",
  async (val, { dispatch }) => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_HOST}/api/v1/images/${val}/`)
      .then(function (response) {
        dispatch(setModalProject(response.data));
        dispatch(getBoundingBoxes(response.data.id));
      })
      .catch(function (error) {
        console.log("error :>> ", error.response.data);
      });
  }
);

export const getBoundingBoxes = createAsyncThunk(
  "getBoundingBoxes/getBoundingBoxes",
  async (id, { dispatch }) => {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/images/${id}/recognize/`,
        JSON.stringify({ id: id }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("key")}`,
          },
        }
      )
      .then(function (response) {
        dispatch(setBoundings(response.data));
      })
      .catch(function (error) {
        console.log("error :>> ", error.response.data);
      });
  }
);

export const upscaleImageProject = createAsyncThunk(
  "upscaleImageProject/upscaleImageProject",
  async (id, { dispatch }) => {
    const token = localStorage.getItem("key");
    console.log("token :>> ", token);
    console.log("id :>> ", id);
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/images/${id}/upscale/`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        console.log("response.data :>> ", response.data);
      })
      .catch(function (error) {
        console.log("error :>> ", error.response.data);
      });
  }
);

export const portfolioUserSlice = createSlice({
  name: "portfolioUser",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setIdUser: (state, action) => {
      state.idUser = action.payload;
    },
    setPortfolio: (state, action) => {
      state.data = action.payload;
    },
    setPaginationCount: (state, action) => {
      state.pagination.count = action.payload.count;
    },
    setPaginationNumPage: (state, action) => {
      state.pagination.numPage = action.payload;
    },
    setProject: (state, action) => {
      state.project = action.payload;
    },
    setFilterTags: (state, action) => {
      const [filters, type] = action.payload;
      state.filterTags[type] = [filters];
    },
    setBoundings: (state, action) => {
      state.boundings = action.payload;
    },
    resetFilterTags: (state, action) => {
      const key = action.payload;
      if (key) {
        state.filterTags[key] = [];
      } else {
        state.filterTags = {
          ...state.otherData,
          type: [],
          design: [],
          style: [],
        };
      }
    },
    setModalProject: (state, action) => {
      state.modalProject = action.payload;
    },
    setOnload: (state, action) => {
      state.onLoadPage = action.payload;
    },
  },
});

export const {
  setToken,
  setIdUser,
  setPortfolio,
  setPaginationCount,
  setPaginationNumPage,
  setProject,
  setFilterTags,
  setBoundings,
  resetFilterTags,
  setModalProject,
} = portfolioUserSlice.actions;
export default portfolioUserSlice.reducer;
