import React, { useEffect } from "react";
import CircleCheckIcon from "../icon/CircleCheckIcon";
// import SavingIcon from '../icon/SavingIcon';
import { useDispatch, useSelector } from "react-redux";
import {
  postPaymentSubscription,
  setStripeLink,
} from "../../toolkit/slices/authorization/stripeSlice";
import { gtmEvent } from "../../lib/gtm";
// import { getSubscriptions } from '../../toolkit/slices/authorization/subscriptionPlanSlice';
import { getCustomPortal } from "../../toolkit/slices/authorization/stripeSlice";
import { changeDateFormat } from "../../lib/scrypts";
import { setSubscriptionDateEnd } from "../../toolkit/slices/authorization/subscriptionPlanSlice";
import { useState } from "react";

const SubscriptionBillingBox = ({
  name,
  value,
  isActiveName,
  active,
  is_paid,
  price,
  priceOld,
  saving,
  savingPrice,
  subscriptionAll,
  cancelHandler,
  choiceHandler,
  dateEnd,
  disable,
  attempts,
  setIsErrorModal,
}) => {
  const dispatch = useDispatch();
  const stripeLink = useSelector((state) => state.stripe.stripeLink);
  const [activeManage, setActiveManage] = useState(false);
  const customerPortalLink = useSelector(
    (state) => state.subscriptionPlan.customerPortalLink
  );
  const manageSubsription = () => {
    subscriptionAll.map((subscription, index) => {
      if (subscription.start && subscription.end) {
        // console.log(`subscription :>> ${index}`, subscription.id);
        dispatch(getCustomPortal(subscription.id));
        dispatch(setSubscriptionDateEnd(changeDateFormat(subscription.end)));
        setActiveManage(true)
      }
      return "";
    });
    if (customerPortalLink === "THROTTLED")
      setIsErrorModal(true);
  };

  useEffect(() => {
    if (activeManage) {
      if (customerPortalLink && customerPortalLink !== "THROTTLED") {
        window.open(customerPortalLink, "_blank");
      }
      setActiveManage(false);
    }
  }, [customerPortalLink, activeManage]);

  const buyProAccount = () => {
    // console.log('buyProAccount :>> ');
    dispatch(
      postPaymentSubscription({
        key: localStorage.getItem("key"),
        subscription_type: 0,
        billing: true,
      })
    );
    gtmEvent("UPSCALE_TO_PRO", "category");
  };

  useEffect(() => {
    if (stripeLink) {
      // window.open(stripeLink, '_blank');
    }
    dispatch(setStripeLink(""));
  }, [stripeLink, dispatch]);

  return (
    <div className={disable ? "box disable" : "box"}>
      <div className="title">
        <span>{name}</span>
        {active && dateEnd && dateEnd !== '01.01.1970' ? (
          <div className="active">
            <CircleCheckIcon />
            <span> You subscribed on {dateEnd}</span>
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className="price_range_block"
        // style={{ display: color === 'green' ? 'none' : 'block' }}
      >
        <div className="buy">
          <div className="price">${price}</div>
          {false ? (
            <div className="visions">
              <div className="">
                {attempts}/<span className="left">100</span>
              </div>{" "}
              <span>Tokens</span>
            </div>
          ) : (
            <div className="visions">
              100 <span>Tokens</span>
            </div>
          )}
        </div>
      </div>

      {active && subscriptionAll.at(-1)?.status ? (
        <button className="button green" onClick={() => manageSubsription()}>
          manage subscribe
        </button>
      ) : (
        <button
          className={disable ? "button green disable" : "button green"}
          // onClick={() => choiceHandler(value)}>
          onClick={() => buyProAccount()}
        >
          upscale to {name}
        </button>
      )}
    </div>
  );
};

export default SubscriptionBillingBox;
