import { useEffect } from "react";
import cl from "./MyModal.module.css";

export const MyModal = ({ children, isActive,classModal, ...props }) => {
  useEffect(() => {
    if (isActive) {
      document.body.classList.add("modalOpen");
    } else {
      document.body.classList.remove("modalOpen");
    }
    return () => {
      document.body.classList.remove("modalOpen");
    };
  }, [isActive]);

  return (
    <div className={[cl.modal, isActive ? cl.activeModal : '', classModal].join(' ')} {...props}>
      {children}
    </div>
  );
};
