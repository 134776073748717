import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = { errorMessage: '', animation: false };

export const postChangePasswordUser = createAsyncThunk(
  'postChangePasswordUser/postChangePasswordUser',
  async (data, { rejectWithValue, dispatch, getState }) => {
    const token = localStorage.getItem('key');
    await axios
      .post(`${process.env.REACT_APP_BACKEND_HOST}/api/v1/rest-auth/password/change/`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        dispatch(setErrorMessage(''));
        dispatch(setAnimationVal(true));
        setTimeout(() => {
          dispatch(setAnimationVal(false));
        }, 1800);
      })
      .catch(function (error) {
        console.log('error :>> ', error.response.data);
        if (error.response.data.new_password1 && error.response.data.new_password2) {
          dispatch(setErrorMessage('Fields password may not be blank.'));
          console.log('Fields password may not be blank.');
        } else if (error.response.data.new_password1) {
          dispatch(setErrorMessage(error.response.data.new_password1));
          console.log(error.response.data.new_password1);
        } else if (error.response.data.new_password2) {
          dispatch(setErrorMessage(error.response.data.new_password2));
          console.log(error.response.data.new_password2);
        }
      });
  },
);

export const changePasswordUserSlice = createSlice({
  name: 'changePasswordUser',
  initialState,
  reducers: {
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setAnimationVal: (state, action) => {
      state.animation = action.payload;
    },
  },
});

export const { setErrorMessage, setAnimationVal } = changePasswordUserSlice.actions;
export default changePasswordUserSlice.reducer;
