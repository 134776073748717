import React from 'react';
import './menu.css';
import { useSelector } from 'react-redux';
import { useLocalStorageCheck } from '../../lib/scrypts';
import NavBar from '../landingPage/Header/HeaderMenu';
import QuizMenu from '../QuizGroup/QuizMenu';

const Menu = () => {
  useLocalStorageCheck('name');
  // const dispatch = useDispatch();
  const pageType = useSelector((state) => state.checkTypePage.type);

  document.body.style.overflowY = 'scroll';
  document.body.style.overflowX = 'hidden';

  const menuType = { landing: <NavBar />, quiz: <QuizMenu />, account: <NavBar />, result: <QuizMenu result={true}/> };

  return pageType === 'common'
    ? menuType.landing
    : pageType === 'quiz'
      ? menuType.quiz
      : pageType === 'result' ? menuType.result : menuType.account;
};

export default Menu;
