import React, { useEffect } from 'react';
import CircleCheckIcon from '../icon/CircleCheckIcon';
// import SavingIcon from '../icon/SavingIcon';
import { useDispatch, useSelector } from 'react-redux';
import SelectVisionsSliderComponent from '../input/SelectVisionsSliderComponent';
import {
  setAdditionalValueVisions,
  setValueMoney,
  setValuePercent,
  setValueVision,
} from '../../toolkit/slices/rangeVisionSlice';
import { postPaymentProduct, setStripeLink } from '../../toolkit/slices/authorization/stripeSlice';
// import { gtmEventProduct } from '../../lib/gtm';

const SubscriptionBillingBoxPoints = ({
  name,
  value,
  isActiveName,
  active,
  is_paid,
  price,
  priceOld,
  saving,
  savingPrice,
  setIsErrorModal,
  cancelHandler,
  choiceHandler,
  dateEnd,
  disable,
}) => {
  const dispatch = useDispatch();
  const stripeLink = useSelector((state) => state.stripe.stripeLink);
  // const customerPortalLink = useSelector((state) => state.subscriptionPlan.customerPortalLink);

  const valueMoney = useSelector((state) => state.rangeVisionSlice.valueMoney);
  const valueVisions = useSelector((state) => state.rangeVisionSlice.valueVisions);
  const valuePercent = useSelector((state) => state.rangeVisionSlice.valuePercent);
  const additionalValueVisions = useSelector((state) => state.rangeVisionSlice.additionalValueVisions);

  const trackBackground = `linear-gradient(to right, #000 ${valuePercent}%, rgb(0, 0, 0, 0.09) ${valuePercent}%, rgb(0, 0, 0, 0.09) 100%)`;
  const errorMessage = useSelector((state) => state.stripe.errorMessage);

  const handleChangeVision = (event) => {
    var min = 2;
    var max = 10;
    var percentage = ((event.target.value - min) / (max - min)) * 100;

    dispatch(setValuePercent(percentage));
    dispatch(setValueMoney(event.target.value));
    dispatch(setValueVision(event.target.value * 5));
    dispatch(setAdditionalValueVisions(event.target.value * 5 / 10));

  };

  const buyMorePoints = () => {
    dispatch(
      postPaymentProduct({
        key: localStorage.getItem('key'),
        amount: Number(valueMoney) * 100,
        name: valueVisions,
        billing: true,
      }),
    );
    if(errorMessage) {
      setIsErrorModal(true)
      return;
    }
    // gtmEventProduct('BUY_MORE', 'category', valueVisions);
  };

  useEffect(() => {
    if (stripeLink) {
      window.open(stripeLink, '_blank');
    }
    dispatch(setStripeLink(''));
  }, [stripeLink, dispatch]);

  return (
    <div className="box green">
      <div className="title">
        <span>{name}</span>
        {active & is_paid ? (
          <div className="active">
            <CircleCheckIcon />
            <span> You subscribed on {dateEnd}</span>
          </div>
        ) : (
          ''
        )}
      </div>
      <SelectVisionsSliderComponent
        color={'green'}
        valueMoney={valueMoney}
        addValue={additionalValueVisions}
        valueVisions={valueVisions}
        handleChangeVision={handleChangeVision}
        trackBackground={trackBackground}
      />
      <button className="button green black" onClick={() => buyMorePoints()}>
        TOP UP
      </button>
    </div>
  );
};

export default SubscriptionBillingBoxPoints;
