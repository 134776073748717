import React from "react";
import { useTopToPage } from "../../lib/scrypts";
import ArticleComponent from "../../components/BlogsPage/BlogComponent/ArticleComponent";

function Article() {
  useTopToPage()
  return <ArticleComponent/>
}

export default Article;
