import cl from "./TagsCloudComp.module.css";
import { motion } from "framer-motion";

const TagsCloudItem = ({
  tagState,
  isActiveTag,
  callback,
  activeTags,
  index,
  nestedState = [],
}) => {
  return (
    <motion.li
      initial={{
        opacity: 0,
        scale: .5,
      }}
      animate={{
        opacity: 1,
        scale: 1,
      }}
      transition={{ duration: 0.3, delay: 0 }}
      className={cl.tagsItem}
      onClick={() => callback(tagState)}
    >
      <div
        className={
          isActiveTag
            ? [cl.tagsParent, cl.activeTag].join(" ")
            : activeTags.length === 5
            ? [cl.tagsParent, cl.cancelTag].join(" ")
            : cl.tagsParent
        }
      >
        <span className={cl.tagsItemText}>{tagState.name}</span>
      </div>
      {/* {nestedState.map((nestedTag, i) => {
        let isActiveNestedTag = activeTags.find(
          (tag) => tag.id === nestedTag.id
        );
        return (
          <div
            className={
              isActiveTag
                ? [
                    cl.hiddenTag,
                    cl.nestedTagsItem,
                    activeTags.length === 5 ? cl.cancelTag : "",
                    isActiveNestedTag ? cl.activeTag : "",
                  ].join(" ")
                : cl.hiddenTag
            }
            key={nestedTag.id}
            onClick={(e) => {
              e.stopPropagation();
              callback({ ...nestedTag, parentId: tagState.id });
            }}
          >
            <span className={cl.tagsItemText}>{nestedTag.name}</span>
          </div>
        );
      })} */}
    </motion.li>
  );
};

export default TagsCloudItem;
