import React from "react";
import "./button.css";
import { Link } from "react-router-dom";
import ArrowBack from "../icon/ArrowBack";
import { useDispatch } from "react-redux";
import { setHashtags } from "../../toolkit/slices/roomTypeSlice";
import { checkPathname } from "../../lib/scrypts";
import { postImages } from "../../toolkit/slices/selectImagesSlice";

const ChoiceButton = ({
  backLink,
  continueLink,
  isActive,
  skip,
  skipLink,
  skipValue,
  chooseYourselfLink,
  visible,
  handleContinue,
  handleSkip,
}) => {
  const dispatch = useDispatch();

  // const navigate = useNavigate();

  let pageLink = window.location.pathname.slice(1);

  const setValueIfSkip = (name, hashtags) => {
    dispatch(setHashtags([checkPathname(pageLink), name, hashtags]));
  };
  return (
    <div className={"choiceButton"}>
      <Link to={backLink} className="transparent">
        <ArrowBack />
      </Link>
      {skip ? (
        <Link
          to={skipLink}
          className="transparent right"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "instant" });
            setValueIfSkip(skipValue, skipValue);
            if (handleSkip) {
              handleSkip();
            }
          }}
        >
          Skip
        </Link>
      ) : (
        ""
      )}

      {/* {visible ? (
        <>
          <Link to={chooseYourselfLink} className={`transparent ${cl.desktopBtn}`} onClick={e=>{window.scrollTo({top:0,behavior:'instant'})}}>
            Choose your style yourself
          </Link>
          <Link to={chooseYourselfLink} className={`transparent ${cl.mobileBtn}`} onClick={e=>{window.scrollTo({top:0,behavior:'instant'})}}>
            Custom AI Suggest
          </Link>
        </>
      
      ) : null} */}

      {visible ? (
        <>
          <Link
            to={chooseYourselfLink}
            className={`transparent right`}
            onClick={(e) => {
              window.scrollTo({ top: 0, behavior: "instant" });
            }}
          >
            AI Suggest
          </Link>
        </>
      ) : null}

      {visible ? (
        <button
          type="button"
          className={isActive ? "next" : "next disable"}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "instant" });
            dispatch(postImages());
            if (handleContinue) {
              handleContinue();
            }
          }}
        >
          Continue
        </button>
      ) : (
        <Link
          to={continueLink}
          className={isActive ? "next" : "next disable"}
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "instant" });
            if (handleContinue) {
              handleContinue();
            }
          }}
        >
          Continue
        </Link>
      )}
    </div>
  );
};

export default ChoiceButton;
