import { useParams } from "react-router-dom";
import cl from "./ArticleComponent.module.css";
import MyCrumbs from "../../UI/MyCrumbs/MyCrumbs";
import { formatDate } from "../../../helpers/blogFormatData";
import { TimeIcon } from "../../icon/Time";
import { FBMiniIcon } from "../../icon/social/FBMiniIcon";
import { LinkMiniIcon } from "../../icon/social/LinkMiniIcon";
import { XMiniIcon } from "../../icon/social/XMiniIcon";
import { Helmet } from "react-helmet-async";
import { useGetBlogsBySlugQuery } from "../../../toolkit/services/public/blogsApi";
import { ArticleSkeleton } from "./ArticleSkeleton";
import { makeMicrodata } from "./ArticleMicrodata";
import { sanitizeHTML } from "../../../helpers/sanitizeHtml";
// import { useEffect } from "react";

const ArticleComponent = () => {
  const { slug } = useParams();
  const { data, isLoading, isError } = useGetBlogsBySlugQuery(slug);

  function addUniqueIds(jsonStr) {
    let counter = 1;
    const headings = [];

    const html = jsonStr.replace(/<h2>([^<]+)<\/h2>/g, (match, content) => {
      const id = `h2-${counter++}`;
      headings.push({ id, content });
      return `<h2 id="${id}">${content}</h2>`;
    });

    return { html, headings };
  }

  if (!isLoading && !isError && data !== undefined) {
    return (
      <>
        <Helmet prioritizeSeoTags>
          <title>{data.title}</title>
          <link
            rel="canonical"
            href={"https://paintit.ai/blogs/" + data.slug + "/"}
          />
          <meta name="description" content={data.meta_description} />
          <meta property="og:description" content={data.meta_description} />
          <meta property="og:title" content={data.meta_title} />
          <meta property="og:image" content={data.meta_image} />
          <meta property="og:type" content="article" />
          <meta
            property="og:url"
            content={"https://paintit.ai/blogs/" + data.slug + "/"}
          />
          <meta name="twitter:description" content={data.meta_description} />
          <meta name="twitter:title" content={data.meta_title} />
          <meta name="twitter:image" content={data.meta_image} />
          <meta
            name="twitter:url"
            content={"https://paintit.ai/blogs/" + data.slug + "/"}
          />
          <script type="application/ld+json">{makeMicrodata(data)}</script>
        </Helmet>
        <div className={`container ${cl.pageWrapper}`}>
          <div className={cl.cont}>
            <MyCrumbs />
            <div className={cl.tagsBlock}>
              <div className={cl.tags}>
                {data.tags.map((tag, i) => (
                  <span className={cl.tag} key={i}>
                    {tag}
                  </span>
                ))}
              </div>
              <span className={cl.readingTime}>
                <TimeIcon width={15} height={15} />
                {`${data.reading_time} min. read`}
              </span>
            </div>
            <h1 className={cl.title}>{data.title}</h1>
            <div className={cl.separateBlock}>
              <div className={cl.separateWrapper}>
                <span className={cl.blogPublished}>
                  {formatDate(data.published_at)}
                </span>
                <div className={cl.shareBlock}>
                  <span className={cl.shareText}>Share</span>
                  <ul className={cl.shareList}>
                    {/* eslint-disable */}
                    <li className={cl.shareItem}>
                      <a
                        href={`https://twitter.com/intent/tweet?url=https://paintit.ai/blogs/${slug}`}
                        target="_blank"
                      >
                        <XMiniIcon />
                      </a>
                    </li>
                    <li className={cl.shareItem}>
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=https://paintit.ai/blogs/${slug}`}
                        target="_blank"
                      >
                        <FBMiniIcon />
                      </a>
                    </li>
                    <li className={cl.shareItem}>
                      <a
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=https://paintit.ai/blogs/${slug}`}
                        target="_blank"
                      >
                        <LinkMiniIcon />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={cl.line}></div>
            </div>
            <div
              className={cl.contentBlog}
              dangerouslySetInnerHTML={sanitizeHTML(
                addUniqueIds(data.text).html
              )}
            />
          </div>
          <div className={cl.shortCut}>
            <h2 className={cl.titleShortCut}>Page Contents:</h2>
            <ul className={cl.listShort}>
              {addUniqueIds(data.text).headings
                ? addUniqueIds(data.text).headings.map((el, i) => {
                    return (
                      <li
                        className={cl.itemShort}
                        onClick={(e) => {
                          const title = document.querySelector(`#${el.id}`);
                          window.scrollTo({
                            top: title.offsetTop,
                            behavior: "smooth",
                          });
                        }}
                        key={el.id}
                      >
                        {`${i + 1}. ${el.content}`}
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>
        </div>
      </>
    );
  }

  return <ArticleSkeleton />;
};

export default ArticleComponent;
