import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { changeDateFormat } from "../../../lib/scrypts";
// import { getCustomPortal } from './stripeSlice';

const initialState = {
  name: "",
  subscriptionAll: [],
  value: {},
  subscriptionData: {},
  end: "",
  customerPortalLink: "",
};

export const getSubscriptions = createAsyncThunk(
  "getSubscriptions/getSubscriptions",
  async (_, { dispatch }) => {
    const token = localStorage.getItem("key");
    // console.log('token :>> ', token);
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/users/subscriptions/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        const subscription = response.data.find((e) => e.is_valid);
        if (subscription) {
          dispatch(setSubscriptionPlan(subscription));
          dispatch(setSubscriptionDateEnd(changeDateFormat(subscription.end)));
        }

        // dispatch(getCustomPortal(subscription.id));
        dispatch(setSubscriptionAll(response.data));
      })
      .catch(function (error) {
        console.log(error.response.data);
      });
  }
);

export const subscriptionPlanSlice = createSlice({
  name: "subscriptionPlan",
  initialState,
  reducers: {
    setSubscriptionPlan: (state, action) => {
      state.value = action.payload;
    },
    setSubscriptionAll: (state, action) => {
      state.subscriptionAll = action.payload;
    },
    setSubscriptionData: (state, action) => {
      state.subscriptionData = action.payload;
    },
    setSubscriptionDateEnd: (state, action) => {
      state.end = action.payload;
    },
    setCustomerPortalLink: (state, action) => {
      state.customerPortalLink = action.payload;
    },
  },
});

export const {
  setSubscriptionPlan,
  setSubscriptionAll,
  setSubscriptionData,
  setSubscriptionDateEnd,
  setCustomerPortalLink,
} = subscriptionPlanSlice.actions;
export default subscriptionPlanSlice.reducer;
