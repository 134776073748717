import React, { useState } from "react";
import "./button.css";
import ShareIcon from "../icon/project/ShareIcon";
import FacebookIcon from "../icon/social/FacebookIcon";
import TwitterIcon from "../icon/social/TwitterIcon";
import PinterestIcon from "../icon/social/PinterestIcon";

const DropDown = ({ link, img }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openText = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }

    // console.log('open');
  };

  return (
    <div
      className={isOpen ? "btn btnDropdown active" : "btn btnDropdown"}
      onClick={() => openText()}
    >
      <ShareIcon />
      <ul className="dropdown">
        <li>
          {/* eslint-disable */}
          <a
            href={
              "https://www.pinterest.com/pin/create/button/?url=" +
              window.location.href
            }
            rel="nofollow"
            target="_blank"
          >
            <PinterestIcon />
          </a>
        </li>
        <li>
          <a
            href={
              "https://www.facebook.com/sharer/sharer.php?u=" +
              window.location.href
            }
            target="_blank"
            rel="nofollow"
          >
            <FacebookIcon />
          </a>
        </li>
        <li>
          <a
            href={
              "https://twitter.com/intent/tweet?url=" + window.location.href
            }
            target="_blank"
            rel="nofollow"
          >
            <TwitterIcon />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default DropDown;
