import React from 'react';

const CryingSmileIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
      <path
        d="M11.355 30C9.345 30 7.71 28.365 7.71 26.355C7.71 24.75 9.48 21.75 11.355 19.215C13.23 21.75 15 24.75 15 26.355C15 26.8337 14.9057 27.3076 14.7225 27.7499C14.5394 28.1921 14.2709 28.5939 13.9324 28.9324C13.5939 29.2709 13.1921 29.5394 12.7499 29.7225C12.3076 29.9057 11.8337 30 11.355 30ZM18 3C16.0302 3 14.0796 3.38799 12.2597 4.14181C10.4399 4.89563 8.78628 6.00052 7.3934 7.3934C4.58035 10.2064 3 14.0218 3 18C3 20.625 3.675 23.07 4.86 25.215C5.1 24 5.715 22.605 6.465 21.255C6.15536 20.1978 5.99877 19.1016 6 18C6 14.8174 7.26428 11.7652 9.51472 9.51472C11.7652 7.26428 14.8174 6 18 6C21.1826 6 24.2348 7.26428 26.4853 9.51472C28.7357 11.7652 30 14.8174 30 18C30 21.1826 28.7357 24.2348 26.4853 26.4853C24.2348 28.7357 21.1826 30 18 30C17.64 30 17.295 30 16.935 29.94C16.23 31.05 15.21 31.92 14.01 32.445C15.285 32.805 16.62 33 18 33C19.9698 33 21.9204 32.612 23.7403 31.8582C25.5601 31.1044 27.2137 29.9995 28.6066 28.6066C29.9995 27.2137 31.1044 25.5601 31.8582 23.7403C32.612 21.9204 33 19.9698 33 18C33 16.0302 32.612 14.0796 31.8582 12.2597C31.1044 10.4399 29.9995 8.78628 28.6066 7.3934C27.2137 6.00052 25.5601 4.89563 23.7403 4.14181C21.9204 3.38799 19.9698 3 18 3ZM18 21C17.385 21 16.785 21.06 16.215 21.18C16.74 22.125 17.205 23.1 17.535 24H18C19.875 24 21.48 24.75 22.155 25.845L24.285 23.715C22.935 22.08 20.625 21 18 21ZM23.25 12C22.05 12 21 13.05 21 14.25C21 15.45 22.05 16.5 23.25 16.5C24.45 16.5 25.5 15.45 25.5 14.25C25.5 13.05 24.45 12 23.25 12ZM15 14.25C15 13.05 13.95 12 12.75 12C11.55 12 10.5 13.05 10.5 14.25C10.5 15.45 11.55 16.5 12.75 16.5C13.95 16.5 15 15.45 15 14.25Z"
        fill="black"
      />
    </svg>
  );
};

export default CryingSmileIcon;
