import Skeleton from "react-loading-skeleton";
import cl from './ArticleComponent.module.css';
import MyCrumbs from "../../UI/MyCrumbs/MyCrumbs";

export const ArticleSkeleton = () => (
  <div className="">
    <div className={`container ${cl.cont}`}>
      <MyCrumbs />
      <div className={cl.tagsBlock}>
        <div className={cl.tags}>
          <Skeleton className={cl.tagSkeleton} />
        </div>
        <Skeleton className={cl.readingTimeSkeleton} />
      </div>
      <Skeleton className={cl.title} />
      <div className={cl.separateBlock}>
        <div className={cl.separateWrapper}>
          <Skeleton className={cl.blogPublishedSkeleton} />
          <div className={cl.shareBlock}>
            <Skeleton className={cl.shareText} />
            <ul className={cl.shareList}>
              {/* eslint-disable */}
              <li className={cl.shareItem}>
                <Skeleton className={cl.socialSkeleton} />
              </li>
              <li className={cl.shareItem}>
                <Skeleton className={cl.socialSkeleton} />
              </li>
              <li className={cl.shareItem}>
                <Skeleton className={cl.socialSkeleton} />
              </li>
            </ul>
          </div>
        </div>
        <div className={cl.line}></div>
      </div>
    </div>
    <div className={cl.contentBlogSkeleton}>
      <div className="container container-blog">
        <Skeleton className={cl.contentSkeletonBig} />
        <br />
        <Skeleton className={cl.contentSkeletonLittle} count={5} />
        <br />
        <Skeleton className={cl.contentSkeletonLittle} count={3} />
        <br />
        <Skeleton className={cl.contentSkeletonimage} />
        <br />
        <Skeleton className={cl.contentSkeletonBig} />
        <br />
        <Skeleton className={cl.contentSkeletonLittle} count={5} />
      </div>
    </div>
  </div>
);
