export const downloadImage = (url, id) => {
  // console.log(id);
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const fileName = `paintit_${id}.jpg`; // Specify the desired file name here
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(link.href);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};
