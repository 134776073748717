import React from 'react';

const CloseModalIcon = () => {
  return (
    // <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path
    //     d="M13.2983 0.70998C13.2058 0.617276 13.0959 0.543728 12.9749 0.493547C12.8539 0.443366 12.7242 0.417535 12.5933 0.417535C12.4623 0.417535 12.3326 0.443366 12.2116 0.493547C12.0907 0.543728 11.9808 0.617276 11.8883 0.70998L6.99827 5.58998L2.10827 0.699979C2.01569 0.607397 1.90578 0.533957 1.78481 0.483852C1.66385 0.433747 1.5342 0.407959 1.40327 0.407959C1.27234 0.407959 1.14269 0.433747 1.02173 0.483852C0.900763 0.533957 0.790852 0.607397 0.69827 0.699979C0.605688 0.792561 0.532248 0.902472 0.482143 1.02344C0.432038 1.1444 0.40625 1.27405 0.40625 1.40498C0.40625 1.53591 0.432038 1.66556 0.482143 1.78652C0.532248 1.90749 0.605688 2.0174 0.69827 2.10998L5.58827 6.99998L0.69827 11.89C0.605688 11.9826 0.532248 12.0925 0.482143 12.2134C0.432038 12.3344 0.40625 12.464 0.40625 12.595C0.40625 12.7259 0.432038 12.8556 0.482143 12.9765C0.532248 13.0975 0.605688 13.2074 0.69827 13.3C0.790852 13.3926 0.900763 13.466 1.02173 13.5161C1.14269 13.5662 1.27234 13.592 1.40327 13.592C1.5342 13.592 1.66385 13.5662 1.78481 13.5161C1.90578 13.466 2.01569 13.3926 2.10827 13.3L6.99827 8.40998L11.8883 13.3C11.9809 13.3926 12.0908 13.466 12.2117 13.5161C12.3327 13.5662 12.4623 13.592 12.5933 13.592C12.7242 13.592 12.8538 13.5662 12.9748 13.5161C13.0958 13.466 13.2057 13.3926 13.2983 13.3C13.3909 13.2074 13.4643 13.0975 13.5144 12.9765C13.5645 12.8556 13.5903 12.7259 13.5903 12.595C13.5903 12.464 13.5645 12.3344 13.5144 12.2134C13.4643 12.0925 13.3909 11.9826 13.2983 11.89L8.40827 6.99998L13.2983 2.10998C13.6783 1.72998 13.6783 1.08998 13.2983 0.70998Z"
    //     fill="black"
    //   />
    // </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M18.2983 5.71022C18.2058 5.61752 18.0959 5.54397 17.9749 5.49379C17.8539 5.44361 17.7242 5.41778 17.5933 5.41778C17.4623 5.41778 17.3326 5.44361 17.2116 5.49379C17.0907 5.54397 16.9808 5.61752 16.8883 5.71022L11.9983 10.5902L7.10827 5.70022C7.01569 5.60764 6.90578 5.5342 6.78481 5.4841C6.66385 5.43399 6.5342 5.4082 6.40327 5.4082C6.27234 5.4082 6.14269 5.43399 6.02173 5.4841C5.90076 5.5342 5.79085 5.60764 5.69827 5.70022C5.60569 5.79281 5.53225 5.90272 5.48214 6.02368C5.43204 6.14464 5.40625 6.27429 5.40625 6.40522C5.40625 6.53615 5.43204 6.6658 5.48214 6.78677C5.53225 6.90773 5.60569 7.01764 5.69827 7.11022L10.5883 12.0002L5.69827 16.8902C5.60569 16.9828 5.53225 17.0927 5.48214 17.2137C5.43204 17.3346 5.40625 17.4643 5.40625 17.5952C5.40625 17.7262 5.43204 17.8558 5.48214 17.9768C5.53225 18.0977 5.60569 18.2076 5.69827 18.3002C5.79085 18.3928 5.90076 18.4662 6.02173 18.5163C6.14269 18.5665 6.27234 18.5922 6.40327 18.5922C6.5342 18.5922 6.66385 18.5665 6.78481 18.5163C6.90578 18.4662 7.01569 18.3928 7.10827 18.3002L11.9983 13.4102L16.8883 18.3002C16.9809 18.3928 17.0908 18.4662 17.2117 18.5163C17.3327 18.5665 17.4623 18.5922 17.5933 18.5922C17.7242 18.5922 17.8538 18.5665 17.9748 18.5163C18.0958 18.4662 18.2057 18.3928 18.2983 18.3002C18.3909 18.2076 18.4643 18.0977 18.5144 17.9768C18.5645 17.8558 18.5903 17.7262 18.5903 17.5952C18.5903 17.4643 18.5645 17.3346 18.5144 17.2137C18.4643 17.0927 18.3909 16.9828 18.2983 16.8902L13.4083 12.0002L18.2983 7.11022C18.6783 6.73022 18.6783 6.09022 18.2983 5.71022Z"
        fill="black"
      />
    </svg>
  );
};

export default CloseModalIcon;
