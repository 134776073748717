import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const setBoundingBoxToServer = createAsyncThunk(
  "products/getProducts",
  async (imageBase64Data) => {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_HOST}/api/v1/images/upload/`,
      JSON.stringify({
        image: imageBase64Data,
        upload_to: "tmp",
      }),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("key")}`,
        },
      }
    );
    const data = res.data;
    if ("id" in data) {
      return data;
    }
    return null;
  }
);

const initialState = {
  productImage: null,
  imageData: null,
  isLoading: false,
};

export const productsFromImage = createSlice({
  name: "products from image",
  initialState,
  reducers: {
    deleteProducts: (state, action) => {
      state.imageData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setBoundingBoxToServer.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.imageData = action.payload;
      }
      state.isLoading = false;
    });
    builder.addCase(setBoundingBoxToServer.pending, (state, action) => {
      state.isLoading = true;
    });
  },
});

export const { deleteProducts } = productsFromImage.actions;
export default productsFromImage.reducer;
