import React from 'react';
import TitlePage from '../titlePage/TitlePage';

const WhoIsOurProduct = () => {
  return (
    <div className="container">
      <div className="whoIsOurProduct">
        <TitlePage
          title={`Revolutionize your space: [break]
          AI-driven design solutions for everyone`}
          lineBreak={2}
          size={'normal'}
          animation={true}
        />
        <div className="option_list">
          <div className="option_line">
            <div className="title">
              <p>Home owners</p>
            </div>
            <div className="description">
              <b>Benefit:</b> We provide you with the ability to efficiently visualize and customize
              your living space. Our AI-driven designs allow you to explore different styles and
              layouts, ensuring your home is a true reflection of your personal taste and functional
              needs.
            </div>
          </div>
          <div className="option_line">
            <div className="title">
              <p>Interior Designers</p>
            </div>
            <div className="description">
              <b>Benefit:</b> We offer a tool that enhances your creative process, providing rapid,
              diverse design options and visualizations for your clients. Our service aids in
              efficient space planning and gives you access to a vast library of styles and trends,
              helping you craft unique and personalized spaces for clients.
            </div>
          </div>
          <div className="option_line">
            <div className="title">
              <p>Real Estate Agents</p>
            </div>
            <div className="description">
              <b>Benefit:</b> Our service is a powerful tool for showcasing the potential of
              properties to clients. We help you visualize renovations and improvements, making your
              properties more appealing and marketable to prospective buyers or tenants.
            </div>
          </div>
          <div className="option_line">
            <div className="title">
              <p>Architects</p>
            </div>
            <div className="description">
              <b>Benefit:</b> We integrate seamlessly into your architectural workflow, offering
              comprehensive project visions. Our tool assists in aligning architectural designs with
              interior aesthetics, ensuring a cohesive look from the structure to the interior.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoIsOurProduct;
