import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setActiveAuthModal } from './authorizationModalSlice';
import { updateLocalStorage } from '../../../lib/scrypts';

const initialState = {
  errorMessageLoginUser: '',
  userData: {},
};

export const getUser = createAsyncThunk('getUser/getUser', async (_, { dispatch }) => {
  // console.log(data.email);
  const userId = localStorage.getItem('key');
  await axios
    .get(
      `${process.env.REACT_APP_BACKEND_HOST}/api/v1/rest-auth/user/`,

      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userId}`,
        },
      },
    )
    .then((response) => {
      dispatch(getUserData(response.data));
      localStorage.setItem('data', JSON.stringify(response.data))
      dispatch(setActiveAuthModal(false));
      updateLocalStorage();
    })
    .catch((error) => {
      console.log(error.response.data);
    });
});

export const getUserSlice = createSlice({
  name: 'getUser',
  initialState,
  reducers: {
    getUserData: (state, action) => {
      state.userData = action.payload;
    },
    deleteAllData: (state, action) => {
      state = initialState
    }
  },
});

export const { getUserData, deleteAllData } = getUserSlice.actions;
export default getUserSlice.reducer;
