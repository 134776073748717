import React, { useEffect } from "react";
import "./roomDesign.css";
import ChoiceButton from "../../button/ChoiceButton";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import RoomDesignCard from "./RoomDesignCard";
import { useDispatch, useSelector } from "react-redux";
import { setHashtags } from "../../../toolkit/slices/roomTypeSlice";
import { AnimatePresence } from "framer-motion";
import { checkPathname } from "../../../lib/scrypts";
import { getFilters } from "../../../toolkit/slices/getFiltersSlice";
import { gtmEvent } from "../../../lib/gtm";

const RoomDesignGrid = () => {
  const dispatch = useDispatch();
  const hastagArr = useSelector((state) => state.roomType.hastagArr);
  const uploadType = useSelector((state) => state.createProject.uploadType);
  const isPaidUser =
    localStorage.getItem("data") &&
    JSON.parse(localStorage.getItem("data")).is_paid;
  const pageLink = window.location.pathname.slice(1);
  const selectCard = (name, hashtags, id) => {
    dispatch(setHashtags([checkPathname(pageLink), name, hashtags, id]));
  };

  let delayVal = 0;
  let activeCardName = hastagArr[checkPathname(pageLink)]["name"];

  useEffect(() => {
    dispatch(getFilters(1));
  }, [dispatch]);

  const cards = useSelector((state) => state.filters.roomDesign);
  const fillSpaceArr = [
    "modern",
    "scandinavian",
    "industrial",
    "midcentury modern",
    "empire",
    "french country",
    "tropical",
    "contemporary",
  ];

  const isAvailable = (card) => !fillSpaceArr.includes(card.name.toLowerCase());

  const sortedCards =
    uploadType === 1 && cards
      ? [
          ...cards.filter((style) => !isAvailable(style)), // недоступные стили внизу
          ...cards.filter(isAvailable), // доступные стили вверху
        ]
      : cards;

  const handleContinue = () => {
    let gtmContinue = {
      0: "Step3_Inspiration_Room_Design",
      1: "Step3_Space_Room_Design",
      2: "Step3_Redesign_Room_Design",
    };
    gtmEvent(gtmContinue[uploadType], "category");
  };

  return (
    <section className="container">
      <div className="relative">
        <AnimatePresence>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 2, 750: 3, 900: 5 }}
          >
            <Masonry
              columnsCount={5}
              gutter="20px"
              className="masonryGrid"
              id="masonryGrid"
            >
              {sortedCards.map((item, index) => {
                delayVal += 0.1;
                return (
                  <RoomDesignCard
                    onClick={() => selectCard(item.name, item.prompt, item.id)}
                    key={index}
                    active={activeCardName}
                    props={item}
                    delayVal={delayVal}
                    disabled={uploadType === 1 && isAvailable(item)}
                    paid_only={item.paid_only}
                    isPaidUser={isPaidUser}
                  />
                );
              })}
            </Masonry>
          </ResponsiveMasonry>
        </AnimatePresence>
        <ChoiceButton
          active={activeCardName}
          backLink={"/interior-ai/room-type/"}
          continueLink={
            uploadType === 1
              ? "/interior-ai/result/"
              : "/interior-ai/additional-tags/"
          }
          isActive={activeCardName}
          handleContinue={handleContinue}
        />
      </div>
    </section>
  );
};

export default RoomDesignGrid;
