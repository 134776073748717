import React from "react";
import { motion } from "framer-motion";
import cl from './StyleMood.module.css';

const StyleMoodCard = ({
  props,
  onClick,
  delayVal,
  paid_only,
  isPaidUser,
  choosenImages,
}) => {
  const { image } = props;
  const active = choosenImages.includes(image);

  const isActive = active ? `${cl.card} ${cl.active}` : cl.card;
  const isPaid = paid_only && paid_only !== isPaidUser ? cl.disable : "";

  return (
    <motion.div
      onClick={onClick}
      className={`${isActive} ${isPaid}`}
      layout
      initial={{
        y: "30px",
        opacity: 0,
      }}
      animate={{
        y: "0px",
        opacity: 1,
      }}
      transition={{ delay: delayVal }}
    >
      <img src={image} alt="" />

      {active && (
        <div className={cl.hashTitle}>{choosenImages.indexOf(image) + 1}/5</div>
      )}

      {isPaid && <div className={`${cl.hashTitle} ${cl.pro}`}>pro</div>}
    </motion.div>
  );
};

export default StyleMoodCard;
