export const CheckCanvas = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="8"
    viewBox="0 0 10 8"
    fill="none"
    {...props}
  >
    <path
      d="M8.34295 0.592028C8.50143 0.441153 8.71232 0.357744 8.93112 0.359399C9.14993 0.361055 9.35953 0.447646 9.51571 0.600902C9.67188 0.754158 9.76241 0.96209 9.76819 1.18082C9.77398 1.39956 9.69456 1.61198 9.5467 1.77328L5.05795 7.38703C4.98077 7.47016 4.88761 7.53688 4.78405 7.58319C4.68049 7.6295 4.56866 7.65445 4.45524 7.65655C4.34181 7.65865 4.22913 7.63786 4.12393 7.59542C4.01873 7.55297 3.92316 7.48975 3.84295 7.40953L0.866203 4.43278C0.783306 4.35553 0.716816 4.26238 0.6707 4.15888C0.624584 4.05538 0.599786 3.94366 0.597788 3.83037C0.595789 3.71708 0.616629 3.60454 0.659065 3.49948C0.701501 3.39442 0.764664 3.29898 0.844785 3.21886C0.924906 3.13874 1.02034 3.07558 1.12541 3.03314C1.23047 2.9907 1.343 2.96986 1.45629 2.97186C1.56958 2.97386 1.68131 2.99866 1.78481 3.04477C1.88831 3.09089 1.98146 3.15738 2.0587 3.24028L4.41445 5.5949L8.32158 0.616779L8.34295 0.592028Z"
      fill="white"
    />
  </svg>
);
