import React from 'react';

const ArrowFooter = () => {
  return (
    <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 7.7V1.99999C8 1.78749 8.072 1.60925 8.216 1.46525C8.36 1.32125 8.538 1.24949 8.75 1.24999C8.9625 1.24999 9.14075 1.32199 9.28475 1.46599C9.42875 1.60999 9.5005 1.78799 9.5 1.99999V9.5C9.5 9.7125 9.428 9.89075 9.284 10.0347C9.14 10.1787 8.962 10.2505 8.75 10.25H1.24999C1.03749 10.25 0.859245 10.178 0.715245 10.034C0.571245 9.89 0.499495 9.712 0.499994 9.5C0.499994 9.2875 0.571995 9.10925 0.715995 8.96525C0.859995 8.82125 1.03799 8.7495 1.24999 8.75H6.95L0.274995 2.075C0.137495 1.9375 0.0687437 1.76249 0.0687437 1.54999C0.0687437 1.33749 0.137495 1.16249 0.274995 1.02499C0.412495 0.887495 0.587494 0.818744 0.799994 0.818744C1.01249 0.818744 1.1875 0.887495 1.325 1.02499L8 7.7Z"
        fill="white"
      />
    </svg>
  );
};

export default ArrowFooter;
