import HeroStaging from "../components/Staging/HeroStaging/HeroStaging";
import BenefitsContainer from "../components/landingPage/BenefitsContainer";
import RoomDesignContainer from "../components/landingPage/RoomDesignContainer";
import QuestionsContainer from "../components/landingPage/QuestionsContainer";
import SeoBlockStaging from "../components/seoBlock/SeoBlockStaging";
import { useTopToPage } from "../lib/scrypts";
import { Helmet } from "react-helmet-async";

function StagingPage() {
  useTopToPage();
  return (
    <main>
      <Helmet>
        <title>AI-Powered Home Staging Solutions</title>
        <meta
          property="og:title"
          content={"AI-Powered Home Staging Solutions"}
        />
        <meta
          name="twitter:title"
          content={"AI-Powered Home Staging Solutions"}
        />
        <meta
          name="description"
          content="Enhance property appeal and real estate listings with AI-driven staging solutions from PaintIt.ai."
        />
        <meta
          property="og:description"
          content={
            "Enhance property appeal and real estate listings with AI-driven staging solutions from PaintIt.ai."
          }
        />
        <meta
          name="twitter:description"
          content={
            "Enhance property appeal and real estate listings with AI-driven staging solutions from PaintIt.ai."
          }
        />
      </Helmet>
      <HeroStaging />
      <RoomDesignContainer
        type={"staging"}
        titleText="From Contemporary to Empire: [break] Stage Your Space in Eight Iconic Styles"
      />
      <BenefitsContainer type="staging" />
      <QuestionsContainer
        data={[
          {
            title: `What is "Fill the Space – Virtual Staging", which?`,
            text: " It's our cool AI that turns boring room plans into true head-turners with full decor and visuals. We have a choice to pick from six room type and eight in design styles to give in to your fantasy room.",
          },
          {
            title: "How does VS work?",
            text: "Choose your location photo or blueprint, room style and design, and our AI will imagine a staged room containing all the furniture and decor in the style chosen.",
          },
          {
            title:
              "How many different rooms and how many kinds of style can I choose from.",
            text: `We offer staging for living rooms, bedrooms, kitchens, offices, bathrooms, and dining rooms styles that express the modern and minimalist to rustic and traditional.`,
          },
          {
            title:
              " Can Virtual Staging be applied in commercial properties like offices, retail, or industrial space?",
            text: `Absolutely! Our services are specifically designed to deck up homes, apartments and other spaces just in order to create an imagery of the scope that the property holds for new owners or tenants.`,
          },
          {
            title:
              "Is there a virtual environment for Virtual Staging available?",
            text: `Sure, we give out a free trial with a few features so that you can try it as you desire before paying for any of the paid plans.`,
          },
          {
            title:
              "How true to the real staging is a virtual staging visualization?",
            text: `Our AI-powered visions, which strive for the highest level of realism possible, help people to see the furnished space as if it is already real.`,
          },
          {
            title:
              "What if I need desired adjusting about the produced scansion?",
            text: `You will be able to make adjustments so that you can get an option which better suits you. Also, you can contact or us for a more personalised service.`,
          },
          {
            title:
              "How much time is it going to take to observe the decorated room.",
            text: `The outcomes are usually out soon after one or two minutes, which depend on how complicated your space is and what choices you make.`,
          },
        ]}
      />
      <SeoBlockStaging />
    </main>
  );
}

export default StagingPage;
