import cl from "./TagsCloudComp.module.css";
import { useDispatch, useSelector } from "react-redux";
import ChoiceButton from "../../button/ChoiceButton";
import React, { useCallback, useEffect, useState } from "react";
import {
  deactivateTag,
  getAllTags,
  getTag,
  removeAllTags,
} from "../../../toolkit/slices/getTagsSlice";
import TagsCloudItem from "./TagsCloudItem";
import {
  removeHashtag,
  setHashtags,
} from "../../../toolkit/slices/roomTypeSlice";
import { checkPathname } from "../../../lib/scrypts";
import TagsAddCloud from "./TagsAddComponent";
import { gtmEvent } from "../../../lib/gtm";

const TagsCloudComponent = () => {
  const dispatch = useDispatch();
  const tagsGetState = useSelector((state) => state.tags.tags);
  const hashtagsState = useSelector((state) => state.roomType);
  const uploadType = useSelector((state) => state.createProject.uploadType);

  const [activeTags, setActiveTags] = useState([]);

  const pageLink = window.location.pathname.slice(1);

  useEffect(() => {
    let roomtypeId = hashtagsState.hastagArr.roomtype.id,
      roomdesignId = hashtagsState.hastagArr.roomdesignstyle.id;
    console.log(roomtypeId, roomdesignId);
    if (roomtypeId && roomdesignId) {
      dispatch(removeAllTags());
      dispatch(
        getAllTags({
          roomtypeId: roomtypeId,
          roomdesignstyle: roomdesignId,
        })
      );
    }
  }, [
    dispatch,
    hashtagsState.hastagArr.roomtype,
    hashtagsState.hastagArr.roomdesignstyle,
  ]);

  const handleTagClick = useCallback(
    (tag) => {
      let isAlreadyInList = activeTags.find((e) => e.id === tag.id);
      if (isAlreadyInList) {
        dispatch(deactivateTag(tag.id));
        setActiveTags(
          activeTags.filter(
            (e) =>
              e.id !== tag.id &&
              !("includedBy" in e && e.includedBy.includes(tag.id)) &&
              (("parentId" in e && e.parentId !== tag.id) || !("parentId" in e))
          )
        );
        return;
      }
      if (activeTags.length < 5) {
        dispatch(getTag(tag.id));
        setActiveTags([...activeTags, tag]);
      }
    },
    [activeTags, dispatch]
  );

  useEffect(() => {
    let pathnameValue = checkPathname(pageLink);
    if (activeTags.length > 0) {
      dispatch(
        setHashtags([
          pathnameValue,
          "Tags",
          activeTags.map((e) => e.name).join(", "),
        ])
      );
      return;
    }
    dispatch(removeHashtag(pathnameValue));
  }, [activeTags, dispatch, pageLink]);

  const handleContinue = () => {
    let gtmContinue = {
      0: "Step5_Inspiration_Tags_Continue",
      1: "Step5_Space_Tags_Continue",
      2: "Step5_Redesign_Tags_Continue",
    };
    gtmEvent(gtmContinue[uploadType], "category");
  };

  const handleSkip = () => {
    let gtmContinue = {
      0: "Step5_Inspiration_Tags_Skip",
      1: "Step5_Space_Tags_Skip",
      2: "Step5_Redesign_Tags_Skip",
    };
    gtmEvent(gtmContinue[uploadType], "category");
  };

  return (
    <section className="container">
      {tagsGetState.length ? (
        <div className={`relative ${cl.tagsWrapper}`}>
          <ul className={cl.tagsList}>
            {tagsGetState
              .filter((e) => !("hidden" in e))
              .map((tagState, i) => {
                let isActiveTag = activeTags.find(
                  (tag) => tag.id === tagState.id
                );
                return (
                  <TagsCloudItem
                    index={i}
                    key={tagState.id}
                    tagState={tagState}
                    isActiveTag={isActiveTag}
                    callback={handleTagClick}
                    activeTags={activeTags}
                  />
                );
              })}
          </ul>
        </div>
      ) : null}
      <TagsAddCloud setActiveTags={setActiveTags} activeTags={activeTags} />
      <ChoiceButton
        active={activeTags.length > 0}
        backLink={"/interior-ai/room-design-style/"}
        continueLink={"/interior-ai/result/"}
        isActive={activeTags.length > 0}
        skip={true}
        skipLink={"/interior-ai/result/"}
        skipValue={""}
        handleContinue={handleContinue}
        handleSkip={handleSkip}
      />
    </section>
  );
};

export default TagsCloudComponent;
