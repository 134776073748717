import React from "react";
import TitlePage from "../titlePage/TitlePage";
import cl from "./LandingPage.module.css";
import { MyRangeImages } from "../UI/MyRangeImages/MyRangeImages";

const ToolsContainer = () => {
  return (
    <div className="container ">
      <div className="tools">
        <div className="title ">
          <TitlePage
            title={
              "AI Design Suite: transform, redesign, and refine [break] your personal and commercial spaces"
            }
            lineBreak={6}
            size={"normal"}
            animation={true}
          />
          <h3>
            Style your space made simple with our AI-powered tools. From first
            visualization to final customization, redefine <br /> and declutter
            your living and commercial areas with a suite designed for creative
            freedom.
          </h3>
        </div>
        <div className="tools_option">
          <div className="discription order_1">
            <TitlePage
              title={"Inspire your space: discover your design vision"}
              lineBreak={2}
              left
              size={"normal"}
              animation={true}
            />
            <div className="subTitle">
              Explore various room types, design styles, elements, and color
              schemes. Get a stunning visualization of your dream space,
              tailored to your preferences.
            </div>
          </div>
          <div className="discription_image order_2">
            <div className="projectModalLanding">
              <div className="overflow">
                <img
                  src="/img/designs/living-room/inspiration.webp"
                  alt="Inpairation"
                  className={cl.imgInpairition}
                />
              </div>
            </div>
          </div>
        </div>
        {/* ---------------------- */}
        <div className="tools_option">
          <div className="discription order_2">
            <TitlePage
              title={"Fill the space transform empty rooms into lively spaces"}
              lineBreak={2}
              left
              size={"normal"}
              animation={true}
            />
            <div className="subTitle">
              Upload an image of an empty room, select its type and preferred
              design style. See it come to life while maintaining its
              architectural essence.
            </div>
          </div>
          <div className="discription_image order_1">
            <MyRangeImages
              imageBefore={"/img/designs/living-room/fill-the-space.webp"}
              imageAfter={
                "/img/designs/living-room/fill-the-space-original.webp"
              }
              rangeClass={cl.toolsRange}
            />
          </div>
        </div>
        {/* ---------------------- */}
        <div className="tools_option">
          <div className="discription order_1">
            <TitlePage
              title={"Redesign and reimagine: give your space a new look"}
              lineBreak={2}
              size={"normal"}
              left
              animation={true}
            />
            <div className="subTitle">
              Upload your current room with its design and furniture. Choose a
              new style and room type to see a transformation that respects your
              space's architecture.
            </div>
          </div>
          <div className="discription_image order_2">
            <MyRangeImages
              imageBefore={"/img/designs/living-room/redesign.webp"}
              imageAfter={"/img/designs/living-room/fill-the-space.webp"}
              rangeClass={cl.toolsRange}
            />
          </div>
        </div>
        {/* ---------------------- */}
        <div className="tools_option">
          <div className="discription order_2">
            <TitlePage
              title={"Clear the canvas: effortlessly remove unwanted elements"}
              lineBreak={2}
              size={"normal"}
              left
              animation={true}
            />
            <div className="subTitle">
              Easily erase undesired items from your room designs. Upload your
              space and pinpoint objects to remove for a cleaner, more refined
              look.
            </div>
          </div>
          <div className="discription_image order_1">
            <MyRangeImages
              imageAfter={"/img/designs/living-room/fill-the-space.webp"}
              imageBefore={
                "/img/designs/living-room/fill-the-space-original.webp"
              }
              rangeClass={cl.toolsRange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToolsContainer;
