import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import imageLogo from "../../../images/logo.png";
import SocialList from "../../socialList/SocialList";
import LoginButton from "../../menu/LoginButton";
import { useLocalStorageCheck } from "../../../lib/scrypts";
import { gtmEvent } from "../../../lib/gtm";
import cl from "./HeaderMenu.module.css";
import { useSelector, useDispatch } from "react-redux";
import { ImageAttempts } from "../../icon/ImageAttempts";
import { getUser } from "../../../toolkit/slices/authorization/getUserSlice";
import AttemptsOverModal from "../../modal/AttemptsOverModal";
import { getSubscriptions } from "../../../toolkit/slices/authorization/subscriptionPlanSlice";
import { smoothScrollToPrice } from "../../../helpers/smoothScrollTo";

const NavBar = ({ result = false }) => {
  useLocalStorageCheck("name");
  const navigate = useNavigate();
  const location = useLocation();

  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [isActiveMobileMenu, setIsActiveMobileMenu] = useState(false);
  const userInfo = useSelector((state) => state.getUser.userData);
  const dataStorage = JSON.parse(localStorage.getItem("data"));
  const [isAttempts, setIsAttempts] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      if (prevScrollPos > currentScrollPos || prevScrollPos < 0) {
        document.getElementById("myTopnav").style.top = "0";
      } else if (
        (prevScrollPos < currentScrollPos && isActiveMobileMenu) ||
        prevScrollPos < 0
      ) {
        document.getElementById("myTopnav").style.top = "0";
      } else {
        document.getElementById("myTopnav").style.top = "-72px";
        setIsActiveMobileMenu(false);
      }
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    // console.log('prevScrollPos :>> ', prevScrollPos);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos, isActiveMobileMenu]);

  const isAuth = localStorage.getItem("key");
  const userName = localStorage.getItem("name");

  function openMenu() {
    setIsActiveMobileMenu((isActiveMobileMenu) => !isActiveMobileMenu);
  }

  useEffect(() => {
    if (localStorage.getItem("data") && !userInfo) {
      dispatch(getUser());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (localStorage.getItem("key")) {
      dispatch(getSubscriptions());
    }
  }, [dispatch]);



  const pricingHandleClick = (e) => {
    e.preventDefault();
    if (location.pathname === "/") {
      smoothScrollToPrice("pricing");
    } else {
      navigate("/", { state: { pricing: true } });
    }
  };

  useEffect(() => {
    if (location.pathname === "/" && location.state) {
      if ("pricing" in location.state)
        setTimeout(() => {
          smoothScrollToPrice("pricing");
        }, 250);
    }
    // eslint-disable-next-line
  }, [location]);

  return (
    <>
      {isAttempts ? (
        <AttemptsOverModal
          title={`Sorry, your attempts are over, you need to ${"Top Up"}`}
          topUp
          isActive={isAttempts}
          setIsActive={setIsAttempts}
          lineBreak={4}
        />
      ) : null}

      <div
        className={`topnav topnav-fixed ${cl.header} ${
          isActiveMobileMenu && "active responsive"
        }`}
        id="myTopnav"
      >
        <div className={`container topnav-container`}>
          <button className="icon" onClick={() => openMenu()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
            >
              <path
                d="M2.25 4.52686H15.75M2.25 9.02686H15.75M2.25 13.5269H15.75"
                stroke="black"
                strokeWidth="1.125"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <div className="logo">
            <Link
              to="/"
              title=""
              className="nav__link "
              onClick={() => {
                localStorage.removeItem("make design");
              }}
            >
              <img src={imageLogo} alt="logo" id="roomForPaintitAi" />
            </Link>
          </div>
          <div className="isMobile login_button buttons">
            {/* <button className="button white">Log In/Sign Up</button> */}{" "}
            {isAuth ? (
              <div className={`buttonMenu login ${cl.attemptsCounts}`}>
                <div className="icon">
                  <ImageAttempts />
                </div>
                <div className={`text ${cl.visible}`}>
                  {"attempts_left" in userInfo && userInfo.attempts_left
                    ? userInfo.attempts_left
                    : dataStorage.attempts_left}
                </div>
              </div>
            ) : null}
            <LoginButton
              textValue={isAuth && userName ? userName : "Log In / sign up"}
            />
          </div>
          <div className="links">
            <div className="isMobile social_buttons">
              <SocialList viewStyle={"modal"} />
            </div>
            <div className="isMobile create_button buttons">
              <button
                className="button green"
                onClick={() => {
                  if (Object.values(userInfo).length && dataStorage) {
                    if (
                      +userInfo.attempts_left <= 0 ||
                      +dataStorage.attempts_left <= 0
                    ) {
                      setIsAttempts(true);
                      return;
                    }
                  }
                  navigate("/interior-ai/mode-options/");
                  localStorage.removeItem("make design");
                  gtmEvent("Create_Now_Head", "category");
                }}
              >
                Create now
              </button>
            </div>
            <div className="menu_links">
              <div className="isMobile menu_title">Menu</div>
              <a href="#pricing" onClick={pricingHandleClick}>
                Pricing
              </a>
              <Link to="/widget/">Widget</Link>
              <Link to="/staging/">Staging</Link>
              <Link to="/blogs/">Blogs</Link>
            </div>

            <div className="isDesktop buttons">
              <button
                className="button green"
                onClick={() => {
                  if (Object.values(userInfo).length && dataStorage) {
                    if (
                      +userInfo.attempts_left <= 0 ||
                      +dataStorage.attempts_left <= 0
                    ) {
                      setIsAttempts(true);
                      return;
                    }
                  }
                  navigate("/interior-ai/mode-options/");
                  gtmEvent("Create_Now_Head", "category");
                }}
              >
                create now
              </button>

              {/* <button className="button white">log in</button> */}
              <LoginButton
                textValue={
                  isAuth ? (userName ? userName : "") : "Log In / sign up"
                }
              />
              {isAuth ? (
                <div className={`buttonMenu login ${cl.attemptsCounts}`}>
                  <div className="icon">
                    <ImageAttempts />
                  </div>
                  <div className="text">
                    {"attempts_left" in userInfo && userInfo.attempts_left
                      ? userInfo.attempts_left
                      : dataStorage.attempts_left}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
