import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  valueMoney: 6,
  additionalValueVisions: 3,
  valueVisions: 30,
  valuePercent: 50,
};

export const rangeVisionSlice = createSlice({
  name: 'rangeVisionSlice',
  initialState,
  reducers: {
    setValueMoney: (state, action) => {
      state.valueMoney = action.payload;
      localStorage.setItem('payments_valueMoney', action.payload);
    },
    setAdditionalValueVisions: (state, action) => {
      state.additionalValueVisions = action.payload;
    },
    setValueVision: (state, action) => {
      state.valueVisions = action.payload;
      localStorage.setItem('payments_valueVisions', action.payload);
    },
    setValuePercent: (state, action) => {
      state.valuePercent = action.payload;
    },
  },
});

export const { setValueMoney, setValueVision, setValuePercent, setAdditionalValueVisions } = rangeVisionSlice.actions;
export default rangeVisionSlice.reducer;
