import React from 'react';
import './isPortfolioEmpty.css';
import Button from '../button/Button';
import BoxIcon from '../icon/BoxIcon';

const IsPortfolioEmpty = () => {
  return (
    <div className="emptyBox">
      <div className="inner">
        <div className="icon">
          <BoxIcon />
        </div>
        <div className="info">You don't have any generated interior yet</div>
        <div>
          <Button link={'/interior-ai/mode-options/'} color={'green'} text={'get started'} />
        </div>
      </div>
    </div>
  );
};

export default IsPortfolioEmpty;
