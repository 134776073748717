import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isActiveAuthModal: false,
  activeModalName: 'Login',
  activeModalPrice: false,
};

export const authorizationModalSlice = createSlice({
  name: 'authorizationModal',
  initialState,
  reducers: {
    setActiveAuthModal: (state, action) => {
      state.isActiveAuthModal = action.payload;
    },
    setActiveModalName: (state, action) => {
      state.activeModalName = action.payload;
    },
    setActiveModalPrice: (state, action) => {
      state.activeModalPrice = action.payload;
    },
  },
});

export const { setActiveAuthModal, setActiveModalName, setActiveModalPrice } =
  authorizationModalSlice.actions;
export default authorizationModalSlice.reducer;
