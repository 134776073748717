import React, { useEffect } from "react";
import StyleMoodResult from "../../components/styleMoodResult/StyleMoodResult";
import ChoiceButton from "../../components/button/ChoiceButton";
import { useTopToPage } from "../../lib/scrypts";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const StyleMoodResultPage = () => {
  useTopToPage();
  const { data } = useSelector((state) => state.selectedImages);

  const hashtagState = useSelector((state) => state.roomType);
  const navigate = useNavigate();

  useEffect(() => {
    const redirect = (e) => {
      navigate("/interior-ai/mode-options/");
    };

    if (
      !hashtagState.hastagArr.roomtype.name &&
      !hashtagState.hastagArr.roomdesign.name
    ) {
      redirect();
    }
  }, [
    navigate,
    hashtagState.hastagArr.roomtype.name,
    hashtagState.hastagArr.roomdesign.name,
  ]);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <StyleMoodResult data={data} />
      <ChoiceButton
        active={true}
        backLink={"/interior-ai/roomdesignai/"}
        continueLink={"/interior-ai/yourstyle/"}
        chooseYourselfLink={"/interior-ai/room-design-style/"}
        isActive={true}
      />
    </>
  );
};

export default StyleMoodResultPage;
