import React from 'react';

const ArrowPrew = ({props}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="17" viewBox="0 0 9 17" fill="none" {...props}>
      <path
        d="M8 1.5L1.70711 7.79289C1.31658 8.18342 1.31658 8.81658 1.70711 9.20711L8 15.5"
        stroke="black"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default ArrowPrew;
