import { ArrowPagIcon } from "../../icon/ArrowPag";
import cl from "./MyPagination.module.css";
import { useSearchParams } from "react-router-dom";

const MyPagination = ({ totalPage, classPag }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  let pageCount = searchParams.get("page");
  // console.log(totalPage, pageCount);

  const handlePagChange = (pageNumber) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    pageNumber === 1
      ? setSearchParams()
      : setSearchParams({ page: pageNumber });
  };

  return (
    <div className={[cl.pagination, classPag].join(" ")}>
      <button
        className={[cl.pagPrev, !pageCount ? cl.pagDisable : ""].join(" ")}
        onClick={(e) => handlePagChange(+pageCount - 1)}
      >
        <ArrowPagIcon
          className={cl.prev}
          style={{ transform: "rotate(180deg)" }}
        />
      </button>
      <div className={cl.pagNumbers}>
        {totalPage <= 3 || +pageCount >= totalPage - 2 ? (
          Array.from({ length: totalPage <= 3 ? totalPage : 3 }, (_, i) =>
            totalPage <= 3 ? i + 1 : i + totalPage - 2
          ).map((el) => (
            <button
              key={el}
              className={[
                cl.pagPage,
                el === +pageCount || (!pageCount && el === 1)
                  ? cl.pagActive
                  : "",
              ].join(" ")}
              onClick={() => {
                handlePagChange(el);
              }}
            >
              {el}
            </button>
          ))
        ) : totalPage > 3 ? (
          <>
            <button
              className={[
                cl.pagPage,
                !pageCount || +pageCount !== 1 ? cl.pagActive : "",
              ].join(" ")}
              onClick={() => {
                handlePagChange(1);
              }}
            >
              {pageCount ? pageCount : "1"}
            </button>
            <button
              className={[
                cl.pagPage,
                totalPage === +pageCount ? cl.pagActive : "",
              ].join(" ")}
              onClick={() => {
                handlePagChange(pageCount ? +pageCount + 1 : 2);
              }}
            >
              {pageCount ? +pageCount + 1 : 2}
            </button>
            <span className={cl.pagMore}>...</span>
            <button
              className={[
                cl.pagPage,
                totalPage === +pageCount ? cl.pagActive : "",
              ].join(" ")}
              onClick={() => {
                handlePagChange(totalPage);
              }}
            >
              {totalPage}
            </button>
          </>
        ) : null}
      </div>
      <button
        className={[
          cl.pagNext,
          (!pageCount && totalPage === 1) || +pageCount === totalPage
            ? cl.pagDisable
            : "",
        ].join(" ")}
        onClick={(e) => handlePagChange(pageCount ? +pageCount + 1 : 2)}
      >
        <ArrowPagIcon className={cl.next} />
      </button>
    </div>
  );
};

export default MyPagination;
