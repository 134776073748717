import React, { useEffect } from "react";
import cl from "./StyleMood.module.css";
import ChoiceButton from "../button/ChoiceButton";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import StyleMoodCard from "./StyleMoodCard";
import { useDispatch, useSelector } from "react-redux";
import {
  setImages,
  removeImage,
  clearState,
} from "../../toolkit/slices/selectImagesSlice";
import { AnimatePresence } from "framer-motion";
import {
  getFilters,
  setDesignToAll,
} from "../../toolkit/slices/getFiltersSlice";
import { useNavigate } from "react-router-dom";
import { setHashtags } from "../../toolkit/slices/roomTypeSlice";

const StyleMoodGrid = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pictures, loading, redirect, data } = useSelector(
    (state) => state.selectedImages
  );

  const isPaidUser =
    localStorage.getItem("data") &&
    JSON.parse(localStorage.getItem("data")).is_paid;

  const handleSelectImages = async (item) => {
    if (pictures.length === 5 || pictures.includes(item)) {
      dispatch(removeImage(item));
      return;
    } else {
      dispatch(setImages(item));
    }
  };

  let delayVal = 0;

  useEffect(() => {
    if (redirect) {
      let dataShort = data.short_response.replace(/[^\p{L}\s]/gu,'');
      dispatch(
        setHashtags([
          "roomdesign",
          dataShort,
          dataShort,
          dataShort,
        ])
      );
      dispatch(
        setDesignToAll({ short: dataShort, long: data.long_response })
      );
      navigate("/interior-ai/suggestion");
    }
    if (!data) {
      navigate("/interior-ai/roomdesignai");
    }
    return () => {
      dispatch(clearState(false));
    };
  }, [dispatch, navigate, redirect, data]);

  useEffect(() => {
    if (loading) {
      document.body.classList.add("modal");
    } else {
      document.body.classList.remove("modal");
    }
    return () => document.body.classList.remove("modal");
  }, [loading]);

  useEffect(() => {
    dispatch(getFilters(1));
  }, [dispatch]);

  const cards = useSelector((state) => state.filters.roomDesign);

  return (
    <section className={"container"}>
      <div className={cl.relative}>
        <AnimatePresence>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 2, 750: 3, 900: 5 }}
          >
            <Masonry
              columnsCount={5}
              gutter="20px"
              className={"masonryGrid"}
              id="masonryGrid"
            >
              {cards.map((item, index) => {
                delayVal += 0.1;
                return (
                  <StyleMoodCard
                    onClick={() => handleSelectImages(item.image)}
                    key={index}
                    props={item}
                    delayVal={delayVal}
                    paid_only={item.paid_only}
                    isPaidUser={isPaidUser}
                    choosenImages={pictures}
                  />
                );
              })}
            </Masonry>
          </ResponsiveMasonry>
        </AnimatePresence>
        <ChoiceButton
          active={true}
          backLink={"/interior-ai/room-type/"}
          continueLink={"/interior-ai/additional-tags/"}
          chooseYourselfLink={"/interior-ai/room-design-style/"}
          isActive={pictures.length}
          visible={true}
        />
      </div>
      {loading && (
        <div className={cl.loader}>
          <div className={cl.dotFalling}></div>
          <h6 className={cl.loaderTitle}>AI creates the interior</h6>
          <p className={cl.loaderDesc}>
            Just wait a little and you'll be pleasantly surprised...
          </p>
        </div>
      )}
    </section>
  );
};

export default StyleMoodGrid;
