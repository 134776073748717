import React from 'react';
import './banner.css';
import TitlePage from '../titlePage/TitlePage';
import Button from '../button/Button';
import { gtmEvent } from '../../lib/gtm';

const Banner = ({ color, btnColor }) => {
  return (
    <div className={`banner ${color}`}>
      <TitlePage
        title={`Receive the full design project [break] you've always dreamed of`}
        lineBreak={5}
        size={'normal'}
        animation={true}
      />
      <div className="row center">
        <Button
          link={'https://paintitgroup.com/'}
          onClick={() => gtmEvent('Try_now', 'category')}
          color={btnColor}
          text={'Try now'}
          target={'_blank'}
        />
      </div>
    </div>
  );
};

export default Banner;
