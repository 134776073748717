import React, { useState } from "react";
import InfoIcon from "../icon/InfoIcon";
import { motion, AnimatePresence } from "framer-motion";
import CircleCheckIcon from "../icon/CircleCheckIcon";
import ArrowUp from "../icon/ArrowUp";
import { useMediaQuery } from "react-responsive";
import SelectVisionsSliderComponent from "../input/SelectVisionsSliderComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  setValueMoney,
  setValueVision,
  setValuePercent,
  setAdditionalValueVisions,
} from "../../toolkit/slices/rangeVisionSlice";
import { gtmEvent } from "../../lib/gtm";

const PriceBlockComponent = ({
  color,
  setIsHovered,
  title,
  description,
  active,
  price,
  oldPrice,
  visions,
  title_btn,
  handler,
}) => {
  const advantagesList = [
    {
      name: "Free trial",
      tooltip: "",
      type: ["Free", "Personal"],
      description: "No",
    },
    { name: "Free trial", tooltip: "", type: ["Pro"], description: "" },
    { name: "Watermark", tooltip: "", type: ["Free"], description: "" },
    {
      name: "Watermark free",
      tooltip: "",
      type: ["Personal", "Pro"],
      description: "",
    },
    { name: "Low quality", tooltip: "", type: ["Free"], description: "" },
    {
      name: "High quality",
      tooltip: "",
      type: ["Personal", "Pro"],
      description: "",
    },

    { name: "Public mode", tooltip: "", type: ["Free"], description: "" },
    {
      name: "Private mode",
      tooltip: "",
      type: ["Personal", "Pro"],
      description: "",
    },

    {
      name: "Limited",
      tooltip: "",
      type: ["Free", "Personal"],
      description: "",
    },
    { name: "Unlimited", tooltip: "", type: ["Pro"], description: "" },

    {
      name: "Personal usage only",
      tooltip: "",
      type: ["Free"],
      description: "",
    },
    {
      name: "Commercial usage license (1 person)",
      tooltip: "",
      type: ["Personal"],
      description: "",
    },
    {
      name: "Commercial usage license (5 persons)",
      tooltip: "",
      type: ["Pro"],
      description: "",
    },

    // {
    //   name: "Number of tokens",
    //   tooltip: "",
    //   type: ["Free"],
    //   description: "1",
    // },
    // {
    //   name: "Number of tokens (soon)",
    //   tooltip: "",
    //   type: ["Personal", "Pro"],
    //   description: "4",
    // },
    // {
    //   name: "Number of tokens (soon)",
    //   tooltip: "",
    //   type: ["Pro"],
    //   description: "4",
    // },
    {
      name: "Inspiration (tokens)",
      tooltip: "",
      type: ["Free", "Personal", "Pro"],
      description: "1",
    },
    {
      name: "Redesign (tokens)",
      tooltip: "",
      type: ["Free", "Personal", "Pro"],
      description: "2",
    },
    {
      name: "Staging (tokens)",
      tooltip: "",
      type: ["Free", "Personal", "Pro"],
      description: "3",
    },
    {
      name: "Furniture recognitions",
      tooltip: "",
      type: ["Free", "Personal", "Pro"],
      description: "",
    },
    {
      name: "Download",
      tooltip: "",
      type: ["Personal", "Pro"],
      description: "",
    },
    {
      name: "Personal AI designer",
      tooltip: "",
      type: ["Personal", "Pro"],
      description: "",
    },
    {
      name: "Premium support",
      tooltip: "",
      type: ["Personal", "Pro"],
      description: "",
    },
    {
      name: "Commercial properties",
      tooltip: "",
      type: ["Pro"],
      description: "",
    },
    // WIDGET
    {
      name: "Unlimited renders",
      tooltip: "",
      type: [
        "Pay-As-You-Stage",
        "Full Access Subscription",
        "Branded Design Suite",
      ],
      description: "",
    },
    {
      name: "No watermark",
      tooltip: "",
      type: [
        "Pay-As-You-Stage",
        "Full Access Subscription",
        "Branded Design Suite",
      ],
      description: "",
    },
    {
      name: "Applicable for all room types",
      tooltip: "",
      type: ["Pay-As-You-Stage"],
      description: "",
    },
    {
      name: "Direct integration onto the site",
      tooltip: "",
      type: ["Pay-As-You-Stage"],
      description: "",
    },
    {
      name: "Up to 800 photos",
      tooltip: "",
      type: ["Full Access Subscription"],
      description: "",
    },
    {
      name: "Up to 1000 photos",
      tooltip: "",
      type: ["Branded Design Suite"],
      description: "",
    },
    {
      name: "On-site integration included",
      tooltip: "",
      type: ["Full Access Subscription", "Branded Design Suite"],
      description: "",
    },
    {
      name: "Multiple room types supported",
      tooltip: "",
      type: ["Full Access Subscription"],
      description: "",
    },
    {
      name: "Custom widget design to match the site aesthetics",
      tooltip: "",
      type: ["Full Access Subscription"],
      description: "",
    },
    {
      name: "All room types included",
      tooltip: "",
      type: ["Branded Design Suite"],
      description: "",
    },
    {
      name: "Fully branded widget design, tailored to blend seamlessly with the developer's or real estate company's site",
      tooltip: "",
      type: ["Branded Design Suite"],
      description: "",
    },

    // { name: '', tooltip: '', type: ['Free', 'Personal', 'Pro'], description: '' },
    // { name: '', tooltip: '', type: ['Free', 'Personal', 'Pro'], description: '' },
  ];

  const dispatch = useDispatch();

  const valueMoney = useSelector((state) => state.rangeVisionSlice.valueMoney);
  const additionalValueVisions = useSelector(
    (state) => state.rangeVisionSlice.additionalValueVisions
  );

  const valueVisions = useSelector(
    (state) => state.rangeVisionSlice.valueVisions
  );
  const valuePercent = useSelector(
    (state) => state.rangeVisionSlice.valuePercent
  );
  const trackBackground = `linear-gradient(to right, #000 ${valuePercent}%, rgb(0, 0, 0, 0.09) ${valuePercent}%, rgb(0, 0, 0, 0.09) 100%)`;
  const handleChangeVision = (event) => {
    var min = 2;
    var max = 10;
    var percentage = ((event.target.value - min) / (max - min)) * 100;

    dispatch(setValuePercent(percentage));
    dispatch(setValueMoney(event.target.value));
    dispatch(setValueVision(event.target.value * 5));
    dispatch(setAdditionalValueVisions((event.target.value * 5) / 10));
  };

  const isMobileMediaQuery = useMediaQuery({ query: "(max-width: 776.25px)" });

  const [isOpen, setIsOpen] = useState(!isMobileMediaQuery && true);
  const openFreeBox = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`priceBlock ${color}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={`title`}>{title}</div>

      <div className="subTitle">{description}</div>
      <div
        className="price_range_block"
        style={{
          display:
            color === "green" && visions !== "per month" ? "none" : "block",
        }}
      >
        <div className="buy">
          <div className="price">
            {oldPrice ? <span className="old_price"> ${oldPrice}</span> : ""}$
            {price}
            {oldPrice ? (
              <span>
                per
                <br /> mounth
              </span>
            ) : (
              ""
            )}
            {visions === "per month" || visions === "per photo" ? (
              <span>
                per
                <br /> {visions.split("per ")[1]}
              </span>
            ) : (
              ""
            )}
          </div>
          {visions !== "per month" && visions !== "per photo" ? (
            <div className="visions">
              {visions} <span>Tokens</span>
            </div>
          ) : null}
        </div>
      </div>
      <div
        style={{
          visibility:
            color === "green" && visions !== "per month" ? "visible" : "hidden",
        }}
      >
        <SelectVisionsSliderComponent
          color={color}
          visions={visions}
          valueMoney={valueMoney}
          valueVisions={valueVisions}
          handleChangeVision={handleChangeVision}
          trackBackground={trackBackground}
          addValue={additionalValueVisions}
        />
      </div>
      {title_btn.toLowerCase() === "schedule demo" ? (
        <button className="button">
          <a
            className="link-button"
            href="https://calendly.com/paintit-ai/paintit-ai-widget-demo"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              gtmEvent("widget_button-neme", "category");
            }}
          >
            {title_btn}
          </a>
        </button>
      ) : (
        <button className="button" onClick={handler}>
          {title_btn}
        </button>
      )}

      <button
        className="openFeaturesMobile"
        style={{
          color: title === "Pro" ? "white" : "#888b8e",
        }}
        onClick={() => {
          openFreeBox();
        }}
      >
        {isOpen ? "Hide features" : " Show features"}{" "}
        <ArrowUp
          color={title === "Pro" ? "white" : "#252525"}
          isOpenFree={isOpen}
        />
      </button>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            style={{ overflow: "hidden" }}
            className="advantages"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 1, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ul>
              {advantagesList.map((advantage, index) => {
                // console.log('advantage :>> ', advantage);
                if (advantage.type.includes(title)) {
                  return (
                    <li key={advantage.name + "_" + index}>
                      <div className="title">
                        {advantage.name}
                        {advantage.tooltip && (
                          <span>
                            <InfoIcon />
                          </span>
                        )}
                      </div>
                      <div className="check">
                        <div className="proPlan">
                          {advantage.description ? (
                            advantage.description
                          ) : (
                            <CircleCheckIcon />
                          )}
                        </div>
                      </div>
                    </li>
                  );
                }
                return null;
              })}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PriceBlockComponent;
