import React, { useEffect } from "react";
import MainLanding from "../components/landingPage/MainLanding";
import { useDispatch, useSelector } from "react-redux";
import { setPageType } from "../toolkit/slices/checkTypePageSlice";
import { useNavigate } from "react-router-dom";
import { googleAuth } from "../toolkit/slices/authorization/socialAuthSlice";
import { checkEmail } from "../toolkit/slices/confirmEmailSlice";
import {
  setActiveAuthModal,
  setActiveModalName,
} from "../toolkit/slices/authorization/authorizationModalSlice";
import {
  setUID,
  setToken,
} from "../toolkit/slices/authorization/resetPasswordSlice";
import { getUser } from "../toolkit/slices/authorization/getUserSlice";
import { useOpenStrype, useTopToPage } from "../lib/scrypts";
import { getFiltersAll } from "../toolkit/slices/getFiltersSlice";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { gtmEvent, gtmEventProduct } from "../lib/gtm";

const LandingPage = ({ pageType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userToken = useSelector((state) => state.login.userToken);
  const onOnload = useSelector((state) => state.portfolioUser.onLoadPage);
  const [urlParams] = useSearchParams();
  const code = urlParams.get("code");
  const social = urlParams.get("social");
  const token = urlParams.get("token");
  const user_id = urlParams.get("user_id");
  const uid = urlParams.get("uid");
  const isLogin = urlParams.get("isLogin");
  const tokens = urlParams.get("tokens");
  const mode = urlParams.get("mode");
  const success = urlParams.get("success");

  useEffect(() => {
    dispatch(setPageType(pageType));
    if (onOnload) {
      dispatch(getFiltersAll());
    }
  });

  useEffect(() => {
    if (code != null) {
      if (social === "google") {
        dispatch(googleAuth({ code: encodeURIComponent(code) }));
      }
    }
  }, [code, social, navigate, dispatch]);

  useEffect(() => {
    if (
      localStorage.getItem("make design") &&
      (localStorage.getItem("key") || userToken)
    ) {
      navigate("/interior-ai/result");
    }
  }, [userToken, navigate]);

  useEffect(() => {
    if (success === "true") {
      if (tokens && mode) {
        if (mode === "payment") {
          const tokensAmount = +tokens;
          gtmEvent("BUY_TOKENS_" + tokensAmount + "_PERSONAL", "category");
          gtmEventProduct({
            id: Date.now(),
            value: (tokensAmount - ~~(tokensAmount / 10)) / 5,
            currency: "USD",
            items: [
              {
                item_id: "tokens",
                item_name: "Tokens",
                quantity: tokensAmount,
              },
            ],
          });
        } else if (mode === "subscription") {
          const tokensAmount = +tokens;
          gtmEvent("SUBSCRIPTION_BUY_" + tokensAmount + "_PRO", "category");
          gtmEventProduct({
            id: Date.now(),
            value: 10,
            currency: "USD",
            items: [
              {
                item_id: "subscription",
                item_name: "PRO",
                quantity: tokensAmount,
              },
            ],
          });
        }
      }
    }
  }, [success, tokens, mode]);

  useEffect(() => {
    if (token !== null && user_id !== null) {
      dispatch(checkEmail({ key: token, user_id: user_id }));
    } else if (isLogin === "false") {
      dispatch(setActiveAuthModal(true));
      dispatch(setActiveModalName("Login stripe"));
    }
  }, [token, user_id, isLogin, dispatch]);

  useEffect(() => {
    if (token !== null && uid !== null) {
      dispatch(setActiveAuthModal(true));
      dispatch(setActiveModalName("Change password"));
      dispatch(setUID(uid));
      dispatch(setToken(token));
    }
  }, [token, uid, dispatch]);

  const isAuth = localStorage.getItem("key");
  useEffect(() => {
    if (isAuth) {
      dispatch(getUser());
    }
  }, [isAuth, dispatch]);

  useTopToPage();

  useOpenStrype();

  return (
    <>
      <Helmet>
        <title>AI Interior Design & Shopping</title>
        <meta property="og:title" content={"AI Interior Design & Shopping"} />
        <meta name="twitter:title" content={"AI Interior Design & Shopping"} />
        <meta
          name="description"
          content={
            "Effortlessly redesign and stage any space with AI-powered tools and integrated shopping."
          }
        />
        <meta
          property="og:description"
          content={
            "Effortlessly redesign and stage any space with AI-powered tools and integrated shopping."
          }
        />
        <meta
          name="twitter:description"
          content={
            "Effortlessly redesign and stage any space with AI-powered tools and integrated shopping."
          }
        />
      </Helmet>
      <MainLanding />
    </>
  );
};

export default LandingPage;
