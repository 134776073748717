import React, { useEffect, useRef } from 'react';
import './authorization.css';
import { motion } from 'framer-motion';
import CloseModalIcon from '../../icon/CloseModalIcon';
import {
  checkPasswordInput,
  useCloseModalWindow,
  useLoginForm,
  useResetPasswordForm,
  useValidationCreateUserForm,
} from '../../../lib/scrypts';
import CheckButtons from './CheckButtons';
import LoginForm from './LoginForm';
import CreateAccountForm from './CreateAccountForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import PriceBlockModal from './PriceBlockModal';
import { useMediaQuery } from 'react-responsive';
import ConfirmModal from './ConfirmModal';
import { useDispatch, useSelector } from 'react-redux';
import ChangePasswordForm from './ChangePasswordForm';
import {
  setActiveModalName,
  setActiveModalPrice,
} from '../../../toolkit/slices/authorization/authorizationModalSlice';
import { setProductInfo } from '../../../toolkit/slices/authorization/singInSlice';
import IsLoginMenu from './IsLoginMenu';
import { useNavigate } from 'react-router-dom';
import LoginStripeForm from './LoginStripeForm';
import cl from './AuthComponent.module.css';

const AuthorizationModal = () => {
  const isMobileMediaQuery = useMediaQuery({ query: '(max-width: 776.25px)' });
  const activeMoadlName = useSelector((state) => state.authorizationModal.activeModalName);
  const activeModalPrice = useSelector((state) => state.authorizationModal.activeModalPrice);
  const isActiveAuthModal = useSelector((state) => state.authorizationModal.isActiveAuthModal);

  useEffect(() => {
    if (isActiveAuthModal) {
      document.body.classList.add('modal')
    } else {
      document.body.classList.remove('modal');
    }
    return () => document.body.classList.remove('modal');
  }, [isActiveAuthModal]);

  const dispatch = useDispatch();
  const isAuth = localStorage.getItem('key');

  const variants = {
    hidden: {
      y: '30px',
      opacity: 0,
    },
    visible: {
      y: '0px',
      opacity: 1,
      transition: { type: 'Tween', delay: 0.1 },
    },
  };

  let variantsOpenPrice = {};
  if (!isMobileMediaQuery) {
    variantsOpenPrice = {
      hiddenPrice: {
        x: '100%',
        transition: { type: 'Tween', delay: 0, duration: .6, ease: [0, 0, 0.2, 1] },
      },
      visiblePrice: {
        x: '-5%',
        transition: { type: 'Tween', delay: 0, duration: .6, ease: [0, 0, 0.2, 1] },
      },
    };
  } else {
    variantsOpenPrice = {
      hiddenPrice: {
        y: '-100%',
        transition: { type: 'Tween', delay: 0, duration: .6, ease: [0, 0, 0.2, 1] },
      },
      visiblePrice: {
        y: '5%',
        transition: { type: 'Tween', delay: 0, duration: .6, ease: [0, 0, 0.2, 1] },
      },
    };
  }

  const ref = useRef();
  const navigate = useNavigate();
  const closeModalWindow = useCloseModalWindow();

  const paymentToggle = (val) => {
    console.log('val :>> ', val);
    dispatch(setProductInfo(val));
    // dispatch(setPlan(val));
    // if (val === 'Pro') {
    //   dispatch(setPrice('19'));
    //   dispatch(setPriceOld('49'));
    //   dispatch(setPlanValue(0));
    // } else if (val === 'Personal') {
    //   dispatch(setPrice('190'));
    //   dispatch(setPriceOld('490'));
    //   dispatch(setPlanValue(4));
    // }
  };
  const authToggle = (val) => {
    dispatch(setActiveModalName(val));
    if (val === 'Login') {
      dispatch(setActiveModalPrice(false));
    } else if (val === 'Create account') {
      dispatch(setActiveModalPrice(true));
    }
  };

  const forgotPasswordToggle = (val) => {
    dispatch(setActiveModalName(val));
  };

  const checkTwoPassword = (pass1, pass2) => {
    let error = true;
    if (pass1.trim() === '') {
      error = true;
    } else if (!checkPasswordInput(pass1)) {
      error = false;
    } else if (pass1 !== pass2) {
      error = false;
    } else {
      error = true;
    }
    return error;
  };

  const {
    createUserName,
    newNameValue,
    createUserEmail,
    newEmailValue,
    createUserPassword1,
    newPassword1Value,
    createUserPassword2,
    newPassword2Value,
  } = useValidationCreateUserForm();

  const { userEmail, onChangeEmail, userPassword, onChangePassword } = useLoginForm();

  const { userEmailChangePassword, onChangeEmailChangePassword } = useResetPasswordForm();

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      className={["modalLogin", cl.modalMain].join(' ')}
      onClick={(e) => {closeModalWindow(e, ref, false, '', navigate, window.location.href)}}>
      <motion.div variants={variants} className="content" ref={ref}>
        {isAuth ? (
          <IsLoginMenu />
        ) : (
          <div className="loginGrid">
            <div className={["whiteSide", cl.modalLogin ,activeMoadlName === 'Create account' ? cl.activePrices : ''].join(' ')}>
              <button
                className="closeModal"
                onClick={(e) =>
                  closeModalWindow(e, ref, false, 'button', navigate, window.location.href)
                }>
                <CloseModalIcon />
              </button>
              {activeMoadlName === 'Login' || activeMoadlName === 'Create account' ? (
                <CheckButtons
                  styling={'whiteCheckBox'}
                  option_1={'Login'}
                  option_2={'Create account'}
                  handle={authToggle}
                  active={activeMoadlName}
                />
              ) : (
                ''
              )}
              {activeMoadlName === 'Login' ? (
                <LoginForm
                  emailOnChange={onChangeEmail}
                  passwordOnChange={onChangePassword}
                  userEmail={userEmail}
                  userPassword={userPassword}
                  forgotPasswordToggle={forgotPasswordToggle}
                />
              ) : activeMoadlName === 'Login stripe' ? (
                <LoginStripeForm
                  emailOnChange={onChangeEmail}
                  passwordOnChange={onChangePassword}
                  userEmail={userEmail}
                  userPassword={userPassword}
                  forgotPasswordToggle={forgotPasswordToggle}
                />
              ) : activeMoadlName === 'Create account' ? (
                <CreateAccountForm
                  nameOnChange={newNameValue}
                  emailOnChange={newEmailValue}
                  passwordOnChange={newPassword1Value}
                  passwordConfirmOnChange={newPassword2Value}
                  createUserName={createUserName}
                  createUserEmail={createUserEmail}
                  createUserPassword1={createUserPassword1}
                  createUserPassword2={createUserPassword2}
                  checkTwoPassword={checkTwoPassword}
                />
              ) : activeMoadlName === 'Verify email' ? (
                <ConfirmModal
                  title={'We have sent a link to [break] verify your email'}
                  lineBreak={'5'}
                  subTitle={'Check your mail and get back to creating beautiful interiors'}
                />
              ) : activeMoadlName === 'Update your account' ? (
                <ConfirmModal
                  title={'Your account [break] has been updated'}
                  lineBreak={'1'}
                  subTitle={
                    <div className="subTitle">
                      You can{' '}
                      <span onClick={() => dispatch(setActiveModalName('Login'))}>Log In</span> now
                      and continue enjoying the best inspiration AI interior design tool
                    </div>
                  }
                />
              ) : activeMoadlName === 'Confirm email' ? (
                <ConfirmModal
                  title={'Well done [break] your email has been verified'}
                  lineBreak={'1'}
                  subTitle={
                    <div className="subTitle">
                      You can{' '}
                      <span onClick={() => dispatch(setActiveModalName('Login'))}>Log In</span> now
                      and continue enjoying the best inspiration AI interior design tool
                    </div>
                  }
                />
              ) : activeMoadlName === 'Stripe paid' ? (
                <ConfirmModal
                  title={'Well done [break] you nead pay'}
                  lineBreak={'1'}
                  subTitle={
                    <div className="subTitle">
                      You can{' '}
                      <span onClick={() => dispatch(setActiveModalName('Login'))}>Log In</span> now
                      and continue enjoying the best inspiration AI interior design tool
                    </div>
                  }
                />
              ) : activeMoadlName === 'Change password' ? (
                <ChangePasswordForm />
              ) : activeMoadlName === 'Password has been changed' ? (
                <ConfirmModal
                  title={'It’s all good [break] your password has been changed'}
                  lineBreak={'2'}
                  subTitle={
                    <div className="subTitle">
                      You can{' '}
                      <span onClick={() => dispatch(setActiveModalName('Login'))}>Log In</span> now
                      and continue enjoying the best inspiration AI interior design tool
                    </div>
                  }
                />
              ) : activeMoadlName === 'Submit change password' ? (
                <ConfirmModal
                  title={'Check your email [break] for change password'}
                  lineBreak={'2'}
                  subTitle={'Check your mail and get back to creating beautiful interiors'}
                />
              ) : (
                activeMoadlName === 'Forgot password' && (
                  <ForgotPasswordForm
                    forgotPasswordToggle={forgotPasswordToggle}
                    userEmailChangePassword={userEmailChangePassword}
                    onChangeEmailChangePassword={onChangeEmailChangePassword}
                  />
                )
              )}
            </div>
            <div className={cl.priceWrapper}>
              <PriceBlockModal
                paymentToggle={paymentToggle}
                isSignUp={activeMoadlName === 'Create account' ? true : false}
                variantsOpenPrice={variantsOpenPrice}
                isVisiblePrice={activeModalPrice}
              />
            </div>
          </div>
        )}
      </motion.div>
    </motion.div>
  );
};

export default AuthorizationModal;
