import React from 'react';

const PasswordShowIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M2.25 2.25L15.75 15.75M7.875 8.00775C7.6223 8.29388 7.4882 8.6656 7.50002 9.04716C7.51184 9.42873 7.6687 9.79143 7.93864 10.0614C8.20857 10.3313 8.57127 10.4882 8.95284 10.5C9.3344 10.5118 9.70612 10.3777 9.99225 10.125"
        stroke="#B2B2B2"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5215 5.67075C4.26 6.555 3.20925 7.815 2.25 9C3.66675 11.2432 6.2115 13.5 9 13.5C10.1625 13.5 11.2823 13.1077 12.2963 12.4875M9 4.5C12.006 4.5 14.0257 6.8685 15.75 9C15.5029 9.39058 15.2329 9.76624 14.9415 10.125"
        stroke="#B2B2B2"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PasswordShowIcon;
