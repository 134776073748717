export const XMiniIcon = (props) => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 15 14"
    style={{ paddingTop: "2px" }}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.8134 0H14.1135L9.08844 5.74329L15 13.5586H10.3713L6.74593 8.81866L2.59769 13.5586H0.296204L5.67098 7.41552L0 0H4.74621L8.02322 4.3325L11.8134 0ZM11.0061 12.1819H12.2806L4.05368 1.30441H2.686L11.0061 12.1819Z"
      fill="black"
    />
  </svg>
);
