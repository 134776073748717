import cl from "./WidgetContact.module.css";
import TitlePage from "../../titlePage/TitlePage";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { gtmEvent } from "../../../lib/gtm";
import { useContactMutation } from "../../../toolkit/services/public/widget";

const WidgetContact = () => {
  const schema = z.object({
    email: z
      .string()
      .min(4, "This field has to be filled.")
      .email("This is not a valid Email"),
    name: z.string().min(2, "This field has to be filled."),
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const [sendData] = useContactMutation("");

  const onSubmit = async (data) => {
    // const quizData = getQuizData();
    try {
      console.log(data);
      sendData({ ...data, action_type: 0 });
      // dispatch(useContact(data.email));
      // if (isAccept) dispatch(setSubscribe(true));
    } catch (error) {
      console.log(error);
    } finally {
      gtmEvent("WIDGET_FORM", "category");
      reset();
    }

    // gtmEvent("Show_moodboard", "category");
    // isAuth
    //   ? dispatch(isLogin({ token: isAuth, body: quizData }))
    //   : dispatch(login({ val: { email: data.mail }, body: quizData }));
  };

  return (
    <section className={cl.section} id="contact">
      <div className={`container ${cl.cont}`}>
        <TitlePage
          title={
            "Elevate your listings with AI-powered designs. [break] Attract buyers and close deals faster!"
          }
          classTitle={cl.titleNew}
          lineBreak={6}
          left
          size={"normal"}
          animation={true}
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          id="email"
          className={cl.formBlock}
        >
          <div className={cl.topForm}>
            <div className={cl.inputBlock}>
              <label className={cl.labelInput}>
                {errors.email?.message || "Enter your Email"}
              </label>
              <input
                type="text"
                placeholder="example@mail.com"
                className={
                  errors.email?.message
                    ? [cl.emailInput, cl.errorInput].join(" ")
                    : cl.emailInput
                }
                {...register("email")}
              />
            </div>
            <div className={cl.inputBlock}>
              <label className={cl.labelInput}>
                {errors.name?.message || "Enter your Name"}
              </label>
              <input
                type="text"
                placeholder="John"
                className={
                  errors.mail?.message
                    ? [cl.emailInput, cl.errorInput].join(" ")
                    : cl.emailInput
                }
                {...register("name")}
              />
            </div>
          </div>

          <div className={cl.btnBlock}>
            <button type="submit" className={`button green ${cl.btn}`}>
              Enhance Your Listings Now
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default WidgetContact;
