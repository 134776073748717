import React, { useEffect } from "react";
import TitlePage from "../../components/titlePage/TitlePage";
import StyleMoodGrid from "../../components/styleMood/StyleMoodGrid";
import { useDispatch, useSelector } from "react-redux";
import { setPageType } from "../../toolkit/slices/checkTypePageSlice";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function StyleMoodPage({ pageType }) {
  const dispatch = useDispatch();
  const hashtagState = useSelector((state) => state.roomType);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setPageType(pageType));
  });

  useEffect(() => {
    const redirect = (e) => {
      navigate("/interior-ai/mode-options/");
    };

    if (
      !hashtagState.hastagArr.roomtype.name &&
      !hashtagState.hastagArr.roomdesign.name
    ) {
      redirect();
    }
  }, [
    navigate,
    hashtagState.hastagArr.roomtype.name,
    hashtagState.hastagArr.roomdesign.name,
  ]);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <TitlePage
        h1
        title={`Select your style mood`}
        lineBreak={1}
        size={"normal type"}
        animation={true}
        desc={`You've provided, your design preferences lean towards a clean, modern aesthetic with nods to midcentury and contemporary styles.`}
      />
      <StyleMoodGrid />
    </>
  );
}
