export const dataPins = {
  living_room: {
    fill_the_space: [
      {
        id: 1,
        active: false,
        visible: false,
        boundingBox: {
          xPercent: 77,
          yPercent: 62,
          wPercent: 10,
          hPercent: 10,
        },
        productInfo: {
          pos: 0,
          name: "Amanda Accent Chair",
          cost: "168$",
          image: "/img/amanda-chair.jpg",
        },
      },
      {
        id: 3,
        active: false,
        visible: false,
        boundingBox: {
          xPercent: 55,
          yPercent: 72,
          wPercent: 10,
          hPercent: 10,
        },
        productInfo: {
          pos: 0,
          name: "Side Table",
          cost: "104$",
          image: "/img/table-cost.jpg",
        },
      },
      {
        id: 7,
        active: false,
        visible: false,
        boundingBox: {
          xPercent: 18,
          yPercent: 54,
          wPercent: 30,
          hPercent: 20,
        },
        productInfo: {
          pos: 1,
          name: "Sinatra Dining Chair",
          cost: "307$",
          image: "/img/chair-sinatra.jpg",
        },
      },
      {
        id: 6,
        active: false,
        visible: false,
        boundingBox: {
          xPercent: 44,
          yPercent: 62,
          wPercent: 30,
          hPercent: 10,
        },
        productInfo: {
          pos: 0,
          name: "Meridiani RENÉ",
          cost: "596$",
          image: "/img/sofa.jpg",
        },
      },
      {
        id: 10,
        active: false,
        visible: false,
        boundingBox: {
          xPercent: 33.5,
          yPercent: 33.5,
          wPercent: 10,
          hPercent: 10,
        },
        productInfo: {
          pos: 2,
          name: "IKEA KNAPPA",
          cost: "50$",
          image: "/img/light.jpg",
        },
      },
    ],
    redesign: [
      {
        id: 1,
        active: false,
        visible: false,
        boundingBox: {
          xPercent: 77,
          yPercent: 62,
          wPercent: 10,
          hPercent: 10,
        },
        productInfo: {
          pos: 0,
          name: "Tou Kettal Chair",
          cost: "254$",
          image: "/img/kettal-chair.jpg",
        },
      },
      {
        id: 3,
        active: false,
        visible: false,
        boundingBox: {
          xPercent: 55,
          yPercent: 72,
          wPercent: 10,
          hPercent: 10,
        },
        productInfo: {
          pos: 0,
          name: "Pebble-Shaped Table",
          cost: "131$",
          image: "/img/table-shaped.jpg",
        },
      },
      {
        id: 7,
        active: false,
        visible: false,
        boundingBox: {
          xPercent: 23,
          yPercent: 70,
          wPercent: 10,
          hPercent: 10,
        },
        productInfo: {
          pos: 1,
          name: "Oke Coffee Table",
          cost: "78$",
          image: "/img/table-oka.jpg",
        },
      },
      {
        id: 6,
        active: false,
        visible: false,
        boundingBox: {
          xPercent: 40,
          yPercent: 73,
          wPercent: 10,
          hPercent: 10,
        },
        productInfo: {
          pos: 1,
          name: "Thgonwid Round Ottoman Pouf",
          cost: "56$",
          image: "/img/ottoman.jpg",
        },
      },
      {
        id: 10,
        active: false,
        visible: false,
        boundingBox: {
          xPercent: 30.5,
          yPercent: 58,
          wPercent: 10,
          hPercent: 10,
        },
        productInfo: {
          pos: 1,
          name: "Lorenzo Chair",
          cost: "131$",
          image: "/img/lorenzo-chair.jpg",
        },
      },
    ],
  },
};
