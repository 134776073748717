import React from "react";
import "./selectedHashtag.css";
import { useSelector } from "react-redux";
import RoomTypeIcon from "../icon/type/RoomTypeIcon";
import RoomDesignIcon from "../icon/type/RoomDesignIcon";
import StyleIcon from "../icon/type/StyleIcon";
import { motion } from "framer-motion";
import TagsMarkIcon from "../icon/type/TagsMarkIcon";

const SelectedHashtag = ({ pickText = "Pick one" }) => {
  const hastagArr = useSelector((state) => state.roomType.hastagArr);
  const getIcon = (key) => {
    switch (key) {
      case "roomtype":
        return <RoomTypeIcon />;
      case "roomdesignstyle":
        return <RoomDesignIcon />;
      case "colorpalette":
        return <StyleIcon />;
      case 'additionaltags':
        return <TagsMarkIcon/>
      default:
        break;
    }
  };
  return (
    <section className="container">
      <div className="selectedHashtag">
        <div className="hastags">
          {hastagArr.roomtype.name ? (
            <>
              <div className={'pickOne'}>{pickText}</div>
              <ul>
                {Object.entries(hastagArr).map(([key, type], index) => {
                  if (type.name && key !== "modeoptions") {
                    return (
                      <motion.li
                        key={index}
                        initial={{
                          y: "5px",
                          opacity: 0,
                        }}
                        animate={{
                          y: "0px",
                          opacity: 1,
                        }}
                      >
                        <i className="">{getIcon(key)}</i>
                        {type.name}
                      </motion.li>
                    );
                  } else {
                    return false;
                  }
                })}
              </ul>
            </>
          ) : (
            <p>{pickText}</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default SelectedHashtag;
