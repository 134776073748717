import React, { useEffect } from "react";
import { useTopToPage } from "../../lib/scrypts";
import TitlePage from "../../components/titlePage/TitlePage";
import SubscriptionBillingGrid from "../../components/account/SubscriptionBillingGrid";
import ChoiceButtonAccount from "../../components/button/ChoiceButtonAccount";
import { useDispatch } from "react-redux";
import { setPageType } from "../../toolkit/slices/checkTypePageSlice";
import { getUser } from "../../toolkit/slices/authorization/getUserSlice";
import MyCrumbs from "../../components/UI/MyCrumbs/MyCrumbs";
import { Helmet } from "react-helmet-async";

const BillingPersonal = ({ pageType }) => {
  useTopToPage();
  const userData = JSON.parse(localStorage.getItem("data"));

  // console.log('userData :>> ', userData);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageType(pageType));
    dispatch(getUser());
  }, [dispatch, pageType]);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="container">
        <MyCrumbs classCrumbs={"centerCrumbs"} />
        <TitlePage
          title={`Welcome to personal account [break] ${userData.first_name}`}
          lineBreak={3}
          h1
          size={"normal"}
          animation={true}
        />
        <SubscriptionBillingGrid
         
          is_paid={userData.is_paid}
        />
      </div>
      <ChoiceButtonAccount isActive={"billing"} />
    </>
  );
};

export default BillingPersonal;
