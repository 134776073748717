import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select, { components } from 'react-select';
import { setHashtags } from '../../toolkit/slices/roomTypeSlice';

const SelectComponent = ({ tabSlider, handleSelect }) => {
  const dispatch = useDispatch();
  const [selectedCountry, setSelectedCountry] = useState();

  useEffect(() => {
    if (tabSlider) {
      setSelectedCountry(tabSlider[0]);
    }
  }, [tabSlider]);

  const handleChange = (value) => {
    setSelectedCountry(value);
    handleSelect(value)
    console.log('value :>> ', value);
    dispatch(setHashtags(['roomtype', value['name'], value['name']]));
  };

  const Option = (props) => (
    <components.Option {...props} className="list_option_view">
      <img width={38} height={38} src={props.data.image} alt="logo" className="country-logo" />
      <p>{props.data.name}</p>
    </components.Option>
  );

  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none">
        <path
          d="M5.62532 8.80592C5.32185 8.50245 5.2538 8.15522 5.42119 7.76422C5.58858 7.37322 5.88822 7.1774 6.32011 7.17676L11.2555 7.17676C11.6868 7.17676 11.9864 7.37258 12.1544 7.76422C12.3225 8.15586 12.2544 8.50309 11.9503 8.80592L9.45865 11.2976C9.36282 11.3934 9.259 11.4653 9.14719 11.5132C9.03539 11.5611 8.9156 11.5851 8.78782 11.5851C8.66004 11.5851 8.54025 11.5611 8.42844 11.5132C8.31664 11.4653 8.21282 11.3934 8.11698 11.2976L5.62532 8.80592Z"
          fill="black"
        />
      </svg>
    </components.DropdownIndicator>
  );

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props} className="selected_option_view">
      <img
        width={38}
        height={38}
        src={selectedCountry.image}
        alt="s-logo"
        className="selected-logo"
      />
      <p>{selectedCountry.name}</p>
      {children}
    </components.SingleValue>
  );
  return (
    <>
      <Select
        value={selectedCountry}
        options={tabSlider}
        onChange={handleChange}
        isSearchable={false}
        styles={{
          singleValue: (base) => ({
            ...base,
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'GeneralSans',
            gap: 10,
          }),
          menu: (base) => ({
            ...base,
            background: '#fff',
            borderRadius: 22,
            borderColor: '#fff',
            overflow: 'hidden',
            zIndex: 9999,
          }),
          menuList: (base) => ({
            ...base,
            '&:hover': { borderColor: '#a7a7a7' },
            zIndex: 9999,
          }),
          option: (base, state, isSelected) => ({
            ...base,
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            fontFamily: 'GeneralSans',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: '#f5f5f5',
            },
          }),
          control: (base, state) => ({
            ...base,
            borderRadius: 80,
            boxShadow: 'none',
            borderColor: state.isFocused ? '#fff' : '#fff',
            '&:hover': { borderColor: '#fff' },
          }),
          valueContainer: (base) => ({
            ...base,
            padding: 4,
          }),
          indicatorSeparator: (base) => ({
            ...base,
            width: 0,
          }),
          indicatorsContainer: (base, state) => ({
            ...base,
            marginRight: 10,
            transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
          }),
        }}
        components={{
          Option,
          SingleValue,
          DropdownIndicator,
        }}
      />
    </>
  );
};

export default SelectComponent;
