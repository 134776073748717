import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  status: "",
  imageLink: "",
  imageId: "",
  modal: false,
  polling: true,
};

export const checkStatus = createAsyncThunk(
  "checkStatus/checkStatus",
  async (data, { dispatch, getState }) => {
    // const params = getState().longPolling; // Access the params from the initial state
    if (data.newImageId) {
      let userId = getState().createProject.userKey;
      if (data.confirmedEmail) {
        const createKey = await axios.post(
          `${process.env.REACT_APP_BACKEND_HOST}/api/v1/rest-auth/login/`,
          JSON.stringify({ email: data.confirmedEmail }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        userId = createKey.data.key;
      }

      // console.log(createKey.data.key);

      const resultStatus = await axios.get(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/integrations/${data.newImageId}/imagine/result/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userId}`,
          },
        }
      );

      dispatch(getStatus(resultStatus.data.status_representation));
      dispatch(getImageId(resultStatus.data.image_box));
    }
  }
);

export const checkStatusIsAuth = createAsyncThunk(
  "checkStatusIsAuth/checkStatusIsAuth",
  async (data, { dispatch, getState }) => {
    // const params = getState().longPolling; // Access the params from the initial state
    console.log("data :>> ", data);
    const resultStatus = await axios.get(
      `${process.env.REACT_APP_BACKEND_HOST}/api/v1/integrations/${data.newImageId}/imagine/result/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.token}`,
        },
      }
    );

    // console.log('checkStatusIsAuth object :>> ', resultStatus.data);

    dispatch(getStatus(resultStatus.data.status_representation));
    dispatch(getImageId(resultStatus.data.image_box));
  }
);

// Polling interval in milliseconds
const pollingInterval = 15000;

// Polling function
export const startPolling = (data) => async (dispatch) => {
  // console.log(data);
  while (true) {
    await dispatch(checkStatus(data));
    const promise = new Promise((resolve) =>
      setTimeout(resolve, pollingInterval)
    );
    await promise;
  }
};

// Polling function
export const startPollingIsAuth = (data) => async (dispatch) => {
  // console.log(data);
  while (true) {
    await dispatch(checkStatusIsAuth(data));
    const promise = new Promise((resolve) =>
      setTimeout(resolve, pollingInterval)
    );
    await promise;
  }
};

// Create a slice for long polling
const longPollingSlice = createSlice({
  name: "longPolling",
  initialState,
  reducers: {
    getStatus: (state, action) => {
      state.status = action.payload;
    },
    getImageLink: (state, action) => {
      state.imageLink = action.payload;
    },
    getImageId: (state, action) => {
      state.imageId = action.payload;
    },
    getModal: (state, action) => {
      state.modal = action.payload;
    },
    stopPolling: (state, action) => {
      // console.log(action.payload);
      state.polling = false;
    },
   
  },
  extraReducers: (builder) => {
    builder.addCase(checkStatus.pending, (state, action) => {
      // Handle the successful response
      // console.log(action.payload);
    });
    builder.addCase(checkStatus.fulfilled, (state, action) => {
      // Handle the successful response
      // console.log(action.payload);
    });
    builder.addCase(checkStatus.rejected, (state, action) => {
      // Handle the error
      state.error = action.error.message;
    });
  },
});

export const {
  getStatus,
  getImageLink,
  getImageId,
  getModal,
  stopPolling,
} = longPollingSlice.actions;

export default longPollingSlice.reducer;
