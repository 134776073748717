import React, { useEffect, useRef, useState } from "react";
import "./projectModal.css";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { closeModalOutside } from "../../lib/scrypts";
import CloseModalIcon from "../icon/CloseModalIcon";
import TitlePage from "../titlePage/TitlePage";
import TitlePageChanging from "../titlePage/TitlePageChanging";
import Button from "../button/Button";
import { useDispatch } from "react-redux";
import { getModal } from "../../toolkit/slices/getResultImageSlice";
import SocialList from "../socialList/SocialList";
import {
  setEmail,
  setModalActive,
} from "../../toolkit/slices/createProjectSlice";
import { setIsConfirm } from "../../toolkit/slices/confirmEmailSlice";

const CreateProjectModal = () => {
  const ref = useRef();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(getModal(false));
    dispatch(setModalActive(false));
    dispatch(setIsConfirm(false));
    dispatch(setEmail(""));
    localStorage.removeItem("make design");
  };

  const variants = {
    hidden: {
      y: "30px",
      opacity: 0,
    },
    visible: {
      y: "0px",
      opacity: 1,
      transition: { type: "Tween", delay: 0.1 },
    },
  };

  const loadingContainerVariants = {
    start: {
      transition: {
        staggerChildren: 0.2,
      },
    },
    end: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const loadingCircleVariants = {
    start: {
      y: "50%",
    },
    end: {
      y: "150%",
    },
  };

  const loadingCircleTransition = {
    duration: 0.5,
    // yoyo: Infinity,
    // ease: 'easeInOut',
    repeatType: "mirror",
    repeat: Infinity,
  };

  const [changingTitle, setChangingTitle] = useState(
    "AI will create visualisation within minutes"
  );
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const titleArray = [
      {
        title: "Just wait a little and you'll be pleasantly surprised",
        lineBreak: 5,
      },
      {
        title:
          "Get ready to be amazed! Our AI is crafting your dream interior design",
        lineBreak: 2,
      },
      {
        title:
          "While the AI works its magic, why not grab a cup of coffee and relax?",
        lineBreak: 1,
      },
      {
        title: "Patience pays off! Your AI interior is just a few moments away",
        lineBreak: 0,
      },
      {
        title:
          "Sit tight while the AI unleashes its creativity for your perfect space",
        lineBreak: 0,
      },
      {
        title: "Watch as our AI brings your imagination to life",
        lineBreak: 0,
      },
      {
        title:
          "Creating the extraordinary takes time-your design is almost ready",
        lineBreak: 0,
      },
      {
        title: "AI will create visualisation within minutes",
        lineBreak: 0,
      },
    ];
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % titleArray.length);
      setChangingTitle(" ");
    }, 10000);
    const currentObject = titleArray[currentIndex];
    setChangingTitle(currentObject.title);
    return () => clearInterval(interval);
  }, [currentIndex]);

  useEffect(() => {
    document.body.classList.add("modal");

    return () => document.body.classList.remove("modal");
  }, []);

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      className="projectModal create center ddf"
      onClick={(e) => closeModalOutside(e, ref, navigate, "/result")}
    >
      <motion.div variants={variants} className="content create adej" ref={ref}>
        <Link to={"/"} className="closeModal" onClick={() => closeModal()}>
          <CloseModalIcon />
        </Link>
        <div className="content-modal">
          <div className="titleBlock">
            <TitlePageChanging
              title={changingTitle}
              // lineBreak={changingLineBreak}
              size={"medium"}
              animation={true}
            />
            <div className="descriptor">
              You can stay here and wait or check your e-mail later
            </div>
          </div>
          <motion.div
            className="loadingContainer"
            variants={loadingContainerVariants}
            initial="start"
            animate="end"
          >
            <motion.span
              className="loadingCircle"
              variants={loadingCircleVariants}
              transition={loadingCircleTransition}
            />
            <motion.span
              className="loadingCircle"
              variants={loadingCircleVariants}
              transition={loadingCircleTransition}
            />
            <motion.span
              className="loadingCircle"
              variants={loadingCircleVariants}
              transition={loadingCircleTransition}
            />
          </motion.div>
          <div className="socialBlock">
            <TitlePage
              size={"mini"}
              title={"Join our social media"}
              lineBreak={1}
              br={false}
              animation={false}
            />
            <SocialList viewStyle={"modal"} />
          </div>
        </div>

        <div className="bottomBlock">
          <div>
            <TitlePage
              size={"medium"}
              title={
                "Receive the full design project [break] you've always dreamed of"
              }
              lineBreak={5}
              br={true}
              animation={false}
            />
            <div className="subTitle">
              Go to a short questionnaire and then
              <br /> add real photos of your room
            </div>
          </div>

          <div className="buttonBlock">
            <Button
              link={"https://paintitgroup.com/"}
              color={"green"}
              text={"Try now"}
              target={"_blank"}
            />
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default CreateProjectModal;
