import React, { useEffect, useState } from "react";
import TitlePage from "../titlePage/TitlePage";
import PriceBlockComponent from "./PriceBlockComponent";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveAuthModal,
  setActiveModalName,
  setActiveModalPrice,
} from "../../toolkit/slices/authorization/authorizationModalSlice";
import { setProductInfo } from "../../toolkit/slices/authorization/singInSlice";
import {
  gtmEvent,
  // gtmEventProduct
} from "../../lib/gtm";
import {
  getCustomPortal,
  postPaymentProduct,
  postPaymentSubscription,
} from "../../toolkit/slices/authorization/stripeSlice";
import {
  getSubscriptions,
  setSubscriptionDateEnd,
} from "../../toolkit/slices/authorization/subscriptionPlanSlice";
import { changeDateFormat } from "../../lib/scrypts";
import AttemptsOverModal from "../modal/AttemptsOverModal";
import { smoothScrollToPrice } from "../../helpers/smoothScrollTo";
// import { useMediaQuery } from 'react-responsive';

const BenefitsContainer = ({ widget = false, type = "" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const userInfo = useSelector((state) => state.getUser.userData);
  const keyStorage = localStorage.getItem("key");
  const valueMoney = useSelector((state) => state.rangeVisionSlice.valueMoney);
  const dataStorage = JSON.parse(localStorage.getItem("data"));
  const errorMessage = useSelector((state) => state.stripe.errorMessage);

  const subscriptionAll = useSelector(
    (state) => state.subscriptionPlan.subscriptionAll
  );
  // console.log(subscriptionAll)
  const customerPortalLink = useSelector(
    (state) => state.subscriptionPlan.customerPortalLink
  );

  const valueVisions = useSelector(
    (state) => state.rangeVisionSlice.valueVisions
  );
  const [isErrorModal, setIsErrorModal] = useState(false);
  const isAuth = localStorage.getItem("key");

  useEffect(() => {
    if (isAuth) {
      if (subscriptionAll.length === 0) {
        dispatch(getSubscriptions());
      }
      if (!customerPortalLink) {
        subscriptionAll.map((subscription, index) => {
          if (subscription.start && subscription.end) {
            // console.log(`subscription :>> ${index}`, subscription.id);
            dispatch(getCustomPortal(subscription.id));
            dispatch(
              setSubscriptionDateEnd(changeDateFormat(subscription.end))
            );
          }
          return "";
        });
      }
    }
  }, [subscriptionAll, customerPortalLink, dispatch, isAuth]);

  const freeAction = () => {
    if ("attempts_left" in userInfo) {
      if (+userInfo.attempts_left <= 0 || +dataStorage.attempts_left <= 0) {
        setIsAttempts(true);
        return;
      }
    }

    navigate("/interior-ai/mode-options/");
    gtmEvent(
      type === "staging" ? "Free_plan_staging" : "Free_plan",
      "category"
    );
  };

  const firstWidgetAction = () => {
    smoothScrollToPrice("contact");
    gtmEvent("Pay-As-You-Stage", "category");
  };

  const personalAction = () => {
    if ((keyStorage || Object.keys(userInfo).length) && isAuth) {
      dispatch(
        postPaymentProduct({
          key: localStorage.getItem("key"),
          amount: Number(valueMoney) * 100,
          name: valueVisions,
          billing: true,
        })
      );
      if (errorMessage) {
        console.log(errorMessage);
        setIsErrorModal(true);
        return;
      }
      // gtmEventProduct("BUY_MORE", "category", valueVisions);
      return;
    }
    dispatch(setActiveModalName("Create account"));
    dispatch(setActiveAuthModal(true));
    dispatch(setActiveModalPrice(true));
    dispatch(setProductInfo("personal"));
    window.scrollTo(0, 0);
    gtmEvent(
      type === "staging" ? "Personal_plan_staging" : "Personal_plan",
      "category"
    );
  };

  const secondActionWidget = () => {
    smoothScrollToPrice("contact");
    gtmEvent("Full Access", "category");
  };

  const [activeManage, setActiveManage] = useState(false);

  const manageSubsription = () => {
    subscriptionAll.map((subscription, index) => {
      if (subscription.start && subscription.end) {
        // console.log(`subscription :>> ${index}`, subscription.id);
        dispatch(getCustomPortal(subscription.id));
        dispatch(setSubscriptionDateEnd(changeDateFormat(subscription.end)));
        setActiveManage(true);
      }
      return "";
    });
    if (customerPortalLink === "THROTTLED") setIsErrorModal(true);
  };

  useEffect(() => {
    if (activeManage) {
      if (customerPortalLink && customerPortalLink !== "THROTTLED") {
        window.open(
          customerPortalLink,
          "_blank",
          "toolbar=no,location=no,menubar=no"
        );
      }
      setActiveManage(false);
    }
  }, [customerPortalLink, activeManage]);

  const proAction = () => {
    // console.log('proAction :>> ');
    let activeSubs = subscriptionAll.find((e) => e.is_valid);
    if ((userInfo || Object.keys(keyStorage).length) && isAuth) {
      if (
        activeSubs &&
        activeSubs.subscription_type === 0 &&
        activeSubs.status
      ) {
        manageSubsription();
        return;
      }
      dispatch(
        postPaymentSubscription({
          key: localStorage.getItem("key"),
          subscription_type: 0,
          billing: true,
        })
      );
      gtmEvent(
        type === "staging" ? "UPSCALE_TO_PRO_STAGING" : "UPSCALE_TO_PRO",
        "category"
      );
      return;
    }
    dispatch(setActiveModalName("Create account"));
    dispatch(setActiveAuthModal(true));
    dispatch(setActiveModalPrice(true));
    dispatch(setProductInfo("pro"));
    window.scrollTo(0, 0);
    gtmEvent(type === "staging" ? "Pro_plan_staging" : "Pro_plan", "category");
  };

  const thirdActionWidget = () => {
    smoothScrollToPrice("contact");
    gtmEvent("Branded Design Suite", "category");
  };

  const [isAttempts, setIsAttempts] = useState(false);

  return (
    <>
      {isErrorModal ? (
        <AttemptsOverModal
          title={`Sorry, you made a lot of requests, try again later!`}
          isButton={false}
          isActive={isErrorModal}
          setIsActive={setIsErrorModal}
          lineBreak={6}
        />
      ) : null}
      {isAttempts ? (
        <AttemptsOverModal
          title={`Sorry, your attempts are over, you need to ${"Top Up"}`}
          topUp
          isActive={isAttempts}
          setIsActive={setIsAttempts}
          lineBreak={4}
        />
      ) : null}
      <div className="container">
        <div className="benefits" id="pricing">
          <div className="title ">
            <TitlePage
              title={
                "Choose Your Plan: Tailored Pricing [break] for Every Need"
              }
              lineBreak={2}
              size={"normal"}
              animation={true}
            />
            <h3>
              Find the perfect plan for your design needs, from free basic
              access to advanced professional features
            </h3>
          </div>
        </div>
        {widget ? (
          <div className="benefits_price">
            <PriceBlockComponent
              color={"grey"}
              setIsHovered={setIsHovered}
              isHovered={isHovered}
              title={"Pay-As-You-Stage"}
              description={"In this tariff plan you have 3 free generations."}
              price={"1"}
              visions={"per photo"}
              title_btn={"Schedule demo"}
              handler={firstWidgetAction}
            />
            <PriceBlockComponent
              color={"green"}
              setIsHovered={setIsHovered}
              title={"Full Access Subscription"}
              description={"Add a set of 10 options for only +5 usd"}
              price={"500"}
              visions={"per month"}
              title_btn={"Schedule demo"}
              handler={secondActionWidget}
            />
            <PriceBlockComponent
              color={"black"}
              setIsHovered={setIsHovered}
              title={"Branded Design Suite"}
              description={
                "Paintit Pro is ideal for redecorating your home, interior designers and real estate agents"
              }
              price={"1 000"}
              visions={"per month"}
              title_btn={"Schedule demo"}
              handler={thirdActionWidget}
            />
          </div>
        ) : (
          <div className="benefits_price">
            <PriceBlockComponent
              color={"grey"}
              setIsHovered={setIsHovered}
              isHovered={isHovered}
              title={"Free"}
              description={"In this tariff plan you have 3 free generations"}
              price={"0"}
              visions={"3"}
              title_btn={"start"}
              handler={freeAction}
            />
            <PriceBlockComponent
              color={"green"}
              setIsHovered={setIsHovered}
              title={"Personal"}
              description={"Add a set of 10 options for only +5 usd"}
              price={"15"}
              visions={"30"}
              title_btn={
                (keyStorage || Object.keys(userInfo).length) && isAuth
                  ? "TOP UP"
                  : "BUY"
              }
              handler={personalAction}
            />
            <PriceBlockComponent
              color={"black"}
              setIsHovered={setIsHovered}
              title={"Pro"}
              description={
                "Paintit Pro is ideal for redecorating your home, interior designers and real estate agents"
              }
              price={"10"}
              active={
                subscriptionAll.find((e) => e.is_valid)?.subscription_type ===
                  0 && isAuth
              }
              oldPrice={"49"}
              visions={"100"}
              title_btn={
                subscriptionAll.find((e) => e.is_valid)?.subscription_type ===
                  0 &&
                isAuth &&
                userInfo.is_paid &&
                subscriptionAll.find((e) => e.is_valid)?.status
                  ? "manage subscribe"
                  : "subscribe"
              }
              handler={proAction}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default BenefitsContainer;
