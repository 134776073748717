import React from 'react';

const ShareIcon = () => {
  return (
    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.2806 9.03061L13.7806 16.5306C13.6757 16.6356 13.542 16.7071 13.3964 16.7361C13.2509 16.7651 13.1 16.7503 12.9629 16.6934C12.8257 16.6366 12.7086 16.5404 12.6262 16.417C12.5437 16.2935 12.4998 16.1484 12.4999 16V12.2715C7.14682 12.5753 3.47651 16.0469 2.44619 17.1465C2.28441 17.3193 2.07225 17.4367 1.83991 17.4819C1.60757 17.5271 1.36689 17.4979 1.15212 17.3984C0.937349 17.2989 0.75943 17.1342 0.643684 16.9277C0.527938 16.7213 0.480263 16.4835 0.507443 16.2484C0.855256 13.224 2.51182 10.315 5.17244 8.05748C7.38213 6.18248 10.0681 4.99279 12.4999 4.78467V0.99998C12.4998 0.851559 12.5437 0.706439 12.6262 0.582994C12.7086 0.459548 12.8257 0.363328 12.9629 0.306513C13.1 0.249699 13.2509 0.234846 13.3964 0.263833C13.542 0.292821 13.6757 0.364347 13.7806 0.469355L21.2806 7.96936C21.3503 8.03901 21.4056 8.12173 21.4434 8.21278C21.4811 8.30382 21.5005 8.40142 21.5005 8.49998C21.5005 8.59854 21.4811 8.69614 21.4434 8.78719C21.4056 8.87823 21.3503 8.96095 21.2806 9.03061Z"
        fill="black"
      />
    </svg>
  );
};

export default ShareIcon;
