import React, { useEffect } from "react";
import "./roomType.css";
import { AnimatePresence } from "framer-motion";
import ChoiceButton from "../../button/ChoiceButton";
import RoomTypeCard from "./RoomTypeCard";
import { useDispatch, useSelector } from "react-redux";
import { setHashtags } from "../../../toolkit/slices/roomTypeSlice";
import { checkPathname } from "../../../lib/scrypts";
import {
  getFilters,
  getFiltersAll,
} from "../../../toolkit/slices/getFiltersSlice";
import { gtmEvent } from "../../../lib/gtm";

const RoomTypeGrid = () => {
  const hastagArr = useSelector((state) => state.roomType.hastagArr);
  // const landind_selected_type = JSON.parse(localStorage.getItem('landind_selected_type'));
  const uploadType = useSelector((state) => state.createProject.uploadType);

  const isPaidUser =
    localStorage.getItem("data") &&
    JSON.parse(localStorage.getItem("data")).is_paid;

  const dispatch = useDispatch();

  let pageLink = window.location.pathname.slice(1);

  const selectCard = (name, hashtags, id) => {
    dispatch(setHashtags([checkPathname(pageLink), name, hashtags, id]));
  };
  let delayVal = 0;
  let activeCardName = hastagArr[checkPathname(pageLink)]["name"];

  useEffect(() => {
    dispatch(getFilters(0));
    dispatch(getFiltersAll());
  }, [dispatch]);

  const cards = useSelector((state) => state.filters.roomType);
  const fillSpaceArr = [
    "living room",
    "bedroom",
    "kitchen",
    "dinner room",
    "bathroom",
    "office",
  ];

  const handleContinue = () => {
    let gtmContinue = {
      0: "Step2_Inspiration_Room_Type",
      1: "Step2_Space_Room_Type",
      2: "Step2_Redesign_Room_Type",
    };
    gtmEvent(gtmContinue[uploadType], "category");
  };

  // const handleSkip = () => {
  //   let gtmContinue = {
  //     0: "Step2_Inspiration_Skip",
  //     1: "Step2_Space_Skip",
  //     2: "Step2_Redesign_Skip",
  //   };
  //   gtmEvent(gtmContinue[uploadType], "category");
  // };

  return (
    <section className="container">
      <div className="relative">
        <AnimatePresence>
          <div className="cards">
            {cards.map((item, index) => {
              delayVal += 0.1;
              return (
                <RoomTypeCard
                  onClick={() => selectCard(item.name, item.prompt, item.id)}
                  key={index}
                  active={activeCardName}
                  props={item}
                  delayVal={delayVal}
                  paid_only={item.paid_only}
                  disabled={
                    uploadType === 1 &&
                    !fillSpaceArr.includes(item.name.toLowerCase())
                  }
                  isPaidUser={isPaidUser}
                />
              );
            })}
          </div>
        </AnimatePresence>
        <ChoiceButton
          active={activeCardName}
          backLink={"/interior-ai/mode-options/"}
          continueLink={"/interior-ai/room-design-style/"}
          isActive={activeCardName}
          skip={false}
          handleContinue={handleContinue}
        />
      </div>
    </section>
  );
};

export default RoomTypeGrid;
