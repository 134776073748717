import React from 'react';

const PinEmail = ({ setNameForm }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData);
    // Add further processing or API call here
  };
  const hideEmail = (val) => {
    const inputString = val;
    const regex = /^(.{2}).*?(@.{2}).*$/;
    const replacement = '$1***$2****';
    const maskedString = inputString.replace(regex, replacement);

    return maskedString;
  };
  return (
    <>
      <div className="title pin">
        To change your email address, we must send you a security code. You will need to enter it in
        the next step. Choose how you want to receive the code:
      </div>
      <form className="account" onSubmit={handleSubmit}>
        <div className="inputBox pin">
          <input
            type="email"
            placeholder="Username"
            value={hideEmail('work.ar4666@gmail.com')}
            disabled
          />
        </div>
        <div className="buttonBox">
          <button className="transparent" onClick={() => setNameForm('')}>
            Cancel
          </button>
          <button className="" onClick={() => setNameForm('')}>
            Save change
          </button>
        </div>
      </form>
    </>
  );
};

export default PinEmail;
