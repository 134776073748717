import MyCrumbs from "../../UI/MyCrumbs/MyCrumbs";
import { Helmet } from "react-helmet-async";
import cl from "./BlogsComponent.module.css";
import { useGetBlogsQuery } from "../../../toolkit/services/public/blogsApi";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { BlogItem, BlogSkeletonItem } from "./BlogsItem";
import { blogsMicrodata } from "./BlogsMicrodata";
import MyPagination from "../../UI/MyPagination/MyPagination";
import { useEffect } from "react";

const BlogsComponent = () => {
  const [searchParams, setSeatchParams] = useSearchParams();
  let pageCount = searchParams.get("page");

  const { data, isLoading, isError, refetch } = useGetBlogsQuery({
    page: pageCount ? pageCount : 1,
    size: 6,
  });

  useEffect(() => {
    if (+pageCount === 1) setSeatchParams();
  }, [pageCount, refetch, setSeatchParams]);

  // eslint-disable-next-line
  const [filter, setFilter] = useState(null);
  // console.log(filter);

  if (!isLoading && data && !isError) {
    return (
      <>
        <Helmet prioritizeSeoTags>
          {pageCount ? (
            <link
              rel="canonical"
              href={"https://paintit.ai/blogs/" + pageCount + "/"}
            />
          ) : (
            <link rel="canonical" href="https://paintit.ai/blogs/" />
          )}
          <script type="application/ld+json">{blogsMicrodata(data)}</script>
        </Helmet>
        <div className="container">
          <MyCrumbs />
          <h1 className={cl.title}>Blogs</h1>
          <ul className={cl.blogsList}>
            {data.results.map((blog) => (
              <BlogItem blog={blog} setFilter={setFilter} key={blog.id} />
            ))}
          </ul>
          <MyPagination
            totalPage={data["num_pages"]}
            classPag={cl.pagination}
          />
        </div>
      </>
    );
  }
  return (
    <>
      <Helmet>
        <title>Blogs</title>
        <link rel="canonical" href="https://paintit.ai/blogs/" />
        <meta name="description" content="Interior AI" />
        {/* {data ? <script type="application/ld+json">{mictodata}</script> : ""} */}
      </Helmet>
      <div className="container">
        <MyCrumbs />
        <h1 className={cl.title}>Blogs</h1>
        <ul className={cl.blogsList}>
          {[1, 2, 3, 4, 5, 6].map((e) => (
            <BlogSkeletonItem key={e} />
          ))}
        </ul>
      </div>
    </>
  );
};

export default BlogsComponent;
