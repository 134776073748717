import {
  FC,
  // useRef,
  // useState
} from "react";
import cl from "./WidgetHero.module.css";
import gsap from "gsap";
import TitlePage from "../../titlePage/TitlePage";
import { ScrollToPlugin } from "gsap/all";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { gtmEvent } from "../../../lib/gtm";
import YouTube, { YouTubeProps } from "react-youtube";
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

interface WidgetHeroProps {}

const WidgetHero: FC<WidgetHeroProps> = () => {
  // const videoRef = useRef<HTMLVideoElement>(null);
  // const [playing, setPlaying] = useState(false);

  // const videoHandler = (control: "play" | "pause") => {
  //   if (videoRef.current) {
  //     if (control === "play") {
  //       videoRef.current.play();
  //       setPlaying(true);
  //     } else if (control === "pause") {
  //       videoRef.current.pause();
  //       setPlaying(false);
  //     }
  //   }
  // };

  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const opts: YouTubeProps["opts"] = {
    height: "100%",
    width: "100%",
  };

  return (
    <section className={cl.section}>
      <div className={`container ${cl.cont}`}>
        <TitlePage
          title={
            "Empower Your Listings [break] AI-Powered Design Widget for Your Site"
          }
          classTitle={cl.titleNew}
          lineBreak={3}
          h1
          size={"normal"}
          animation={true}
        />
        <p className={` ${cl.descr}`}>
          Effortlessly transform your online property listings with our
          easy-to-integrate AI widget that offers customized design styles in an
          instan
        </p>
        <div className={cl.btnBlock}>
          <a
            className={`button green ${cl.btn}`}
            href="https://calendly.com/paintit-ai/paintit-ai-widget-demo"
            onClick={() => {
              gtmEvent("SCHEDULE_YOUR_DEMO", "category");
            }}
            target="_blank"
            rel="noreferrer"
          >
            Schedule Your Demo
          </a>
        </div>
        <div className={cl.videoBlock}>
          <YouTube
            videoId="tisCbg2Droc"
            className={cl.youtube}
            opts={opts}
            onReady={onPlayerReady}
          />
        </div>
      </div>
    </section>
  );
};

export default WidgetHero;
