import React, { useEffect, useRef, useState } from "react";
import "./../../projectModal.css";
import { Link, useNavigate } from "react-router-dom";
import CloseModalIcon from "../../../icon/CloseModalIcon";
import SocialList from "../../../socialList/SocialList";
import { useDispatch, useSelector } from "react-redux";
import { closeModalOutside } from "../../../../lib/scrypts";
import RoomDesignIcon from "../../../icon/type/RoomDesignIcon";
import RoomTypeIcon from "../../../icon/type/RoomTypeIcon";
import StyleIcon from "../../../icon/type/StyleIcon";
import Banner from "../../../banner/Banner";
import DropDown from "../../../button/DropDown";
import DownloadIcon from "../../../icon/project/DownloadIcon";
import { motion } from "framer-motion";
import { downloadImage } from "../../../../lib/dowload";
// import { Helmet } from 'react-helmet';
import LoadMore from "../../../button/LoadMore";
import { gtmEvent } from "../../../../lib/gtm";
import TitlePage from "../../../titlePage/TitlePage";
import {
  getProject,
  // getBoundingBoxes,
} from "../../../../toolkit/slices/account/portfolioUserSlice";
// New imports
import cl from "./ProjectModal.module.css";
import { Helmet } from "react-helmet-async";
import {
  deleteProducts,
  setBoundingBoxToServer,
} from "../../../../toolkit/slices/productsImageSlice";
import { v4 as uuidv4 } from "uuid";
import { MyRangeImages } from "../../../UI/MyRangeImages/MyRangeImages";
// import Hotjar from "@hotjar/browser";
import { hotjar } from "react-hotjar";

const icons = [<RoomTypeIcon />, <RoomDesignIcon />, <StyleIcon />];

const ProjectModal = ({ idModal, created = false }) => {
  const dispatch = useDispatch();
  const boundings = useSelector((state) => state.portfolioUser.boundings);
  const imageData = useSelector((state) => state.productsFromImage);
  const modalProject = useSelector((state) => state.portfolioUser.modalProject);
  const currentURL = window.location.href;
  const checkPath = window.location.pathname.split("/").includes("account");
  let closeLink = currentURL.split("?");
  const canvasRef = useRef();
  const imageRef = useRef();
  const ref = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getProject(idModal));

    const timeoutId = setTimeout(() => {
      setOnLoadImg(false);
    }, 2000);
    localStorage.removeItem("make design");
    return () => {
      setOnLoadImg(true);
      clearTimeout(timeoutId);
    };
  }, [dispatch, idModal, checkPath]);

  // console.log(boundings);

  useEffect(() => {
    if (idModal) {
      document.body.classList.add("modal");
    } else {
      document.body.classList.remove("modal");
    }
    return () => document.body.classList.remove("modal");
  }, [idModal]);

  const variants = {
    hidden: {
      y: "30px",
      opacity: 0,
    },
    visible: {
      y: "0px",
      opacity: 1,
      transition: { type: "Tween", delay: 0.1 },
    },
  };

  useEffect(() => {
    if (created) {
      setTimeout(() => {
        console.log("Hotjar init");
        const siteId = 3899170;
        const hotjarVersion = 6;

        hotjar.initialize(siteId, hotjarVersion, {
          debug: true,
          nonce: "rAnDoM",
        });
      }, 3000);
    }
  }, [created]);

  const [isOpen, setIsOpen] = useState(false);
  const [onLoadImg, setOnLoadImg] = useState(true);

  const openText = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
      gtmEvent("Read_more_tips", "category");
    }
  };

  const projectModalRef = useRef(null);

  // Buy Block Functionality
  const [areaState, setAreaState] = useState(null);

  useEffect(() => {
    const percentBox = (infoObj, sizeObj) => {
      let xPercent = Math.round((infoObj.x / sizeObj.width) * 1e4) * 0.01,
        yPercent = Math.round((infoObj.y / sizeObj.height) * 1e4) * 0.01,
        wPercent = Math.round((infoObj.w / sizeObj.width) * 1e4) * 0.01,
        hPercent = Math.round((infoObj.h / sizeObj.height) * 1e4) * 0.01;

      return {
        id: uuidv4(),
        xPercent,
        yPercent,
        wPercent,
        hPercent,
      };
    };

    const getPercentPositionArr = (products, sizeObj) => {
      return products.map((product) => {
        return {
          ...percentBox(product, sizeObj),
        };
      });
    };

    // if (areaState) {
    //   const persentArr = getPercentPositionArr(areaState.products, {
    //     width: areaState.imageMetadata.width,
    //     height: areaState.imageMetadata.height,
    //   });

    //   setAreaState({ ...areaState, products: persentArr });
    // }

    if (localStorage.getItem("make design")) {
      localStorage.removeItem("make design");
    }

    if (boundings) {
      if ("bounding_boxes" in boundings) {
        const boundingBoxes = boundings.bounding_boxes;
        const widthInitial = boundings.width;
        const heightInitial = boundings.height;
        const boundingRes = getPercentPositionArr(boundingBoxes, {
          width: widthInitial,
          height: heightInitial,
        });
        if (boundingRes.length) {
          setAreaState(boundingRes);
        }
      }
    }
  }, [boundings]);

  const handleClickArea = (id, nested, imageNestedRef) => {
    try {
      const canvas = canvasRef.current;
      const activeArea = areaState.find((product) => product.id === id);
      const ctx = canvas.getContext("2d");
      const image =
        nested && imageNestedRef ? imageNestedRef : imageRef.current;

      const imageWidth = image.naturalWidth;
      const imageHeight = image.naturalHeight;

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      let widthOfBoundingBox = activeArea.wPercent * imageWidth * 0.01;
      let heightOfBoundingBox = activeArea.hPercent * imageHeight * 0.01;
      let xOfBoundingBox = activeArea.xPercent * imageWidth * 0.01;
      let yOfBoundingBox = activeArea.yPercent * imageHeight * 0.01;

      canvas.width = widthOfBoundingBox;
      canvas.height = heightOfBoundingBox;
      ctx.drawImage(
        image,
        -xOfBoundingBox,
        -yOfBoundingBox,
        imageWidth,
        imageHeight
      );
      const imageRes = canvas.toDataURL("image/png");
      
      if (modalProject.prompt_type === 2) {
        gtmEvent("Redesign_lens", "category");
      } else if (modalProject.prompt_type === 0) {
        gtmEvent("Inspiration_lens", "category");
      } else if (modalProject.prompt_type === 1) {
        gtmEvent("Space_lens", "category");
      } else {
        gtmEvent("click_lens", "category");
      }

      dispatch(setBoundingBoxToServer(imageRes));
    } catch (error) {
      alert("Something went wrong, please try again later.");
    }
  };

  const linkRef = useRef(null);

  useEffect(() => {
    if (imageData.imageData) {
      if (imageData.imageData.image) {
        //        const link = linkRef.current;
        window.location.href = `https://lens.google.com/uploadbyurl?url=${imageData.imageData.image}`;
        //       link.click();
        //      console.log(link.href, link.click);
        dispatch(deleteProducts());
      }
    }
  }, [imageData, dispatch]);

  return (
    <>
      <a
        ref={linkRef}
        rel="noreferrer"
        href="https://lens.google"
        target="_blank"
        style={{
          display: "none",
          position: "absolute",
          opacity: 0,
          pointerEvents: "none",
        }}
      >
        lens
      </a>
      <canvas ref={canvasRef} className={cl.canvasHidden}></canvas>
      <Helmet>
        <link rel="canonical" href="https://paintit.ai/interior-ai/" />
        <meta name="robots" content="noindex, nofollow" />
        {Object.keys(modalProject).length ? (
          modalProject.image.image ? (
            <>
              <meta property="og:image" content={modalProject.image.image} />
              <meta name="twitter:image" content={modalProject.image.image} />
            </>
          ) : null
        ) : null}
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:url" content={window.location.href} />
      </Helmet>
      <motion.div
        initial="hidden"
        whileInView="visible"
        className="projectModal"
        ref={projectModalRef}
        onClick={(e) => {
          dispatch(deleteProducts());
          closeModalOutside(e, ref, navigate, closeLink);
        }}
      >
        <motion.div
          variants={variants}
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="content"
          ref={ref}
        >
          <Link
            to={closeLink}
            className="closeModal"
            onClick={(e) => localStorage.removeItem("make design")}
          >
            <CloseModalIcon />
          </Link>

          <div className="image ">
            <div className={onLoadImg ? "skeleton" : ""}></div>
            {Object.keys(modalProject).length && (
              <>
                {/* <img src={modalProject.image.image} alt="modal img" style={{ display: 'none' }} /> */}
                <div className="overflow" key={modalProject.image.id}>
                  {modalProject.origin_images.length !== 0 ? (
                    // <ImageBeforeAfter
                    //   beforeImage={modalProject.origin_images[0].image}
                    //   afterImage={modalProject.image.image}
                    //   modalProject={modalProject}
                    //   setOnLoadImg={setOnLoadImg}
                    //   key={1}
                    // />
                    <MyRangeImages
                      imageAfter={modalProject.origin_images[0].image}
                      imageBefore={modalProject.image.image}
                      pinsDataMin={true}
                      pinsData={areaState ? areaState : null}
                      handleClickArea={handleClickArea}
                      rangeClass={cl.range}
                    />
                  ) : (
                    <div className={cl.imageBlock}>
                      {areaState &&
                        areaState.map((product) => (
                          <div
                            className={cl.iProductBlock}
                            key={product.id}
                            style={{
                              // width: product.wPercent + "%",
                              // height: product.hPercent + "%",
                              left:
                                product.xPercent + product.wPercent / 2 + "%",
                              top:
                                product.yPercent + product.hPercent / 2 + "%",
                            }}
                          >
                            <div
                              className={cl.iProductCenter}
                              onClick={(e) => handleClickArea(product.id)}
                            >
                              <span className={cl.iProductDot}></span>
                            </div>
                          </div>
                        ))}
                      <img
                        key={2}
                        ref={imageRef}
                        crossOrigin="*"
                        src={modalProject.image.image}
                        alt={`AI generated interior design visualisation of the ${modalProject.tags[1]["name"]} in ${modalProject.tags[0]["name"]} style from paintit.ai. `}
                      />
                    </div>
                  )}

                  {/* <img
                  src={modalProject.image.image}
                  alt={`AI generated interior design visualisation of the ${modalProject.tags[2]['name']} ${modalProject.tags[1]['name']} in ${modalProject.tags[0]['name']} colors from paintit.ai. `}
                /> */}

                  <div className="buttonsBlock">
                    <DropDown
                      link={`/?id=${idModal}`}
                      img={modalProject.image.image}
                    />
                    <button
                      onClick={() =>
                        downloadImage(`${modalProject.image.image}`, idModal)
                      }
                    >
                      <DownloadIcon />
                    </button>
                    {/* {isAccount && (
                    <button
                      className="withText"
                      // onClick={() => downloadImage(`${modalProject.image.image}`, idModal)}>
                      onClick={() => dispatch(upscaleImageProject(modalProject.id))}>
                      Upscale
                    </button>
                  )} */}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={`imageInfo ${cl.listOfAdditionalInfo}`}>
            <div className="hashtags">
              <ul>
                {Object.keys(modalProject).length &&
                  modalProject.tags
                    .slice()
                    .sort((a, b) => a.tag_type - b.tag_type)
                    .map((tag, index) => {
                      return (
                        <li key={index}>
                          {icons[tag.tag_type]} <div>{tag.name}</div>
                        </li>
                      );
                    })}
              </ul>
            </div>
            <div className="socials">
              <div className="mobileTitle">
                <TitlePage
                  size={"mini"}
                  title={"Join our social media"}
                  lineBreak={1}
                  br={false}
                  animation={false}
                />
              </div>
              <SocialList viewStyle={"modal"} />
            </div>
          </div>
          {/* <ProjectModalProducts /> */}
          <div className="tipsAndHint">
            <div className="title left">Tips & Hints</div>
            <div className={isOpen ? "description active" : "description"}>
              <div className="overflow">
                <ul className={cl.listTips}>
                  <li>
                    <div className={cl.numberTip}>1</div>
                    <div className={cl.itemContentTips}>
                      <h3 className={cl.titleTips}>
                        Experiment with different styles and color schemes:
                      </h3>
                      Utilize AI-generated interior design images to explore
                      various design styles and color combinations that you
                      might not have considered before. These generated visuals
                      can help you envision how different elements come
                      together, allowing you to mix and match until you find a
                      combination that suits your taste and preferences.
                    </div>
                  </li>
                  <li>
                    <div className={cl.numberTip}>2</div>
                    <div className={cl.itemContentTips}>
                      <h3 className={cl.titleTips}>
                        Generate multiple iterations for fresh ideas:
                      </h3>
                      Don't hesitate to create multiple AI-generated interior
                      design images. Each time you generate a new image, you'll
                      get a fresh set of ideas and possibilities. This iterative
                      process can spark creativity and lead to unique design
                      concepts that you might not have imagined otherwise.
                    </div>
                  </li>

                  <li>
                    <div className={cl.numberTip}>3</div>
                    <div className={cl.itemContentTips}>
                      <h3 className={cl.titleTips}>
                        Save your favorite results:
                      </h3>
                      Whenever you come across an AI-generated interior design
                      image that resonates with you, make sure to save it for
                      future reference. Create a digital collection or mood
                      board with your favorite designs, and use them as
                      inspiration when planning your actual interior design
                      project.
                    </div>
                  </li>
                  <li>
                    <div className={cl.numberTip}>4</div>

                    <div className={cl.itemContentTips}>
                      <h3 className={cl.titleTips}>
                        Share the results with friends to gather opinions:
                      </h3>
                      Share your AI-generated interior design images with
                      friends, family, or online communities to get their
                      feedback and opinions. Different perspectives can provide
                      valuable insights and help you refine your design ideas.
                      You might even receive suggestions or ideas that you
                      hadn't considered, leading to further improvements.
                    </div>
                  </li>
                  <li>
                    <div className={cl.numberTip}>5</div>

                    <div className={cl.itemContentTips}>
                      <h3 className={cl.titleTips}>
                        Seek inspiration from details, even if the image doesn't
                        match your room:
                      </h3>
                      Sometimes, an AI-generated interior design image might not
                      fully match your room's layout or existing elements.
                      However, don't dismiss these images entirely. Instead,
                      focus on individual design details, such as furniture
                      pieces, decor items, or color combinations. Extracting
                      ideas from specific elements can inspire you to
                      incorporate them into your space in creative ways.
                    </div>
                  </li>
                </ul>
                <p>
                  Using AI-generated interior design images can be a fun and
                  innovative way to explore possibilities, gain inspiration, and
                  refine your vision for your dream space. Remember that these
                  images are just a starting point, and with your creativity and
                  personal touch, you can transform them into a unique and
                  personalized interior design that truly reflects your style.
                </p>
                <br />
              </div>
            </div>
            <LoadMore
              onClick={() => {
                openText();
              }}
              text={isOpen ? "Close" : "read more..."}
            />
          </div>

          <Banner color={"grey"} btnColor={"green"} />
        </motion.div>
      </motion.div>
    </>
  );
};

export default ProjectModal;
