export const Eraser = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <path
      d="M8.55566 4.12875C9.80816 2.87625 10.4344 2.25 11.2122 2.25C11.9907 2.25 12.6169 2.87625 13.8694 4.12875C15.1219 5.38125 15.7482 6.0075 15.7482 6.786C15.7482 7.56375 15.1219 8.19 13.8694 9.4425L10.7247 12.588L5.41016 7.2735L8.55566 4.12875Z"
      fill="#171D24"
    />
    <path
      d="M4.617 8.06836L9.93075 13.3821L9.44325 13.8696C9.15975 14.1539 8.9085 14.4051 8.67975 14.6241H15.75C15.8992 14.6241 16.0423 14.6834 16.1477 14.7889C16.2532 14.8944 16.3125 15.0374 16.3125 15.1866C16.3125 15.3358 16.2532 15.4789 16.1477 15.5844C16.0423 15.6898 15.8992 15.7491 15.75 15.7491H6.75C5.988 15.7304 5.3625 15.1041 4.12875 13.8704C2.87625 12.6179 2.25 11.9916 2.25 11.2131C2.25 10.4354 2.87625 9.80911 4.12875 8.55661L4.617 8.06836Z"
      fill="#171D24"
    />
  </svg>
)
