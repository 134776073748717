import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadImage } from "../../../toolkit/slices/roomTypeSlice";
import ProgressLine from "./ProgressLine";
import UploadImageIcon from "../../icon/UploadImageIcon";
import cl from "./UploadPhoto.module.css";

const AccordionUploadPhoto = ({ handleCancel, handleUpload, id }) => {
  const dispatch = useDispatch();
  const { imageBase64, imageFileName, imageFileSize } = useSelector(
    (state) => state.createProject
  );
  const fileInputRef = useRef(null);
  const isAuth = localStorage.getItem("key");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    handleUpload(file, id);
    // if (file && file.size < 4001000) {
    //   if (
    //     file.type.startsWith("image/") &&
    //     file.type.match(/(png|jpg|jpeg)/gi)
    //   ) {
    //     const reader = new FileReader();
    //     reader.onload = () => {
    //       const img = new Image();
    //       img.onload = handleUpload(img);
    //       img.src = reader.result;
    //     };
    //     reader.readAsDataURL(file);
    //   }
    // }
  };

  const fileSizeMB = (val) => {
    const fileSizeInKB = val / 1024;
    const fileSizeInMB = (fileSizeInKB / 1024).toFixed(2);
    return fileSizeInMB;
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // LOADING ANIMATION
  const [lineAnimation, setLineAnimation] = useState(0);
  useEffect(() => {
    if (imageBase64) {
      dispatch(uploadImage(imageBase64));
      setLineAnimation(100);
    }
  }, [imageBase64, dispatch]);

  return (
    <div
      className="grid__description"
      onClick={(event) => event.stopPropagation()}
    >
      <div className="row center">
        <div
          className={imageBase64 ? "imageDropZone upload" : "imageDropZone"}
          // onDrop={handleDrop}
          // onDragOver={handleDragOver}
          onClick={(e) => {
            if (!imageBase64) {
              handleButtonClick();
            }
          }}
        >
          {imageBase64 ? (
            <>
              <div className="droppedImageBlock">
                {/* <img src={image} alt="Dropped" /> */}
                <div>{imageFileName}</div>
                <p>
                  {fileSizeMB(imageFileSize)}
                  MB
                </p>
              </div>
              <ProgressLine percentage={lineAnimation} />
              <button
                className="underline"
                onClick={(e) => {
                  handleCancel(e);
                  setLineAnimation(0);
                }}
              >
                Cancel
              </button>
            </>
          ) : (
            <>
              <div className="iconRound">
                <div className="icon">
                  <UploadImageIcon />
                </div>
              </div>
              <div className="dropBlock">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
                <button className={cl.btnDrag}>
                  <span>Click to upload</span> or drag and drop
                </button>
                <p>Maximum image size {isAuth ? 20 : 4} MB</p>
                <p>Acceptable format - .jpg .png</p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccordionUploadPhoto;
