import { FC } from "react";
import cl from "./WidgetEffiency.module.css";
import TitlePage from "../../titlePage/TitlePage";
import { Link } from "react-router-dom";

interface WidgetEffiencyProps {}

const WidgetEffiency: FC<WidgetEffiencyProps> = () => {
  return (
    <section className={cl.section}>
      <div className={`container ${cl.cont}`}>
        <div className={cl.leftBlock}>
          <p className={cl.topTitle}>Case Study</p>
          <TitlePage
            title={"Efficiency in Scale - The AI Design Impact"}
            classTitle={cl.titleNew}
            lineBreak={10}
            left
            size={"normal"}
            animation={true}
          />
          <img
            src="/img/efficiency-wid.svg"
            className={cl.effImage}
            alt="Efficiency"
          />
        </div>
        <div className={cl.rightBlock}>
          <p className={cl.descr}>
            In a detailed analysis of a 17-property project, with a diverse mix
            of one to four-bedroom apartments totaling 1161.55 square meters, we
            compared the traditional design studio approach with our AI-powered
            solution.
            <br />
            <br />
            <span>*Traditional Approach Costs:*</span>
            <br />
            <br />- <span>*3D Modeling from 2D Blueprints:*</span> Without AI,
            the manual process for elevating walls from 2D plans to 3D models
            was estimated at $2,323.10.
            <br />
            <br />- <span>*Visualization Creation:*</span> Crafting two sets of
            visualizations for each square meter was priced at a steep
            $41,815.80.
            <br />
            <br />
            In total, a conventional design studio would require an investment
            of $44,138.90 for a project of this scale...
          </p>
          <Link
            to="/blogs/virtual-staging-widget/"
            className={cl.readFullCase}
          >
            Read full case study
            <svg
              width="12"
              height="11"
              viewBox="0 0 12 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.02503 4.90164L5.59122 1.46783C5.4632 1.33981 5.3992 1.18906 5.3992 1.01556C5.3992 0.84206 5.4632 0.691605 5.59122 0.564193C5.71923 0.436178 5.86999 0.372171 6.04349 0.372171C6.21699 0.372171 6.36744 0.436178 6.49485 0.564193L11.013 5.08236C11.141 5.21038 11.205 5.36113 11.205 5.53463C11.205 5.70813 11.141 5.85858 11.013 5.986L6.49485 10.5042C6.36684 10.6322 6.21608 10.6962 6.04259 10.6962C5.86909 10.6962 5.71863 10.6322 5.59122 10.5042C5.4632 10.3762 5.3992 10.2254 5.3992 10.0519C5.3992 9.8784 5.4632 9.72795 5.59122 9.60053L9.02503 6.16672H0.982687C0.81702 6.16672 0.670179 6.10272 0.542164 5.9747C0.414149 5.84669 0.350143 5.69985 0.350143 5.53418C0.350143 5.36851 0.414149 5.22167 0.542164 5.09366C0.670179 4.96564 0.81702 4.90164 0.982687 4.90164H9.02503Z"
                fill="black"
              />
            </svg>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default WidgetEffiency;
