export const CrossIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    {...props}
  >
    <path
      d="M13.2983 0.710224C13.2058 0.61752 13.0959 0.543973 12.9749 0.493791C12.8539 0.44361 12.7242 0.417779 12.5933 0.417779C12.4623 0.417779 12.3326 0.44361 12.2116 0.493791C12.0907 0.543973 11.9808 0.61752 11.8883 0.710224L6.99827 5.59022L2.10827 0.700223C2.01569 0.607642 1.90578 0.534202 1.78481 0.484097C1.66385 0.433992 1.5342 0.408203 1.40327 0.408203C1.27234 0.408203 1.14269 0.433992 1.02173 0.484097C0.900763 0.534202 0.790852 0.607642 0.69827 0.700223C0.605688 0.792805 0.532248 0.902716 0.482143 1.02368C0.432038 1.14464 0.40625 1.27429 0.40625 1.40522C0.40625 1.53615 0.432038 1.6658 0.482143 1.78677C0.532248 1.90773 0.605688 2.01764 0.69827 2.11022L5.58827 7.00022L0.69827 11.8902C0.605688 11.9828 0.532248 12.0927 0.482143 12.2137C0.432038 12.3346 0.40625 12.4643 0.40625 12.5952C0.40625 12.7262 0.432038 12.8558 0.482143 12.9768C0.532248 13.0977 0.605688 13.2076 0.69827 13.3002C0.790852 13.3928 0.900763 13.4662 1.02173 13.5163C1.14269 13.5665 1.27234 13.5922 1.40327 13.5922C1.5342 13.5922 1.66385 13.5665 1.78481 13.5163C1.90578 13.4662 2.01569 13.3928 2.10827 13.3002L6.99827 8.41022L11.8883 13.3002C11.9809 13.3928 12.0908 13.4662 12.2117 13.5163C12.3327 13.5665 12.4623 13.5922 12.5933 13.5922C12.7242 13.5922 12.8538 13.5665 12.9748 13.5163C13.0958 13.4662 13.2057 13.3928 13.2983 13.3002C13.3909 13.2076 13.4643 13.0977 13.5144 12.9768C13.5645 12.8558 13.5903 12.7262 13.5903 12.5952C13.5903 12.4643 13.5645 12.3346 13.5144 12.2137C13.4643 12.0927 13.3909 11.9828 13.2983 11.8902L8.40827 7.00022L13.2983 2.11022C13.6783 1.73022 13.6783 1.09022 13.2983 0.710224Z"
      fill="black"
    />
  </svg>
);
