import { FC } from "react";
import BenefitsContainer from "../components/landingPage/BenefitsContainer";
import QuestionsContainer from "../components/landingPage/QuestionsContainer";
import { useTopToPage } from "../lib/scrypts";
import WidgetHero from "../components/Widget/WidgetHero/WidgetHero";
import WidgetInnovation from "../components/Widget/WidgetInnovation/WidgetInnovation";
// import WidgetTools from "../components/Widget/WidgetTools/WidgetTools";
import WidgetContact from "../components/Widget/WidgetContact/WidgetContact";
import WidgetEffiency from "../components/Widget/WidgetEffiency/WidgetEffiency";

interface indexProps {}

const WidgetPage: FC<indexProps> = () => {
  useTopToPage();
  return (
    <main className="main-flow">
      <WidgetHero />
      <WidgetInnovation />
      {/* <WidgetTools /> */}
      <WidgetContact />
      <WidgetEffiency />
      <BenefitsContainer widget={true} />
      <QuestionsContainer
        data={[
          {
            title: `What types of images does the widget work with?`,
            text: "The widget works with 2D floor plans and images of empty rooms from both new developments and existing real estate listings.",
          },
          {
            title: "Is the widget compatible with any CMS?",
            text: "Yes, our widget is versatile and can be easily installed on any CMS by embedding a simple code snippet, allowing rapid integration within five minutes.",
          },
          {
            title: "How long does it take to install the widget?",
            text: `Installation of the widget itself takes about five minutes. For cases involving 2D plans, the preparation can take up to a week depending on the number of properties, plus any additional time required to customize the widget to match the site's design and branding.`,
          },
          {
            title:
              "Can Virtual Staging be applied in commercial properties like offices, retail, or industrial space?",
            text: `Absolutely! Our services are specifically designed to deck up homes, apartments and other spaces just in order to create an imagery of the scope that the property holds for new owners or tenants.`,
          },
          {
            title:
              "How long does it take to generate images through the widget?",
            text: `Image generation through the widget takes up to fifteen seconds, keeping clients engaged on your site without long waiting times.`,
          },
          {
            title: "What is the value of the widget for businesses?",
            text: `The widget streamlines the design visualization process, transforming static 2D floor plans into interactive 3D visuals within seconds. This quick integration significantly enhances user engagement and can potentially increase property sales by allowing customers to visualize spaces in real-time. Compared to traditional design methods, our AI-powered solution is cost-effective, reducing expenses related to 3D modeling and visualization creation <a href="https://paintit.ai/blogs/virtual-staging-widget/" target="_blank">as highlighted in our analysis</a> where traditional methods could cost up to $44,138.90 for a similar scale project.`,
          },
        ]}
      />
    </main>
  );
};

export default WidgetPage;
