import React from "react";
import { motion } from "framer-motion";

const RoomDesignCard = ({
  props,
  onClick,
  active,
  delayVal,
  paid_only,
  isPaidUser,
  disabled,
}) => {
  const { name, image } = props;

  const isActive = name === active ? "card active" : "card";
  const isPaid = paid_only && paid_only !== isPaidUser ? "disable" : "";

  return (
    <motion.div
      onClick={onClick}
      className={`${isActive} ${isPaid} ${disabled  ? "disableCard" : ""}`}
      layout
      initial={{
        y: "30px",
        opacity: 0,
      }}
      animate={{
        y: "0px",
        opacity: 1,
      }}
      transition={{ delay: delayVal }}
    >
      <img src={image} alt="" />
      <div className="hashTitle">{name}</div>
      {isPaid && <div className="hashTitle pro">pro</div>}
    </motion.div>
  );
};

export default RoomDesignCard;
