import React from 'react';

const StyleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.07206 2.58779C9.18608 2.52196 9.31541 2.4873 9.44706 2.4873C9.57871 2.4873 9.70805 2.52196 9.82206 2.58779L15.6676 5.96279C15.7816 6.02861 15.8763 6.12329 15.9421 6.2373C16.0079 6.35131 16.0426 6.48064 16.0426 6.61229V12.496C16.0426 12.891 15.9386 13.279 15.7411 13.621C15.5436 13.963 15.2596 14.2471 14.9176 14.4445L10.5721 16.9548C10.23 17.1523 9.84202 17.2562 9.44706 17.2562C9.0521 17.2562 8.66411 17.1523 8.32206 16.9548L3.97656 14.4453C3.63453 14.2478 3.3505 13.9638 3.15302 13.6218C2.95554 13.2797 2.85157 12.8917 2.85156 12.4968V6.61229C2.85157 6.48064 2.88622 6.35131 2.95205 6.2373C3.01787 6.12329 3.11255 6.02861 3.22656 5.96279L9.07206 2.58779ZM9.44706 5.48729C9.64597 5.48729 9.83674 5.5663 9.97739 5.70696C10.118 5.84761 10.1971 6.03837 10.1971 6.23729V9.55454L13.0696 11.2128C13.1555 11.2617 13.231 11.3271 13.2916 11.4053C13.3522 11.4834 13.3968 11.5728 13.4227 11.6683C13.4486 11.7637 13.4554 11.8634 13.4426 11.9614C13.4299 12.0595 13.3978 12.1541 13.3484 12.2398C13.2989 12.3254 13.233 12.4005 13.1544 12.4606C13.0759 12.5206 12.9862 12.5646 12.8906 12.5899C12.7949 12.6151 12.6953 12.6213 12.5973 12.6079C12.4993 12.5945 12.4049 12.5618 12.3196 12.5118L9.44706 10.8535L6.57456 12.5118C6.48923 12.5618 6.39485 12.5945 6.29686 12.6079C6.19886 12.6213 6.09918 12.6151 6.00355 12.5899C5.90793 12.5646 5.81825 12.5206 5.73968 12.4606C5.66111 12.4005 5.5952 12.3254 5.54574 12.2398C5.49628 12.1541 5.46426 12.0595 5.45151 11.9614C5.43876 11.8634 5.44554 11.7637 5.47146 11.6683C5.49737 11.5728 5.54191 11.4834 5.60251 11.4053C5.66311 11.3271 5.73858 11.2617 5.82456 11.2128L8.69706 9.55454V6.23729C8.69706 6.03837 8.77608 5.84761 8.91673 5.70696C9.05738 5.5663 9.24815 5.48729 9.44706 5.48729Z"
        fill="black"
      />
    </svg>
  );
};

export default StyleIcon;
