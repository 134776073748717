import React from "react";
import "./titlePage.css";
import { motion } from "framer-motion";

const TitlePage = ({
  h6 = undefined,
  h1 = false,
  size,
  title,
  lineBreak,
  animation,
  desc = undefined,
  classTitle,
  none = undefined,
  left = false,
}) => {
  function splitString(str, lineBreak) {
    let variants = {};

    if (animation) {
      variants = {
        hidden: {
          y: "300px",
          opacity: 0,
          display: "block",
        },
        visible: {
          y: "0px",
          opacity: 1,
          display: "block",
        },
      };
      // return variants;
    }

    const wrapWord = (text, col) => {
      let arr = text.split(/\s+/);
      const words = arr.map((word, index) => {
        return (
          <motion.span
            key={index}
            className={index > col ? "italic regular" : "normal"}
            style={
              word === "[break]"
                ? { flexBasis: "100%", width: 0, height: 0, overflow: "hidden" }
                : {}
            }
            variants={variants}
            transition={{
              type: "Tween",
              delay: (0.2 * index) / 3,
              duration: 0.7,
            }}
          >
            {word}&nbsp;
          </motion.span>
        );
      });
      return words;
    };

    const result = (
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.8 }}
        className={`titleArr ${classTitle} ${left ? "left" : ""}`}
      >
        {wrapWord(str, lineBreak)}
      </motion.div>
    );
    return result;
  }
  const subTitleVariants = {
    subHidden: {
      x: 0,
      opacity: 0,
    },
    subVisible: {
      x: 0,
      opacity: 0.4,
    },
    transition: { type: "Tween", delay: 0.3, duration: 0.7 },
  };
  const subTitle = (
    <motion.div
      initial="subHidden"
      whileInView="subVisible"
      viewport={{ once: true, amount: 0.8 }}
      className={`titleArr titleMini ${classTitle}`}
    >
      <motion.h3 variants={subTitleVariants}>{h6}</motion.h3>
    </motion.div>
  );

  return (
    <div
      className={`title title-quiz ${size}`}
      style={none ? { margin: "0" } : undefined}
    >
      {h6 ? subTitle : ""}
      {h1 ? (
        <h1>{splitString(title, lineBreak)}</h1>
      ) : (
        <h2>{splitString(title, lineBreak)}</h2>
      )}
      {desc ? (
        <div className="desc-container">
          <p className="desc">{desc}</p>
        </div>
      ) : null}
    </div>
  );
};

export default TitlePage;
