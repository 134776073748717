import React, { useEffect } from "react";
import TitlePage from "../../components/titlePage/TitlePage";
import SelectedHashtag from "../../components/selectedHashtag/SelectedHashtag";
import RoomDesignGrid from "../../components/QuizGroup/RoomDesignPage/RoomDesignGrid";
import { useDispatch, useSelector } from "react-redux";
import { setPageType } from "../../toolkit/slices/checkTypePageSlice";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function RoomDesign({ pageType }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageType(pageType));
  });

  const navigate = useNavigate();
  const hashtagState = useSelector((state) => state.roomType);
  useEffect(() => {
    const redirect = (e) => {
      navigate("/interior-ai/mode-options/");
    };

    if (!hashtagState.hastagArr.roomtype.name) {
      redirect();
    }
  }, [navigate, hashtagState.hastagArr.roomtype.name]);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <TitlePage
        h1
        title={`Choose your room design style`}
        lineBreak={1}
        size={"normal type"}
        animation={true}
      />
      <SelectedHashtag />
      <RoomDesignGrid />
    </>
  );
}
