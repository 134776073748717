import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  isConfirm: false,
  imagine_id: '',
};

export const postEmagineRetry = createAsyncThunk(
  'postEmagineRetry/postEmagineRetry',
  async (_, { dispatch, getState }) => {
    let userEmail = getState().confirmEmail.userEmail;
    let imagine_id = getState().confirmEmail.imagine_id;
    const createKey = await axios.post(
      `${process.env.REACT_APP_BACKEND_HOST}/api/v1/rest-auth/login/`,
      JSON.stringify({ email: userEmail }),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    const imagineRetry = await axios.post(
      `${process.env.REACT_APP_BACKEND_HOST}/api/v1/integrations/${imagine_id}/imagine/retry/`,
      JSON.stringify({}),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${createKey.data.key}`,
        },
      },
    );

    console.log(imagineRetry.data);
    dispatch(setImagineId(imagine_id));
  },
);

export const emagineRetrySlice = createSlice({
  name: 'emagineRetry',
  initialState,
  reducers: {
    setImagineId: (state, action) => {
      state.imagine_id = action.payload;
    },
  },
});

export const { setImagineId } = emagineRetrySlice.actions;
export default emagineRetrySlice.reducer;
