export const FBMiniIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20 10.0576C20 4.53762 15.52 0.0576172 10 0.0576172C4.48 0.0576172 0 4.53762 0 10.0576C0 14.8976 3.44 18.9276 8 19.8576V13.0576H6V10.0576H8V7.55762C8 5.62762 9.57 4.05762 11.5 4.05762H14V7.05762H12C11.45 7.05762 11 7.50762 11 8.05762V10.0576H14V13.0576H11V20.0076C16.05 19.5076 20 15.2476 20 10.0576Z"
      fill="#252834"
    />
  </svg>
);
