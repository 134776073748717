import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { postEmagineRetry } from './imagineRetrySlice';
import { setActiveAuthModal, setActiveModalName } from './authorization/authorizationModalSlice';

const initialState = {
  isConfirm: false,
  imagine_id: '',
  userEmail: '',
};

export const checkEmail = createAsyncThunk(
  'checkEmail/checkEmail',
  async (data, { dispatch, getState }) => {
    // try {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/rest-auth/registration/verify-email/`,
        JSON.stringify(data),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        dispatch(setIsConfirm(true));
        dispatch(setEmail(response.data.email));
        const typePage = getState().checkTypePage.type;
        // console.log(data.imagine_id)
        if (typePage === 'common' && !data.imagine_id) {
          dispatch(setActiveAuthModal(true));
          dispatch(setActiveModalName('Login stripe'));
        }
        if (data.imagine_id) {
          dispatch(setImagineId(data.imagine_id));
          dispatch(postEmagineRetry());
        }
      })
      .catch((error) => {
        console.log('error :>> ', error.response.data);
      });
  },
);

export const confirmEmailSlice = createSlice({
  name: 'confirmEmail',
  initialState,
  reducers: {
    setIsConfirm: (state, action) => {
      state.isConfirm = action.payload;
      console.log('дёрг дёрг дёрг :>> ', action.payload);
    },
    setImagineId: (state, action) => {
      state.imagine_id = action.payload;
    },
    setEmail: (state, action) => {
      state.userEmail = action.payload;
    },
  },
});

export const { setIsConfirm, setImagineId, setEmail } = confirmEmailSlice.actions;
export default confirmEmailSlice.reducer;
