import React from "react";

const TwitterIcon = () => {
  return (
    <svg
      width="21"
      height="19"
      viewBox="0 0 15 14"
      fill="none"
      style={{paddingTop: '2px'}}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8134 0H14.1135L9.08844 5.74329L15 13.5586H10.3713L6.74593 8.81866L2.59769 13.5586H0.296204L5.67098 7.41552L0 0H4.74621L8.02322 4.3325L11.8134 0ZM11.0061 12.1819H12.2806L4.05368 1.30441H2.686L11.0061 12.1819Z"
        fill="black"
      />
    </svg>
  );
};

export default TwitterIcon;
