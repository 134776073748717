import React from 'react';

const RoomTypeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
      <path
        d="M8.33087 3.36423C8.39529 3.3116 8.47057 3.27391 8.5513 3.25387C8.63203 3.23383 8.7162 3.23195 8.79775 3.24836L14.4227 4.37336C14.5502 4.39883 14.6649 4.46767 14.7474 4.56817C14.8298 4.66867 14.8749 4.79462 14.875 4.92461V15.0462C14.8749 15.1762 14.8298 15.3022 14.7474 15.4027C14.6649 15.5032 14.5502 15.572 14.4227 15.5975L8.79775 16.7225C8.71617 16.7388 8.632 16.7368 8.55129 16.7167C8.47057 16.6965 8.39533 16.6587 8.33099 16.606C8.26664 16.5533 8.21479 16.4869 8.17917 16.4117C8.14355 16.3366 8.12505 16.2544 8.125 16.1712V3.79961M11.5 9.98486C11.5 9.83567 11.4407 9.6926 11.3352 9.58711C11.2298 9.48162 11.0867 9.42236 10.9375 9.42236C10.7883 9.42236 10.6452 9.48162 10.5398 9.58711C10.4343 9.6926 10.375 9.83567 10.375 9.98486C10.375 10.134 10.4343 10.2771 10.5398 10.3826C10.6452 10.4881 10.7883 10.5474 10.9375 10.5474C11.0867 10.5474 11.2298 10.4881 11.3352 10.3826C11.4407 10.2771 11.5 10.134 11.5 9.98486ZM7 4.36211H4.1875C4.03832 4.36211 3.89524 4.42137 3.78975 4.52686C3.68426 4.63235 3.625 4.77542 3.625 4.92461V15.0462C3.625 15.1954 3.68426 15.3385 3.78975 15.444C3.89524 15.5495 4.03832 15.6087 4.1875 15.6087H7V4.36211ZM8.33087 3.36423C8.26647 3.41703 8.21459 3.48348 8.17899 3.55877C8.14338 3.63406 8.12494 3.71632 8.125 3.79961L8.33087 3.36423Z"
        fill="black"
      />
    </svg>
  );
};

export default RoomTypeIcon;
