import React from "react";
import { Link } from "react-router-dom";
import { setActiveAuthModal } from "../../../toolkit/slices/authorization/authorizationModalSlice";
import ArrowFooter from "../../icon/ArrowFooter";
import cl from "./AuthComponent.module.css";
import { useDispatch, useSelector } from "react-redux";
import CloseModalIcon from "../../icon/CloseModalIcon";
import { deleteAllData } from "../../../toolkit/slices/authorization/getUserSlice";
import LogOutIcon from "../../icon/LogOutIcon";

const IsLoginMenu = () => {
  const dispatch = useDispatch();
  const subscriptionAll = useSelector(
    (state) => state.subscriptionPlan.subscriptionAll
  );
  const userInfo = useSelector((state) => state.getUser.userData);
  const dataStorage = JSON.parse(localStorage.getItem("data"));

  const closeModalAfterChangeLink = () => {
    dispatch(setActiveAuthModal(false));
  };

  let activeSubscription = subscriptionAll.find((e) => e.is_valid);

  const logOut = () => {
    localStorage.removeItem("key");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    localStorage.removeItem("data");
    localStorage.removeItem("user");
    localStorage.removeItem("stripeLink");
    dispatch(deleteAllData());
    dispatch(setActiveAuthModal(false));
  };

  return (
    <div className="loginGrid isAuth">
      <div className="whiteSideIsAuth authMini">
        <button
          className="closeModal closeModalMini"
          onClick={(e) => dispatch(setActiveAuthModal(false))}
        >
          <CloseModalIcon />
        </button>
        <div className={cl.listsBlock}>
          <ul className={cl.userInfoModal}>
            <li className={cl.infoItem}>
              Current subscription:
              <span>{
                activeSubscription && activeSubscription.start && activeSubscription.end ? 
                (new Date(activeSubscription.end).getTime() - new Date(activeSubscription.start).getTime()) / (1000*60*60*24) === 3 ? 
                'Pro-Trial'
                : 'Pro'
                : userInfo.is_paid ? 'Personal' : 'Free'
                }</span>
            </li>
            <li className={cl.infoItem}>
              Visualisations left:
              <span>
                {" "}
                {"attempts_left" in userInfo && userInfo.attempts_left
                  ? userInfo.attempts_left
                  : dataStorage.attempts_left}
              </span>
            </li>
          </ul>
          <ul className={cl.listOfLinks}>
            <li>
              <Link
                to={"/profile/"}
                onClick={() => closeModalAfterChangeLink()}
              >
                Personal Area <ArrowFooter />
              </Link>
            </li>
            <li>
              <Link
                to={"/profile/gallery/"}
                onClick={() => closeModalAfterChangeLink()}
              >
                My Gallery <ArrowFooter />
              </Link>
            </li>
            <li>
              <Link
                to={"/profile/billing/"}
                onClick={() => closeModalAfterChangeLink()}
              >
                Billing settings <ArrowFooter />
              </Link>
            </li>
          </ul>
        </div>

        <Link
          to={"/"}
          className={`button white transperent ${cl.buttonMini}`}
          onClick={() => {logOut();localStorage.removeItem("num_fill")}}
        >
          <LogOutIcon />
          Log out
        </Link>
      </div>
    </div>
  );
};

export default IsLoginMenu;
