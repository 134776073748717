import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./seoBlock.css";
import LoadMore from "../button/LoadMore";

const SeoBlockStaging = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [animCompleate, setAnimCompleate] = useState(false);

  const openText = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const onAnimationComplete = () => {
    setAnimCompleate(true);
  };
  const onAnimationStart = () => {
    setAnimCompleate(false);
  };

  return (
    <section className="tipsAndHint tipsAndHintStaging home">
      <div className="container">
        {/* <h2 className="title left">
          AI-Generated Interior Design Visualizations: Transforming the Future
          of Design
        </h2> */}
        {/* <motion.div className={isOpen ? 'description active' : 'description'}> */}

        <div className="overflow">
          <div
            className={animCompleate && !isOpen ? "opacity active" : "opacity "}
          >
            <p>
              Open your door to the newest revolution in interior design
              presided by Virtual Staging AI (Fill The Space) that allows to
              transform the look of place without any stress connected with the
              conventional staging. Our AI-powered solution harnesses the
              capability of digital transformation to represent virtual rooms
              with full furniture architecture and accentuates real-time ability
              to redefine spaces.
            </p>
          </div>

          <AnimatePresence initial={false}>
            {isOpen && (
              <motion.div
                className="description"
                key="content"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1, height: "auto" },
                  collapsed: { opacity: 1, height: 0 },
                }}
                transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
                onAnimationComplete={onAnimationComplete}
                onAnimationStart={onAnimationStart}
              >
                <h3>Unlock the Power of Virtual Staging:</h3>
                <p>
                  Immediate Impact: Our AI algorithm constantly works in the
                  background to generate photos of your empty rooms that are
                  visually appealing and interesting which creates a great
                  experience for the one looking at the ad and a potential
                  client as well.
                </p>
                <ul>
                  <li>
                    <div className="listTitle">Design Flexibility:</div>
                    On top of that, we have six different types of rooms and
                    eight different styles of interior design to select from.
                    Thus, you have a suitable environment for your unique taste
                    and functional requirements.
                  </li>
                  <li>
                    <div className="listTitle">Market Readiness:</div>
                    Whether you need a cozy apartment or a large commercial
                    space, we try to make your home an attention-grabber by
                    virtual staging, faster than traditional methods.
                  </li>
                  <li>
                    <div className="listTitle">Accessibility:</div>
                    We want to give the people what they deserve: stylish
                    interiors at affordable price. This is the reason behind the
                    fact that we offer our service from free trials to premium
                    packages so that no matter what level of service one needs
                    or can afford, we have something for everyone.
                  </li>
                </ul>
                <h3>Why It Works:</h3>
                <p>
                  Visual Appeal: Our AI-modified spaces are not only jazzed up;
                  their designs are made to grab users'attention and spark
                  enthusiasm. Usage of the appropriate colors and furnishings
                  allows us to show our clients that their space can become
                  greater than it is now and be the place they dream about.
                </p>
                <ul>
                  <li>
                    <div className="listTitle">Visual Appeal:</div>
                    Our AI-modified spaces are not only jazzed up; their designs
                    are made to grab users'attention and spark enthusiasm. Usage
                    of the appropriate colors and furnishings allows us to show
                    our clients that their space can become greater than it is
                    now and be the place they dream about.
                  </li>
                  <li>
                    <div className="listTitle">Cost-Effectiveness:</div>
                    With virtual events, the stress of logistics and cost
                    associated with staging process are gone. Interactive
                    furnishing, which is very economical, delivers maximum bang
                    for the buck.
                  </li>
                  <li>
                    <div className="listTitle">Speed and Convenience:</div>
                    Get your virtual staging image instantly. There's no need to
                    wait for arrangements of furniture, or the interruption this
                    may cause —they just need to be quick and high-quality
                    visualizations.
                  </li>
                </ul>
                <h3>Experience the Difference:</h3>
                <p>
                  Drive more positive engagement and ensure clients have a
                  longlasting and great first impression of the property. By
                  means of artificial intelligence driven virtual staging our
                  real estate business is better equipped for the challenges of
                  the modern world raising the bar in the instantaneous market
                  of today. We aim to redefine the way we deal with and, when it
                  comes to it, market properties with virtual staging and how
                  accurate it is in imitating the real thing
                </p>
                <br />
                <p>
                  Adopt Virtual Staging AI and don't miss out on the opportunity
                  of creating feeder - homing tales that sell. Your home or your
                  office is like you raise a child, and you will want to present
                  it in the best light possible — and that is exactly what we
                  make real with our virtual staging.
                </p>
                <br />
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        <LoadMore
          onClick={() => {
            openText();
          }}
          text={isOpen ? "Close" : "read more..."}
        />
      </div>
    </section>
  );
};

export default SeoBlockStaging;
