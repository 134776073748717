import React, { useEffect, useState } from "react";
import cl from "./ResultGrid.module.css";
import RoomDesignIcon from "../../icon/type/RoomDesignIcon";
import RoomTypeIcon from "../../icon/type/RoomTypeIcon";
import StyleIcon from "../../icon/type/StyleIcon";
import TitlePage from "../../titlePage/TitlePage";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GoogleLoginButton from "../../menu/GoogleLoginButton";
import FacebookLoginButton from "../../menu/FacebookLoginButton";
import {
  isLogin,
  login,
  setEmail,
  setSubscribe,
} from "../../../toolkit/slices/createProjectSlice";
import { startPolling } from "../../../toolkit/slices/getResultImageSlice";
import { gtmEvent } from "../../../lib/gtm";
import { collectDataForProject } from "../../../helpers/collectDataForProject";
import { useSearchParams } from "react-router-dom";
import TagsMarkIcon from "../../icon/type/TagsMarkIcon";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const ResultGrid = () => {
  const isAuth = localStorage.getItem("key");
  const quizCashStorage = localStorage.getItem("make design");

  // STATE

  const hastagArr = useSelector((state) => state.roomType.hastagArr);
  const newImageId = useSelector((state) => state.createProject.imageId);
  const errorMessage = useSelector((state) => state.createProject.errorMessage);
  const configState = useSelector((state) => state.config);
  const createProjectState = useSelector((state) => state.createProject);
  const filtersState = useSelector((state) => state.filters.all);
  const filtersDesign = useSelector((state) => state.filters.designAi);
  const userToken = useSelector((state) => state.login.userToken);
  const [params] = useSearchParams();
  const uploadType = useSelector((state) => state.createProject.uploadType);

  // console.log(filtersState)

  // HOOKS
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // HELPER FUNCTIONS

  const getQuizData = () => {
    let isQuizBlank =
        filtersState.length > 0 &&
        hastagArr.roomtype.name &&
        hastagArr.roomdesignstyle.name,
      quizData;

    if (isQuizBlank) {
      quizData = collectDataForProject({
        hashTag: hastagArr,
        prompts: configState,
        generalInfo: createProjectState,
        filters: filtersDesign
          ? [...filtersState, filtersDesign]
          : filtersState,
      });
    } else if (quizCashStorage) {
      quizData = JSON.parse(quizCashStorage);
    } else {
      quizData = null;
    }
    // alert(JSON.stringify(quizData))
    // console.log(quizData)
    return quizData;
  };

  // FORM RESULT

  const [isAccept, setIsAccept] = useState(false);
  const schema = z.object({
    mail: z
      .string()
      .min(4, "This field has to be filled.")
      .email("This is not a valid Email"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    const quizData = getQuizData();
    const imagineIdQuery = params.get("imagine_id");
    if (quizData) {
      if (isAuth) {
        // console.log(quizData)
        dispatch(isLogin({ token: isAuth, body: quizData }));
        return;
      }
      localStorage["make design"] = JSON.stringify(quizData);
      return;
    }

    !imagineIdQuery && navigate("/");
    // eslint-disable-next-line
  }, [dispatch, navigate, userToken]);

  useEffect(() => {
    if (newImageId) {
      dispatch(startPolling(newImageId));
    }
  }, [newImageId, dispatch]);

  const onSubmit = (data) => {
    const quizData = getQuizData();
    dispatch(setEmail(data.email));
    if (isAccept) dispatch(setSubscribe(true));

    gtmEvent(
      [
        "Step6_Inspiration_SHOW_INTERIOR",
        "Step6_Space_SHOW_INTERIOR",
        "Step6_Redesign_SHOW_INTERIOR",
      ][uploadType],
      "category"
    );

    // gtmEvent("Show_moodboard", "category");
    isAuth
      ? dispatch(isLogin({ token: isAuth, body: quizData }))
      : dispatch(login({ val: { email: data.mail }, body: quizData }));
  };

  // DATA

  const icons = {
    roomtype: <RoomTypeIcon />,
    roomdesign: <RoomDesignIcon />,
    yourstyle: <StyleIcon />,
    tagscloud: <TagsMarkIcon />,
  };

  return (
    <div className={["container", cl.cont].join(" ")}>
      <div className={cl.resultWrapper}>
        <div className={`hashtags ${cl.hashtagsBlock}`}>
          <ul>
            {Object.entries(hastagArr).map(([key, type], index) => {
              if (type.name && key !== "modeoptions") {
                return (
                  <li key={key}>
                    {icons[key]} <div>{type["name"]}</div>
                  </li>
                );
              } else {
                return "";
              }
            })}
          </ul>
        </div>
        <TitlePage
          size={"medium"}
          classTitle={cl.title}
          h1
          title={"For user verification, please provide your email address"}
          lineBreak={3}
          animation={true}
        />
        <p className={cl.descrResult}>
          Please enter your current email address or login with google or
          facebook otherwise you will not be able to continue
        </p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id="email"
          className={cl.formBlock}
        >
          <div className={cl.inputBlock}>
            <label className={cl.labelInput}>
              {errors.mail?.message || errorMessage || "Enter your Email"}
            </label>
            <input
              type="text"
              placeholder="example@mail.com"
              className={
                errors.mail?.message
                  ? [cl.emailInput, cl.errorInput].join(" ")
                  : cl.emailInput
              }
              {...register("mail")}
            />
          </div>
          <p className={cl.privacyText}>
            By clicking on "See Result" button, I agree to Paintitai's{" "}
            <Link to={"/terms-of-service/"} className={cl.link}>
              Terms of Use
            </Link>
          </p>
          <div
            className={
              isAccept
                ? [cl.checkbox, cl.activeCheckbox].join(" ")
                : cl.checkbox
            }
          >
            <input
              type="checkbox"
              className={cl.inputCheckbox}
              value={isAccept}
              onClick={(e) => setIsAccept(!isAccept)}
            />
            <div className={cl.checkWrapper}>
              <div className={cl.checkBlock}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7"
                  height="6"
                  viewBox="0 0 7 6"
                  fill="none"
                >
                  <path
                    d="M5.22603 0.727368C5.33168 0.626785 5.47228 0.571178 5.61814 0.572282C5.76401 0.573385 5.90375 0.631113 6.00787 0.733284C6.11198 0.835454 6.17234 0.974076 6.17619 1.1199C6.18005 1.26572 6.1271 1.40734 6.02853 1.51487L3.03603 5.25737C2.98457 5.31279 2.92247 5.35727 2.85343 5.38814C2.78439 5.41902 2.70983 5.43565 2.63422 5.43705C2.5586 5.43845 2.48348 5.42459 2.41335 5.39629C2.34321 5.368 2.2795 5.32585 2.22603 5.27237L0.241531 3.28787C0.186266 3.23637 0.14194 3.17427 0.111196 3.10527C0.0804516 3.03627 0.0639201 2.96179 0.0625875 2.88626C0.061255 2.81073 0.0751486 2.73571 0.103439 2.66567C0.13173 2.59563 0.173839 2.532 0.227253 2.47859C0.280667 2.42518 0.344292 2.38307 0.414333 2.35478C0.484374 2.32649 0.559396 2.31259 0.634923 2.31392C0.710451 2.31526 0.784936 2.33179 0.853935 2.36253C0.922935 2.39328 0.985035 2.4376 1.03653 2.49287L2.60703 4.06262L5.21178 0.743868C5.21647 0.738094 5.22073 0.732585 5.22603 0.727368Z"
                    fill="black"
                  />
                </svg>
              </div>
              <span className={cl.checkText}>Sign up for updates</span>
            </div>
          </div>
          <div className={cl.separateBlock}>
            <span className={cl.line}></span>
            <span className={cl.separateText}>Or</span>
          </div>
          <div className={cl.buttonsBlock}>
            <GoogleLoginButton btnClass={cl.authBlock} />
            <FacebookLoginButton btnClass={cl.authBlock} />
          </div>
          <div className={cl.btnBlock}>
            <button type="submit" className={cl.btnForm}>
              See Result
            </button>
          </div>

          <Link to={"/interior-ai/additional-tags/"} className={cl.backButton}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="13"
              viewBox="0 0 8 13"
              fill="none"
            >
              <path
                d="M6.5 1L1.70711 5.79289C1.31658 6.18342 1.31658 6.81658 1.70711 7.20711L6.5 12"
                stroke="black"
                strokeWidth="1.5"
              />
            </svg>
            <span>Back to questions</span>
          </Link>
        </form>
      </div>
    </div>
  );
};

export default ResultGrid;
