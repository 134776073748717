import React, { useEffect, useState } from "react";
import TitlePage from "../../components/titlePage/TitlePage";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserPortfolio,
  resetFilterTags,
  setPaginationNumPage,
} from "../../toolkit/slices/account/portfolioUserSlice";
import ProjectCard from "../../components/homeProjects/ProjectCard";
import ChoiceButtonAccount from "../../components/button/ChoiceButtonAccount";
import { useTopToPage } from "../../lib/scrypts";
import { setPageType } from "../../toolkit/slices/checkTypePageSlice";
import IsPortfolioEmpty from "../../components/isPortfolioEmpty/IsPortfolioEmpty";
import { getFiltersAll } from "../../toolkit/slices/getFiltersSlice";
import Pagination from "../../components/pagination/Pagination";
import FilterGrid from "../../components/filter/FilterGrid";
import MyCrumbs from "../../components/UI/MyCrumbs/MyCrumbs";
// import { checkStatusIsAuth, startPollingIsAuth } from '../../toolkit/slices/getResultImageSlice';
import { Helmet } from "react-helmet-async";

const GalleryPersonal = ({ pageType }) => {
  useTopToPage();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(resetFilterTags());
    dispatch(setPaginationNumPage(0));
    dispatch(getUserPortfolio());
    dispatch(setPageType(pageType));
    dispatch(getFiltersAll());
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [dispatch, pageType]);

  const userData = JSON.parse(localStorage.getItem("data"));

  const variants = {
    hidden: {
      y: "30px",
      opacity: 0,
    },
    visible: {
      y: "0px",
      opacity: 1,
    },
  };

  let increment = 1;

  const imageArr = useSelector((state) => state.portfolioUser.data);
  console.log(imageArr);

  // const test = () => {
  //   const newImageId = '341b6043-5bb7-4adf-9acc-e17603b892c3';
  //   const token = 'e32d70238d7598e67506d0ffb4390a890a01ff6c';
  //   dispatch(checkStatusIsAuth({ newImageId: newImageId, token: token }));
  // };

  const projectsView = imageArr.map((slide, index) => {
    increment++;
    if (increment >= 2) {
      increment = 1 / 10;
    }
    return (
      <motion.div
        key={index}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.8 }}
      >
        <motion.div
          variants={variants}
          transition={{ type: "Tween", delay: 0.1 * increment, duration: 0.7 }}
        >
          <ProjectCard
            id={slide.id}
            link={slide.image}
            tags={slide.tags}
            imageArr={imageArr}
            account={true}
          />
        </motion.div>
      </motion.div>
    );
  });
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div id="projectsGrid" className="container">
        <MyCrumbs classCrumbs={"centerCrumbs"} />
        <TitlePage
          title={`Welcome to personal account [break] ${userData.first_name} `}
          lineBreak={3}
          size={"normal"}
          h1
          animation={true}
        />
      </div>
      {/* <button className="button green" onClick={() => test()}>
        test
      </button> */}
      <div className="projects account">
        <div className="container">
          <FilterGrid pageType={pageType} />
          {imageArr.length !== 0 && !isLoading ? (
            <div className="projectsGrid">{projectsView}</div>
          ) : (
            <IsPortfolioEmpty />
          )}
          <Pagination pageType={pageType} count={10} />
        </div>
      </div>
      <ChoiceButtonAccount isActive={"portfolio"} />
    </>
  );
};

export default GalleryPersonal;
