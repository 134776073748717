import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import LandingPage from "./routes/LandingPage.jsx";
import RoomDesign from "./routes/quiz/RoomDesign.jsx";
import StyleMoodPage from "./routes/quiz/StyleMoodPage";
import RoomType from "./routes/quiz/RoomType.jsx";
import Main from "./routes/Main.jsx";
import Home from "./routes/Home.jsx";
// import YourStyle from "./routes/quiz/YourStyle.jsx";
import { Provider } from "react-redux";
import store from "./toolkit/store";
import { HelmetProvider } from "react-helmet-async";

import Result from "./routes/Result";
import Blogs from "./routes/Blog/Blogs.jsx";
import InfoPersonal from "./routes/account/InfoPersonal";
import GalleryPersonal from "./routes/account/GalleryPersonal";
import BillingPersonal from "./routes/account/BillingPersonal";
import Error404 from "./routes/Error404";
import UploadPhoto from "./routes/quiz/UploadPhoto";
import PrivacyPolicy from "./components/privacyPolicy/PrivacyPolicy.jsx";
import TermsOfService from "./components/privacyPolicy/TermsOfService.jsx";
import StyleMoodResultPage from "./routes/quiz/StyleMoodResultPage";
import TagsCloudPage from "./routes/quiz/TagsCloudPage.jsx";
import Article from "./routes/Blog/Article.jsx";
import StagingPage from "./routes/StagingPage";
import WidgetPage from "./routes/WidgetPage";

// let link = window.location.pathname.split('/')[1];
// let baseName = link === process.env.REACT_APP_BASE_NAME ? process.env.REACT_APP_BASE_NAME : '';

const PrivateRoute = ({ element }) => {
  const isAuth = localStorage.getItem("key");
  return isAuth ? element : <Navigate to="/?isLogin=false" replace />;
};

const router = createBrowserRouter(
  [
    {
      path: '*',
      element: <Error404 pageType={"not found"} />,
    },
    {
      path: "/",
      element: <Main />,
      errorElement: <Error404  pageType={"error"}/>,
      children: [
        // { path: '/landing', element: <LandingPage pageType={'common'} /> },
        {
          errorElement: <Error404  pageType={"error"}/>,
          children: [
            // { path: '', element: <Home pageType={'common'} /> },
            { path: "", element: <LandingPage pageType={"common"} /> },
            { path: "/interior-ai", element: <Home pageType={"common"} /> },
            { path: "/staging", element: <StagingPage /> },
            { path: "/widget", element: <WidgetPage /> },
            {
              path: "/privacy-policy",
              element: <PrivacyPolicy pageType={"common"} />,
            },
            {
              path: "/terms-of-service",
              element: <TermsOfService pageType={"common"} />,
            },
            {
              path: "/interior-ai/mode-options",
              element: <UploadPhoto pageType={"quiz"} />,
            },
            {
              path: "/interior-ai/roomdesignai",
              element: <StyleMoodPage pageType={"quiz"} />,
            },
            {
              path: "/interior-ai/suggestion",
              element: <StyleMoodResultPage pageType={"quiz"} />,
            },
            {
              path: "/interior-ai/room-design-style",
              element: <RoomDesign pageType={"quiz"} />,
            },
            {
              path: "/interior-ai/room-type",
              element: <RoomType pageType={"quiz"} />,
            },
            // {
            //   path: "/interior-ai/color-palette",
            //   element: <YourStyle pageType={"quiz"} />,
            // },
            {
              path: "/interior-ai/additional-tags",
              element: <TagsCloudPage pageType={"quiz"} />,
            },
            {
              path: "/interior-ai/result",
              element: <Result pageType={"common"} />,
            },
            {
              path: "/profile",
              element: (
                <PrivateRoute element={<InfoPersonal pageType={"account"} />} />
              ),
            },
            {
              path: "/blogs",
              element: (
                <Blogs />
              ),
            },
            {
              path: "/blogs/:slug",
              element: (
                <Article />
              ),
            },
            {
              path: "/profile/gallery",
              element: (
                <PrivateRoute
                  element={<GalleryPersonal pageType={"account"} />}
                />
              ),
            },
            {
              path: "/profile/billing",
              element: (
                <PrivateRoute
                  element={<BillingPersonal pageType={"account"} />}
                />
              ),
            },
          ],
        },
      ],
    },
  ]
  // { basename: `/${baseName}/` },
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </HelmetProvider>
);
