import React from "react";
import TitlePage from "../../titlePage/TitlePage";
import cl from "./TestimonialsComp.module.css";
import { TestimonialsSwiper } from "./TestimonialsSwiper";
import { testimonialsData } from "./TestimonialsData";

const TestimonialsContainer = () => {
  return (
    <div className="container_full bg_grey">
      <div className="testimonials">
        <div className="title">
          <TitlePage
            title={"Customer voices:  real results, [break] real stories"}
            lineBreak={0}
            size={"normal"}
            animation={true}
          />
        </div>
        <div className={cl.swipersBlock}>
          <div className={cl.overlay}></div>
          {/* FIRST SWIPER */}
          <TestimonialsSwiper
            dataReviews={testimonialsData.slice(0,6)}
            centeredSlides={true}
            className={cl.swiperTop}
          />

          {/* SECOND SWIPER */}
          <TestimonialsSwiper
            dataReviews={testimonialsData.slice(6,15)}
            className={cl.swiperBottom}
          />
        </div>
      </div>
    </div>
  );
};

export default TestimonialsContainer;
