import React from "react";

const PartnersContainer = () => {
  const partner_fox = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1017 430"
      width="1017"
      height="430"
    >
      <style></style>
      <g id="layer1">
        <g id="g1614">
          <path
            id="path991-0"
            fillRule="evenodd"
            d="M895.21 215.08l121.63 214.34-140.09.24-51.45-90.96-51.44 90.96-140.08-.24 121.63-214.34L633.78 0h139.38l52.14 91.9L877.46 0h139.38L895.21 215.08zM483.4.14c118.55 0 214.66 96.1 214.66 214.64 0 118.54-96.11 214.64-214.66 214.64-118.56 0-214.66-96.1-214.66-214.64C268.74 96.24 364.84.14 483.4.14zM521 126.53c0-20.11-17.43-38.12-37.01-38.12-19.6 0-35.44 18.01-35.44 38.12l-.04 175.83c0 20.13 15.88 36.43 35.47 36.43 19.59 0 37.02-15.79 37.02-35.91V126.53zM.02 429.42V0h267.03l7.87 117.13H130.01v56.2h118.18v117.5H130.01v138.59H.02z"
            fill="#000"
          />
        </g>
      </g>
    </svg>
  );
  const partner_digital_journal = (
    <svg
      width="150"
      height="62"
      viewBox="0 0 150 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_49)">
        <path d="M16.1125 0.214844H0V24.6895H16.1125V0.214844Z" fill="black" />
        <path
          d="M18.9244 0.380747H28.3471C29.9396 0.342385 31.5201 0.664992 32.9701 1.32438C34.2228 1.91106 35.289 2.83238 36.0512 3.98669C36.6774 5.08545 37.1185 6.27985 37.3565 7.52192C37.68 9.13947 37.8331 10.7864 37.8134 12.4359C37.83 14.0793 37.6742 15.7199 37.3484 17.3308C37.1111 18.5719 36.6972 19.7725 36.1192 20.896C35.3682 22.0525 34.3136 22.9801 33.0708 23.5773C31.6368 24.2388 30.0699 24.5617 28.4913 24.521H18.9244V22.8186H20.6023V2.08582H18.9244V0.380747ZM24.7738 22.8431H27.466C28.8602 22.8431 29.8573 22.6446 30.4574 22.2475C32.6166 20.8788 33.6971 17.5747 33.6989 12.3353C33.6989 7.27173 32.6628 4.08912 30.5907 2.78743C30.1705 2.51887 29.7023 2.33418 29.2119 2.24354C28.5759 2.12759 27.9303 2.07295 27.2838 2.08038H24.7738V22.8431Z"
          fill="black"
        />
        <path
          d="M38.5504 0.380615H46.7739V2.08569H44.8703V22.8185H46.7739V24.5235H38.5504V22.8185H40.6797V2.08569H38.5504V0.380615Z"
          fill="black"
        />
        <path
          d="M65.6384 20.8987C63.7675 23.6779 61.1197 25.024 57.695 24.937C53.8588 24.9044 51.1893 23.619 49.6864 21.0809V21.0972C48.4699 19.1882 47.8616 16.3527 47.8616 12.5909C47.8616 8.67493 48.5043 5.76335 49.7897 3.85614V3.87245C51.2237 1.32346 53.9422 0.0326458 57.9452 1.29099e-05C60.3798 -0.0504555 62.7534 0.764809 64.6431 2.30063L65.1734 6.55379H63.1964C63.2123 5.27 62.7297 4.03007 61.8503 3.0947C60.9638 2.16648 59.6675 1.69783 57.9615 1.68877C56.0561 1.66701 54.613 2.57348 53.6322 4.40818C52.6514 6.24287 52.123 8.9505 52.0468 12.5311C52.123 16.05 52.6324 18.7222 53.5751 20.5479C54.5178 22.3735 55.8966 23.28 57.7113 23.2673C58.4589 23.2562 59.1973 23.102 59.8869 22.8132C60.5139 22.5449 61.0773 22.1474 61.5403 21.6465V15.3402H59.1798V13.5971H67.0906V15.3157H65.6275L65.6384 20.8987Z"
          fill="black"
        />
        <path
          d="M67.5067 0.380615H75.7166V2.08569H73.813V22.8185H75.7166V24.5235H67.5067V22.8185H69.6332V2.08569H67.5067V0.380615Z"
          fill="black"
        />
        <path
          d="M94.4261 0.380615L95.057 4.76431H93.2758C93.0093 2.97856 92.3838 2.08569 91.3994 2.08569H87.7772V22.8185H89.4224V24.5235H81.8787V22.8185H83.6083V2.08569H80.0024C79.0161 2.08569 78.3897 2.97856 78.1232 4.76431H76.3284L76.9593 0.380615H94.4261Z"
          fill="black"
        />
        <path
          d="M93.2078 24.5235V22.8429H94.9863C96.1611 19.1047 97.3395 15.3591 98.5216 11.6063C99.7036 7.85356 100.874 4.11165 102.032 0.380615H103.882L111.123 22.8429H112.856V24.5317H105.655V22.8429H106.873L106.359 21.2385C106.194 20.72 106.028 20.2042 105.861 19.6912L105.361 18.1356C105.194 17.6117 105.023 17.0742 104.847 16.523H98.9866C98.8098 17.0669 98.6385 17.6108 98.478 18.1275C98.3176 18.6442 98.1571 19.1609 97.9967 19.6748C97.8363 20.1888 97.6758 20.7028 97.5154 21.2222C97.3549 21.7416 97.1863 22.28 97.0096 22.8538H98.2714V24.5426L93.2078 24.5235ZM104.325 14.7935C103.906 13.5353 103.496 12.2944 103.096 11.0706C102.695 9.84688 102.291 8.60411 101.883 7.34231C101.473 8.62043 101.08 9.85595 100.703 11.0489L99.5305 14.7935H104.325Z"
          fill="black"
        />
        <path
          d="M120.225 22.8185H128.169C129.155 22.8185 129.781 21.9193 130.048 20.1208H131.842L131.212 24.5235H114.376V22.8185H116.021V2.08569H114.343V0.380615H121.83V2.08569H120.225V22.8185Z"
          fill="black"
        />
        <path
          d="M22.3318 32.032H20.537V30.3269H28.2819V32.032H26.5197V52.3188C26.498 54.9439 26.0048 57.007 25.0404 58.5082C24.0414 60.0528 22.2965 60.952 19.8055 61.2058L19.074 59.615C21.2495 59.5388 22.3373 57.085 22.3373 52.2535L22.3318 32.032Z"
          fill="black"
        />
        <path
          d="M38.8169 29.93C45.0661 29.93 48.1907 34.0834 48.1907 42.3903C48.1907 50.6972 45.0715 54.8561 38.8332 54.867C32.5967 54.867 29.4784 50.7081 29.4784 42.3903C29.4784 34.0725 32.5912 29.9191 38.8169 29.93ZM38.8169 31.6513C35.3831 31.6513 33.6663 35.231 33.6663 42.3903C33.6663 49.5496 35.3886 53.1347 38.8332 53.1456C42.2669 53.1456 43.9846 49.5659 43.9865 42.4066C43.9883 35.2473 42.2651 31.6622 38.8169 31.6513Z"
          fill="black"
        />
        <path
          d="M63.161 30.3269H68.5998V32.032H66.8975V44.5086C66.8975 51.3597 64.2687 54.7853 59.0112 54.7853C55.8893 54.7853 53.6576 53.7755 52.316 51.7559C51.2282 50.0916 50.6844 47.6595 50.6844 44.4597V32.032H48.9711V30.3269H56.5012V32.032H54.8695V44.5984C54.8695 47.3812 55.0853 49.3066 55.5168 50.3744C56.1912 52.1602 57.6261 53.0539 59.8216 53.0557C63.1447 53.0557 64.8063 50.1877 64.8063 44.4515V32.032H63.161V30.3269Z"
          fill="black"
        />
        <path
          d="M76.2632 52.7566H78.0471V54.4616H70.3811V52.7566L72.059 52.7729V32.0238H70.3811V30.3187H80.5517C81.5364 30.3106 82.5169 30.448 83.4615 30.7266C84.3272 30.982 85.1463 31.3749 85.8872 31.8905C86.6448 32.4547 87.2664 33.1813 87.7065 34.0171C88.14 34.8698 88.3594 35.8153 88.3455 36.7719C88.3461 37.5193 88.1963 38.2591 87.905 38.9474C87.5996 39.6591 87.1757 40.3138 86.6513 40.8836C86.1642 41.3852 85.616 41.8238 85.0197 42.1889C84.4292 42.554 83.7877 42.8289 83.1161 43.0048C83.6177 44.6362 84.3396 46.1915 85.2617 47.6278C86.4081 49.4313 87.689 51.1458 89.0934 52.7566H90.8882V54.4616H85.5038C84.1782 52.7267 82.9629 50.9102 81.8652 49.0228C80.7963 47.1822 79.8471 45.2747 79.0234 43.312H76.2632V52.7566ZM76.2632 41.5553H80.4538C80.9384 41.5501 81.4109 41.4036 81.8135 41.1338C82.2954 40.8059 82.7145 40.3942 83.0508 39.9182C83.3719 39.4313 83.6365 38.9095 83.8395 38.3627C84.0279 37.8599 84.1264 37.3278 84.1304 36.7909C84.1527 35.5914 83.7694 34.4196 83.0427 33.4651C82.7015 33.0038 82.2549 32.6308 81.7402 32.3772C81.2255 32.1237 80.6576 31.9969 80.084 32.0075L76.2768 32.0238L76.2632 41.5553Z"
          fill="black"
        />
        <path
          d="M109.282 32.032V54.4698H107.006L95.9952 35.3904V52.7566H97.6269V54.4617H92.3839V52.7566H94.0617V32.032H92.3839V30.3269H97.7493L107.338 46.7413V32.032H105.706V30.3269H110.952V32.032H109.282Z"
          fill="black"
        />
        <path
          d="M111.909 54.4698V52.7811H113.688C114.862 49.0428 116.041 45.2982 117.223 41.5472C118.405 37.7962 119.583 34.0561 120.758 30.3269H122.602L129.849 52.7811H131.592V54.4698H124.356V52.7811H125.585L125.071 51.1766L124.573 49.6293C124.408 49.118 124.239 48.5986 124.073 48.0738L123.559 46.4612H117.677C117.496 47.0051 117.326 47.5399 117.169 48.0656C117.011 48.5914 116.85 49.1072 116.687 49.613L116.206 51.1603C116.046 51.6806 115.877 52.2245 115.697 52.792H116.962V54.4807L111.909 54.4698ZM123.026 44.7398C122.607 43.4834 122.198 42.2425 121.797 41.0169C121.396 39.7914 120.992 38.5504 120.584 37.294C120.174 38.574 119.781 39.8095 119.404 41.0006L118.224 44.7398H123.026Z"
          fill="black"
        />
        <path
          d="M138.383 52.7566H146.326C147.314 52.7566 147.941 51.8583 148.205 50.0617H150L149.366 54.4617H132.533V52.7566H134.178V32.032H132.501V30.3269H139.995V32.032H138.383V52.7566Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_49">
          <rect width="150" height="61.2058" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  const partner_tcj = (
    <svg
      width="500"
      height="180"
      viewBox="0 0 500 180"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1_2"
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="61"
        height="81"
      >
        <path
          d="M1.83856 1.33334H61.3334V81.3333H1.83856V1.33334Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1_2)">
        <path
          d="M12.2448 23.8542C12.2448 23.8542 14.8073 23.9271 14.7396 22.7188C14.6719 21.5052 13.099 22.3594 11.9584 22.2917C10.8177 22.2188 8.89065 20.7917 9.03648 17.5156C9.17711 14.2396 11.7448 12.6719 15.3073 12.2448C18.8698 11.8177 26.2865 13.6719 29.0677 14.5261C31.8438 15.3802 39.6875 18.1667 44.6771 18.0208C44.6771 21.5781 44.6771 38.1042 44.6771 38.1042L40.6823 42.026L44.6771 45.7292V72.7292C44.6771 72.7292 30.7761 74.6563 22.1511 63.1146C24.2188 61.7604 29.9948 59.0573 33.4167 54.2083C36.8386 49.3646 36.625 45.5885 36.625 42.8125C36.625 40.0313 36.4844 21.6563 36.4844 21.6563L23.6511 28.7031V29.7761C23.6511 29.7761 26.3594 30.2031 26.3594 32.9063C26.3594 35.6146 26.3594 53.4219 26.3594 53.4219C26.3594 53.4219 26.3594 56.7708 23.9375 58.7656C21.5156 60.7604 20.9427 61.2604 20.9427 61.2604C20.9427 61.2604 17.0938 56.6354 16.599 47.3698C16.099 38.1094 18.4531 29.5625 25.0052 21.724C26.2188 20.7292 25.9323 19.3021 23.9375 20.2969C21.9427 21.2917 10.0365 26.4948 6.0469 40.4583C2.05211 54.4219 8.68231 68.8125 14.7448 73.5156C20.8021 78.2188 29.8542 83.9167 45.9636 78.7865C53.7344 75.4375 59.9375 67.1771 59.9375 67.1771L58.6511 65.75C58.6511 65.75 53.1615 71.5885 46.8177 72.4479C46.9636 68.5313 46.8177 45.8021 46.8177 45.8021L50.9531 41.8125L46.9636 37.9635V18.4427C46.9636 18.4427 51.7344 19.8698 55.2292 16.4479C58.724 13.0313 60.3594 9.75001 60.8594 8.46876C61.3594 7.18751 60.9323 6.33334 59.3646 8.11459C57.7969 9.89584 55.7292 11.25 50.7396 10.4636C45.75 9.67709 34.6302 5.54688 29.9219 4.47918C25.2188 3.41147 18.0209 0.276052 8.82294 3.77084C3.97398 6.33334 0.411482 10.75 3.33336 19.8698C4.47398 22.3594 8.03648 24.4219 12.2448 23.8542Z"
          fill="black"
        />
      </g>
      <mask
        id="mask1_1_2"
        maskUnits="userSpaceOnUse"
        x="62"
        y="0"
        width="38"
        height="92"
      >
        <path d="M62.6667 0.994797H100V92H62.6667V0.994797Z" fill="white" />
      </mask>
      <g mask="url(#mask1_1_2)">
        <path
          d="M63.5833 5.8698C63.5833 5.8698 69.6823 5.38542 69.7344 12.1719C70.5365 10.5677 70.5365 9.125 71.2813 7.3073C72.0313 5.48959 74.2761 1.91146 79.1979 1.0573C81.6042 0.682297 80.4271 2.02084 79.1979 2.92709C77.9688 3.83334 75.8281 6.50521 75.8281 11.4219C75.8281 16.3385 75.8281 34.5573 75.8281 34.5573L84.5938 27.026L98.8177 35.3594C98.8177 35.3594 99.9896 35.7865 98.8177 36.6458C97.6406 37.5 97.4792 37.9271 97.4792 38.9948C97.4792 40.0625 97.4792 66.401 97.4792 66.401C97.4792 66.401 97.4271 77.625 93.7396 82.6458C90.0469 87.6719 85.7708 91.6771 75.2396 91.3021C74.3854 91.25 73.9531 89.9687 75.5052 89.8073C77.0573 89.6458 84.7552 87.9896 86.4115 80.8021C87.6406 75.2188 87.3229 69.9323 87.3229 68.0104C87.3229 66.0833 87.3229 40.1719 87.3229 40.1719L79.3021 34.5625L75.8802 37.1823V70.625L80.2136 72.7083L72.9427 81.099L62.6771 73.5677L65.6198 71.2188V11.3229C65.6198 11.3177 65.6719 6.82813 63.5833 5.8698Z"
          fill="black"
        />
      </g>
      <mask
        id="mask2_1_2"
        maskUnits="userSpaceOnUse"
        x="221"
        y="0"
        width="38"
        height="92"
      >
        <path d="M221.333 0.994797H258.667V92H221.333V0.994797Z" fill="white" />
      </mask>
      <g mask="url(#mask2_1_2)">
        <path
          d="M222.62 5.8698C222.62 5.8698 228.714 5.38542 228.766 12.1719C229.568 10.5677 229.568 9.125 230.318 7.3073C231.062 5.48959 233.307 1.91146 238.229 1.0573C240.635 0.682297 239.458 2.02084 238.229 2.92709C237 3.83334 234.859 6.50521 234.859 11.4219C234.859 16.3385 234.859 34.5573 234.859 34.5573L243.63 27.026L257.849 35.3594C257.849 35.3594 259.026 35.7865 257.849 36.6458C256.672 37.5 256.51 37.9271 256.51 38.9948C256.51 40.0625 256.51 66.401 256.51 66.401C256.51 66.401 256.458 77.625 252.771 82.6458C249.083 87.6719 244.807 91.6771 234.271 91.3021C233.417 91.25 232.99 89.9687 234.542 89.8073C236.089 89.6458 243.786 87.9896 245.448 80.8021C246.677 75.2188 246.354 69.9323 246.354 68.0104C246.354 66.0833 246.354 40.1719 246.354 40.1719L238.333 34.5625L234.917 37.1823V70.625L239.245 72.7083L231.974 81.099L221.708 73.5677L224.651 71.2188V11.3229C224.651 11.3177 224.703 6.82813 222.62 5.8698Z"
          fill="black"
        />
      </g>
      <path
        d="M126.885 73.1354L116.833 67.526V56.7708C121.448 53.3594 136.094 42.5312 137.844 41.1823C136.188 40.1667 129.021 31.8854 125.547 27.2344C119.448 31.9375 104.964 40.0052 104.964 40.0052L107.047 41.8229V69.3958L104.964 71.3177L122.016 81.0417L134.844 67.5781L133.563 66.2969L126.885 73.1354ZM117.958 33.8125L128.115 45.724L116.833 54.3646V34.5052L117.958 33.8125Z"
        fill="black"
      />
      <path
        d="M486.208 73.3542L476.156 67.7448V56.9948C480.771 53.5833 495.411 42.75 497.167 41.4011C495.505 40.3906 488.344 32.1042 484.87 27.4583C478.771 32.1615 464.286 40.2292 464.286 40.2292L466.37 42.0469V69.6146L464.286 71.5365L481.339 81.2656L494.167 67.7969L492.885 66.5156L486.208 73.3542ZM477.281 34.0313L487.437 45.9427L476.156 54.5833V34.7292L477.281 34.0313Z"
        fill="black"
      />
      <path
        d="M259.901 37.1771L261.396 38.6198L264.5 35.625L269.255 39.3125V70.4635L267.437 72.2812L280.964 81.099L290.266 69.2865L288.719 68.4323L285.99 71.9583L279.521 69.7656V37.3385L280.802 36.1094L287.807 43.375L294.917 35.625L286.417 26.7031L278.823 35.4687L269.365 26.7031L259.901 37.1771Z"
        fill="black"
      />
      <path
        d="M329.776 63.5677C329.776 63.5677 329.776 42.8958 329.776 40.9167C329.776 38.9375 331.328 38.0313 332.234 37.4427C324.219 33.5937 318.922 29 316.677 26.7031C304.807 37.6562 296.203 39.9531 296.203 39.9531C296.203 39.9531 296.203 39.9531 296.203 40.8594C298.286 41.1823 298.234 43.375 298.234 43.375C298.234 43.375 298.339 67.099 298.339 68.0573C298.339 69.0208 297.271 69.9844 295.932 70.7292C296.896 71.1042 307.802 78.5312 311.276 81.0937C316.464 75.7552 330.792 67.099 332.234 66.2969C329.724 65.4896 329.776 63.5677 329.776 63.5677ZM320.047 72.2969L319.083 72.901L308.464 68.0937V35.4687L309.458 34.75C309.458 34.75 314.984 38.9896 320.047 40.5937C320.01 46.3646 320.047 72.2969 320.047 72.2969Z"
        fill="black"
      />
      <path
        d="M333.896 37.2292L335.068 38.5625L338.385 35.5156L342.177 39.0469V70.9948L339.932 73.4531L350.573 81.0938L358.005 72.901L352.229 69.8229V37.7083L356.719 34.3958L364.578 39.849V70.5156L362.016 72.4375L373.401 81.0885L383.292 70.6719L381.958 69.3906L379.177 72.3802L374.63 69.7083V38.2396L377.12 35.8125C377.12 35.8125 366.078 30.0156 362.068 26.6979C358.917 29.3125 352.073 34.9792 352.073 34.9792L343.516 26.9635C343.516 26.9688 338.865 32.3698 333.896 37.2292Z"
        fill="black"
      />
      <path
        d="M392.828 2.69792C392.828 2.69792 398.458 7.96875 400.526 9.53646C398.745 11.3177 392.828 16.875 392.828 16.875L385.411 9.78646L392.828 2.69792Z"
        fill="black"
      />
      <path
        d="M380.51 37.5L382.328 39.0469L385.109 36.1094L387.839 38.2969V70.25L385.698 72.4427L397.089 81.0937L406.766 69.6094L405.214 68.3281L402.432 71.6927L397.833 69.0208V36.4271L399.865 34.3958L390.401 26.7031L380.51 37.5Z"
        fill="black"
      />
      <path
        d="M408.365 40.7396V39.8854C408.365 39.8854 420.307 34.7552 428.859 26.7031C431.891 31.1198 437.771 37.9583 437.771 37.9583L429.823 44.6927C429.823 44.6927 424.443 38.5312 421.979 34.2917C420.948 35.0781 420.948 35.0781 420.948 35.0781V67.276L431.464 71.8698L437.203 66.099L438.734 67.6302L425.724 81.099L408.37 71.125V70.5521L410.792 69.0937V42.2031C410.792 42.1979 409.974 41.1667 408.365 40.7396Z"
        fill="black"
      />
      <path
        d="M440.802 5.93751L441.229 5.51042C441.229 5.51042 446.432 6.82813 447.286 12.1719C447.573 9.50001 450.172 2.76563 456.661 1.59376C458.406 1.41147 457.302 2.23438 456.661 2.76563C456.016 3.30209 452.807 7.71876 452.807 12.1719C452.807 16.625 452.807 69.9271 452.807 69.9271L457.599 72.224L460.385 68.7552L462.203 69.9844L453.057 81.2656L440.438 73.401V72.599L442.901 70.6198V12.5417C442.901 12.5417 443.026 7.60938 440.802 5.93751Z"
        fill="black"
      />
      <path
        d="M204.724 71.9583V46.1615L208.578 42.0938L204.563 38.0313V20.1042C206.406 20.8229 208.354 21.2813 210.125 21.151C215.255 20.776 220.229 13.8854 220.229 13.8854C220.229 13.8854 220.229 13.8854 218.891 12.5469C216.167 15.3281 214.292 14.4219 212.797 13.4583C211.302 12.4948 206.917 9.02084 204.885 6.9948C199.646 11.4792 181.521 23.2865 181.521 23.2865C181.521 23.2865 181.464 23.2865 181.521 24.0885C183.927 23.9323 184.245 26.5521 184.245 26.5521C184.245 26.5521 184.245 44.9844 184.245 53.0521C184.245 61.1198 179.703 63.526 177.188 64.6979C174.625 61.5469 172.646 57.5938 171.469 52.5677C170.297 47.5469 170.297 40.3854 171.953 31.4635C173.771 22.1146 183.021 14.2031 183.021 14.2031C183.021 14.2031 183.021 14.2031 182.271 13.1354C179.865 13.724 173.021 17.25 167.089 23.5052C161.151 29.7552 158.375 38.4115 158.104 43.4844C157.839 48.5625 157.891 57.2708 162.807 65.7135C167.729 74.1563 178.74 81.849 193.818 80.9375C208.891 80.0313 218.516 65.6563 218.516 65.6563C218.516 65.6563 218.516 65.6563 217.661 64.4844C213.802 67.4896 210.005 70.3802 204.724 71.9583ZM178.849 66.2969C178.849 66.2969 183.021 64.6927 188.63 61.4844C194.245 58.2813 194.141 52.5104 194.141 52.5104V17.5156L197.135 15.3229C197.776 16.1667 199.781 17.7396 202.266 19.0417V38.1927L198.417 42.1979L202.266 46.1615V72.5729C200.193 72.9896 197.901 73.2135 195.318 73.1875C184.464 73.0833 178.849 66.2969 178.849 66.2969Z"
        fill="black"
      />
      <path
        d="M249.917 120.703L251.479 122.214L254.714 119.083L259.677 122.937V155.516L257.781 157.417L271.896 166.641L281.604 154.292L279.984 153.391L277.141 157.083L270.391 154.792V120.87L271.729 119.589L279.036 127.187L286.458 119.083L277.589 109.75L269.667 118.917L259.792 109.75L249.917 120.703Z"
        fill="black"
      />
      <path
        d="M199.651 148.385C199.651 148.385 199.651 126.76 199.651 124.693C199.651 122.625 201.271 121.672 202.219 121.057C193.849 117.036 188.328 112.229 185.984 109.828C173.599 121.281 164.62 123.682 164.62 123.682C164.62 123.682 164.62 123.682 164.62 124.635C166.792 124.969 166.74 127.26 166.74 127.26C166.74 127.26 166.849 152.073 166.849 153.078C166.849 154.083 165.734 155.089 164.339 155.875C165.344 156.26 176.724 164.031 180.349 166.714C185.76 161.125 200.714 152.073 202.219 151.234C199.594 150.396 199.651 148.385 199.651 148.385ZM189.5 157.51L188.495 158.141L177.411 153.115V118.99L178.453 118.245C178.453 118.245 184.219 122.677 189.5 124.354C189.458 130.391 189.5 157.51 189.5 157.51Z"
        fill="black"
      />
      <path
        d="M254.547 155.703L253.323 154.307L249.865 157.495L245.901 153.807V120.385L248.245 117.818L237.141 109.828L229.385 118.396L235.411 121.615V155.203L230.729 158.667L222.526 152.964V120.891L225.203 118.88L213.323 109.828L203 120.724L204.396 122.068L207.297 118.943L212.036 121.734V154.646L209.437 157.187C209.437 157.187 220.964 163.25 225.146 166.719C228.437 163.984 235.578 158.057 235.578 158.057L244.505 166.437C244.505 166.432 249.359 160.792 254.547 155.703Z"
        fill="black"
      />
      <path
        d="M286.458 120.833L287.682 122.229L291.141 119.047L295.104 122.734V156.151L292.76 158.724L303.865 166.714L311.615 158.141L305.594 154.922V121.339L310.276 117.875L318.479 123.573V155.646L315.797 157.656L327.682 166.708L338.005 155.812L336.609 154.469L333.708 157.599L328.964 154.807V121.891L331.563 119.354C331.563 119.354 320.042 113.286 315.854 109.823C312.568 112.557 305.422 118.479 305.422 118.479L296.495 110.099C296.495 110.104 291.646 115.75 286.458 120.833Z"
        fill="black"
      />
      <mask
        id="mask3_1_2"
        maskUnits="userSpaceOnUse"
        x="377"
        y="83"
        width="25"
        height="85"
      >
        <path d="M377.333 83.0104H401.333V168H377.333V83.0104Z" fill="white" />
      </mask>
      <g mask="url(#mask3_1_2)">
        <path
          d="M378.875 88.1823L379.323 87.7344C379.323 87.7344 384.75 89.1146 385.646 94.7031C385.943 91.9114 388.656 84.8698 395.427 83.6406C397.245 83.4531 396.094 84.3125 395.427 84.8698C394.755 85.4271 391.406 90.0469 391.406 94.7031C391.406 99.3594 391.406 155.109 391.406 155.109L396.406 157.51L399.312 153.88L401.208 155.167L391.667 166.964L378.5 158.74V157.901L381.068 155.833V95.0937C381.068 95.0937 381.203 89.9323 378.875 88.1823Z"
          fill="black"
        />
      </g>
      <path
        d="M377.312 153.344L373.896 157.141L369.062 154.234V121.823C369.062 121.823 369.062 121.823 371.292 119.292C358.797 114.75 350.615 109.828 350.615 109.828L340.276 122.87L341.911 124.286L346 119.141L358.422 123.318L358.437 130.859C355.594 131.917 350.417 134.089 346.667 136.208C342.057 138.818 337.297 141.719 336.703 151.406C336.109 161.094 344.214 166.641 344.214 166.641L358.719 158.562L368.755 166.641L378.5 154.615L377.312 153.344ZM352.844 159.599C347.635 156.099 346.37 148.87 347.562 142.906C349.01 137.432 355.365 134.302 358.443 133.078L358.495 156.771C358.495 156.771 358.495 156.771 352.844 159.599Z"
        fill="black"
      />
      <path
        d="M162.833 91.8542C162.052 90.7344 162.052 90.7344 162.052 90.7344C162.052 90.7344 156.917 95.0937 150.057 93.5833C143.198 92.0781 132.099 88.3906 126.125 88.1667C120.156 87.9375 113.797 89.6198 112.344 97.3281C111.453 102.526 113.906 106.828 120.49 108.562L121.547 107.5C121.547 107.5 118.646 105.432 118.536 102.526C118.422 99.6198 120.766 97.6667 123.557 97.5521C126.344 97.4375 129.635 98.724 131.531 99.2812C131.958 99.4115 132.802 99.75 133.932 100.146V120.182L129.693 124.484L133.932 128.453V154.417C130.859 152.687 126.958 151.312 122.219 151.198C112.901 150.974 105.089 153.99 103.417 163.156C102.135 171.203 106.656 176.51 111.172 177.797C112.063 176.74 112.063 176.74 112.063 176.74C112.063 176.74 108.714 175.062 108.771 170.365C108.828 165.672 111.505 162.26 116.917 161.146C122.323 160.026 128.854 161.37 133.151 167.182C138.167 163.323 144.755 158.797 148.771 155.443C152.453 152.26 157.302 146.896 157.302 140.859C157.302 134.823 157.302 104.536 157.302 104.536C157.302 104.536 156.755 96.8281 162.833 91.8542ZM156.474 96.3802C155.188 97.776 144.422 107.474 144.422 118.245C144.422 129.016 144.422 150.807 144.422 150.807C144.422 150.807 144.646 157.682 140.516 159.635C139.49 158.526 138.063 157.172 136.229 155.875V128.453L140.406 124.26L136.219 120.068V100.87C140.292 102.042 146.052 102.906 150.385 99.7812C156.469 95.5885 156.469 95.5885 156.469 95.5885C156.469 95.5885 157.755 94.9844 156.474 96.3802Z"
        fill="black"
      />
    </svg>
  );

  const partner_bcb = (
    <svg
      width="150"
      height="90"
      viewBox="0 0 150 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_46)">
        <path
          d="M144.429 89.1065H5.57141C2.49166 89.1065 0 86.6097 0 83.5351V5.57141C0 2.49166 2.49682 0 5.57141 0H144.429C147.508 0 150 2.49682 150 5.57141V83.5351C150.005 86.6149 147.508 89.1065 144.429 89.1065Z"
          fill="black"
        />
        <path
          d="M142.602 76.1839C142.602 76.1839 141.4 73.8883 140.09 72.1395C138.78 70.3907 137.794 67.7701 137.248 65.9129C136.701 64.0558 134.627 62.4153 134.844 59.903C135.06 57.3907 134.514 47.6562 132.223 45.0923C130.913 43.8439 130.696 42.5285 130.804 41.1098C130.913 39.6912 130.696 36.5237 129.277 34.8832C127.859 33.2428 126.873 31.0606 127.09 25.9226C124.795 25.9226 123.593 25.9226 123.593 25.9226C123.593 25.9226 121.189 21.223 118.785 20.898C116.381 20.5678 114.962 20.3511 114.307 19.1492C113.652 17.9472 112.45 18.819 110.809 19.1492C109.169 19.4793 108.627 19.0099 107.75 18.5353C106.878 18.0555 104.583 18.2773 103.927 19.5876C103.272 20.898 101.745 21.6615 101.085 20.0261C100.43 18.3857 98.4644 18.494 97.2624 17.7305C96.0605 16.967 94.6418 15.5432 94.6418 15.5432H11.4008V56.5086H20.3614V27.2329H31.8293V32.3658C31.8293 32.3658 44.3908 19.4742 56.6272 32.1491C51.711 35.4249 42.6419 53.7796 59.7947 66.0161C59.7947 68.4201 59.7947 68.4201 59.7947 68.4201V68.7502H47.3828V44.1689C47.3828 44.1689 46.4749 37.7257 40.465 37.6122C35.0019 37.7205 32.7063 43.1836 32.7063 45.8042C32.7063 48.4249 32.7063 56.5086 32.7063 56.5086H41.3368V76.1736H142.602V76.1839ZM67.8835 39.2578C76.0756 35.8737 80.6668 42.8638 80.6668 42.8638H93.8835C92.4648 33.5781 83.3958 28.6618 83.3958 28.6618V27.0214L95.3021 26.913V30.9523C95.3021 30.9523 95.3021 30.9523 96.0656 30.9523C96.7208 29.642 102.731 25.711 109.721 26.2527C121.519 27.6714 122.937 40.6713 122.937 40.6713V68.7451H110.376C110.376 68.7451 110.376 47.9864 110.376 46.1292C110.376 44.2721 109.829 37.607 103.386 37.607C96.174 38.0455 96.0656 45.082 96.0656 45.082V68.7399H83.3906C83.3906 68.7399 83.3906 68.7399 83.3906 67.2078C92.568 62.6217 93.8783 54.3161 93.8783 54.3161C93.8783 54.3161 83.8291 54.2078 79.568 54.2078C73.6665 60.1094 66.3514 56.6118 63.9474 52.6808C61.4352 48.105 63.2923 41.5535 67.8835 39.2578Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_46">
          <rect width="150" height="89.1065" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  return (
    <div className="container" style={{display: 'none'}}>
      <div className="partners">
        <div>
          {/* eslint-disable */}
          <a
            href="https://www.wicz.com/story/49216554/introducing-paintitai-revolutionizing-interior-design-with-aigenerated-visualizations"
            rel="nofollow"
            target="_blank"
          >
            {partner_fox}
          </a>
        </div>
        <div>
          <a
            href="https://digitaljournal.com/pr/news/getnews/introducing-paintit-ai-revolutionizing-interior-design-with-ai-generated-visualizations"
            rel="nofollow"
            target="_blank"
          >
            {partner_digital_journal}
          </a>
        </div>
        <div>
          <a
            href="https://www.newschannelnebraska.com/story/49216554/introducing-paintitai-revolutionizing-interior-design-with-aigenerated-visualizations"
            rel="nofollow"
            target="_blank"
          >
            {partner_bcb}
          </a>
        </div>
        <div>
          <a
            href="http://markets.chroniclejournal.com/chroniclejournal/article/getnews-2023-7-19-introducing-paintitai-revolutionizing-interior-design-with-ai-generated-visualizations/"
            rel="nofollow"
            target="_blank"
          >
            {partner_tcj}
          </a>
        </div>
      </div>
    </div>
  );
};

export default PartnersContainer;
