import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from 'axios';

const initialState = {
  hastagArr: {
    modeoptions: { name: "", hashtag: "", id: "" },
    roomtype: { name: "", hashtag: "", id: "" },
    roomdesignstyle: { name: "", hashtag: "", id: "" },
    colorpalette: { name: "", hashtag: "", id: "" },
    additionaltags: { name: "", hashtag: "", id: "" },
  },
  hastagArr2: [
    { type: "modeoptions", name: "", hashtag: "", id: "" },
    { type: "roomtype", name: "", hashtag: "", id: "" },
    { type: "roomdesignstyle", name: "", hashtag: "", id: "" },
    { type: "colorpalette", name: "", hashtag: "", id: "" },
    { type: "additionaltags", name: "", hashtag: "", id: "" },
  ],
};

export const uploadImage = createAsyncThunk(
  "uploadImage/uploadImage",
  async (image, { rejectWithValue, dispatch, getState }) => {
    dispatch(setProgressUpload(image));
  }
);

export const roomTypeSlice = createSlice({
  name: "roomType",
  initialState,
  reducers: {
    setHashtags: (state, action) => {
      state.hastagArr[action.payload[0]].name = action.payload[1];
      state.hastagArr[action.payload[0]].hashtag = action.payload[2];
      state.hastagArr[action.payload[0]].id = action.payload[3]
        ? action.payload[3]
        : "";
      state.hastagArr2.map((val) => {
        if (val.type === action.payload[0]) {
          val.name = action.payload[1];
          val.hashtag = action.payload[1];
          if (action.payload[3]) {
            val.id = action.payload[3] ? action.payload[3] : "";
          }
          return "";
        } else {
          return "";
        }
      });
    },
    removeHashtag: (state, action) => {
      state.hastagArr = {
        ...state.hastagArr,
        [action.payload]: { name: "", hashtag: "", id: "" },
      };
      state.hastagArr2 = state.hastagArr2.map((e) => {
        if (e.type === action.payload) {
          return { ...e, name: "", hashtag: "", id: "" };
        }
        return e;
      });
    },
    removeHashtags: (state, action) => {
      state.hastagArr = {
        modeoptions: { name: "", hashtag: "" },
        roomtype: { name: "", hashtag: "" },
        roomdesignstyle: { name: "", hashtag: "" },
        colorpalette: { name: "", hashtag: "" },
        additionaltags: { name: "", hashtag: "", id: "" },
      };
      state.hastagArr2 = [
        { type: "modeoptions", name: "", hashtag: "" },
        { type: "roomtype", name: "", hashtag: "" },
        { type: "roomdesignstyle", name: "", hashtag: "" },
        { type: "colorpalette", name: "", hashtag: "" },
        { type: "additionaltags", name: "", hashtag: "", id: "" },
      ];
    },
    setProgressUpload: (state, action) => {
      state.hastagArr.modeoptions.name = action.payload;
    },
  },
});

export const { setHashtags, removeHashtags, removeHashtag, setProgressUpload } =
  roomTypeSlice.actions;
export default roomTypeSlice.reducer;
