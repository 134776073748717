import { Outlet, useSearchParams } from "react-router-dom";
import Menu from "../components/menu/Menu";
import Footer from "../components/footer/Footer";
import ProjectModal from "../components/modal/DrawModal/ProjectModal/ProjectModal";
import AuthorizationModal from "../components/modal/authorization/AuthorizationModal";
import { useSelector } from "react-redux";

export default function Main() {
  // const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  let idProject = searchParams.get("id");
  const authModal = useSelector(
    (state) => state.authorizationModal.isActiveAuthModal
  );

  return (
    <>
    
      <Menu />
      <Outlet />
      {idProject && <ProjectModal idModal={idProject} created={true} />}
      <Footer />
      {authModal && <AuthorizationModal />}
    </>
  );
}
