import { useEffect, useRef, useState } from 'react';
import './filter.css';
import RoomDesignIcon from '../icon/type/RoomDesignIcon';
import StyleIcon from '../icon/type/StyleIcon';
import RoomTypeIcon from '../icon/type/RoomTypeIcon';
import CloseModalIcon from '../icon/CloseModalIcon';
import Select, { components } from 'react-select';
import { useDispatch } from 'react-redux';
import {
  getHomePortfolio,
  getUserPortfolio,
  resetFilterTags,
  setFilterTags,
  setPaginationNumPage,
} from '../../toolkit/slices/account/portfolioUserSlice';

let stateOptions = [];

const selectStyles = {
  control: (provided) => ({
    ...provided,
    flexDirection: 'row-reverse',
    margin: 26,
    marginBottom: 0,
    border: 0,
    borderRadius: 0,
    borderBottom: '1px solid rgb(0 0 0 / 30%)',
    borderColor: 'rgb(0 0 0 / 30%)',
    boxShadow: '0 0 0 0',
    ':hover': {
      boxShadow: '0 0 0 0',
      borderColor: 'rgb(0 0 0 / 30%)',
    },
  }),
  IndicatorsContainer: (base) => ({
    ...base,
    padding: 0,
  }),
  menu: () => ({
    // boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)'
  }),
  container: () => ({ marginBottom: 24 }),
  option: (base) => ({
    justifyContent: 'space-between',
    textTransform: 'uppercase',
    lineHeight: '100%',
    '&:hover': {
      backgroundColor: 'transparent  !important',
    },
  }),
  menuList: (base) => ({
    ...base,

    '::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },
    '::-webkit-scrollbar-track': {
      background: '#E2E2E2',
      borderRadius: 50,
    },
    '::-webkit-scrollbar-thumb': {
      background: '#000',
      borderRadius: 50,
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#000',
    },
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    paddingLeft: 26,
    paddingRight: 26,
    marginTop: 24,
  }),
};

const FilterDropDown = ({ type, typeVal, optionFilter, setTrue, setFalse, isReset, pageType }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (isOpen && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isOpen]);

  useEffect(() => {
    if (isReset) {
      setValue([]);
    }
  }, [isReset]);

  const [value, setValue] = useState([]);

  stateOptions = optionFilter;

  const dispatch = useDispatch();

  // const submitHandle = useCallback(() => {
  //   if (pageType === 'account') {
  //     dispatch(getUserPortfolio());
  //     setIsOpen(false);
  //   } else {
  //     dispatch(getHomePortfolio());
  //     setIsOpen(false);
  //   }
  // }, [dispatch, pageType]);

  const cancelHandle = () => {
    dispatch(resetFilterTags(type));
    setIsOpen(false);
    setValue([]);
  };

  const cancelAllHandle = () => {
    if (pageType === 'account') {
      dispatch(resetFilterTags());
      setTrue();
      dispatch(setPaginationNumPage(0));
      dispatch(getUserPortfolio());
    } else {
      dispatch(resetFilterTags());
      setTrue();
      dispatch(setPaginationNumPage(0));
      dispatch(getHomePortfolio());
    }
  };

  const selectHandler = (newValue) => {
    console.log('newValue :>> ', newValue);
    setValue(newValue);
    dispatch(setFilterTags([newValue, type]));
    dispatch(setPaginationNumPage(0));
    if (pageType === 'common') {
      dispatch(getHomePortfolio());
    } else {
      dispatch(getUserPortfolio());
    }

    setIsOpen(false);
  };

  if (type !== 'reset') {
    return (
      <Dropdown
        className="relative"
        isOpen={isOpen}
        dropdownRef={dropdownRef}
        onClose={() => {
          setIsOpen(false);
        }}
        target={
          <div className={value.length ? 'inputButton active' : 'inputButton '}>
            <i
              onClick={() => {
                setIsOpen((prev) => !prev);
                setFalse();
              }}>
              {type === 'type' ? (
                <>
                  <RoomTypeIcon />
                </>
              ) : type === 'design' ? (
                <RoomDesignIcon />
              ) : (
                type === 'style' && <StyleIcon />
              )}
            </i>
            {value && (
              <div
                className="countTypes"
                onClick={() => {
                  setIsOpen((prev) => !prev);
                  setFalse();
                }}>
                {type}
              </div>
            )}
            {value.length !== 0 && <div className="count">1</div>}
            {value.length !== 0 && (
              <button className="close" onClick={() => cancelHandle()}>
                <CloseModalIcon />
              </button>
            )}
          </div>
        }>
        <Select
          autoFocus
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          // isMulti
          backspaceRemovesValue={false}
          components={{
            IndicatorSeparator: null,
            Option: InputOption,
            IndicatorsContainer: InputIcon,
          }}
          controlShouldRenderValue={false}
          isClearable={false}
          menuIsOpen
          onChange={(newValue) => {
            selectHandler(newValue);
          }}
          options={stateOptions}
          placeholder={`Search ${type}`}
          styles={selectStyles}
          tabSelectsValue={false}
          value={value}
        />
        {/* <div className="buttonsFilterBlock">
          <button className="button green" onClick={() => submitHandle()}>
            Apply
          </button>
          <button className="button transparent" onClick={() => cancelHandle()}>
            Reset
          </button>
        </div> */}
      </Dropdown>
    );
  } else {
    return (
      <>
        <button
          onClick={() => {
            cancelAllHandle();
          }}
          className="resetFilter">
          Reset all filters
        </button>
      </>
    );
  }
};

const InputIcon = (props) => {
  return (
    <components.IndicatorsContainer {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none">
        <path
          d="M12.2501 12.2501L9.7167 9.7167M9.7167 9.7167C10.1501 9.28335 10.4938 8.76889 10.7283 8.20269C10.9629 7.63649 11.0836 7.02964 11.0836 6.41679C11.0836 5.80394 10.9629 5.19709 10.7283 4.63088C10.4938 4.06468 10.1501 3.55022 9.7167 3.11687C9.28335 2.68352 8.76889 2.33977 8.20269 2.10524C7.63649 1.87071 7.02964 1.75 6.41679 1.75C5.80394 1.75 5.19709 1.87071 4.63088 2.10524C4.06468 2.33977 3.55022 2.68352 3.11687 3.11687C2.24168 3.99206 1.75 5.17908 1.75 6.41679C1.75 7.6545 2.24168 8.84151 3.11687 9.7167C3.99206 10.5919 5.17908 11.0836 6.41679 11.0836C7.6545 11.0836 8.84151 10.5919 9.7167 9.7167Z"
          stroke="#808080"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </components.IndicatorsContainer>
  );
};

const Menu = (props) => {
  return <div className="Menu" {...props} />;
};

const Dropdown = ({ children, isOpen, target, onClose, dropdownRef }) => (
  <div className={isOpen ? 'Dropdown active' : 'Dropdown'} ref={dropdownRef}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
  </div>
);

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  // const [isActive, setIsActive] = useState(false);
  // const onMouseDown = () => setIsActive(true);
  // const onMouseUp = () => setIsActive(false);
  // const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = 'transparent';

  const style = {
    alignItems: 'center',
    backgroundColor: bg,
    color: 'inherit',
    display: 'flex ',
  };

  // prop assignment
  const props = {
    ...innerProps,
    // onMouseDown,
    // onMouseUp,
    // onMouseLeave,
    style,
  };

  return (
    <>
      <components.Option
        {...rest}
        className="optionSelect"
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}>
        {children}
        <input
          className="customCheckBox"
          type="checkbox"
          checked={isSelected}
          onChange={() => {
            // setIsActive(true);
          }}
        />
      </components.Option>
    </>
  );
};

export default FilterDropDown;
