import React from 'react';

const TickIcon = () => {
  return (
    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.50156 5.2001L7.92656 0.775097C8.06406 0.637597 8.23906 0.568848 8.45156 0.568848C8.66406 0.568848 8.83906 0.637597 8.97656 0.775097C9.11406 0.912597 9.18281 1.0876 9.18281 1.3001C9.18281 1.5126 9.11406 1.6876 8.97656 1.8251L4.02656 6.7751C3.87656 6.9251 3.70156 7.0001 3.50156 7.0001C3.30156 7.0001 3.12656 6.9251 2.97656 6.7751L1.02656 4.8251C0.889062 4.6876 0.820312 4.5126 0.820312 4.3001C0.820312 4.0876 0.889062 3.9126 1.02656 3.7751C1.16406 3.6376 1.33906 3.56885 1.55156 3.56885C1.76406 3.56885 1.93906 3.6376 2.07656 3.7751L3.50156 5.2001Z"
        fill="white"
      />
    </svg>
  );
};

export default TickIcon;
