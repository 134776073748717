import React from 'react';
import Menu from '../menu/Menu';
import TitlePage from '../titlePage/TitlePage';
import Button from '../button/Button';
import './error404Grid.css';

const Error404Grid = ({isError}) => {
  return (
    <div className="errorContent">
      <Menu />
      <div className="inner">
        <div className="afterImg">
          <TitlePage title={isError ? '500' : '404'} lineBreak={3} size={'normal'} animation={true} />
          <TitlePage
            title={isError ? "Oops, something went wrong, [break] please try again later!" : "The page you are [break] looking for doesn't exist"}
            lineBreak={3}
            size={'normal'}
            animation={true}
          />
          <div className="buttonBlock">
            <Button link={'/'} color={'green'} text={'Go to Home page'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error404Grid;
