export const PrevCanvasIcon = ({rev, ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="6"
    viewBox="0 0 16 6"
    fill="none"
    style={{position:'relative'}}
    {...props}
  >
    {rev ? (
      <g style={{transform: "scale(-1, 1) translate(-100%, 0)"}}>
        <path
          d="M8.375 0C6.3875 0 4.5875 0.7425 3.2 1.95L1.7825 0.5325C1.31 0.06 0.5 0.39 0.5 1.0575V5.25C0.5 5.6625 0.8375 6 1.25 6H5.4425C6.11 6 6.4475 5.19 5.975 4.7175L4.5425 3.285C5.585 2.415 6.9125 1.875 8.3825 1.875C10.7525 1.875 12.8 3.255 13.775 5.25C13.9775 5.67 14.4575 5.88 14.9 5.73C15.4325 5.5575 15.7025 4.95 15.4625 4.44C14.1725 1.815 11.4875 0 8.375 0Z"
          fill="#171D24"
        />
      </g>
    ) : (
      <path
        d="M8.375 0C6.3875 0 4.5875 0.7425 3.2 1.95L1.7825 0.5325C1.31 0.06 0.5 0.39 0.5 1.0575V5.25C0.5 5.6625 0.8375 6 1.25 6H5.4425C6.11 6 6.4475 5.19 5.975 4.7175L4.5425 3.285C5.585 2.415 6.9125 1.875 8.3825 1.875C10.7525 1.875 12.8 3.255 13.775 5.25C13.9775 5.67 14.4575 5.88 14.9 5.73C15.4325 5.5575 15.7025 4.95 15.4625 4.44C14.1725 1.815 11.4875 0 8.375 0Z"
        fill="#171D24"
      />
    )}
  </svg>
);
