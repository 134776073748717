import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setActiveModalName } from './authorizationModalSlice';

const initialState = {
  errorMessage: '',
  uid: '',
  token: '',
};

export const postResetPassword = createAsyncThunk(
  'postResetPassword/postResetPassword',
  async (data, { dispatch }) => {
    await axios
      .post(`${process.env.REACT_APP_BACKEND_HOST}/api/v1/rest-auth/password/reset/`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        // console.log('response postResetPassword:>> ', response.data);
        dispatch(setErrorMessage(''));
        dispatch(setActiveModalName('Submit change password'));
      })
      .catch(function (error) {
        dispatch(setErrorMessage(error.response.data.email[0]));
        console.log(error.response.data.email[0]);
      });
  },
);

export const postResetPasswordConfirm = createAsyncThunk(
  'postResetPasswordConfirm/postResetPasswordConfirm',
  async (data, { dispatch, getState }) => {
    // console.log('data :>> ', data);
    console.log('uid :>> ', getState().resetPassword.uid);
    let newData = {
      uid: getState().resetPassword.uid,
      token: getState().resetPassword.token,
      new_password1: data.new_password1,
      new_password2: data.new_password2,
    };
    // console.log('newData :>> ', newData);
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/rest-auth/password/reset/confirm/`,
        newData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then(function (response) {
        dispatch(setErrorMessage(''));
        // console.log('response.data.detail :>> ', response.data.detail);
        dispatch(setActiveModalName('Password has been changed'));
      })
      .catch(function (error) {
        if (error.response.data.new_password1 && error.response.data.new_password2) {
          dispatch(setErrorMessage('Fields password may not be blank.'));
          console.log('Fields password may not be blank.');
        } else if (error.response.data.new_password1) {
          dispatch(setErrorMessage(error.response.data.new_password1));
          console.log(error.response.data.new_password1);
        } else if (error.response.data.new_password2) {
          dispatch(setErrorMessage(error.response.data.new_password2));
          console.log(error.response.data.new_password2);
        }
      });
  },
);

export const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setUID: (state, action) => {
      state.uid = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
});

export const { setErrorMessage, setUID, setToken } = resetPasswordSlice.actions;
export default resetPasswordSlice.reducer;
