import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './seoBlock.css';
import LoadMore from '../button/LoadMore';

const SeoBlock = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [animCompleate, setAnimCompleate] = useState(false);

  const openText = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const onAnimationComplete = () => {
    console.log('anim ok');
    setAnimCompleate(true);
  };
  const onAnimationStart = () => {
    setAnimCompleate(false);
  };

  return (
    <section className="tipsAndHint home">
      <h2 className="title left">
        AI-Generated Interior Design Visualizations: Transforming the Future of Design
      </h2>
      {/* <motion.div className={isOpen ? 'description active' : 'description'}> */}

      <div className="overflow">
        <div className={animCompleate && !isOpen ? 'opacity active' : 'opacity '}>
          <p>
            Are you looking for innovative ways to visualize your interior design ideas? Look no
            further than AI-generated interior design visualizations. With the advancements in
            artificial intelligence (AI) technology, designers and architects are now able to
            harness the power of AI to create stunning and realistic visualizations of interior
            spaces. These AI-generated images have the potential to revolutionize the way we
            approach interior design, offering new possibilities and streamlining the design
            process.
          </p>
        </div>

        <AnimatePresence initial={false}>
          {isOpen && (
            <motion.div
              className="description"
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 1, height: 0 },
              }}
              transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
              onAnimationComplete={onAnimationComplete}
              onAnimationStart={onAnimationStart}>
              <h3>The Power of AI in Interior Design</h3>
              <p>
                AI software, such as Midjourney, DALL-E, and Stable Diffusion, utilizes neural
                networks to convert text inputs into AI-generated images. This technology has the
                potential to transform the creation and concept stages of designing buildings and
                products. It allows designers to quickly capture their vision and explore ideas in a
                visual format, replacing traditional sketching and idea creation. AI-generated
                imagery is often colorful and dreamlike, providing a unique perspective on design
                possibilities.
              </p>
              <h3>Benefits of AI-Generated Interior Design Visualizations</h3>
              <ul>
                <li>
                  <div className="listTitle">Time Efficiency:</div>
                  AI-generated visualizations can be created in seconds, eliminating the need for
                  time-consuming sketching and idea generation. This allows designers to explore
                  multiple design options more efficiently and dedicate more time to critical
                  thinking aspects of the design process.
                </li>
                <li>
                  <div className="listTitle">Visualization Accuracy: </div>
                  AI-generated images provide a realistic representation of interior spaces,
                  allowing designers and clients to visualize the final result more accurately. This
                  can help in making informed design decisions and ensuring client satisfaction.
                </li>
                <li>
                  <div className="listTitle">Innovation and Creativity: </div>
                  AI has the ability to push the boundaries of what is possible in design. By
                  drawing from a vast database of design elements, AI can create unique and
                  unconventional visualizations that challenge traditional design norms. This opens
                  up new avenues for innovation and creativity in interior design.
                </li>
                <li>
                  <div className="listTitle">Streamlined Design Process: </div>
                  AI can assist in automating mundane production tasks, freeing up designers' time
                  to focus on critical thinking and problem-solving aspects of the design process.
                  This streamlines the overall design process and allows designers to deliver
                  projects more efficiently.
                </li>
              </ul>
              <h4 className="listTitle">The Future of AI in Interior Design</h4>
              <p>
                As AI continues to evolve, its integration into the architectural and interior
                design fields is expected to grow. Designers will be able to leverage AI to generate
                design concepts, material renderings, and even assist in the selection of furniture
                and color schemes. However, it is important to note that AI will not replace human
                creativity and intervention. Designers will still play a crucial role in shaping and
                refining AI-generated visualizations to reach their full potential. In conclusion,
                AI-generated interior design visualizations have the potential to revolutionize the
                way we approach and visualize interior design. With their time efficiency,
                visualization accuracy, and ability to push the boundaries of creativity,
                AI-generated images offer exciting possibilities for designers and clients alike. As
                AI technology continues to advance, we can expect to see further integration of AI
                in the interior design process, streamlining workflows and fostering innovation.
              </p>
              <br />
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <LoadMore
        onClick={() => {
          openText();
        }}
        text={isOpen ? 'Close' : 'read more...'}
      />
    </section>
  );
};

export default SeoBlock;
