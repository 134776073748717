import * as React from "react";
import * as SliderPrimitive from "@radix-ui/react-slider";

import { cn } from "src/lib/utils";

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn(
      "relative flex w-full touch-none select-none items-center overflow-hidden rounded-full",
      className
    )}
    {...props}
  >
    <SliderPrimitive.Track className="relative h-[18px] w-full bg-[#F5F5F5] grow overflow-hidden rounded-full">
      <SliderPrimitive.Range className="absolute h-full slider-bg" />
    </SliderPrimitive.Track>
    <SliderPrimitive.Thumb className="flex items-center justify-center relative h-[18px] w-[18px]  transition-colors disabled:pointer-events-none disabled:opacity-50">
      <span className="block relative z-10 w-full h-full rounded-full border-[1px] border-[#00FFC3] cursor-pointer bg-white"></span>
      <span className="block absolute left-0 top-0 bottom-0 right-auto m-auto w-[50%] h-full bg-green"></span>
      <span className="block absolute left-auto top-0 bottom-0 right-0 m-auto w-[50%] h-full bg-[#F5F5F5]"></span>
    </SliderPrimitive.Thumb>
  </SliderPrimitive.Root>
));
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
