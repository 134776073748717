import React, { useEffect, useState } from "react";
import TitlePage from "../titlePage/TitlePage";
// import ImageBeforeAfterLanding from "../imageBeforeAfter/ImageBeforeAfterLanding";
import CheckModeButtons from "./CheckModeButtons";
import cl from "./LandingPage.module.css";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { MyRangeImages } from "../UI/MyRangeImages/MyRangeImages";

const RoomDesignContainer = ({ type = "", titleText = "" }) => {
  // const sliderRef = useRef(null);
  // const dispatch = useDispatch();

  const [listOfImages, setListOfImages] = useState({});
  // console.log('listOfImages :>> ', listOfImages);
  const importAll = (r) => {
    return r.keys().map(r);
  };

  useEffect(() => {
    const importImages = () => {
      const images_bedroom = importAll(
        require.context("./images/bedroom/", false, /\.(png|jpe?g|svg)$/)
      );
      const dinning_room = importAll(
        require.context("./images/dinning_room/", false, /\.(png|jpe?g|svg)$/)
      );

      const getFileArray = (images) => {
        return images.map((image) => {
          // Разделение пути к изображению и возврат только имени файла
          const imagePathParts = image.split("/");
          const file_path = image;
          const file_name =
            imagePathParts[imagePathParts.length - 1].split(".")[0];
          return { name: file_name, path: file_path };
        });
      };

      setListOfImages({
        // bedroom: { name: getFileNames(images_bedroom), path: getFilePath(images_bedroom) },
        bedroom: getFileArray(images_bedroom),
        dinning_room: getFileArray(dinning_room),
      });
      // setListOfImages([
      //   { bedroom: getFileArray(images_bedroom) },
      //   { dinning_room: getFileArray(dinning_room) },
      // ]);
    };

    importImages();
  }, []);

  const cards = useSelector((state) => state.filters.roomDesign);
  const [isActiveStyle, setIsActiveStyle] = useState(
    "de042ea8-ed29-4aad-8e84-021748562a54"
  );

  // const [onLoadImg, setOnLoadImg] = useState(true);
  const [roomDesignNameActive, setRoomDesignNameActive] = useState(
    type === "staging" ? "staging" : "art-deco"
  );
  const [imageMode, setImageMode] = useState("dinning_room");

  const modeList =
    type !== "staging"
      ? [
          { id: "bedroom", name: "Bedroom" },
          { id: "dinning_room", name: "Dinning room" },
        ]
      : [
          {
            id: "dinning_room",
            name: "Modern",
            image: "/img/staging/t-i1.png",
          },
          {
            id: "bedroom",
            name: "Neoclassic",
            image: "/img/staging/t-i.png",
          },
        ];

  const selectRoomTypeView = (val) => {
    const type_name = val.target.getAttribute("data-name-id");
    // console.log('object :>> ', type_name);
    setImageMode(type_name);
  };

  return (
    <div className={"container"}>
      <div className="roomDesign">
        <div className="title ">
          {/* {listOfImages.bedroom.map((val) => {
            return <img src={val.path} alt={val} />;
          })} */}
          <TitlePage
            title={
              titleText.length > 1
                ? titleText
                : "The art of interiors: explore styles [break] with our visual guides"
            }
            lineBreak={3}
            size={"normal"}
            animation={true}
          />
          <h3>
            Experience effortless ai home transformation using our professional
            tools
          </h3>
        </div>
        <div className="projectModalLanding">
          <div className={!listOfImages[imageMode] ? "skeleton" : ""}></div>
          <div className="overflow">
            {/* <ImageBeforeAfterLanding
              activeType={imageMode}
              listOfImages={listOfImages}
              setOnLoadImg={setOnLoadImg}
              roomDesignNameActive={roomDesignNameActive}
            /> */}
            {listOfImages[imageMode] ? (
              <MyRangeImages
                imageBefore={
                  listOfImages[imageMode].find(
                    (e) => e.name === roomDesignNameActive
                  ).path
                }
                imageAfter={
                  listOfImages[imageMode].find((e) =>
                    type === "staging"
                      ? e.name === "orig"
                      : e.name === "original"
                  ).path
                }
                rangeClass={cl.rangeBlock}
              />
            ) : null}
            <div className="positionRadioButtonBox">
              <CheckModeButtons
                option={"style"}
                groupName={"roomStyle"}
                styling={"whiteCheckBox"}
                modeList={modeList}
                type={"staging"}
                active={imageMode}
                selectRoomTypeView={selectRoomTypeView}
              />
            </div>
          </div>
        </div>
        {type !== "staging" ? (
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={18}
            className={cl.swiperRooms}
          >
            {cards
              .filter(
                (e) => e.name !== "Burning Man" && e.name !== "Scandinavian"
              )
              .map((slide) => (
                <SwiperSlide
                  className={[
                    cl.roomsSlide,
                    slide.id === isActiveStyle ? cl.activeStyle : "",
                  ].join(" ")}
                  onClick={(e) => {
                    setRoomDesignNameActive(
                      slide.name.toLowerCase().replace(/ /gim, "-")
                    );
                    setIsActiveStyle(slide.id);
                  }}
                  key={slide.id}
                >
                  <img
                    src={slide.image}
                    alt={slide.description}
                    id="roomForPaintitAi"
                    className={cl.imageRooms}
                  />
                  <p className={cl.textStyle}>{slide.name}</p>
                </SwiperSlide>
              ))}
          </Swiper>
        ) : null}
      </div>
    </div>
  );
};

export default RoomDesignContainer;
