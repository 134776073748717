export const makeMicrodata = (data) => {
  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    headline: data.title,
    description: data.description,
    image: data.meta_image,
    author: {
      "url": "https://paintit.ai/",
      "@type": "Person",
      name: "Paintit.ai",
    },
    publisher: {
      "@type": "Organization",
      name: "Paintit.ai",
      logo: {
        "@type": "ImageObject",
        url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.eu-startups.com%2Fdirectory%2Fpaintit-ai%2F&psig=AOvVaw1ACSbkG--AW4KzQ5m_452B&ust=1707333401331000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCPC0x9m2l4QDFQAAAAAdAAAAABAE",
      },
    },
    datePublished: data.published_at,
    dateModified: data.published_at,
  });
};
