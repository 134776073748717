import { Link } from "react-router-dom";
import cl from "./MyCrumbs.module.css";
import { usePathname } from "../../../hooks/usePathname";
import { pathToTitle } from "../../../helpers/pathToTitle";

const MyCrumbs = ({ classCrumbs = "" }) => {
  const pathname = usePathname();
  const arrOfPaths = pathname
    .replace(/\//gi, "[b]/[b]")
    .split("[b]")
    .filter(e => e);
  arrOfPaths[arrOfPaths.length - 1] === "/" && arrOfPaths.splice(-1, 1);
  return (
    <div className={`${cl.crumbsWrapper} ${classCrumbs ? "classCrumbs" : ""}`}>
      <ul className={cl.listCrumbs}>
        <li className={cl.itemCrumbs}>
          <Link to={"/"} className={cl.linkCrumbs}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
            >
              <path
                d="M7.3399 0.909905C7.11964 0.712012 6.834 0.602539 6.5379 0.602539C6.2418 0.602539 5.95616 0.712012 5.7359 0.909905L1.3359 4.8635C1.21065 4.97593 1.11046 5.11345 1.04182 5.26713C0.973182 5.4208 0.937639 5.5872 0.9375 5.7555V11.3995C0.9375 11.7178 1.06393 12.023 1.28897 12.248C1.51402 12.4731 1.81924 12.5995 2.1375 12.5995H3.7375C4.05576 12.5995 4.36098 12.4731 4.58603 12.248C4.81107 12.023 4.9375 11.7178 4.9375 11.3995V8.1995C4.9375 8.09342 4.97964 7.99168 5.05466 7.91666C5.12967 7.84165 5.23141 7.7995 5.3375 7.7995H7.7375C7.84359 7.7995 7.94533 7.84165 8.02034 7.91666C8.09536 7.99168 8.1375 8.09342 8.1375 8.1995V11.3995C8.1375 11.7178 8.26393 12.023 8.48897 12.248C8.71402 12.4731 9.01924 12.5995 9.3375 12.5995H10.9375C11.2558 12.5995 11.561 12.4731 11.786 12.248C12.0111 12.023 12.1375 11.7178 12.1375 11.3995V5.7555C12.1375 5.58726 12.102 5.4209 12.0335 5.26723C11.965 5.11356 11.865 4.97601 11.7399 4.8635L7.3399 0.909905Z"
                fill="#686A6C"
              />
            </svg>
          </Link>
        </li>
        {arrOfPaths.map((path, i, arr) =>
          path !== "/" ? (
            <li
              className={[
                cl.itemCrumbs,
                arr.length === i + 1 && cl.disItemCrumbs,
              ].join(" ")}
              key={i}
            >
              <Link to={"/" + path} className={cl.linkCrumbs}>
                {pathToTitle(path)}
              </Link>
            </li>
          ) : (
            <li className={cl.itemCrumbs} key={i}>
              <span>/</span>
            </li>
          )
        )}
      </ul>
    </div>
  );
};

export default MyCrumbs;
