import React from 'react';

const FacebookLoginButton = ({btnClass = ''}) => {
    return (
        process.env.REACT_APP_AUTH_FB &&
        <button
            onClick={() => (window.location.href = `${process.env.REACT_APP_AUTH_FB}`)}
            className={`button transparent ${btnClass}`}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none">
                <path
                    d="M20 12C20 7.58175 16.4183 4 12 4C7.58175 4 4 7.58169 4 12C4 15.993 6.9255 19.3027 10.75 19.9028V14.3125H8.71875V12H10.75V10.2375C10.75 8.2325 11.9444 7.125 13.7717 7.125C14.647 7.125 15.5625 7.28125 15.5625 7.28125V9.25H14.5538C13.5599 9.25 13.25 9.86669 13.25 10.4994V12H15.4688L15.1141 14.3125H13.25V19.9028C17.0745 19.3027 20 15.9931 20 12Z"
                    fill="#1877F2"
                />
                <path
                    d="M15.1141 14.3125L15.4688 12H13.25V10.4994C13.25 9.86662 13.5599 9.25 14.5538 9.25H15.5625V7.28125C15.5625 7.28125 14.647 7.125 13.7717 7.125C11.9444 7.125 10.75 8.2325 10.75 10.2375V12H8.71875V14.3125H10.75V19.9028C11.1635 19.9676 11.5814 20.0001 12 20C12.4186 20.0001 12.8365 19.9676 13.25 19.9028V14.3125H15.1141Z"
                    fill="white"
                />
            </svg>
            Log In with Facebook
        </button>
    );
};

export default FacebookLoginButton;
