export const LinkMiniIcon = (props) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 0.0576172C16.5304 0.0576172 17.0391 0.268331 17.4142 0.643404C17.7893 1.01848 18 1.52718 18 2.05762V16.0576C18 16.5881 17.7893 17.0968 17.4142 17.4718C17.0391 17.8469 16.5304 18.0576 16 18.0576H2C1.46957 18.0576 0.960859 17.8469 0.585786 17.4718C0.210714 17.0968 0 16.5881 0 16.0576V2.05762C0 1.52718 0.210714 1.01848 0.585786 0.643404C0.960859 0.268331 1.46957 0.0576172 2 0.0576172H16ZM15.5 15.5576V10.2576C15.5 9.39301 15.1565 8.56382 14.5452 7.95245C13.9338 7.34108 13.1046 6.99762 12.24 6.99762C11.39 6.99762 10.4 7.51762 9.92 8.29762V7.18762H7.13V15.5576H9.92V10.6276C9.92 9.85762 10.54 9.22762 11.31 9.22762C11.6813 9.22762 12.0374 9.37512 12.2999 9.63767C12.5625 9.90022 12.71 10.2563 12.71 10.6276V15.5576H15.5ZM3.88 5.61762C4.32556 5.61762 4.75288 5.44062 5.06794 5.12556C5.383 4.8105 5.56 4.38318 5.56 3.93762C5.56 3.00762 4.81 2.24762 3.88 2.24762C3.43178 2.24762 3.00193 2.42567 2.68499 2.74261C2.36805 3.05954 2.19 3.4894 2.19 3.93762C2.19 4.86762 2.95 5.61762 3.88 5.61762ZM5.27 15.5576V7.18762H2.5V15.5576H5.27Z"
      fill="#252834"
    />
  </svg>
);
