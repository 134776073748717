export const ImageAttempts = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.51162 4.15398C7.87341 3.94867 10.2486 3.94867 12.6104 4.15398L13.7429 4.25298C14.2694 4.29882 14.7651 4.52054 15.1503 4.88244C15.5355 5.24434 15.7876 5.72534 15.8661 6.24798C16.2062 8.51027 16.2062 10.8107 15.8661 13.073C15.7876 13.5956 15.5355 14.0766 15.1503 14.4385C14.7651 14.8004 14.2694 15.0221 13.7429 15.068L12.6104 15.1662C10.2479 15.3717 7.87337 15.3717 5.51162 15.1662L4.37912 15.068C3.85247 15.0223 3.35654 14.8006 2.97123 14.4387C2.58591 14.0768 2.33366 13.5957 2.25512 13.073C1.91496 10.8107 1.91496 8.51026 2.25512 6.24798C2.33363 5.72534 2.58577 5.24434 2.97093 4.88244C3.35609 4.52054 3.85185 4.29882 4.37837 4.25298L5.51162 4.15398ZM12.5129 5.27523C10.2157 5.07552 7.90553 5.07552 5.60837 5.27523L4.47587 5.37348C4.20119 5.39751 3.94258 5.51325 3.74165 5.70207C3.54072 5.89089 3.40915 6.14182 3.36812 6.41448C3.17527 7.69748 3.09721 8.99512 3.13487 10.292L6.03812 7.38798C6.0923 7.33388 6.15697 7.29142 6.22814 7.26319C6.29931 7.23496 6.3755 7.22155 6.45204 7.2238C6.52857 7.22605 6.60385 7.2439 6.67324 7.27625C6.74264 7.30861 6.8047 7.3548 6.85562 7.41198L9.60962 10.5095L11.5071 9.87648C11.6111 9.84166 11.7231 9.83801 11.8292 9.866C11.9352 9.89398 12.0308 9.95237 12.1041 10.034L14.7434 12.9665C15.0765 10.7955 15.0801 8.58657 14.7539 6.41448C14.7128 6.14171 14.5811 5.89069 14.38 5.70185C14.179 5.51302 13.9202 5.39735 13.6454 5.37348L12.5129 5.27523ZM14.0256 13.8515L11.5139 11.0607L9.61337 11.6937C9.50892 11.7286 9.39654 11.7321 9.29015 11.7037C9.18376 11.6753 9.08804 11.6163 9.01487 11.534L6.41162 8.60523L3.22862 11.7875C3.26537 12.161 3.31112 12.5345 3.36737 12.9057C3.40828 13.1786 3.53989 13.4298 3.74098 13.6188C3.94208 13.8078 4.20094 13.9236 4.47587 13.9475L5.60837 14.0457C7.90562 14.2452 10.2156 14.2452 12.5129 14.0457L13.6454 13.9475C13.7789 13.9355 13.9079 13.9025 14.0256 13.8515Z"
      fill="#171D24"
    />
  </svg>
);
