import React, { useEffect } from "react";
import TitlePage from "../../components/titlePage/TitlePage";
import SelectedHashtag from "../../components/selectedHashtag/SelectedHashtag";
import RoomTypeGrid from "../../components/QuizGroup/RoomTypePage/RoomTypeGrid";
import { useDispatch, useSelector } from "react-redux";
import { setPageType } from "../../toolkit/slices/checkTypePageSlice";
import { Helmet } from "react-helmet-async";
import { setUserToken } from "../../toolkit/slices/authorization/logInSlice";
import { useNavigate } from "react-router-dom";

const RoomType = ({ pageType }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageType(pageType));
    dispatch(setUserToken(localStorage.getItem("key")));
  });

  const navigate = useNavigate();
  const { prompt } = useSelector((state) => state.config);
  useEffect(() => {
    const redirect = (e) => {
      navigate("/interior-ai/mode-options/");
    };

    if (!prompt) {
      redirect();
    }
  }, [navigate, prompt]);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <TitlePage
        h1
        title={`Choose your room type`}
        lineBreak={1}
        size={"normal type"}
        animation={true}
      />
      <SelectedHashtag />
      <RoomTypeGrid />
    </>
  );
};

export default RoomType;
