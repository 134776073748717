import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { setUserToken } from './logInSlice';
import { getUser } from './getUserSlice';
import { updateLocalStorage } from '../../../lib/scrypts';

const initialState = {
  data: {},
};

export const googleAuth = createAsyncThunk('googleAuth/googleAuth', async (data, { dispatch }) => {
  await axios
    .post(`${process.env.REACT_APP_BACKEND_HOST}/api/v1/rest-auth/login/google/`, data)
    .then(function (response) {
      if (response.data.key) {
        dispatch(setUserToken(response.data.key));
        localStorage.setItem('key', response.data.key);
        localStorage.setItem('email', response.data.user_data.email);
        localStorage.setItem('name', response.data.user_data.first_name);
        localStorage.setItem('user', response.data.user_data.id);
        localStorage.setItem('data', JSON.stringify(response.data.user_data));
        dispatch(getUser());
        updateLocalStorage();
      }
    })
    .catch(function (error) {
      console.log(error.response.data);
    });
});

export const socialAuthSlice = createSlice({
  name: 'socialAuth',
  initialState,
  reducers: {
    setGoogleAuthData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setGoogleAuthData } = socialAuthSlice.actions;
export default socialAuthSlice.reducer;
