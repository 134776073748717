import React from 'react';

const LogOutIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
      <path
        d="M11 6.5V5C11 4.60218 10.842 4.22064 10.5607 3.93934C10.2794 3.65804 9.89782 3.5 9.5 3.5H4.25C3.85218 3.5 3.47064 3.65804 3.18934 3.93934C2.90804 4.22064 2.75 4.60218 2.75 5V14C2.75 14.3978 2.90804 14.7794 3.18934 15.0607C3.47064 15.342 3.85218 15.5 4.25 15.5H9.5C9.89782 15.5 10.2794 15.342 10.5607 15.0607C10.842 14.7794 11 14.3978 11 14V12.5"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.25 9.5H16.25M16.25 9.5L14 7.25M16.25 9.5L14 11.75"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LogOutIcon;
