import React, { useState, useEffect } from 'react';
import './titlePage.css';
import { motion } from 'framer-motion';

const TitlePageChanging = ({ h6, size, title, lineBreak, animation }) => {
  const [state, setState] = useState(1);

  useEffect(() => {
    setState(title);
  }, [title]);

  function splitString(str, lineBreak) {
    let variants = {};

    if (animation) {
      variants = {
        hidden: {
          y: '300px',
          opacity: 0,
          display: 'block',
        },
        visible: {
          y: '0px',
          opacity: 1,
          display: 'block',
        },
      };
    }

    const wrapWord = (text, col) => {
      let arr = text.split(/\s+/);
      const words = arr.map((word, index) => {
        return (
          <motion.span
            key={index}
            className={index > col ? 'italic' : 'normal'}
            style={
              word === '[break]'
                ? { flexBasis: '100%', width: 0, height: 0, overflow: 'hidden' }
                : {}
            }
            variants={variants}
            transition={{ type: 'Tween', delay: (0.2 * index) / 3, duration: 0.7 }}>
            {word}&nbsp;
          </motion.span>
        );
      });
      return words;
    };

    const result = (
      <motion.div initial="hidden" animate={'visible'} key={state} className="titleArr">
        {wrapWord(str, lineBreak)}
      </motion.div>
    );
    return result;
  }

  return <div className={`title ${size}`}>{splitString(title, lineBreak)}</div>;
};

export default TitlePageChanging;
