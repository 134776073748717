export const createNewPropmptString = (
  quizArr,
  promptString,
  filters,
  type = "general",
  dataArr = []
) => {
  console.log(dataArr);
  const dataSchemaArr = [
    {
      regExp: /\[roomtype\]/g,
      replacer: quizArr.roomtype.hashtag,
      nestedProperty: null,
    },
    {
      regExp: /\[roomdesign\]/g,
      replacer: quizArr.roomdesignstyle.hashtag,
      nestedProperty: null,
    },
    {
      regExp: /\[palette\]/g,
      replacer: quizArr.colorpalette.hashtag,
      nestedProperty: null,
    },
    {
      regExp: /\[tags\]/g,
      replacer: `Use these tags as optional to the design: ${quizArr.additionaltags.hashtag}`,
      nestedProperty: null,
    },
    {
      regExp: /\[roomtype_description\]/g,
      replacer: quizArr.roomtype.name,
      nestedProperty: "description",
    },
    {
      regExp: /\[palette_description\]/g,
      replacer: quizArr.colorpalette.name,
      nestedProperty: null,
    },
    {
      regExp: /\[roomdesign_description\]/g,
      replacer: quizArr.roomdesignstyle.name,
      nestedProperty: "description",
    },
    {
      regExp: /\[roomtype_description_1\]/g,
      replacer: quizArr.roomtype.name,
      nestedProperty: "description_1",
    },
    {
      regExp: /\[palette_description_1\]/g,
      replacer: quizArr.colorpalette.hashtag,
      nestedProperty: null,
    },
    {
      regExp: /\[roomdesign_description_1\]/g,
      replacer: quizArr.roomdesignstyle.name,
      nestedProperty: "description_1",
    },
    // {
    //   regExp: /\[roomtype_description_2\]/g,
    //   replacer: quizArr.roomtype.name,
    //   nestedProperty: "description_2",
    // },
    // {
    //   regExp: /\[palette_description_2\]/g,
    //   replacer: quizArr.colorpalette.name,
    //   nestedProperty: "description_2",
    // },
    {
      regExp: /\[roomdesign_description_2\]/g,
      replacer: quizArr.roomdesignstyle.name,
      nestedProperty: "description_2",
    },
    ...dataArr,
  ];

  const getDescriptionForPrompt = (hashtag, description) => {
    if (description !== null) {
      const descr = filters.find((val) => val.name === hashtag);
      if (descr && description in descr) return hashtag[`${description}`];
    }
    return hashtag;
  };

  let res = dataSchemaArr.reduce(
    (prmpt, repl) =>
      type !== "general" && !repl.replacer
        ? prmpt
        : prmpt.replace(
            repl.regExp,
            getDescriptionForPrompt(repl.replacer, repl.nestedProperty)
          ),
    promptString
  );

  return res;
};

export const collectDataForProject = (states) => {
  const {
    imageBase64,
    imagePaintBase64,
    imageHeight,
    imageWidth,
    ...generalInfo
  } = states.generalInfo;
  let body = {};
  body.prompt = createNewPropmptString(
    states.hashTag,
    states.prompts.prompt,
    states.filters
  );
  body.negative_prompt = createNewPropmptString(
    states.hashTag,
    states.prompts.promptNegative,
    states.filters
  );
  if (states.hashTag.colorpalette.name.length > 1) {
    body.tags = [
      { name: states.hashTag.roomtype.hashtag, tag_type: 0 },
      { name: states.hashTag.roomdesignstyle.hashtag, tag_type: 1 },
      { name: states.hashTag.colorpalette.name, tag_type: 2 },
    ];
  } else {
    body.tags = [
      { name: states.hashTag.roomtype.hashtag, tag_type: 0 },
      { name: states.hashTag.roomdesignstyle.hashtag, tag_type: 1 },
    ];
  }

  if (states.generalInfo.imageBase64) {
    if (states.generalInfo.imagePaintBase64) {
      body.image = states.generalInfo.imagePaintBase64;
      // body.prompt = body.prompt + 'Replace all black lines in the photo with similar decor, interior elements. If the lines are not accurate, just delete them.';
    } else body.image = states.generalInfo.imageBase64;
  }
  if (
    states.generalInfo.imageHeight !== 0 &&
    states.generalInfo.imageWidth !== 0
  ) {
    body.width = ~~states.generalInfo.imageWidth;
    body.height = ~~states.generalInfo.imageHeight;
  }

  if (generalInfo.guidanceScale) {
    body.guidance_scale = generalInfo.guidanceScale;
  }
  if (generalInfo.imageStrength) {
    body.image_strength = generalInfo.imageStrength;
  }
  if (generalInfo.photorealism) {
    body.photorealism = generalInfo.photorealism;
  }

  body.prompt_type = states.generalInfo.uploadType;
  return body;
};
