import React, { useEffect, useState } from 'react';
import ConfirmIcon from '../../icon/confirm/ConfirmIcon';

const ProgressLine = ({ percentage }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < percentage) {
        setCount(count + 1);
      } else {
        clearInterval(interval);
      }
    }, 10); // Интервал в миллисекундах

    return () => {
      clearInterval(interval);
    };
  }, [count, percentage]);

  return (
    <div className="uploadLine">
      {count === 100 ? <ConfirmIcon /> : <span>{count}%</span>}
      <div style={{ width: `${count}%` }} className="loading"></div>
    </div>
  );
};

export default ProgressLine;
