import React from 'react';

const BoxIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="mdi:box-variant">
        <path
          id="Vector"
          d="M3.20074 16.461C3.02892 16.3665 2.87792 16.2384 2.75678 16.0842C2.63565 15.93 2.54686 15.7529 2.49574 15.5637C2.44461 15.3744 2.4322 15.1767 2.45925 14.9825C2.48629 14.7883 2.55224 14.6015 2.65314 14.4334L4.87314 10.6002C5.03594 10.3042 5.28754 10.097 5.56874 9.9786L17.1571 3.4666C17.3939 3.289 17.6899 3.2002 18.0007 3.2002C18.3115 3.2002 18.6075 3.289 18.8443 3.4666L30.5363 10.0378C30.8175 10.1858 31.0543 10.4226 31.1875 10.7186L33.3335 14.4482C33.7479 15.1586 33.4963 16.0614 32.8007 16.461L31.3207 17.3194V24.6602C31.3207 25.2226 31.0099 25.711 30.5363 25.9626L18.8443 32.5338C18.6075 32.7114 18.3115 32.8002 18.0007 32.8002C17.6899 32.8002 17.3939 32.7114 17.1571 32.5338L5.46514 25.9626C5.22786 25.8379 5.02929 25.6506 4.89101 25.421C4.75272 25.1914 4.67999 24.9282 4.68074 24.6602V16.461C4.23674 16.7126 3.67434 16.7274 3.20074 16.461ZM18.0007 6.3822V16.2982L26.8215 11.3402L18.0007 6.3822ZM7.64074 23.787L16.5207 28.7894V18.8586L7.64074 13.871V23.787ZM28.3607 23.787V19.0214L20.9607 23.3134C20.4723 23.5798 19.9247 23.565 19.4807 23.3282V28.7894L28.3607 23.787ZM20.7387 20.013L30.0331 14.6406L29.1747 13.1458L19.8803 18.5182L20.7387 20.013Z"
          fill="black"
        />
      </g>
    </svg>
  );
};

export default BoxIcon;
