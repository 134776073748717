import React from 'react';
import ReactPaginate from 'react-paginate';
import './pagination.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  getHomePortfolio,
  getUserPortfolio,
  setPaginationNumPage,
} from '../../toolkit/slices/account/portfolioUserSlice';
import ArrowNext from '../icon/ArrowNext';
import ArrowPrew from '../icon/ArrowPrew';
import { scrollToSection } from '../../lib/scrypts';

function PaginatedItems({ itemsPerPage, pageType }) {
  const dispatch = useDispatch();
  const countItems = useSelector((state) => state.portfolioUser.pagination.count);
  const curentPage = useSelector((state) => state.portfolioUser.pagination.numPage);
  const items = [...Array(countItems).keys()];
  const pageCount = Math.ceil(items.length / itemsPerPage);

  const handlePageClick = (event) => {
    scrollToSection('projectsGrid');
    console.log('event.selected :>> ', event.selected);
    if (pageType === 'account') {
      dispatch(setPaginationNumPage(event.selected));
      dispatch(getUserPortfolio());
    } else {
      dispatch(setPaginationNumPage(event.selected));
      dispatch(getHomePortfolio());
    }
  };

  return (
    <>
      {pageCount > 1 && (
        <ReactPaginate
          forcePage={curentPage}
          breakLabel="..."
          nextLabel={<ArrowNext />}
          previousLabel={<ArrowPrew />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          renderOnZeroPageCount={null}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
        />
      )}
    </>
  );
}

const Pagination = ({ pageType }) => {
  return (
    <div className="row center">
      <PaginatedItems pageType={pageType} itemsPerPage={10} />
    </div>
  );
};

export default Pagination;
