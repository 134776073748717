import React from 'react';
import { motion } from 'framer-motion';
import './projectModal.css';
import { useLocalStorage } from '../../lib/scrypts';
import { Link } from 'react-router-dom';

const CookiesPolicyModal = () => {
  const [storedValue, setStoredValue] = useLocalStorage('ck', true);

  const variants = {
    hidden: {
      y: '150%',
      x: '-50%',
      opacity: 0,
    },
    visible: {
      y: '0%',
      x: '-50%',
      opacity: 1,
      transition: { type: 'Tween', delay: 5 },
    },
  };

  return (
    <>
      <motion.div
        initial="hidden"
        animate={storedValue ? 'visible' : 'hidden'}
        variants={variants}
        className="cookiesPolicy">
        <div className="textBlock">
          We use essential cookies to make our site work. With your consent, we may also use
          non-essential cookies to improve user experience, personalize content, and analyze website
          traffic. For these reasons, we may share your site usage data with our social media and
          analytics partners. By clicking “Accept,“ you agree to our website's cookie use as
          described in our{' '}
          <u>
            <Link to="/privacy-policy/" target="_blank" rel="noreferrer">
              Cookie Policy
            </Link>
          </u>
          . You can change your cookie settings at any time on your browser preferences.
        </div>
        <div className="buttonBlock">
          <button className="button white transperent" onClick={() => setStoredValue(false)}>
            Accept
          </button>
        </div>
      </motion.div>
    </>
  );
};

export default CookiesPolicyModal;
