import ReactGA from 'react-ga4';

export const gtmEvent = (eventName, eventCategory) => {
  ReactGA.initialize([
    {
      trackingId: 'G-LXLFR4X745',
    },
  ]);
  // Send a custom event
  ReactGA.event({
    category: eventCategory,
    action: eventName,
  });
};

export const gtmEventProduct = ( itemInfo, eventName = 'purchase', eventCategory = "category" ) => {
  ReactGA.initialize([
    {
      trackingId: 'G-LXLFR4X745',
    },
  ]);

  // Send a custom event
  ReactGA.event({
    category: eventCategory,
    action: eventName,
    transaction_id: 'T_12345',
    ...itemInfo,
    // value: visValue,
    // currency: 'USD',
    // coupon: 'SUMMER_SALE',
    // items: [
    //   {
    //     item_id: 'SKU_12345',
    //     item_name: 'Stan and Friends Tee',
    //     affiliation: 'Google Merchandise Store',
    //     coupon: 'SUMMER_FUN',
    //     discount: 2.22,
    //     index: 0,
    //     item_brand: 'Google',
    //     item_category: 'Apparel',
    //     item_category2: 'Adult',
    //     item_category3: 'Shirts',
    //     item_category4: 'Crew',
    //     item_category5: 'Short sleeve',
    //     item_list_id: 'related_products',
    //     item_list_name: 'Related Products',
    //     item_variant: 'green',
    //     location_id: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
    //     price: 9.99,
    //     quantity: 1,
    //   },
    // ],
  });
};
