import React, { useState } from "react";
import TitlePage from "../titlePage/TitlePage";
import { sanitizeHTML } from "../../helpers/sanitizeHtml";

const QuestionsContainer = ({
  data = [
    {
      title: "What is Paintit.ai?",
      text: "Paintit.ai is an AI-driven interior design service, designed to revolutionize the way you approach space design. Leveraging the latest in AI technology, our platform offers personalized, innovative design solutions that capture your unique vision and style preferences. From residential to commercial spaces, Paintit.ai ensures a blend of creativity and precision in every project.",
    },
    {
      title: "Is Paintit.ai free to use?",
      text: `We offer a basic 'Free' plan to get you started with limited features. To access more advanced capabilities and enjoy a full range of design options, we provide 'Personal' and 'Pro' subscription plans. Visit our pricing section for detailed information on each plan.`,
    },
    {
      title: "How does Paintit.ai work?",
      text: `Paintit.ai harnesses the power of advanced AI technology, complemented by your personal input, to create bespoke interior designs. Begin by selecting the type of space and your preferred style, then upload photos of your area. Our AI intelligently analyzes your preferences to generate initial design concepts. These concepts can then be refined and tailored to perfectly align with your individual taste and vision.`,
    },
  ],
}) => {
  const questionsList = data;

  // const [accordionActive, setAccordionActive] = useState();
  const [activeIndex, setActiveIndex] = useState(null);

  const accordionHandler = (e, index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="container">
      <div className="questions-container">
        <div className="questions-container_title">
          <TitlePage
            title={`Your questions [break] answered`}
            lineBreak={1}
            size={"normal"}
            animation={true}
          />
        </div>
        <div className="questions-container_accordion">
          <div className="accordion-container">
            <div className="accordion-container__items">
              {questionsList.map((item, index) => {
                return (
                  <div
                    className="accordion-container__item item-accordion"
                    key={index}
                  >
                    <div className="item-accordion_title">
                      <button onClick={(e) => accordionHandler(e, index)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M11.0003 14.5L5.07534 13.75C4.65868 13.7 4.38768 13.475 4.26234 13.075C4.13701 12.675 4.22468 12.3333 4.52534 12.05L14.7503 2.25C14.8337 2.16667 14.9337 2.10433 15.0503 2.063C15.167 2.02167 15.3253 2.00067 15.5253 2C15.8587 2 16.113 2.14167 16.2883 2.425C16.4637 2.70833 16.4677 3 16.3003 3.3L13.0003 9.5L18.9253 10.25C19.342 10.3 19.613 10.525 19.7383 10.925C19.8637 11.325 19.776 11.6667 19.4753 11.95L9.25034 21.75C9.16701 21.8333 9.06701 21.896 8.95034 21.938C8.83368 21.98 8.67534 22.0007 8.47534 22C8.14201 22 7.88768 21.8583 7.71234 21.575C7.53701 21.2917 7.53301 21 7.70034 20.7L11.0003 14.5Z"
                            fill="black"
                          />
                        </svg>
                        {item.title}
                        <div
                          className={`item-accordion_arrow ${
                            activeIndex === index ? "active" : ""
                          }`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M13.1992 8.39941L10.4106 11.188C10.1834 11.4152 9.81503 11.4152 9.58781 11.188L6.79922 8.39941"
                              stroke="black"
                              strokeWidth="0.872727"
                            />
                          </svg>
                        </div>
                      </button>
                    </div>
                    <div
                      className={`item-accordion_text  ${
                        activeIndex === index ? "active" : ""
                      }`}
                    >
                      <div className={`item-accordion_text_content`}>
                        <p
                          dangerouslySetInnerHTML={sanitizeHTML(item.text)}
                        ></p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionsContainer;
