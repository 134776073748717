import React from "react";
import { gtmEvent } from "../../lib/gtm";

const GoogleLoginButton = ({ btnClass = "" }) => {
  return (
    process.env.REACT_APP_AUTH_GOOGLE && (
      <button
        onClick={() => {
          window.location.href = `${process.env.REACT_APP_AUTH_GOOGLE}`;
          gtmEvent('Log_In_with_Google', "category");

        }}
        className={`button transparent ${btnClass}`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M19.8444 10.4332H19.2V10.4H12V13.6H16.5212C15.8616 15.4628 14.0892 16.8 12 16.8C9.3492 16.8 7.2 14.6508 7.2 12C7.2 9.3492 9.3492 7.2 12 7.2C13.2236 7.2 14.3368 7.6616 15.1844 8.4156L17.4472 6.1528C16.0184 4.8212 14.1072 4 12 4C7.582 4 4 7.582 4 12C4 16.418 7.582 20 12 20C16.418 20 20 16.418 20 12C20 11.4636 19.9448 10.94 19.8444 10.4332Z"
            fill="#FFC107"
          />
          <path
            d="M4.92578 8.2764L7.55418 10.204C8.26538 8.4432 9.98778 7.2 12.0034 7.2C13.227 7.2 14.3402 7.6616 15.1878 8.4156L17.4506 6.1528C16.0218 4.8212 14.1106 4 12.0034 4C8.93058 4 6.26578 5.7348 4.92578 8.2764Z"
            fill="#FF3D00"
          />
          <path
            d="M11.997 20C14.0634 20 15.941 19.2092 17.3606 17.9232L14.8846 15.828C14.0544 16.4593 13.04 16.8008 11.997 16.8C9.9162 16.8 8.1494 15.4732 7.4838 13.6216L4.875 15.6316C6.199 18.2224 8.8878 20 11.997 20Z"
            fill="#4CAF50"
          />
          <path
            d="M19.8444 10.4331H19.2V10.3999H12V13.5999H16.5212C16.2057 14.4865 15.6373 15.2612 14.8864 15.8283L14.8876 15.8275L17.3636 17.9227C17.1884 18.0819 20 15.9999 20 11.9999C20 11.4635 19.9448 10.9399 19.8444 10.4331Z"
            fill="#1976D2"
          />
        </svg>
        Log In with Google
      </button>
    )
  );
};

export default GoogleLoginButton;
