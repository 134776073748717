export const CollageIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    {...props}
  >
    <path
      d="M2.5 0C1.39 0 0.5 0.89 0.5 2V16C0.5 17.11 1.39 18 2.5 18H8.5V0M10.5 0V8H18.5V2C18.5 0.89 17.61 0 16.5 0M10.5 10V18H16.5C17.61 18 18.5 17.11 18.5 16V10"
      fill="white"
    />
  </svg>
);
