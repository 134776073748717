import { Swiper, SwiperSlide } from "swiper/react";
import cl from "./TestimonialsComp.module.css";

export const TestimonialsSwiper = ({ dataReviews, ...props }) => {
  return (
    <Swiper
      initialSlide={2}
      grabCursor={true}
      spaceBetween={23}
      breakpoints={{
        300: {
          slidesPerView: 1.2,
          centeredSlides: true,
        },
        576: {
          slidesPerView: "auto",
        },
      }}
      loop
      {...props}
    >
      {dataReviews.map((slide) => (
        <SwiperSlide key={slide.name} className={cl.reviewSlide}>
          <div className={cl.slideHead}>
            <img
              src={"/img/reviews/" + slide.img}
              alt="Person"
              className={cl.svgIcon}
            />
            <div className={cl.personInfo}>
              <div className={cl.personName}>{slide.name}</div>
              <div className={cl.personJob}>{slide.profession}</div>
            </div>
          </div>
          <p className={cl.reviewText}>{slide.text}</p>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
