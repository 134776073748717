import React from 'react';
import './button.css';

const LoadMore = ({ onClick, text }) => {
  return (
    <div onClick={onClick} className="loadMoreBtn">
      <button>{text}</button>
    </div>
  );
};

export default LoadMore;
