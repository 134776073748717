import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { patchUser } from '../../toolkit/slices/account/changeInfoUserSlice';
import ConfirmIcon from '../icon/confirm/ConfirmIcon';
import { getUserInfoAccount } from '../../toolkit/slices/account/infoUserSlice';

const NameForm = () => {
  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.changeInfoUser.errorMessage);
  const animation = useSelector((state) => state.changeInfoUser.animation);
  const userName = localStorage.getItem('name');

  const [formData, setFormData] = useState({
    first_name: userName,
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(patchUser(formData));
    dispatch(getUserInfoAccount());
    localStorage.setItem('name', formData.first_name);
  };
  return (
    <form className="account" onSubmit={handleSubmit}>
      <div className="inputBox">
        <div className="title">your Name</div>
        <input
          type="text"
          placeholder="Username"
          value={formData.first_name}
          onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
          className={errorMessage && 'error'}
        />
      </div>
      <button className="" type="submit">
        {animation ? <ConfirmIcon /> : 'Save change'}
      </button>
      <div className="errorMessage">{errorMessage}</div>
    </form>
  );
};

export default NameForm;
