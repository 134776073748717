import { api } from "./api";

export const blogsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBlogs: build.query({
      query: ({ page, size }) => `/blog/posts/?page=${page}&page_size=${size}`,
      providesTags: (_result, _err) => [{ type: "Blogs" }],
    }),
    getBlogsBySlug: build.query({
      query: (slug) => `/blog/posts/${slug}/`,
      providesTags: (_result, _err) => [{ type: "Blogs" }],
    }),
  }),
});

export const { useGetBlogsQuery, useGetBlogsBySlugQuery } = blogsApi;
