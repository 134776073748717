import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  pictures: [],
  loading: false,
  data: null,
  redirect: false,
};

// request that returns a data from chatGPT
export const postImages = createAsyncThunk(
  "postImages/postImages",
  async (_, { getState }) => {
    const { pictures } = getState().selectedImages;

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/images/suggestion/`,
        { images: pictures }
      );
      return res.data;
    } catch (error) {
      console.log("error:", error);
    }
  }
);

export const selectImagesSlice = createSlice({
  name: "selectedImages",
  initialState,
  reducers: {
    setImages: (state, action) => {
      state.pictures = [...state.pictures, action.payload];
    },
    removeImage: (state, action) => {
      state.pictures = state.pictures.filter(
        (image) => image !== action.payload
      );
    },
    clearState: (state, action) => {
      state.redirect = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(postImages.pending, (state) => {
        state.loading = true;
      })
      .addCase(postImages.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.redirect = true;
      })
      .addCase(postImages.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { setImages, removeImage, clearState } = selectImagesSlice.actions;
export default selectImagesSlice.reducer;
